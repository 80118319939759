// ContactFormComponent.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import DataProtectionInformationContactForm from './DataProtectionInformationContactForm';

const ContactFormComponent = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        acceptPrivacyPolicy: false
    });

    const [formSubmitted, setFormSubmitted] = useState(false);  // Estat per controlar si el formulari s'ha enviat

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.acceptPrivacyPolicy) {
            alert('Heu d\'acceptar la Política de Privacitat.');
            return;
        }

        axios.post(`${apiUrl}/contact/send-message`, formData)
            .then(response => {
                console.log('Contact message submitted:', formData);
                setFormSubmitted(true);  // Actualitza l'estat quan el formulari s'ha enviat correctament
            })
            .catch(error => {
                console.error('Hi ha hagut un error enviant el formulari:', error);
                alert('Hi ha hagut un error enviant el vostre missatge.');
            });
    };

    return (
        <div className="form-container">
            {/* Mostrar la vista de confirmació si el formulari s'ha enviat */}
            {formSubmitted ? (
                <div className="confirmation-message">
                    <h2>{t('thank_you')}</h2>
                    <p>{t('your_message_has_been_sent')}</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="register-form">
                    <h2>{t('contact_us')}</h2>
                    <p>{t('contact_form_explanation')}</p>
                    <label className="form-label">
                        {t('your_name')}
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="input-field"
                    />
                    <label className="form-label">
                        {t('your_email')}
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="input-field"
                    />
                    <label className="form-label">
                        {t('your_message')}
                    </label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        className="input-field wide-input"
                        rows="4"
                    />
                    
                    <DataProtectionInformationContactForm formData={formData} handleChange={handleChange} fontSize="0.8em" />
                    
                    <button type="submit" className="button-center">{t('submit')}</button>
                </form>
            )}
        </div>
    );
};

export default ContactFormComponent;