// TwoAFCexperiment.jsx
import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from './../../auth-and-backend/auth/authContext.js';
import { useTranslation } from 'react-i18next';
import userSpecificData from './userSpecificData';
import createRandomPairs from '../../shared-files/tools-and-functions/createRandomPairs.js';
import useAudioPlayerCallback from '../../shared-files/tools-and-functions/useAudioPlayerCallback';
import calculateSampleRanking from './CalculateSampleRanking.js';

  const TwoAFCexperiment = () => {
  const { user } = useAuth();
  const username = user.username;
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [samplePairs, setSamplePairs] = useState([]);
  const [currentPairIndex, setCurrentPairIndex] = useState(0);
  const [userResponses, setUserResponses] = useState([]);
  const { playAudio, stopAudio } = useAudioPlayerCallback();
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [hasExperimentStarted, setHasExperimentStarted] = useState(false);
  const [showBreakMessage, setShowBreakMessage] = useState(false);
  const [audioStartTime, setAudioStartTime] = useState(null);
  const playAudioWithTimeTracking = (audioSrc, callback) => {
    playAudio(audioSrc, {
      onStarted: () => {
        setAudioStartTime(Date.now()); // Set the start time when audio actually starts
      },
      onEnded: callback
    });
  };  

  const currentPair = useMemo(() => {
    return samplePairs[currentPairIndex] || [];
  }, [samplePairs, currentPairIndex]);

  useEffect(() => {
    const userData = userSpecificData[user.username];
    const attribute = userData?.attributes?.[0];
    if (attribute) {
      setTitle(t(attribute.title));
      if (attribute.audioFilesSets) {
        const samples = attribute.audioFilesSets.flat();
        const pairs = createRandomPairs(samples);
        setSamplePairs(pairs);
      }
    }
  }, [user.username, t]);  

  // play the samples one after the other
  const [isPlaying, setIsPlaying] = useState(false);
  
  // Function declarations
  const handleStart = () => {
    setIsPlaying(true);
    setAudioStartTime(Date.now());
    setHasExperimentStarted(true);
  
    playAudio(currentPair[0], {
        onEnded: () => {
            setTimeout(() => {
                playAudio(currentPair[1]);
            }, 500);
        }
    });
  };
  
  const handleReplay = () => {
    setIsPlaying(true);  // Start playing, set isPlaying to true
    playAudio(currentPair[0], {
      onEnded: () => {
        setTimeout(() => {
          playAudio(currentPair[1], {
            onEnded: () => {
              setIsPlaying(false);  // Finished playing both, set isPlaying to false
            }
          });
        }, 500); // Adjust this delay as necessary
      }
    });
  };    

  const handleUserSelection = (response) => {
    // Assuming 'response' is passed as either "1" or "2"
    const formattedResponse = response === "1" ? "Sample 1" : "Sample 2";
    setSelectedResponse(formattedResponse);
};

  const handleOkButtonClick = () => {
    if (selectedResponse !== null) {
      stopAudio(); // Stop the current audio
  
      // Get the current samples
      const extractSampleName = (filePath) => filePath.split('/').pop().split('.')[0];
      const sample1 = extractSampleName(currentPair[0]); // Use 'sample1' instead of 'sample1Name'
      const sample2 = extractSampleName(currentPair[1]); // Use 'sample2' instead of 'sample2Name'
      
      // Calculate confirmation time
      setAudioStartTime(Date.now());  // Reset the start time for the next pair
      const confirmationTime = Date.now() - audioStartTime;
      if (typeof confirmationTime !== 'number' || isNaN(confirmationTime)) {
        console.error("Invalid confirmation time", confirmationTime);
        return; // or handle the error appropriately
      }
  
      // Record the user's response along with the samples and confirmation time
      setUserResponses([...userResponses, { 
        pair: currentPairIndex, 
        sample1, 
        sample2,
        response: selectedResponse, 
        confirmationTime 
      }]);
  
      // Clear the current selection
      setSelectedResponse(null);
  
      // Check if a break is needed after this pair
      if ((currentPairIndex + 1) % 25 === 0) {
        setShowBreakMessage(true);
      }
  
      // Move to the next pair
      if (currentPairIndex < samplePairs.length - 1) {
        const nextPairIndex = currentPairIndex + 1;
        setCurrentPairIndex(nextPairIndex);
  
        // Automatically start playing the next pair after a short delay
        setTimeout(() => {
          setIsPlaying(true);
          const nextPair = samplePairs[nextPairIndex];
          playAudioWithTimeTracking(nextPair[0], () => { // Ensure you use playAudioWithTimeTracking here
            setTimeout(() => {
              playAudioWithTimeTracking(nextPair[1], () => { // And also here
                setIsPlaying(false);
              });
            }, 500); // Delay between first and second sample
          });
        }, 500); // Delay before starting the next pair
      } else {
        // All pairs are completed. Handle the completion
        console.log('Experiment completed. Responses:', userResponses);
        // Implement submission logic or navigation to result page
      }
    }
  };  

  // Additional variables and constants
  const progressText = `${currentPairIndex + 1} / ${samplePairs.length}`;
  
  const handleContinue = () => {
    setShowBreakMessage(false);
    setAudioStartTime(Date.now());  // Reset the start time after the break
    // Optionally, automatically start the next pair or let the rater start manually
  };

  const handleDownloadResults = () => {
    // Define the CSV header with columns separated by commas
    let csvContent = "Pair,Sample1,Sample2,Response,ConfirmationTime\n";
  
    // Iterate through userResponses and append data to CSV content with commas
    userResponses.forEach((item) => {
      const row = `${item.pair},${item.sample1},${item.sample2},${item.response},${item.confirmationTime}`;
      csvContent += row + "\n";
    });
  
    // Create a Blob and initiate the download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const fileName = `${username}_${title}_responses.csv`; // Dynamic filename
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    };  

   // Conditional rendering
   if (!samplePairs.length) return <div>{t('loading')}</div>;
   if (showBreakMessage) {
     return (
       <div>
         <p>{t('break_message')}</p>
         <button onClick={handleContinue}>{t('continue')}</button>
       </div>
     );
   }

  // Function to handle CSV download
  const handleDownloadRanking = () => {
    // Calculate the sample ranking
    const sampleRanking = calculateSampleRanking(userResponses);

    // Create CSV content from the sampleRanking data
    const csvContent = sampleRanking.map((item) => `${item.sample}, ${item.count}`).join('\n');

    // Create a Blob and initiate the download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const fileName = `${username}_${title}_ranking.csv`; // Dynamic filename
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <h2>{title}</h2>
      <br />
      <p>{t('2-AFC_test_instructions')}</p>
      <br />
      <div className="progress-container">
        <div className="progress-bar" style={{ width: `${(currentPairIndex + 1) / samplePairs.length * 100}%` }}></div>
      </div>
      <p>{progressText}</p>
      <br />
      <div>
      {!hasExperimentStarted && (
        <button onClick={handleStart} disabled={isPlaying}>{t('click_to_start')}</button>
      )}
        <button onClick={handleReplay} disabled={isPlaying}>{t('click_to_replay')}</button>
      </div>
      <div>
      <button onClick={() => handleUserSelection('Sample 1')} 
                disabled={selectedResponse === 'Sample 1'}>
          {t('choose_sample_1')}
        </button>
        <button onClick={() => handleUserSelection('Sample 2')} 
                disabled={selectedResponse === 'Sample 2'}>
          {t('choose_sample_2')}
        </button>
        {selectedResponse !== null && (
        <button onClick={handleOkButtonClick} disabled={selectedResponse === null}>{t('confirm_selection')}</button>
      )}
      </div>
      {/* Conditionally render the Download Results button */}
      {currentPairIndex === samplePairs.length - 1 && (
        <button onClick={handleDownloadResults}>{t('download_responses')}</button>
      )}
      {currentPairIndex === samplePairs.length - 1 && (
        <button onClick={handleDownloadRanking}>{t('download_ranking')}</button>
      )}
    </div>
  );
};

  export default TwoAFCexperiment;