// JoinAnchors.jsx

import React from 'react'; 
import { useTranslation } from 'react-i18next';

const JoinAnchors = () => {
    const { t } = useTranslation();
  return (
    <div>
      <h1>{t('contribute_to_future_perceptual_anchors')}</h1>
      <h2 class="red-title">{t('available_soon')}</h2>
      {/* Contingut */}
    </div>
  );
};

export default JoinAnchors;