// PairComparison.jsx

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useAudioPlayerCallback from '../shared-files/tools-and-functions/useAudioPlayerCallback';

const PairComparison = ({ evaluatedAttribute, samplePairs, onCompletion }) => {
  const { t } = useTranslation();
  const [currentPairIndex, setCurrentPairIndex] = useState(0);
  const [selectedSample, setSelectedSample] = useState(null);
  const [isExperimentStarted, setIsExperimentStarted] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const { playAudio } = useAudioPlayerCallback();
  const [selectedPairs, setSelectedPairs] = useState([]);

  const playCurrentPair = useCallback(async () => {
    setIsAudioPlaying(true);
    try {
      await playAudio(samplePairs[currentPairIndex][0].audioLink);
      await playAudio(samplePairs[currentPairIndex][1].audioLink);
    } catch (error) {
      console.error('Error playing audio:', error);
    }
    setIsAudioPlaying(false);
  }, [currentPairIndex, playAudio, samplePairs]);

  useEffect(() => {
    // Assegura't que l'efecte no s'executi fins que l'usuari premi "Start"
  }, []); // Depèn de si necessites algun efecte inicial.

  const startExperiment = useCallback(() => {
    if (!isExperimentStarted) {
      setIsExperimentStarted(true);
      playCurrentPair(); // S'ha mogut dins de useCallback per evitar dependències innecessàries.
    }
  }, [isExperimentStarted, playCurrentPair]);

  const handleConfirmSelection = useCallback(() => {
    if (selectedSample !== null) {
      const currentPair = samplePairs[currentPairIndex];
      const index = selectedSample - 1; // Adjust if selectedSample is already zero-based.
      const selectedSampleData = currentPair[index];
  
      if (!selectedSampleData) {
        console.error("Selected sample data is undefined.");
        return;
      }
  
      // Include both samples in the pair along with the selected sample information
      const newSelection = {
        pair: currentPair.map(sample => ({
          sampleName: sample.sampleName,
          audioLink: sample.audioLink,
          collectionId: sample.collectionId,
        })),
        selectedSample: {
          sampleName: selectedSampleData.sampleName,
          audioLink: selectedSampleData.audioLink,
          collectionId: selectedSampleData.collectionId,
        },
      };
  
      const updatedSelectedPairs = [...selectedPairs, newSelection];
    
      if (currentPairIndex < samplePairs.length - 1) {
        setCurrentPairIndex(currentPairIndex + 1);
        setSelectedPairs(updatedSelectedPairs);
        setSelectedSample(null);
      } else {
        onCompletion({ userResponses: updatedSelectedPairs });
        setIsExperimentStarted(false);
      }
    }
  }, [selectedSample, currentPairIndex, samplePairs, onCompletion, selectedPairs]);
  
  

  const handleUserSelection = (sample) => {
    setSelectedSample(sample);
  };

  return (
    <div>
      <h2>{evaluatedAttribute}</h2>
      <p>{t('training_pair_comparison_instructions')}</p>
      <div className="progress-container">
        <div className="progress-bar" style={{ width: `${(currentPairIndex + 1) / samplePairs.length * 100}%` }}></div>
      </div>
      <p>{t('progress')}: {currentPairIndex + 1} / {samplePairs.length}</p>
      {!isExperimentStarted ? (
        <button onClick={startExperiment}>{t('click_to_start')}</button>
      ) : (
        <button onClick={playCurrentPair} disabled={isAudioPlaying}>{t('click_to_replay')}</button>
      )}
      {isExperimentStarted && (
        <>
          <button onClick={() => handleUserSelection(1)} disabled={false}>{t('choose_sample_1')}</button>
          <button onClick={() => handleUserSelection(2)} disabled={false}>{t('choose_sample_2')}</button>
          <button onClick={handleConfirmSelection} disabled={selectedSample === null}>{t('confirm_selection')}</button>
        </>
      )}
    </div>
  );
};

export default PairComparison;