// calculateSampleRanking.js

function calculateSampleRanking(userResponses) {
  const sampleCounts = {};

  // Initialize counts for all samples to ensure they are included in the ranking
  userResponses.forEach(({ sample1, sample2 }) => {
    if (!sampleCounts[sample1]) sampleCounts[sample1] = 0;
    if (!sampleCounts[sample2]) sampleCounts[sample2] = 0;
  });

  // Increment counts only for the selected samples
  userResponses.forEach((response) => {
    const selectedSample = response.response === "Sample 1" ? response.sample1 : response.sample2;
    sampleCounts[selectedSample]++;
  });

  const ranking = Object.entries(sampleCounts).map(([sample, count]) => {
    const audioLink = `samples/PVQD-sustained-vowels/${sample}.wav`; // Full path to the audio file
    return {
      sampleName: sample, // Sample name without path
      samplePath: audioLink, // Full path to the audio file
      count,
    };
  });

  ranking.sort((a, b) => a.count - b.count);

  return ranking;
}

export default calculateSampleRanking;