// ResourcesForResearch.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import './../../locales/i18n';
import { Link } from 'react-router-dom';
import NavigationIcons from './../app-views/NavigationIcons';
//import React, { useState } from 'react';
//import { Link, useNavigate } from 'react-router-dom';
//import { useAuth } from './../../auth-and-backend/auth/authContext';
//import LoginModal from './../../auth-and-backend/modals/LoginModal';
//import SignUpModal from './../../auth-and-backend/modals/SignUpModal';

function ResourcesForResearch() {
    const { t } = useTranslation();
    //const { user } = useAuth();
    //const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    //const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
    //const navigate = useNavigate();

    // const handleRestrictedLinkClick = (e) => {
    //     if (!user) {
    //         e.preventDefault();
    //         setIsLoginModalOpen(true);
    //     }
    // };

    // const handleRestrictedButtonClick = (profile) => {
    //     if (!user) {
    //         setIsLoginModalOpen(true);
    //     } else {
    //         handleProfileSelect(profile);
    //     }
    // };

    // const renderLockIcon = (isRestricted) => {
    //     return isRestricted ? (
    //         user ? (
    //             <i className="fas fa-lock-open" style={{ color: '#a5d3ce' }}></i>
    //         ) : (
    //             <i className="fas fa-lock" style={{ color: 'red' }}></i>
    //         )
    //     ) : null;
    // };

    // const handleProfileSelect = (profile) => {
    //     const path = profile === 'instructor' ? '/cape-v-workshop-instructor' : '/cape-v-workshop-student';
    //     navigate(path, { state: { profile } });
    // };

    return (
        <div className="main-content">
            <h1>{t('resources_for_research_title')}</h1>
            <p>{t('resources_for_research_presentation')}</p>

            <p className="centered-text">{t('upload_and_evaluate_your_own_samples')}:</p>
            <Link to="/cape-v-scholar" className="button-link">{t('cape_v_scholar_title')}</Link><br/><br/><br/>

            <p className="centered-text">{t('collaborate_with_allvoiced_to_advance_vocal_science')}:</p>
            <Link to="/research-at-all-voiced" className="navigation-button">{t('research_at_allvoiced')}</Link><br/>
            
            
            {/* <LoginModal
                isOpen={isLoginModalOpen}
                onRequestClose={() => setIsLoginModalOpen(false)}
                openSignUpModal={() => { setIsLoginModalOpen(false); setIsSignUpModalOpen(true); }}
            />
            <SignUpModal
                isOpen={isSignUpModalOpen}
                onRequestClose={() => setIsSignUpModalOpen(false)}
                openLoginModal={() => { setIsSignUpModalOpen(false); setIsLoginModalOpen(true); }}
            /> */}
            <div className="navigation-container">
                <NavigationIcons exclude={['/resources-for-research']} />
            </div>
        </div>
    );
}

export default ResourcesForResearch;