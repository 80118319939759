// TranslationIssuesModal.jsx

import React from 'react';
import Modal from 'react-modal';
import TranslationIssuesComponent from './../auth/TranslationIssuesComponent';

Modal.setAppElement('#root');

const TranslationIssuesModal = ({ isOpen, onRequestClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Translation Issues Modal"
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '600px',
                    minWidth: '300px',
                    padding: '20px',
                    maxHeight: '90vh', // Limita l'altura màxima al 80% de l'alçada de la pantalla
                    overflowY: 'auto', // Afegeix scroll vertical si el contingut és més gran que l'altura màxima
                },
            }}
        >
            <TranslationIssuesComponent />
        </Modal>
    );
};

export default TranslationIssuesModal;
