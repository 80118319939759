// Mark.jsx

import React from 'react';

const Mark = ({ value, isSelected, isBlue, isSelectedBlue, onMarkClick }) => {
  const markClass = `mark ${isSelected ? 'selected' : ''} ${isBlue ? 'blue-mark' : ''} ${isSelectedBlue ? 'selected-blue' : ''}`;
  
  return (
    <div
      className={markClass}
      style={{ left: `${value}%` }}
      onClick={() => onMarkClick(value)}
    />
  );
};

export default Mark;