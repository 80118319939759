// UpdateConsentModal.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Trans, useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const UpdateConsentModal = ({ isOpen, onUpdateConsent }) => { // Canvi aquí
    const { t } = useTranslation();
    const [consentGiven, setConsentGiven] = useState(null);

    const handleCheckboxChange = (value) => {
        setConsentGiven(value);
    };

    const handleUpdateConsent = () => {
        if (consentGiven !== null) {
            console.log("Valor de consentiment:", consentGiven);
            onUpdateConsent(consentGiven); // Canvi aquí
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {}}
            contentLabel="Update Consent Modal"
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '600px',
                    minWidth: '300px',
                    padding: '20px',
                    maxHeight: '90vh', 
                    overflowY: 'auto', 
                },
            }}
        >
            <h2>{t('update_your_research_data_consent')}</h2>
            <p>{t('update_your_research_data_consent_presentation')}</p>
            <h3 className="centered-text">{t('global_database_title')}</h3>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img 
                    src="/images/global-database-icon.png" 
                    alt="Global Database Icon" 
                    className="centered-icon" 
                    style={{ maxWidth: '100px', height: 'auto' }}
                />
            </div>
            <div className="checkbox-group">
                <label>
                    <input
                        type="radio"
                        name="consent"
                        value="true"
                        checked={consentGiven === true}
                        onChange={() => handleCheckboxChange(true)}
                    />
                    <Trans
                        i18nKey="accept_research_usage"
                        components={{
                            a1: <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Política de Privacitat</a>,
                            a2: <a href="/global-database-project-information-participants" target="_blank" rel="noopener noreferrer">Informació a les persones participants</a>,
                            a3: <a href="/global-database-project-informed-consent" target="_blank" rel="noopener noreferrer">Consentiment informat</a>,
                        }}
                    />
                </label>
                <br/>
                <label>
                    <input
                        type="radio"
                        name="consent"
                        value="false"
                        checked={consentGiven === false}
                        onChange={() => handleCheckboxChange(false)}
                    />
                    {t('accept_research_usage_false')}
                </label>
            </div>
            <br/>
            <div className="modal-buttons">
                <button
                    onClick={handleUpdateConsent}
                    className="button-center"
                    disabled={consentGiven === null}
                >
                    {t('update')}
                </button>
            </div>
        </Modal>
    );
};

UpdateConsentModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onUpdateConsent: PropTypes.func.isRequired, // Canvi aquí
};

export default UpdateConsentModal;