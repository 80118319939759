// RatingDetails.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

const RatingDetails = ({ results = {} }) => {
  const { t } = useTranslation();

  const formatPitchDirection = (direction = {}) => {
    return `${direction.tooHigh ? t('too_high') : ""}${direction.tooHigh && direction.tooLow ? ", " : ""}${direction.tooLow ? t('too_low') : ""}` || t('normal');
  };

  const formatLoudnessDirection = (direction = {}) => {
    return `${direction.tooLoud ? t('too_loud') : ""}${direction.tooLoud && direction.tooSoft ? ", " : ""}${direction.tooSoft ? t('too_soft') : ""}` || t('normal');
  };

  const formatDetails = (details = {}) => {
    if (!details || Object.keys(details).length === 0) return t('none');  // Si no hi ha dades, retorna 'cap'
    
    const formattedDetails = Object.entries(details).map(([key, obj]) => {
      if (key === 'normal_resonance') {
        return t('normal_resonance');
      }
      const actualValue = obj?.value && typeof obj.value === 'object' ? obj.value.value : obj?.value || 0;  // Prevenir valors indefinits
      const label = obj?.label ? obj.label : t(key);
      return `${label}: ${actualValue}/100 (${t(obj?.variability || 'consistent')})`;
    }).join(', ');

    return formattedDetails || t('none');
  };

  const shouldDisplayRating = (rating) => {
    return rating !== 'normal_resonance';
  };

  return (
    <div className="highlighted-section">
      {/* Afegeix el nom de la mostra només si existeix */}
      {results.sampleName && (
        <p className="centered-text"><strong>{t('sample')}:</strong> {results.sampleName}</p>
      )}
      {shouldDisplayRating('overall_severity') && (
        <p className="centered-text"><strong>{t('overall_severity')}:</strong> {results.overallSeverity?.value || 0}/100 ({t(results.overallSeverity?.variability || 'consistent')})</p>
      )}
      {shouldDisplayRating('roughness') && (
        <p className="centered-text"><strong>{t('roughness')}:</strong> {results.roughness?.value || 0}/100 ({t(results.roughness?.variability || 'consistent')})</p>
      )}
      {shouldDisplayRating('breathiness') && (
        <p className="centered-text"><strong>{t('breathiness')}:</strong> {results.breathiness?.value || 0}/100 ({t(results.breathiness?.variability || 'consistent')})</p>
      )}
      {shouldDisplayRating('strain') && (
        <p className="centered-text"><strong>{t('strain')}:</strong> {results.strain?.value || 0}/100 ({t(results.strain?.variability || 'consistent')})</p>
      )}
      {shouldDisplayRating('pitch') && (
        <p className="centered-text"><strong>{t('pitch')}:</strong> {results.pitch?.value || 0}/100, {formatPitchDirection(results.pitch?.direction || {})} ({t(results.pitch?.variability || 'consistent')})</p>
      )}
      {shouldDisplayRating('loudness') && (
        <p className="centered-text"><strong>{t('loudness')}:</strong> {results.loudness?.value || 0}/100, {formatLoudnessDirection(results.loudness?.direction || {})} ({t(results.loudness?.variability || 'consistent')})</p>
      )}
      <p className="centered-text"><strong>{t('comments_resonance')}:</strong> {formatDetails(results?.additionalResonances)}</p>
      <p className="centered-text"><strong>{t('additional_features')}:</strong> {formatDetails(results?.additionalFeatures)}</p>
      <p className="centered-text"><strong>{t('general_comments')}:</strong> {results.comments || t('none')}</p>
    </div>
  );
};

export default RatingDetails;