// anchorsData.js

const anchorsData = [
    {
      number: 1,
      frequencyRange: "collection_1_frequency_range",
      lineData: [
        {
          key: 'roughness',
          label: "roughness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [

          ],
        },
        {
          key: 'breathiness',
          label: "breathiness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 0, audioLink: '/samples/PVQD-sustained-vowels/1_09.wav' },
            { value: 3, audioLink: '/samples/PVQD-sustained-vowels/1_03.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/1_06.wav' },
            { value: 9, audioLink: '/samples/PVQD-sustained-vowels/1_12.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/1_17.wav' },
            { value: 11, audioLink: '/samples/PVQD-sustained-vowels/1_10.wav' },
            { value: 13, audioLink: '/samples/PVQD-sustained-vowels/1_08.wav' },
            { value: 13, audioLink: '/samples/PVQD-sustained-vowels/1_11.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/1_21.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/1_01.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/1_19.wav' },
            { value: 25, audioLink: '/samples/PVQD-sustained-vowels/1_13.wav' },
            { value: 26, audioLink: '/samples/PVQD-sustained-vowels/1_16.wav' },
            { value: 28, audioLink: '/samples/PVQD-sustained-vowels/1_22.wav' },
            { value: 40, audioLink: '/samples/PVQD-sustained-vowels/1_07.wav' },
            { value: 40, audioLink: '/samples/PVQD-sustained-vowels/1_14.wav' },
            { value: 46, audioLink: '/samples/PVQD-sustained-vowels/1_24.wav' },
            { value: 48, audioLink: '/samples/PVQD-sustained-vowels/1_18.wav' },
            { value: 52, audioLink: '/samples/PVQD-sustained-vowels/1_25.wav' },
            { value: 61, audioLink: '/samples/PVQD-sustained-vowels/1_20.wav' },
            { value: 63, audioLink: '/samples/PVQD-sustained-vowels/1_15.wav' },
            { value: 74, audioLink: '/samples/PVQD-sustained-vowels/1_05.wav' },
            { value: 77, audioLink: '/samples/PVQD-sustained-vowels/1_23.wav' },
            { value: 91, audioLink: '/samples/PVQD-sustained-vowels/1_04.wav' },
            { value: 96, audioLink: '/samples/PVQD-sustained-vowels/1_02.wav' }
          ],
        },
        {
          key: 'strain',
          label: "strain",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 4, audioLink: '/samples/PVQD-sustained-vowels/1_01.wav' },
            { value: 4, audioLink: '/samples/PVQD-sustained-vowels/1_03.wav' },
            { value: 4, audioLink: '/samples/PVQD-sustained-vowels/1_06.wav' },
            { value: 4, audioLink: '/samples/PVQD-sustained-vowels/1_09.wav' },
            { value: 4, audioLink: '/samples/PVQD-sustained-vowels/1_11.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/1_17.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/1_19.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/1_12.wav' },
            { value: 15, audioLink: '/samples/PVQD-sustained-vowels/1_22.wav' },
            { value: 17, audioLink: '/samples/PVQD-sustained-vowels/1_02.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/1_16.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/1_14.wav' },
            { value: 21, audioLink: '/samples/PVQD-sustained-vowels/1_04.wav' },
            { value: 22, audioLink: '/samples/PVQD-sustained-vowels/1_13.wav' },
            { value: 25, audioLink: '/samples/PVQD-sustained-vowels/1_25.wav' },
            { value: 26, audioLink: '/samples/PVQD-sustained-vowels/1_08.wav' },
            { value: 28, audioLink: '/samples/PVQD-sustained-vowels/1_07.wav' },
            { value: 31, audioLink: '/samples/PVQD-sustained-vowels/1_18.wav' },
            { value: 32, audioLink: '/samples/PVQD-sustained-vowels/1_15.wav' },
            { value: 33, audioLink: '/samples/PVQD-sustained-vowels/1_20.wav' },
            { value: 34, audioLink: '/samples/PVQD-sustained-vowels/1_05.wav' },
            { value: 38, audioLink: '/samples/PVQD-sustained-vowels/1_23.wav' },
            { value: 39, audioLink: '/samples/PVQD-sustained-vowels/1_10.wav' },
            { value: 41, audioLink: '/samples/PVQD-sustained-vowels/1_24.wav' },
            { value: 63, audioLink: '/samples/PVQD-sustained-vowels/1_21.wav' }
          ],
        },
      ],
    },
    {
      number: 2,
      frequencyRange: "collection_2_frequency_range",
      lineData: [
        {
          key: 'roughness',
          label: "roughness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 5, audioLink: '/samples/PVQD-sustained-vowels/2_22.wav' },
            { value: 6, audioLink: '/samples/PVQD-sustained-vowels/2_05.wav' },
            { value: 6, audioLink: '/samples/PVQD-sustained-vowels/2_19.wav' },
            { value: 9, audioLink: '/samples/PVQD-sustained-vowels/2_13.wav' },
            { value: 9, audioLink: '/samples/PVQD-sustained-vowels/2_20.wav' },
            { value: 9, audioLink: '/samples/PVQD-sustained-vowels/2_15.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/2_23.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/2_07.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/2_09.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/2_01.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/2_24.wav' },
            { value: 26, audioLink: '/samples/PVQD-sustained-vowels/2_21.wav' },
            { value: 31, audioLink: '/samples/PVQD-sustained-vowels/2_17.wav' },
            { value: 38, audioLink: '/samples/PVQD-sustained-vowels/2_25.wav' },
            { value: 39, audioLink: '/samples/PVQD-sustained-vowels/2_10.wav' },
            { value: 40, audioLink: '/samples/PVQD-sustained-vowels/2_06.wav' },
            { value: 43, audioLink: '/samples/PVQD-sustained-vowels/2_08.wav' },
            { value: 44, audioLink: '/samples/PVQD-sustained-vowels/2_02.wav' },
            { value: 53, audioLink: '/samples/PVQD-sustained-vowels/2_03.wav' },
            { value: 57, audioLink: '/samples/PVQD-sustained-vowels/2_12.wav' },
            { value: 74, audioLink: '/samples/PVQD-sustained-vowels/2_04.wav' },
            { value: 79, audioLink: '/samples/PVQD-sustained-vowels/2_16.wav' },
            { value: 79, audioLink: '/samples/PVQD-sustained-vowels/2_14.wav' },
            { value: 80, audioLink: '/samples/PVQD-sustained-vowels/2_11.wav' },
            { value: 88, audioLink: '/samples/PVQD-sustained-vowels/2_18.wav' }
          ],
        },
        {
          key: 'breathiness',
          label: "breathiness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 2, audioLink: '/samples/PVQD-sustained-vowels/2_02.wav' },
            { value: 4, audioLink: '/samples/PVQD-sustained-vowels/2_09.wav' },
            { value: 6, audioLink: '/samples/PVQD-sustained-vowels/2_07.wav' },
            { value: 8, audioLink: '/samples/PVQD-sustained-vowels/2_06.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/2_15.wav' },
            { value: 11, audioLink: '/samples/PVQD-sustained-vowels/2_22.wav' },
            { value: 13, audioLink: '/samples/PVQD-sustained-vowels/2_08.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/2_19.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/2_12.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/2_21.wav' },
            { value: 24, audioLink: '/samples/PVQD-sustained-vowels/2_01.wav' },
            { value: 25, audioLink: '/samples/PVQD-sustained-vowels/2_04.wav' },
            { value: 27, audioLink: '/samples/PVQD-sustained-vowels/2_11.wav' },
            { value: 27, audioLink: '/samples/PVQD-sustained-vowels/2_24.wav' },
            { value: 29, audioLink: '/samples/PVQD-sustained-vowels/2_20.wav' },
            { value: 30, audioLink: '/samples/PVQD-sustained-vowels/2_13.wav' },
            { value: 35, audioLink: '/samples/PVQD-sustained-vowels/2_05.wav' },
            { value: 40, audioLink: '/samples/PVQD-sustained-vowels/2_16.wav' },
            { value: 45, audioLink: '/samples/PVQD-sustained-vowels/2_10.wav' },
            { value: 48, audioLink: '/samples/PVQD-sustained-vowels/2_14.wav' },
            { value: 52, audioLink: '/samples/PVQD-sustained-vowels/2_25.wav' },
            { value: 53, audioLink: '/samples/PVQD-sustained-vowels/2_17.wav' },
            { value: 58, audioLink: '/samples/PVQD-sustained-vowels/2_18.wav' },
            { value: 59, audioLink: '/samples/PVQD-sustained-vowels/2_23.wav' },
            { value: 60, audioLink: '/samples/PVQD-sustained-vowels/2_03.wav' }
          ],
        },
        {
          key: 'strain',
          label: "strain",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 3, audioLink: '/samples/PVQD-sustained-vowels/2_05.wav' },
            { value: 5, audioLink: '/samples/PVQD-sustained-vowels/2_13.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/2_01.wav' },
            { value: 11, audioLink: '/samples/PVQD-sustained-vowels/2_08.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/2_09.wav' },
            { value: 15, audioLink: '/samples/PVQD-sustained-vowels/2_15.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/2_22.wav' },
            { value: 17, audioLink: '/samples/PVQD-sustained-vowels/2_19.wav' },
            { value: 17, audioLink: '/samples/PVQD-sustained-vowels/2_20.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/2_12.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/2_23.wav' },
            { value: 22, audioLink: '/samples/PVQD-sustained-vowels/2_07.wav' },
            { value: 23, audioLink: '/samples/PVQD-sustained-vowels/2_24.wav' },
            { value: 27, audioLink: '/samples/PVQD-sustained-vowels/2_03.wav' },
            { value: 27, audioLink: '/samples/PVQD-sustained-vowels/2_25.wav' },
            { value: 29, audioLink: '/samples/PVQD-sustained-vowels/2_21.wav' },
            { value: 33, audioLink: '/samples/PVQD-sustained-vowels/2_17.wav' },
            { value: 36, audioLink: '/samples/PVQD-sustained-vowels/2_04.wav' },
            { value: 37, audioLink: '/samples/PVQD-sustained-vowels/2_02.wav' },
            { value: 43, audioLink: '/samples/PVQD-sustained-vowels/2_14.wav' },
            { value: 43, audioLink: '/samples/PVQD-sustained-vowels/2_10.wav' },
            { value: 44, audioLink: '/samples/PVQD-sustained-vowels/2_06.wav' },
            { value: 48, audioLink: '/samples/PVQD-sustained-vowels/2_18.wav' },
            { value: 50, audioLink: '/samples/PVQD-sustained-vowels/2_16.wav' },
            { value: 52, audioLink: '/samples/PVQD-sustained-vowels/2_11.wav' }
          ],
        },
      ],
    },
    {
      number: 3,
      frequencyRange: "collection_3_frequency_range",
      lineData: [
        {
          key: 'roughness',
          label: "roughness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 3, audioLink: '/samples/PVQD-sustained-vowels/3_04.wav' },
            { value: 5, audioLink: '/samples/PVQD-sustained-vowels/3_21.wav' },
            { value: 5, audioLink: '/samples/PVQD-sustained-vowels/3_17.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/3_22.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/3_24.wav' },
            { value: 8, audioLink: '/samples/PVQD-sustained-vowels/3_20.wav' },
            { value: 8, audioLink: '/samples/PVQD-sustained-vowels/3_23.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/3_09.wav' },
            { value: 11, audioLink: '/samples/PVQD-sustained-vowels/3_12.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/3_03.wav' },
            { value: 15, audioLink: '/samples/PVQD-sustained-vowels/3_19.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/3_25.wav' },
            { value: 26, audioLink: '/samples/PVQD-sustained-vowels/3_02.wav' },
            { value: 27, audioLink: '/samples/PVQD-sustained-vowels/3_08.wav' },
            { value: 28, audioLink: '/samples/PVQD-sustained-vowels/3_06.wav' },
            { value: 29, audioLink: '/samples/PVQD-sustained-vowels/3_11.wav' },
            { value: 32, audioLink: '/samples/PVQD-sustained-vowels/3_01.wav' },
            { value: 32, audioLink: '/samples/PVQD-sustained-vowels/3_07.wav' },
            { value: 36, audioLink: '/samples/PVQD-sustained-vowels/3_16.wav' },
            { value: 39, audioLink: '/samples/PVQD-sustained-vowels/3_13.wav' },
            { value: 40, audioLink: '/samples/PVQD-sustained-vowels/3_18.wav' },
            { value: 42, audioLink: '/samples/PVQD-sustained-vowels/3_15.wav' },
            { value: 61, audioLink: '/samples/PVQD-sustained-vowels/3_14.wav' },
            { value: 65, audioLink: '/samples/PVQD-sustained-vowels/3_10.wav' },
            { value: 67, audioLink: '/samples/PVQD-sustained-vowels/3_05.wav' }
          ],
        },
        {
          key: 'breathiness',
          label: "breathiness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 1, audioLink: '/samples/PVQD-sustained-vowels/3_21.wav' },
            { value: 2, audioLink: '/samples/PVQD-sustained-vowels/3_12.wav' },
            { value: 2, audioLink: '/samples/PVQD-sustained-vowels/3_20.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/3_23.wav' },
            { value: 8, audioLink: '/samples/PVQD-sustained-vowels/3_18.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/3_08.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/3_19.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/3_04.wav' },
            { value: 24, audioLink: '/samples/PVQD-sustained-vowels/3_25.wav' },
            { value: 26, audioLink: '/samples/PVQD-sustained-vowels/3_22.wav' },
            { value: 33, audioLink: '/samples/PVQD-sustained-vowels/3_03.wav' },
            { value: 36, audioLink: '/samples/PVQD-sustained-vowels/3_02.wav' },
            { value: 38, audioLink: '/samples/PVQD-sustained-vowels/3_13.wav' },
            { value: 38, audioLink: '/samples/PVQD-sustained-vowels/3_17.wav' },
            { value: 44, audioLink: '/samples/PVQD-sustained-vowels/3_01.wav' },
            { value: 44, audioLink: '/samples/PVQD-sustained-vowels/3_16.wav' },
            { value: 45, audioLink: '/samples/PVQD-sustained-vowels/3_09.wav' },
            { value: 46, audioLink: '/samples/PVQD-sustained-vowels/3_11.wav' },
            { value: 47, audioLink: '/samples/PVQD-sustained-vowels/3_10.wav' },
            { value: 49, audioLink: '/samples/PVQD-sustained-vowels/3_14.wav' },
            { value: 53, audioLink: '/samples/PVQD-sustained-vowels/3_06.wav' },
            { value: 66, audioLink: '/samples/PVQD-sustained-vowels/3_24.wav' },
            { value: 71, audioLink: '/samples/PVQD-sustained-vowels/3_05.wav' },
            { value: 76, audioLink: '/samples/PVQD-sustained-vowels/3_15.wav' },
            { value: 82, audioLink: '/samples/PVQD-sustained-vowels/3_07.wav' }
          ],
        },
        {
          key: 'strain',
          label: "strain",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 3, audioLink: '/samples/PVQD-sustained-vowels/3_04.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/3_22.wav' },
            { value: 11, audioLink: '/samples/PVQD-sustained-vowels/3_21.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/3_12.wav' },
            { value: 13, audioLink: '/samples/PVQD-sustained-vowels/3_17.wav' },
            { value: 14, audioLink: '/samples/PVQD-sustained-vowels/3_02.wav' },
            { value: 15, audioLink: '/samples/PVQD-sustained-vowels/3_08.wav' },
            { value: 15, audioLink: '/samples/PVQD-sustained-vowels/3_09.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/3_25.wav' },
            { value: 17, audioLink: '/samples/PVQD-sustained-vowels/3_01.wav' },
            { value: 17, audioLink: '/samples/PVQD-sustained-vowels/3_23.wav' },
            { value: 17, audioLink: '/samples/PVQD-sustained-vowels/3_19.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/3_20.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/3_24.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/3_03.wav' },
            { value: 27, audioLink: '/samples/PVQD-sustained-vowels/3_11.wav' },
            { value: 35, audioLink: '/samples/PVQD-sustained-vowels/3_06.wav' },
            { value: 35, audioLink: '/samples/PVQD-sustained-vowels/3_16.wav' },
            { value: 40, audioLink: '/samples/PVQD-sustained-vowels/3_07.wav' },
            { value: 41, audioLink: '/samples/PVQD-sustained-vowels/3_18.wav' },
            { value: 43, audioLink: '/samples/PVQD-sustained-vowels/3_14.wav' },
            { value: 46, audioLink: '/samples/PVQD-sustained-vowels/3_10.wav' },
            { value: 50, audioLink: '/samples/PVQD-sustained-vowels/3_15.wav' },
            { value: 56, audioLink: '/samples/PVQD-sustained-vowels/3_13.wav' },
            { value: 59, audioLink: '/samples/PVQD-sustained-vowels/3_05.wav' }
          ],
        },
      ],
    },
    {
      number: 4,
      frequencyRange: "collection_4_frequency_range",
      lineData: [
        {
          key: 'roughness',
          label: "roughness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
          ],
        },
        {
          key: 'breathiness',
          label: "breathiness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 0, audioLink: '/samples/PVQD-sustained-vowels/4_24.wav' },
            { value: 2, audioLink: '/samples/PVQD-sustained-vowels/4_23.wav' },
            { value: 4, audioLink: '/samples/PVQD-sustained-vowels/4_17.wav' },
            { value: 5, audioLink: '/samples/PVQD-sustained-vowels/4_09.wav' },
            { value: 6, audioLink: '/samples/PVQD-sustained-vowels/4_03.wav' },
            { value: 9, audioLink: '/samples/PVQD-sustained-vowels/4_22.wav' },
            { value: 11, audioLink: '/samples/PVQD-sustained-vowels/4_13.wav' },
            { value: 13, audioLink: '/samples/PVQD-sustained-vowels/4_25.wav' },
            { value: 14, audioLink: '/samples/PVQD-sustained-vowels/4_05.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/4_02.wav' },
            { value: 22, audioLink: '/samples/PVQD-sustained-vowels/4_04.wav' },
            { value: 23, audioLink: '/samples/PVQD-sustained-vowels/4_21.wav' },
            { value: 28, audioLink: '/samples/PVQD-sustained-vowels/4_06.wav' },
            { value: 28, audioLink: '/samples/PVQD-sustained-vowels/4_20.wav' },
            { value: 31, audioLink: '/samples/PVQD-sustained-vowels/4_10.wav' },
            { value: 34, audioLink: '/samples/PVQD-sustained-vowels/4_07.wav' },
            { value: 35, audioLink: '/samples/PVQD-sustained-vowels/4_14.wav' },
            { value: 36, audioLink: '/samples/PVQD-sustained-vowels/4_11.wav' },
            { value: 40, audioLink: '/samples/PVQD-sustained-vowels/4_16.wav' },
            { value: 46, audioLink: '/samples/PVQD-sustained-vowels/4_19.wav' },
            { value: 59, audioLink: '/samples/PVQD-sustained-vowels/4_15.wav' },
            { value: 62, audioLink: '/samples/PVQD-sustained-vowels/4_12.wav' },
            { value: 74, audioLink: '/samples/PVQD-sustained-vowels/4_18.wav' },
            { value: 78, audioLink: '/samples/PVQD-sustained-vowels/4_08.wav' },
            { value: 80, audioLink: '/samples/PVQD-sustained-vowels/4_01.wav' }
          ],
        },
        {
          key: 'strain',
          label: "strain",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 0, audioLink: '/samples/PVQD-sustained-vowels/4_05.wav' },
            { value: 2, audioLink: '/samples/PVQD-sustained-vowels/4_12.wav' },
            { value: 4, audioLink: '/samples/PVQD-sustained-vowels/4_08.wav' },
            { value: 5, audioLink: '/samples/PVQD-sustained-vowels/4_20.wav' },
            { value: 8, audioLink: '/samples/PVQD-sustained-vowels/4_03.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/4_06.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/4_07.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/4_13.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/4_25.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/4_14.wav' },
            { value: 14, audioLink: '/samples/PVQD-sustained-vowels/4_15.wav' },
            { value: 15, audioLink: '/samples/PVQD-sustained-vowels/4_22.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/4_19.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/4_04.wav' },
            { value: 23, audioLink: '/samples/PVQD-sustained-vowels/4_02.wav' },
            { value: 26, audioLink: '/samples/PVQD-sustained-vowels/4_10.wav' },
            { value: 26, audioLink: '/samples/PVQD-sustained-vowels/4_21.wav' },
            { value: 27, audioLink: '/samples/PVQD-sustained-vowels/4_11.wav' },
            { value: 30, audioLink: '/samples/PVQD-sustained-vowels/4_16.wav' },
            { value: 30, audioLink: '/samples/PVQD-sustained-vowels/4_24.wav' },
            { value: 31, audioLink: '/samples/PVQD-sustained-vowels/4_09.wav' },
            { value: 34, audioLink: '/samples/PVQD-sustained-vowels/4_23.wav' },
            { value: 34, audioLink: '/samples/PVQD-sustained-vowels/4_01.wav' },
            { value: 34, audioLink: '/samples/PVQD-sustained-vowels/4_17.wav' },
            { value: 37, audioLink: '/samples/PVQD-sustained-vowels/4_18.wav' }
          ],
        },
      ],
    },
    {
      number: 5,
      frequencyRange: "collection_5_frequency_range",
      lineData: [
        {
          key: 'roughness',
          label: "roughness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 1, audioLink: '/samples/PVQD-sustained-vowels/5_01.wav' },
            { value: 2, audioLink: '/samples/PVQD-sustained-vowels/5_03.wav' },
            { value: 2, audioLink: '/samples/PVQD-sustained-vowels/5_23.wav' },
            { value: 2, audioLink: '/samples/PVQD-sustained-vowels/5_16.wav' },
            { value: 3, audioLink: '/samples/PVQD-sustained-vowels/5_13.wav' },
            { value: 4, audioLink: '/samples/PVQD-sustained-vowels/5_04.wav' },
            { value: 9, audioLink: '/samples/PVQD-sustained-vowels/5_25.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/5_18.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/5_24.wav' },
            { value: 14, audioLink: '/samples/PVQD-sustained-vowels/5_21.wav' },
            { value: 15, audioLink: '/samples/PVQD-sustained-vowels/5_11.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/5_05.wav' },
            { value: 20, audioLink: '/samples/PVQD-sustained-vowels/5_08.wav' },
            { value: 21, audioLink: '/samples/PVQD-sustained-vowels/5_10.wav' },
            { value: 22, audioLink: '/samples/PVQD-sustained-vowels/5_09.wav' },
            { value: 24, audioLink: '/samples/PVQD-sustained-vowels/5_14.wav' },
            { value: 29, audioLink: '/samples/PVQD-sustained-vowels/5_20.wav' },
            { value: 30, audioLink: '/samples/PVQD-sustained-vowels/5_06.wav' },
            { value: 31, audioLink: '/samples/PVQD-sustained-vowels/5_07.wav' },
            { value: 31, audioLink: '/samples/PVQD-sustained-vowels/5_02.wav' },
            { value: 32, audioLink: '/samples/PVQD-sustained-vowels/5_22.wav' },
            { value: 37, audioLink: '/samples/PVQD-sustained-vowels/5_17.wav' },
            { value: 55, audioLink: '/samples/PVQD-sustained-vowels/5_12.wav' },
            { value: 56, audioLink: '/samples/PVQD-sustained-vowels/5_19.wav' },
            { value: 79, audioLink: '/samples/PVQD-sustained-vowels/5_15.wav' }
          ],
        },
        {
          key: 'breathiness',
          label: "breathiness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 11, audioLink: '/samples/PVQD-sustained-vowels/5_01.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/5_05.wav' },
            { value: 13, audioLink: '/samples/PVQD-sustained-vowels/5_23.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/5_16.wav' },
            { value: 20, audioLink: '/samples/PVQD-sustained-vowels/5_02.wav' },
            { value: 23, audioLink: '/samples/PVQD-sustained-vowels/5_08.wav' },
            { value: 24, audioLink: '/samples/PVQD-sustained-vowels/5_13.wav' },
            { value: 28, audioLink: '/samples/PVQD-sustained-vowels/5_03.wav' },
            { value: 28, audioLink: '/samples/PVQD-sustained-vowels/5_14.wav' },
            { value: 29, audioLink: '/samples/PVQD-sustained-vowels/5_10.wav' },
            { value: 29, audioLink: '/samples/PVQD-sustained-vowels/5_20.wav' },
            { value: 29, audioLink: '/samples/PVQD-sustained-vowels/5_09.wav' },
            { value: 35, audioLink: '/samples/PVQD-sustained-vowels/5_17.wav' },
            { value: 37, audioLink: '/samples/PVQD-sustained-vowels/5_22.wav' },
            { value: 39, audioLink: '/samples/PVQD-sustained-vowels/5_24.wav' },
            { value: 40, audioLink: '/samples/PVQD-sustained-vowels/5_21.wav' },
            { value: 41, audioLink: '/samples/PVQD-sustained-vowels/5_12.wav' },
            { value: 41, audioLink: '/samples/PVQD-sustained-vowels/5_04.wav' },
            { value: 41, audioLink: '/samples/PVQD-sustained-vowels/5_11.wav' },
            { value: 54, audioLink: '/samples/PVQD-sustained-vowels/5_06.wav' },
            { value: 56, audioLink: '/samples/PVQD-sustained-vowels/5_07.wav' },
            { value: 56, audioLink: '/samples/PVQD-sustained-vowels/5_19.wav' },
            { value: 59, audioLink: '/samples/PVQD-sustained-vowels/5_18.wav' },
            { value: 71, audioLink: '/samples/PVQD-sustained-vowels/5_25.wav' },
            { value: 73, audioLink: '/samples/PVQD-sustained-vowels/5_15.wav' }
          ],
        },
        {
          key: 'strain',
          label: "strain",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 3, audioLink: '/samples/PVQD-sustained-vowels/5_04.wav' },
            { value: 5, audioLink: '/samples/PVQD-sustained-vowels/5_13.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/5_24.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/5_03.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/5_18.wav' },
            { value: 14, audioLink: '/samples/PVQD-sustained-vowels/5_16.wav' },
            { value: 15, audioLink: '/samples/PVQD-sustained-vowels/5_25.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/5_23.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/5_11.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/5_06.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/5_21.wav' },
            { value: 21, audioLink: '/samples/PVQD-sustained-vowels/5_09.wav' },
            { value: 23, audioLink: '/samples/PVQD-sustained-vowels/5_19.wav' },
            { value: 24, audioLink: '/samples/PVQD-sustained-vowels/5_08.wav' },
            { value: 27, audioLink: '/samples/PVQD-sustained-vowels/5_10.wav' },
            { value: 30, audioLink: '/samples/PVQD-sustained-vowels/5_05.wav' },
            { value: 34, audioLink: '/samples/PVQD-sustained-vowels/5_20.wav' },
            { value: 36, audioLink: '/samples/PVQD-sustained-vowels/5_12.wav' },
            { value: 37, audioLink: '/samples/PVQD-sustained-vowels/5_02.wav' },
            { value: 37, audioLink: '/samples/PVQD-sustained-vowels/5_17.wav' },
            { value: 38, audioLink: '/samples/PVQD-sustained-vowels/5_14.wav' },
            { value: 40, audioLink: '/samples/PVQD-sustained-vowels/5_15.wav' },
            { value: 41, audioLink: '/samples/PVQD-sustained-vowels/5_01.wav' },
            { value: 42, audioLink: '/samples/PVQD-sustained-vowels/5_07.wav' },
            { value: 49, audioLink: '/samples/PVQD-sustained-vowels/5_22.wav' },
          ],
        },
      ],
    },
    {
      number: 6,
      frequencyRange: "collection_6_frequency_range",
      lineData: [
        {
          key: 'roughness',
          label: "roughness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/6_01.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/6_02.wav' },
            { value: 2, audioLink: '/samples/PVQD-sustained-vowels/6_03.wav' },
            { value: 46, audioLink: '/samples/PVQD-sustained-vowels/6_04.wav' },
            { value: 6, audioLink: '/samples/PVQD-sustained-vowels/6_05.wav' },
            { value: 10, audioLink: '/samples/PVQD-sustained-vowels/6_06.wav' },
            { value: 17, audioLink: '/samples/PVQD-sustained-vowels/6_07.wav' },
            { value: 1, audioLink: '/samples/PVQD-sustained-vowels/6_08.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/6_09.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/6_10.wav' },
            { value: 14, audioLink: '/samples/PVQD-sustained-vowels/6_11.wav' },
            { value: 14, audioLink: '/samples/PVQD-sustained-vowels/6_12.wav' },
            { value: 23, audioLink: '/samples/PVQD-sustained-vowels/6_13.wav' },
            { value: 6, audioLink: '/samples/PVQD-sustained-vowels/6_14.wav' },
            { value: 6, audioLink: '/samples/PVQD-sustained-vowels/6_15.wav' },
            { value: 11, audioLink: '/samples/PVQD-sustained-vowels/6_16.wav' },
            { value: 38, audioLink: '/samples/PVQD-sustained-vowels/6_17.wav' },
            { value: 50, audioLink: '/samples/PVQD-sustained-vowels/6_18.wav' },
            { value: 33, audioLink: '/samples/PVQD-sustained-vowels/6_19.wav' },
            { value: 23, audioLink: '/samples/PVQD-sustained-vowels/6_20.wav' },
            { value: 65, audioLink: '/samples/PVQD-sustained-vowels/6_21.wav' },
            { value: 55, audioLink: '/samples/PVQD-sustained-vowels/6_22.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/6_23.wav' },
            { value: 28, audioLink: '/samples/PVQD-sustained-vowels/6_24.wav' },
            { value: 65, audioLink: '/samples/PVQD-sustained-vowels/6_25.wav' },
          ],
        },
        {
          key: 'breathiness',
          label: "breathiness",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 24, audioLink: '/samples/PVQD-sustained-vowels/6_01.wav' },
            { value: 39, audioLink: '/samples/PVQD-sustained-vowels/6_02.wav' },
            { value: 0, audioLink: '/samples/PVQD-sustained-vowels/6_03.wav' },
            { value: 20, audioLink: '/samples/PVQD-sustained-vowels/6_04.wav' },
            { value: 20, audioLink: '/samples/PVQD-sustained-vowels/6_05.wav' },
            { value: 51, audioLink: '/samples/PVQD-sustained-vowels/6_06.wav' },
            { value: 5, audioLink: '/samples/PVQD-sustained-vowels/6_07.wav' },
            { value: 6, audioLink: '/samples/PVQD-sustained-vowels/6_08.wav' },
            { value: 4, audioLink: '/samples/PVQD-sustained-vowels/6_09.wav' },
            { value: 41, audioLink: '/samples/PVQD-sustained-vowels/6_10.wav' },
            { value: 23, audioLink: '/samples/PVQD-sustained-vowels/6_11.wav' },
            { value: 49, audioLink: '/samples/PVQD-sustained-vowels/6_12.wav' },
            { value: 7, audioLink: '/samples/PVQD-sustained-vowels/6_13.wav' },
            { value: 15, audioLink: '/samples/PVQD-sustained-vowels/6_14.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/6_15.wav' },
            { value: 46, audioLink: '/samples/PVQD-sustained-vowels/6_16.wav' },
            { value: 24, audioLink: '/samples/PVQD-sustained-vowels/6_17.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/6_18.wav' },
            { value: 54, audioLink: '/samples/PVQD-sustained-vowels/6_19.wav' },
            { value: 48, audioLink: '/samples/PVQD-sustained-vowels/6_20.wav' },
            { value: 17, audioLink: '/samples/PVQD-sustained-vowels/6_21.wav' },
            { value: 18, audioLink: '/samples/PVQD-sustained-vowels/6_22.wav' },
            { value: 51, audioLink: '/samples/PVQD-sustained-vowels/6_23.wav' },
            { value: 28, audioLink: '/samples/PVQD-sustained-vowels/6_24.wav' },
            { value: 43, audioLink: '/samples/PVQD-sustained-vowels/6_25.wav' },
          ],
        },
        {
          key: 'strain',
          label: "strain",
          blackMarks: Array.from({ length: 100 }, (_, i) => i + 1),
          blueMarks: [
            { value: 3, audioLink: '/samples/PVQD-sustained-vowels/6_01.wav' },
            { value: 16, audioLink: '/samples/PVQD-sustained-vowels/6_02.wav' },
            { value: 0, audioLink: '/samples/PVQD-sustained-vowels/6_03.wav' },
            { value: 34, audioLink: '/samples/PVQD-sustained-vowels/6_04.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/6_05.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/6_06.wav' },
            { value: 19, audioLink: '/samples/PVQD-sustained-vowels/6_07.wav' },
            { value: 3, audioLink: '/samples/PVQD-sustained-vowels/6_08.wav' },
            { value: 27, audioLink: '/samples/PVQD-sustained-vowels/6_09.wav' },
            { value: 21, audioLink: '/samples/PVQD-sustained-vowels/6_10.wav' },
            { value: 26, audioLink: '/samples/PVQD-sustained-vowels/6_11.wav' },
            { value: 28, audioLink: '/samples/PVQD-sustained-vowels/6_12.wav' },
            { value: 39, audioLink: '/samples/PVQD-sustained-vowels/6_13.wav' },
            { value: 12, audioLink: '/samples/PVQD-sustained-vowels/6_14.wav' },
            { value: 2, audioLink: '/samples/PVQD-sustained-vowels/6_15.wav' },
            { value: 14, audioLink: '/samples/PVQD-sustained-vowels/6_16.wav' },
            { value: 30, audioLink: '/samples/PVQD-sustained-vowels/6_17.wav' },
            { value: 39, audioLink: '/samples/PVQD-sustained-vowels/6_18.wav' },
            { value: 37, audioLink: '/samples/PVQD-sustained-vowels/6_19.wav' },
            { value: 23, audioLink: '/samples/PVQD-sustained-vowels/6_20.wav' },
            { value: 53, audioLink: '/samples/PVQD-sustained-vowels/6_21.wav' },
            { value: 43, audioLink: '/samples/PVQD-sustained-vowels/6_22.wav' },
            { value: 20, audioLink: '/samples/PVQD-sustained-vowels/6_23.wav' },
            { value: 27, audioLink: '/samples/PVQD-sustained-vowels/6_24.wav' },
            { value: 70, audioLink: '/samples/PVQD-sustained-vowels/6_25.wav' },
          ],
        },
      ],
    },
  ];

  export default anchorsData;