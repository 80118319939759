// LoginComponent.jsx

import React, { useState } from 'react';
import { useAuth } from './authContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginComponent = ({ onRequestClose }) => { 
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false); 
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (forgotPassword) {
      // Gestiona la sol·licitud de recuperació de contrasenya
      try {
        await axios.post(`${apiUrl}/users/password-reset`, { email: formData.email });
        setMessage(t('password_reset_email_sent'));
        setError('');
        setResetLinkSent(true); // Marca que s'ha enviat l'enllaç de restabliment
      } catch (error) {
        console.error('Error sending password reset email:', error);
        setError(t('password_reset_email_error'));
        setMessage('');
      }
    } else {
      // Gestiona el login normal
      try {
        await auth.login(formData.email, formData.password);
        setMessage(t('login_successful'));
        setError('');
        
        if (onRequestClose) {
          onRequestClose();
        }
  
        const redirectRoute = localStorage.getItem('preLoginRoute') || '/';
        localStorage.removeItem('preLoginRoute');
        navigate(redirectRoute, { replace: true });
  
      } catch (error) {
        console.error('Login error:', error);
        setError(t('login_failed'));
        setMessage('');
      }
    }
  };  

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder={t('email')}
          className="input-field"
          required
        />
        {!forgotPassword && (
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder={t('password')}
            className="input-field"
            required
          />
        )}
        <br/>
        <br/>
        <button type="submit" className="button-center">
          {forgotPassword ? t('send_reset_link') : t('login')}
        </button>
      </form>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        {!forgotPassword ? (
          <button 
            onClick={() => setForgotPassword(true)} 
            className="button-simplelink"
          >
            {t('forgot_password')}
          </button>
        ) : (
          <>
            {resetLinkSent ? (
              <p>{t('password_reset_email_sent')}</p>
            ) : (
              <button 
                onClick={() => setForgotPassword(false)} 
                className="button-simplelink"
              >
                {t('return_to_login')}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LoginComponent;