// getRandomCollectionAndVoices.js

const collections = [
    { id: 1, voices: ['samples/PVQD-sustained-vowels/1_01.wav', 'samples/PVQD-sustained-vowels/1_02.wav', 'samples/PVQD-sustained-vowels/1_03.wav', 'samples/PVQD-sustained-vowels/1_04.wav', 'samples/PVQD-sustained-vowels/1_05.wav', 'samples/PVQD-sustained-vowels/1_06.wav', 'samples/PVQD-sustained-vowels/1_07.wav', 'samples/PVQD-sustained-vowels/1_08.wav', 'samples/PVQD-sustained-vowels/1_09.wav', 'samples/PVQD-sustained-vowels/1_10.wav', 'samples/PVQD-sustained-vowels/1_11.wav', 'samples/PVQD-sustained-vowels/1_12.wav', 'samples/PVQD-sustained-vowels/1_13.wav', 'samples/PVQD-sustained-vowels/1_14.wav', 'samples/PVQD-sustained-vowels/1_15.wav', 'samples/PVQD-sustained-vowels/1_16.wav', 'samples/PVQD-sustained-vowels/1_17.wav', 'samples/PVQD-sustained-vowels/1_18.wav', 'samples/PVQD-sustained-vowels/1_19.wav', 'samples/PVQD-sustained-vowels/1_20.wav', 'samples/PVQD-sustained-vowels/1_21.wav', 'samples/PVQD-sustained-vowels/1_22.wav', 'samples/PVQD-sustained-vowels/1_23.wav', 'samples/PVQD-sustained-vowels/1_24.wav', 'samples/PVQD-sustained-vowels/1_25.wav'] },
    { id: 2, voices: ['samples/PVQD-sustained-vowels/2_01.wav', 'samples/PVQD-sustained-vowels/2_02.wav', 'samples/PVQD-sustained-vowels/2_03.wav', 'samples/PVQD-sustained-vowels/2_04.wav', 'samples/PVQD-sustained-vowels/2_05.wav', 'samples/PVQD-sustained-vowels/2_06.wav', 'samples/PVQD-sustained-vowels/2_07.wav', 'samples/PVQD-sustained-vowels/2_08.wav', 'samples/PVQD-sustained-vowels/2_09.wav', 'samples/PVQD-sustained-vowels/2_10.wav', 'samples/PVQD-sustained-vowels/2_11.wav', 'samples/PVQD-sustained-vowels/2_12.wav', 'samples/PVQD-sustained-vowels/2_13.wav', 'samples/PVQD-sustained-vowels/2_14.wav', 'samples/PVQD-sustained-vowels/2_15.wav', 'samples/PVQD-sustained-vowels/2_16.wav', 'samples/PVQD-sustained-vowels/2_17.wav', 'samples/PVQD-sustained-vowels/2_18.wav', 'samples/PVQD-sustained-vowels/2_19.wav', 'samples/PVQD-sustained-vowels/2_20.wav', 'samples/PVQD-sustained-vowels/2_21.wav', 'samples/PVQD-sustained-vowels/2_22.wav', 'samples/PVQD-sustained-vowels/2_23.wav', 'samples/PVQD-sustained-vowels/2_24.wav', 'samples/PVQD-sustained-vowels/2_25.wav'] },
    { id: 3, voices: ['samples/PVQD-sustained-vowels/3_01.wav', 'samples/PVQD-sustained-vowels/3_02.wav', 'samples/PVQD-sustained-vowels/3_03.wav', 'samples/PVQD-sustained-vowels/3_04.wav', 'samples/PVQD-sustained-vowels/3_05.wav', 'samples/PVQD-sustained-vowels/3_06.wav', 'samples/PVQD-sustained-vowels/3_07.wav', 'samples/PVQD-sustained-vowels/3_08.wav', 'samples/PVQD-sustained-vowels/3_09.wav', 'samples/PVQD-sustained-vowels/3_10.wav', 'samples/PVQD-sustained-vowels/3_11.wav', 'samples/PVQD-sustained-vowels/3_12.wav', 'samples/PVQD-sustained-vowels/3_13.wav', 'samples/PVQD-sustained-vowels/3_14.wav', 'samples/PVQD-sustained-vowels/3_15.wav', 'samples/PVQD-sustained-vowels/3_16.wav', 'samples/PVQD-sustained-vowels/3_17.wav', 'samples/PVQD-sustained-vowels/3_18.wav', 'samples/PVQD-sustained-vowels/3_19.wav', 'samples/PVQD-sustained-vowels/3_20.wav', 'samples/PVQD-sustained-vowels/3_21.wav', 'samples/PVQD-sustained-vowels/3_22.wav', 'samples/PVQD-sustained-vowels/3_23.wav', 'samples/PVQD-sustained-vowels/3_24.wav', 'samples/PVQD-sustained-vowels/3_25.wav'] },
    { id: 4, voices: ['samples/PVQD-sustained-vowels/4_01.wav', 'samples/PVQD-sustained-vowels/4_02.wav', 'samples/PVQD-sustained-vowels/4_03.wav', 'samples/PVQD-sustained-vowels/4_04.wav', 'samples/PVQD-sustained-vowels/4_05.wav', 'samples/PVQD-sustained-vowels/4_06.wav', 'samples/PVQD-sustained-vowels/4_07.wav', 'samples/PVQD-sustained-vowels/4_08.wav', 'samples/PVQD-sustained-vowels/4_09.wav', 'samples/PVQD-sustained-vowels/4_10.wav', 'samples/PVQD-sustained-vowels/4_11.wav', 'samples/PVQD-sustained-vowels/4_12.wav', 'samples/PVQD-sustained-vowels/4_13.wav', 'samples/PVQD-sustained-vowels/4_14.wav', 'samples/PVQD-sustained-vowels/4_15.wav', 'samples/PVQD-sustained-vowels/4_16.wav', 'samples/PVQD-sustained-vowels/4_17.wav', 'samples/PVQD-sustained-vowels/4_18.wav', 'samples/PVQD-sustained-vowels/4_19.wav', 'samples/PVQD-sustained-vowels/4_20.wav', 'samples/PVQD-sustained-vowels/4_21.wav', 'samples/PVQD-sustained-vowels/4_22.wav', 'samples/PVQD-sustained-vowels/4_23.wav', 'samples/PVQD-sustained-vowels/4_24.wav', 'samples/PVQD-sustained-vowels/4_25.wav'] },
    { id: 5, voices: ['samples/PVQD-sustained-vowels/5_01.wav', 'samples/PVQD-sustained-vowels/5_02.wav', 'samples/PVQD-sustained-vowels/5_03.wav', 'samples/PVQD-sustained-vowels/5_04.wav', 'samples/PVQD-sustained-vowels/5_05.wav', 'samples/PVQD-sustained-vowels/5_06.wav', 'samples/PVQD-sustained-vowels/5_07.wav', 'samples/PVQD-sustained-vowels/5_08.wav', 'samples/PVQD-sustained-vowels/5_09.wav', 'samples/PVQD-sustained-vowels/5_10.wav', 'samples/PVQD-sustained-vowels/5_11.wav', 'samples/PVQD-sustained-vowels/5_12.wav', 'samples/PVQD-sustained-vowels/5_13.wav', 'samples/PVQD-sustained-vowels/5_14.wav', 'samples/PVQD-sustained-vowels/5_15.wav', 'samples/PVQD-sustained-vowels/5_16.wav', 'samples/PVQD-sustained-vowels/5_17.wav', 'samples/PVQD-sustained-vowels/5_18.wav', 'samples/PVQD-sustained-vowels/5_19.wav', 'samples/PVQD-sustained-vowels/5_20.wav', 'samples/PVQD-sustained-vowels/5_21.wav', 'samples/PVQD-sustained-vowels/5_22.wav', 'samples/PVQD-sustained-vowels/5_23.wav', 'samples/PVQD-sustained-vowels/5_24.wav', 'samples/PVQD-sustained-vowels/5_25.wav'] },
    { id: 6, voices: ['samples/PVQD-sustained-vowels/6_01.wav', 'samples/PVQD-sustained-vowels/6_02.wav', 'samples/PVQD-sustained-vowels/6_03.wav', 'samples/PVQD-sustained-vowels/6_04.wav', 'samples/PVQD-sustained-vowels/6_05.wav', 'samples/PVQD-sustained-vowels/6_06.wav', 'samples/PVQD-sustained-vowels/6_07.wav', 'samples/PVQD-sustained-vowels/6_08.wav', 'samples/PVQD-sustained-vowels/6_09.wav', 'samples/PVQD-sustained-vowels/6_10.wav', 'samples/PVQD-sustained-vowels/6_11.wav', 'samples/PVQD-sustained-vowels/6_12.wav', 'samples/PVQD-sustained-vowels/6_13.wav', 'samples/PVQD-sustained-vowels/6_14.wav', 'samples/PVQD-sustained-vowels/6_15.wav', 'samples/PVQD-sustained-vowels/6_16.wav', 'samples/PVQD-sustained-vowels/6_17.wav', 'samples/PVQD-sustained-vowels/6_18.wav', 'samples/PVQD-sustained-vowels/6_19.wav', 'samples/PVQD-sustained-vowels/6_20.wav', 'samples/PVQD-sustained-vowels/6_21.wav', 'samples/PVQD-sustained-vowels/6_22.wav', 'samples/PVQD-sustained-vowels/6_23.wav', 'samples/PVQD-sustained-vowels/6_24.wav', 'samples/PVQD-sustained-vowels/6_25.wav'] },
  ];
  
  function getRandomCollectionAndVoices() {
    const collectionIndex = Math.floor(Math.random() * collections.length);
    const selectedCollection = collections[collectionIndex];
    const shuffledVoices = selectedCollection.voices.sort(() => 0.5 - Math.random());
    const selectedVoices = shuffledVoices.slice(0, 4);
  
    // Map the selected voices to include sampleName, audioLink, and collectionId
    const voicesWithDetails = selectedVoices.map((voicePath) => {
      return {
        sampleName: extractSampleName(voicePath),
        audioLink: voicePath,
        collectionId: selectedCollection.id, // Afegir el camp collectionId
      };
    });
  
    return voicesWithDetails;
  }
  
  // Helper function to extract the sample name from the file path
  function extractSampleName(filePath) {
    // This assumes the sample name is the part of the file path after the last '/'
    // and removes the file extension
    return filePath.substring(filePath.lastIndexOf('/') + 1, filePath.lastIndexOf('.'));
  }
  
  export { getRandomCollectionAndVoices };