// BarGraph.jsx

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

const BarGraph = ({ attributeData, attributeName }) => {
    const { t } = useTranslation();
    const d3Container = useRef(null);

    useEffect(() => {
        if (!attributeData) return;

        const { pastRatings = [], userRating, sharedRatings = [] } = attributeData;

        // Distingim entre les dades de feedbackDataOriginalPVQD i les dades compartides
        const allRatingsFromPVQD = pastRatings.filter(rating => rating !== undefined); // Dades de PVQD (feedbackDataOriginalPVQD)
        const allRatingsFromShared = sharedRatings.filter(rating => rating !== undefined); // Dades compartides (other_allvoiced_users_evaluations)

        const totalRatings = allRatingsFromPVQD.length + allRatingsFromShared.length;

        // Si hi ha valoracions, transforma les dades a percentatges
        const ratingsCount = [0, 1, 2, 3].map(val => ({
            value: val,
            countFromPVQD: allRatingsFromPVQD.filter(r => r === val).length,
            countFromShared: allRatingsFromShared.filter(r => r === val).length,
            percentageFromPVQD: totalRatings ? (allRatingsFromPVQD.filter(r => r === val).length / totalRatings) * 100 : 0,  // Percentatge per PVQD
            percentageFromShared: totalRatings ? (allRatingsFromShared.filter(r => r === val).length / totalRatings) * 100 : 0,  // Percentatge per shared
        }));

        const margin = { top: 20, right: 100, bottom: 40, left: 40 }; // Ajusta el marge dret
        let width = 960 - margin.left - margin.right;
        let height = 200 - margin.top - margin.bottom;

        const svg = d3.select(d3Container.current)
            .attr('width', "100%")
            .attr('height', "100%")
            .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .style('overflow', 'visible');

        svg.selectAll("*").remove(); // Neteja per a re-dibuixar

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Configuració de l'escala x
        const xScale = d3.scaleBand()
            .domain([0, 1, 2, 3])
            .range([0, width])
            .padding(0.4);

        // Escala per a l'altura (percentatge)
        const yScale = d3.scaleLinear()
            .domain([0, 100]) // Percentatge de 0 a 100
            .range([height, 0]);

        // Eixos X i Y
        chart.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(d3.axisBottom(xScale).ticks(4));

        chart.append("g")
            .call(d3.axisLeft(yScale).ticks(5).tickFormat(d => d + "%"));  // Percentatge com a format

        // Dibuixa les barres apilades de PVQD i Shared dins de la mateixa barra
        chart.selectAll(".bar")
            .data(ratingsCount)
            .enter()
            .append("g")
            .attr("class", "bar")
            .attr("transform", d => `translate(${xScale(d.value)}, 0)`)
            .each(function(d) {
                const barGroup = d3.select(this);

                // Barra per a PVQD
                barGroup.append("rect")
                    .attr("y", yScale(d.percentageFromPVQD))
                    .attr("height", height - yScale(d.percentageFromPVQD))
                    .attr("width", xScale.bandwidth())
                    .attr("fill", "#a5d3ce");  // Color per les valoracions de PVQD (feedbackDataOriginalPVQD)

                // Barra per a Shared, apilada sobre la de PVQD
                barGroup.append("rect")
                    .attr("y", yScale(d.percentageFromPVQD + d.percentageFromShared))  // Comença just on acaba PVQD
                    .attr("height", height - yScale(d.percentageFromShared))
                    .attr("width", xScale.bandwidth())
                    .attr("fill", "#209385");  // Color per les valoracions compartides (other_allvoiced_users_evaluations)
            });

        // Dibuixa la valoració pròpia com un **punt separat** sobre la barra corresponent
        if (userRating !== undefined) {
            const userPercentage = ratingsCount.find(d => d.value === userRating).percentageFromPVQD + 
                                   ratingsCount.find(d => d.value === userRating).percentageFromShared;  // Percentatge combinat corresponent a la valoració de l'usuari

            // Marca la valoració de l'usuari com un punt per sobre de la barra
            chart.append("circle")
                .attr("cx", xScale(userRating) + xScale.bandwidth() / 2)  // Centre del punt sobre la barra
                .attr("cy", yScale(userPercentage) - 10)  // Punt separat 10 píxels per sobre de la barra
                .attr("r", 6)  // Radi del punt
                .attr("fill", "#0b5345")  // Color distintiu per la valoració de l'usuari
                .attr("stroke", "black")  // Contorn negre per fer el punt més visible
                .attr("stroke-width", 1.5);
        }

        // Llegenda fora del gràfic, a la dreta
        const legendGroup = svg.append('g')
            .attr('transform', `translate(${width + 60}, ${margin.top})`);  // Mou la llegenda fora de la zona del gràfic

        const legends = [
            { color: "#0b5345", text: t('your_evaluations') },  // Valoració pròpia
            { color: "#209385", text: t('other_allvoiced_users_evaluations') },  // Valoracions compartides (d'altres usuaris)
            { color: "#a5d3ce", text: t('evaluations_from_pvqd_walden_2022') },  // Dades originals de PVQD (feedbackDataOriginalPVQD)
        ];

        const legend = legendGroup.selectAll(".legend")
            .data(legends)
            .enter().append("g")
            .attr("class", "legend")
            .attr("transform", (_, i) => `translate(0, ${i * 20})`);

        legend.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", d => d.color);

        legend.append("text")
            .attr("x", 24)
            .attr("y", 14)
            .style("text-anchor", "start")
            .style("font-size", "11px")
            .text(d => d.text);

    }, [attributeData, t]);

    return (
        <div>
            <h3>{attributeName}</h3>
            <svg ref={d3Container}></svg>
        </div>
    );
};

export default BarGraph;