// CAPEVShareRenderAdditionalCharacteristics.jsx

import React from 'react';
import CAPEVShareVASGraphs from './VASGraphs';

const CAPEVShareRenderAdditionalCharacteristics = ({
  formData,
  sharedRatings,
  t,
  calculateSharedEvaluationsVariability, 
  calculateSharedPercentage,
  extractSharedResonanceData,
  extractSharedAdditionalFeaturesData,
  extractSharedComments,
}) => {
  const resonanceKeys = [
    'normal_resonance',
    'hypernasality',
    'hyponasality',
    'other'
  ];

  const featureKeys = [
    'diplophonia',
    'fry',
    'falsetto',
    'asthenia',
    'aphonia',
    'pitch_instability',
    'tremor',
    'wet_gurgly',
    'other'
  ];

  const renderGraph = (key, title, sharedValues, formDataForKey, category) => {
    if (key === 'normal_resonance') {
      const sharedPercentage = Number(calculateSharedPercentage(sharedRatings, key, category)) || 0;
      return (
        <div key={key}>
          <h4>{t(title)}</h4>
          {formDataForKey && (
            <p className="small">
              {t('your_evaluations')}: {t(formDataForKey.variability || '')}
            </p>
          )}
          <p className="small">
            {t('other_allvoiced_users_evaluations')}: {sharedPercentage.toFixed(0)}%
          </p>
        </div>
      );
    }

    let sharedPercentage = Number(calculateSharedPercentage(sharedRatings, key, category)) || 0;

    const { consistentPercent: sharedConsistentPercent, intermittentPercent: sharedIntermittentPercent } = calculateSharedEvaluationsVariability(sharedValues, key);

    return (
      <div key={key}>
        <h4>{t(title)}</h4>
        {formDataForKey && (
          <p className="small">
            {t('your_evaluations')}: {t(formDataForKey.variability || '')}
          </p>
        )}
        <p className="small">
          {t('other_allvoiced_users_evaluations')}: {sharedPercentage.toFixed(0)}% ({t('C')} = {sharedConsistentPercent.toFixed(0)}%, {t('I')} = {sharedIntermittentPercent.toFixed(0)}%)
        </p>
        <CAPEVShareVASGraphs
          attributeName={title}
          attributeData={{
            formRating: formDataForKey ? formDataForKey.value : null,
            sharedRatings: sharedValues.map(v => v.value),
          }}
        />
      </div>
    );
  };

  return (
    <div className="additional-info">
      <h2>{t('additional_attributes_evaluations')}</h2>
      <h3>{t('comments_resonance')}</h3>
      {resonanceKeys.map(key => {
        const sharedValues = extractSharedResonanceData(sharedRatings, key) || [];
        const formDataForKey = formData && formData.additionalResonances ? formData.additionalResonances[key] : null;
        return renderGraph(key, key, sharedValues, formDataForKey, 'additionalResonances');
      })}

      <h3>{t('additional_features')}</h3>
      {featureKeys.map(key => {
        const sharedValues = extractSharedAdditionalFeaturesData(sharedRatings, key) || [];
        const formDataForKey = formData && formData.additionalFeatures ? formData.additionalFeatures[key] : null;
        return renderGraph(key, key, sharedValues, formDataForKey, 'additionalFeatures');
      })}

      <h3>{t('general_comments')}</h3>
      <p className="small">
        <strong>{t('your_evaluations')}:</strong> {formData && formData.comments ? formData.comments : t('no_comments')}
      </p>
      <p className="small">
        <strong>{t('other_allvoiced_users_evaluations')}: </strong> 
        {extractSharedComments(sharedRatings).length > 0 
          ? extractSharedComments(sharedRatings).join(', ') 
          : t('no_comments')}
      </p>
    </div>
  );
};

export default CAPEVShareRenderAdditionalCharacteristics;