// StageTwo.jsx

import React, { useState, useEffect } from 'react';

// Afegim aquesta definició per tenir disponible `subcategoryMap`
const subcategoryMap = {
    overall: ["overall_severity"],
    pitch: ["excessively_high_pitched_voice", "excessively_low_pitched_voice", "monotone_voice", "pitch_breaks", "pitch_instability", "tremor"],
    loudness: ["excessively_loud_voice", "excessively_soft_voice", "monoloud_voice", "loudness_instability", "hardness_of_the_glottal_attacks", "tremor"],
    voice_quality_phonation_features: ["roughness", "breathiness", "strain", "asthenia", "falsetto", "fry", "wet_gurgly", "diplophonia", "voice_quality_instability"],
    voice_quality_vocal_tract_features: ["lack_of_ring", "hypernasality", "hyponasality", "voice_with_constricted_larynx", "audible_nasal_air_escape", "resonance_instability"],
    length: ["from_phonation_breaks_to_aphonia", "voice_arrests"],
};

const StageTwo = ({ previousResults, onUpdateSelections, t }) => {
    const [selections, setSelections] = useState({});

    useEffect(() => {
        // Initialize selections based on categories selected in Stage One
        const initialSelections = {};
        Object.keys(previousResults.selections).forEach(category => {
            if (previousResults.selections[category]) { // Only if the category was actually selected
                initialSelections[category] = subcategoryMap[category]?.reduce((acc, subcat) => {
                    // Initialize from existing selections or set to false if not previously selected
                    acc[subcat] = previousResults.selections[category]?.[subcat] ?? false;
                    return acc;
                }, {}) ?? {}; // Guard against undefined subcategory maps
            }
        });
        setSelections(initialSelections);
    }, [previousResults.selections]);

    const toggleSelection = (category, subcategory) => {
        setSelections(prevSelections => {
            const newSelections = {
                ...prevSelections,
                [category]: {
                    ...prevSelections[category],
                    [subcategory]: !prevSelections[category][subcategory]
                }
            };
    
            // Update parent component's state
            onUpdateSelections({
                ...previousResults.selections,
                [category]: newSelections[category]
            });
    
            return newSelections;
        });
    };    

    return (
        <div>
            <h2>{t('stage')} 2. {t('select_subcategories')}</h2>
            <p>{t('lorem_ipsum')}</p>
            {Object.keys(selections).map(category => (
                <div key={category}>
                    <h4>{t(category)}</h4>
                    <div className="button-container">
                        {Object.keys(selections[category]).map(subcat => (
                            <button
                                key={subcat}
                                onClick={() => toggleSelection(category, subcat)}
                                className={`button-selectable ${selections[category][subcat] ? 'selected' : ''}`}
                            >
                                {t(subcat)}
                            </button>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default StageTwo;