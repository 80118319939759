// userSpecificData.js

const userSpecificData = {
    admin: {
      attributes: [
        {
          title: 'roughness',
          audioFilesSets: [
            ['samples/PVQD-sustained-vowels/2_05.wav', 'samples/PVQD-sustained-vowels/2_13.wav', 'samples/PVQD-sustained-vowels/2_22.wav', 'samples/PVQD-sustained-vowels/2_09.wav', 'samples/PVQD-sustained-vowels/2_19.wav', 'samples/PVQD-sustained-vowels/2_20.wav', 'samples/PVQD-sustained-vowels/2_24.wav', 'samples/PVQD-sustained-vowels/2_15.wav', 'samples/PVQD-sustained-vowels/2_23.wav', 'samples/PVQD-sustained-vowels/2_01.wav', 'samples/PVQD-sustained-vowels/2_07.wav', 'samples/PVQD-sustained-vowels/2_21.wav', 'samples/PVQD-sustained-vowels/2_17.wav', 'samples/PVQD-sustained-vowels/2_25.wav', 'samples/PVQD-sustained-vowels/2_10.wav', 'samples/PVQD-sustained-vowels/2_08.wav', 'samples/PVQD-sustained-vowels/2_03.wav', 'samples/PVQD-sustained-vowels/2_02.wav', 'samples/PVQD-sustained-vowels/2_12.wav', 'samples/PVQD-sustained-vowels/2_06.wav', 'samples/PVQD-sustained-vowels/2_14.wav', 'samples/PVQD-sustained-vowels/2_16.wav', 'samples/PVQD-sustained-vowels/2_04.wav', 'samples/PVQD-sustained-vowels/2_11.wav', 'samples/PVQD-sustained-vowels/2_18.wav'],
          ],
          // Other specific configurations
        },
        // More attributes...
      ],
    },
    noel: {
      attributes: [
        {
          title: 'roughness',
          audioFilesSets: [
            ['samples/PVQD-sustained-vowels/2_05.wav', 'samples/PVQD-sustained-vowels/2_13.wav', 'samples/PVQD-sustained-vowels/2_22.wav', 'samples/PVQD-sustained-vowels/2_09.wav', 'samples/PVQD-sustained-vowels/2_19.wav', 'samples/PVQD-sustained-vowels/2_20.wav', 'samples/PVQD-sustained-vowels/2_24.wav', 'samples/PVQD-sustained-vowels/2_15.wav', 'samples/PVQD-sustained-vowels/2_23.wav', 'samples/PVQD-sustained-vowels/2_01.wav', 'samples/PVQD-sustained-vowels/2_07.wav', 'samples/PVQD-sustained-vowels/2_21.wav', 'samples/PVQD-sustained-vowels/2_17.wav', 'samples/PVQD-sustained-vowels/2_25.wav', 'samples/PVQD-sustained-vowels/2_10.wav', 'samples/PVQD-sustained-vowels/2_08.wav', 'samples/PVQD-sustained-vowels/2_03.wav', 'samples/PVQD-sustained-vowels/2_02.wav', 'samples/PVQD-sustained-vowels/2_12.wav', 'samples/PVQD-sustained-vowels/2_06.wav', 'samples/PVQD-sustained-vowels/2_14.wav', 'samples/PVQD-sustained-vowels/2_16.wav', 'samples/PVQD-sustained-vowels/2_04.wav', 'samples/PVQD-sustained-vowels/2_11.wav', 'samples/PVQD-sustained-vowels/2_18.wav'],
          ],
          // Other specific configurations
        },
        // More attributes...
      ],
    },
    // More users...
  };
  
  export default userSpecificData;