// PasswordResetComponent.jsx

import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PasswordResetComponent = () => {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${apiUrl}/users/password-reset/${token}`, { password: newPassword });
      setMessage(t('password_reset_success'));
      setTimeout(() => navigate('/login'), 2000); // Redirigeix a la pàgina de login després de 2 segons
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage(t('password_reset_error'));
    }
  };

  return (
    <div>
      <h2>{t('reset_password')}</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="password"
          name="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder={t('enter_new_password')}
          className="input-field"
          required
        />
        <button type="submit" className="button-center">{t('reset_password')}</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default PasswordResetComponent;