// useAudioPlayerWithStop.js

import { useState } from 'react';

const useAudioPlayerWithStop = () => {
  const [audio, setAudio] = useState(null);

  const playAudio = (audioLink) => {
    const uniqueAudioLink = audioLink + '?t=' + new Date().getTime();
    const newAudio = new Audio(uniqueAudioLink);
    setAudio(newAudio);
    newAudio.play().catch(e => console.error("Error playing audio:", e));
  };

  const stopAudio = () => {
    if (audio) {
      audio.pause();
      setAudio(null);
    }
  };

  return { playAudio, stopAudio };
};

export default useAudioPlayerWithStop;