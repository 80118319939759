// authContext.js

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null); // Estat per gestionar errors
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

    const fetchUserData = useCallback(async (token) => {
        try {
            const response = await axios.get(`${apiUrl}/users/me`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUser({ ...response.data, token });
            setError(null); // Reinicia l'estat d'error si tot va bé
            console.log('User data set in context:', response.data);
        } catch (error) {
            console.error('Error fetching user data:', error);

            // Si l'error és perquè el token ha expirat, realitza el logout
            if (error.response && error.response.data.message === 'jwt expired') {
                logout(); 
                window.location.href = '/login'; 
            } else {
                setError('Failed to fetch user data');
            }
        }
    }, [apiUrl]);

    const login = async (email, password) => {
        try {
            const response = await axios.post(`${apiUrl}/users/login`, { email, password });
            const { token, user } = response.data;
            localStorage.setItem('token', token);
            setUser({ ...user, token });
            console.log('User logged in and state set:', { ...user, token });
            await fetchUserData(token);
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('token');
        setError(null); // Neteja l'estat d'error quan es fa logout
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
    
        if (token) {
            fetchUserData(token); 
        } 
    }, [fetchUserData]);  

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    logout();
                    alert('Your session has expired. You have been logged out.');
                }
                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);  
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser, login, logout, error }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;