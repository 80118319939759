// StageOne.jsx

import React, { useState } from 'react';

function StageOne({ onUpdateSelections, selections, t }) {
    const [localSelections, setLocalSelections] = useState(selections || {
        overall: false,
        pitch: false,
        loudness: false,
        voice_quality_phonation_features: false,
        voice_quality_vocal_tract_features: false,
        length: false,
    });

    const toggleSelection = (category) => {
        const updatedSelections = {
            ...localSelections,
            [category]: !localSelections[category]
        };
        setLocalSelections(updatedSelections);
        onUpdateSelections(updatedSelections);
    };

    return (
        <div>
            <h2>{t('stage')} 1. {t('select_categories')}</h2>
            <p>{t('lorem_ipsum')}</p>
            <div className="button-container">
                {Object.keys(localSelections).map((category) => (
                    <button 
                        key={category}
                        onClick={() => toggleSelection(category)}
                        className={`button-selectable ${localSelections[category] ? 'selected' : ''}`}
                    >
                        {t(category)}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default StageOne;