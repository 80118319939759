// RBSForm.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function RBSForm({ onSubmit, sampleData }) {
  const { t } = useTranslation();
  const [roughness, setRoughness] = useState(0);
  const [breathiness, setBreathiness] = useState(0);
  const [strain, setStrain] = useState(0);
  const [showSociodemographicData, setShowSociodemographicData] = useState(false); // Estat per la checkbox

  const submit = () => {
    const data = {
        roughness: Number(roughness),
        breathiness: Number(breathiness),
        strain: Number(strain)
    };
    onSubmit(data);
  };

  return (
    <div className="VocalEvaluationForm">
      {/* Checkbox per mostrar dades sociodemogràfiques */}
      <div className="sociodemographic-toggle" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <input
          type="checkbox"
          id="showSociodemographicData"
          checked={showSociodemographicData}
          onChange={() => setShowSociodemographicData(!showSociodemographicData)}
        />
        <label htmlFor="showSociodemographicData">
          {t('show_sociodemographic_data')}
        </label>
      </div>

      {showSociodemographicData && sampleData && (
        <div className="sample-info">
          <p className="small">{t('subject')}: {sampleData.participantId || t('not_available')}</p>
          <p className="small">{t('age')}: {sampleData.age || t('not_available')}</p>
          <p className="small">{t('gender')}: {sampleData.gender ? t(sampleData.gender) : t('not_available')}</p>
          <p className="small">{t('diagnose')}: {sampleData.diagnose ? t(sampleData.diagnose) : t('not_available')}</p>
          <p className="small">{t('language')}: {sampleData.language ? t(sampleData.language) : t('not_available')}</p>
          <p className="small">{t('database')}: {sampleData.database || t('not_available')}</p>
        </div>
      )}
      <br/>
      <div className="inputContainer">
        <label>{t('roughness')}</label>
        <input
          type="range"
          min="0"
          max="100"
          value={roughness}
          className="slider"
          onChange={(e) => setRoughness(e.target.value)}
        />
      </div>
      <div className="inputContainer">
        <label>{t('breathiness')}</label>
        <input
          type="range"
          min="0"
          max="100"
          value={breathiness}
          className="slider"
          onChange={(e) => setBreathiness(e.target.value)}
        />
      </div>
      <div className="inputContainer">
        <label>{t('strain')}</label>
        <input
          type="range"
          min="0"
          max="100"
          value={strain}
          className="slider"
          onChange={(e) => setStrain(e.target.value)}
        />
      </div>
      <br/>
      <div className="button-container">
        <button className="button-center" onClick={submit}>{t('submit')}</button>
      </div>
    </div>
  );
}

export default RBSForm;