// CAPEVFeedback.jsx

import React, { useEffect, useRef } from 'react';
import CAPEVShareRenderStandardAttributes from './RenderStandardAttributes';
import CAPEVShareRenderAdditionalCharacteristics from './RenderAdditionalCharacteristics';
import SharedDataProcessor from './SharedDataProcessor';

const CAPEVFeedback = ({
    t,
    formData, 
    sampleData, 
    formSubmitted, 
    sharedEvaluations = [],   
    pvqdEvaluations = [],     
    showSociodemographicData,
    showStandardAttributes,
    showAdditionalAttributes,
}) => {
  const feedbackRef = useRef(null);

  useEffect(() => {
    if (feedbackRef.current) {
      feedbackRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const {
    sharedRatings,
    calculateSharedEvaluationsVariability,
    filterSharedEvaluationsByDirection,
    calculateSharedEvaluationsDirectionPercentage,
    calculateSharedPercentage,
    extractSharedRatings,
    extractSharedResonanceData,
    extractSharedAdditionalFeaturesData,
    extractSharedComments
  } = SharedDataProcessor({ sharedEvaluations });

  const attributes = [
    'overallSeverity',
    'roughness',
    'breathiness',
    'strain',
    'pitch',
    'loudness'
  ];

  const attributeMapping = {
    overallSeverity: t('overall_severity'),
    roughness: t('roughness'),
    breathiness: t('breathiness'),
    strain: t('strain'),
    pitch: t('pitch'),
    loudness: t('loudness')
  };

  const transformPVQDEvaluations = (evaluations, attribute) => {
    if (!evaluations) return [];
  
    // Evitar la transformació de dades per pitch i loudness
    if (['pitch', 'loudness'].includes(attribute)) {
      return []; // Retornar una llista buida per aquests atributs
    }
  
    return evaluations.flatMap(evaluation => [
      ...(evaluation[attribute] || []).map(value => ({ type: attribute, value }))
    ]);
  };  

  return (
    <div className="feedback-container" ref={feedbackRef}>
      {showSociodemographicData && sampleData && (
        <div className="sample-info">
          <h2>{t('sociodemographic_data')}</h2>
          <p className="small centered-text">{t('subject')}: {sampleData.participantId}</p>
          <p className="small centered-text">{t('age')}: {sampleData.age}</p>
          <p className="small centered-text">{t('gender')}: {sampleData.gender ? t(sampleData.gender) : t('not_available')}</p>
          <p className="small centered-text">{t('diagnose')}: {sampleData.diagnose ? t(sampleData.diagnose) : t('not_available')}</p>
          <p className="small centered-text">{t('language')}: {sampleData.language ? t(sampleData.language) : t('not_available')}</p>
          <p className="small centered-text">{t('database')}: {sampleData.database}</p>
        </div>
      )}
      {showStandardAttributes && (
        <CAPEVShareRenderStandardAttributes
          t={t}
          attributes={attributes}
          attributeMapping={attributeMapping}
          formSubmitted={formSubmitted} 
          formData={formData || {}} 
          sharedRatings={sharedRatings}
          transformPVQDEvaluations={transformPVQDEvaluations}
          pvqdEvaluations={pvqdEvaluations} 
          calculateSharedEvaluationsVariability={calculateSharedEvaluationsVariability}
          filterSharedEvaluationsByDirection={filterSharedEvaluationsByDirection}
          calculateSharedEvaluationsDirectionPercentage={calculateSharedEvaluationsDirectionPercentage}
          extractSharedRatings={extractSharedRatings}
        />
      )}
      <br/>
      {showAdditionalAttributes && (
        <CAPEVShareRenderAdditionalCharacteristics
          t={t}
          formSubmitted={formSubmitted} 
          formData={formData || {}} 
          sharedRatings={sharedRatings}
          calculateSharedEvaluationsVariability={calculateSharedEvaluationsVariability} 
          calculateSharedPercentage={calculateSharedPercentage}
          extractSharedResonanceData={extractSharedResonanceData}
          extractSharedAdditionalFeaturesData={extractSharedAdditionalFeaturesData}
          extractSharedComments={extractSharedComments}
        />
      )}
    </div>
  );
};

export default CAPEVFeedback;