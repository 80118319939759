// formatResultsForCVS.js
// Ara només es fa servir a CAPEVClinic perquè a CAPEVScholar fem servir formatResultsToClipboard.js que inclou el format tant de cvs com de report

export const formatResultsForCSV = (data, t) => {
  const allAdditionalResonances = ['hypernasality', 'hyponasality', 'other'];
  const allAdditionalFeatures = ['diplophonia', 'fry', 'falsetto', 'asthenia', 'aphonia', 'pitch_instability', 'tremor', 'wet_gurgly', 'other'];

  const additionalResonancesParts = allAdditionalResonances.map(resonance => {
    const detail = data.additionalResonances[resonance] || { value: 0, variability: 'NA' };
    const value = typeof detail.value === 'object' ? detail.value.value : detail.value;
    const resonanceLabel = resonance === 'other' && detail && detail.label ? detail.label : t(resonance);
    return `${t(resonanceLabel)},${value},${t(detail.variability)}`;
  });

  const additionalFeaturesParts = allAdditionalFeatures.map(feature => {
    const detail = data.additionalFeatures[feature] || { value: 0, variability: 'NA' };
    const value = typeof detail.value === 'object' ? detail.value.value : detail.value;
    const featureLabel = feature === 'other' && detail && detail.label ? detail.label : t(feature);
    return `${t(featureLabel)},${value},${t(detail.variability)}`;
  });

  const interpretDirection = (direction, context) => {
    let directionString = '';
    if (context === "pitch") {
      if (direction.tooHigh) directionString += "too_high";
      if (direction.tooHigh && direction.tooLow) directionString += ", ";
      if (direction.tooLow) directionString += "too_low";
    } else if (context === "loudness") {
      if (direction.tooLoud) directionString += "too_loud";
      if (direction.tooLoud && direction.tooSoft) directionString += ", ";
      if (direction.tooSoft) directionString += "too_soft";
    }
    return directionString || "normal";
  };

  const parts = [
    `${t('sample')},${data.sampleName}`, 
    `${t('overall_severity')},${data.overallSeverity.value},${t(data.overallSeverity.variability)}`,
    `${t('roughness')},${data.roughness.value},${t(data.roughness.variability)}`,
    `${t('breathiness')},${data.breathiness.value},${t(data.breathiness.variability)}`,
    `${t('strain')},${data.strain.value},${t(data.strain.variability)}`,
    `${t('pitch')},${data.pitch.value},${interpretDirection(data.pitch.direction, "pitch")},${t(data.pitch.variability)}`,
    `${t('loudness')},${data.loudness.value},${interpretDirection(data.loudness.direction, "loudness")},${t(data.loudness.variability)}`
  ];

  const comments = [`${t('general_comments')},${data.comments}`];

  return [...parts, ...additionalResonancesParts, ...additionalFeaturesParts, ...comments].join(',');
};