// Results.jsx
import React from 'react'; 
import { useTranslation } from 'react-i18next'; 

const Results = ({ evaluatedAttribute, pairComparisonData, calculatedRankingData, rankingData, sliderValues }) => {
  const { t } = useTranslation(); 

  return (
    <div>
      <h1>{evaluatedAttribute}</h1>
      <h2>{t('results')}</h2>
      <div>
        <h3>1. {t('pair_comparison')}:</h3>
        <ul>
          {pairComparisonData.map((comparison, index) => (
            <li key={index}>
              {t('pair')} {index + 1}: {comparison.pair[0].sampleName} vs. {comparison.pair[1].sampleName} ({t('selected')}: {comparison.selectedSample.sampleName})
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3>2. {t('calculated_ranking')}:</h3>
        <ul>
          {calculatedRankingData.map((item, index) => (
            <li key={index}>
              {t('rank')} {index + 1}: {item.sampleName} ({t('selected')} {item.count} {t('times')})
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3>3. {t('confirmed_ranking')}:</h3>
        <ul>
          {rankingData && rankingData[0].items.map((item, index) => (
            <li key={index}>{t('rank')} {index + 1}: {item.sampleName}</li>
          ))}
        </ul>
      </div>
      <div>
        <h3>4. {t('vas_rankings')}:</h3>
        <ul>
          {sliderValues.map((item, index) => (
            <li key={index}>{t('sample')} {index + 1} ({item.sampleName}): {item.value}/100</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Results;