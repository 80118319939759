// Sliders.jsx 

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAudioPlayer from '../shared-files/tools-and-functions/useAudioPlayer';

const Sliders = ({ evaluatedAttribute, sorts, onCompletion }) => {
  const { t } = useTranslation();
  const { playAudio } = useAudioPlayer();

  // Initialize sliderValues state with both sample names and initial slider values
  const [sliderValues, setSliderValues] = useState(sorts[0].items.map(item => ({
    sampleName: item.sampleName, // Assuming each item has a sampleName property
    value: 0 // Initial value for each slider
  })));

  const handleSliderChange = (index, value) => {
    const newSliderValues = [...sliderValues];
    newSliderValues[index].value = value; // Update the value for the slider at the specified index
    setSliderValues(newSliderValues);
  };

  const handleCompleteRatings = () => {
    // Pass the structured sliderValues (including sample names and values) and the sorted data to the parent component
    if (onCompletion) onCompletion(sliderValues, sorts);
  };

  return (
    <div>
      <h2>{evaluatedAttribute}</h2>
      <p>{t('training_sliders_instructions')}</p>
      {sorts[0].items.map((item, index) => (
        <div key={index}>
          <button onClick={() => playAudio(item.audioLink)}>Play</button>
          <input
            type="range"
            min="0"
            max="100"
            value={sliderValues[index].value} // Access the value from the structured sliderValues
            onChange={(e) => handleSliderChange(index, parseInt(e.target.value))}
          />
        </div>
      ))}
      <button onClick={handleCompleteRatings}>{t('your_ratings')}</button>
    </div>
  );
};

export default Sliders;
