//CollectionsFreeSortingTask.jsx

import React from 'react';
import sampleCollections from './sampleCollections.js'; // Adjust the path according to your file structure
import { useTranslation } from 'react-i18next';
import FreeSortingTaskStructure from './../free-sorting-task/FreeSortingTaskStructure.jsx';

const CollectionsFreeSortingTask = () => {
    const { t } = useTranslation();

    // Function to randomize the samples for each collection
    const getRandomizedSamples = (collection) => {
        // Assuming each collection has a 'voices' property that is an array of audio file paths
        return [...collection.voices].sort(() => Math.random() - 0.5);
    };

    return (
        <div>
            <h2>{t('free_sorting_task')}</h2> 
            <p>{t('CFST_instructions')}</p>
            {sampleCollections.map((collection) => {
                const randomizedSamples = getRandomizedSamples(collection);
                const userData = {
                    attributes: [
                        {
                            audioFilesSets: [randomizedSamples],
                            // Add more properties if necessary
                        }
                    ]
                };

                return (
                    <div key={collection.id}>
                        <h3>{`${t('collection')} ${collection.id}`}</h3>
                        <FreeSortingTaskStructure userData={userData} />
                    </div>
                );
            })}
        </div>
    );
};

export default CollectionsFreeSortingTask;