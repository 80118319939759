// NavigationIcons.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

function NavigationIcons({ exclude = [] }) {
  const icons = [
    { path: '/', icon: 'home-icon.png', textKey: 'home' }, 
    { path: '/resources-for-autonomous-training', icon: 'training-icon.png', textKey: 'resources_for_autonomous_training_title' },
    { path: '/resources-for-teaching-and-learning', icon: 'teaching-icon.png', textKey: 'resources_for_teaching_and_learning_title' },
    { path: '/resources-for-research', icon: 'research-icon.png', textKey: 'resources_for_research_title' },
    { path: '/all-the-resources', icon: 'all-resources-icon.png', textKey: 'all_the_resources_title' }, 
  ];

  return (
    <div className="icon-button-container">
      {icons.filter(icon => !exclude.includes(icon.path)).map(icon => (
        <div key={icon.path} className="icon-container">
          <Link to={icon.path} className="icon-button">
            <img src={`/images/${icon.icon}`} alt={`${icon.textKey} Icon`} className="mini-icon" />
            <span className="link-tooltip">
              <Trans i18nKey={icon.textKey} />
            </span>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default NavigationIcons;