// calculateSampleRanking.js

function calculateSampleRanking(userResponses) {
  const sampleCounts = {};

  // Initialize or ensure counts for samples in pairs
  userResponses.forEach(response => {
    response.pair.forEach(sample => {
        const name = sample.sampleName;
        if (!sampleCounts[name]) {
            sampleCounts[name] = { count: 0, ...sample };
        }
    });

    // Detailed logging for debugging
    console.log(`Processing response:`, response);
    console.log(`Sample counts before processing:`, sampleCounts);

    // Check if selectedSample is an object and log its properties
    if (typeof response.selectedSample === 'object') {
        console.log(`Selected sample object properties:`, response.selectedSample);
    }

    // Assuming selectedSample directly contains the sample name
    const selectedSampleName = response.selectedSample ? response.selectedSample.sampleName : null;

    // Increment count for selected sample if it's found in sampleCounts
    if (selectedSampleName && sampleCounts[selectedSampleName]) {
        sampleCounts[selectedSampleName].count++;
        console.log(`Incremented count for ${selectedSampleName}`);
    } else {
        console.log(`Selected sample not found in counts:`, selectedSampleName);
    }
  });

  const ranking = Object.values(sampleCounts).sort((a, b) => a.count - b.count);
  console.log("Ranking:", ranking);
  return ranking;
}

export default calculateSampleRanking;