// DirectionDeviation.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function DirectionDeviation({ directionLabel1, directionLabel2, value, setValue }) {
  const { t } = useTranslation();
  const handleOptionChange = (event) => {
    const { name, checked } = event.target;

    const newDirection = {
      ...value,
      [name]: checked,
    };
    setValue(newDirection);
  };

  return (
    <div>
      <label className="horizontal-labels">
        <input
          type="checkbox"
          name={directionLabel1 === t('too_high') || directionLabel1 === t('too_loud') ? (directionLabel1 === t('too_high') ? "tooHigh" : "tooLoud") : ""}
          checked={directionLabel1 === t('too_high') ? value.tooHigh : value.tooLoud || false}
          onChange={handleOptionChange}
        />
        {directionLabel1}
      </label>
      <label className="horizontal-labels">
        <input
          type="checkbox"
          name={directionLabel2 === t('too_low') || directionLabel2 === t('too_soft') ? (directionLabel2 === t('too_low') ? "tooLow" : "tooSoft") : ""}
          checked={directionLabel2 === t('too_low') ? value.tooLow : value.tooSoft || false}
          onChange={handleOptionChange}
        />
        {directionLabel2}
      </label>
    </div>
  );
}

export default DirectionDeviation;