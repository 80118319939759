// ProtectedRoute.jsx

import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './authContext';
import axios from 'axios';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { user } = useAuth();
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchUserRole = async () => {
      const token = localStorage.getItem('token');  // Afegeix el token des de localStorage
      if (user && token) {
        try {
          const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';
          const response = await axios.get(`${apiUrl}/users/${user._id}/role`, {
            headers: {
              Authorization: `Bearer ${token}`,  // Passa el token com a part de l'encapçalament
            }
          });
          setUserRole(response.data.role);
        } catch (error) {
          console.error('Error fetching user role:', error);
          setError(true);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };    

    fetchUserRole();
  }, [user]);

  // Mostra una pantalla de càrrega mentre s'obté el rol
  if (loading) {
    return <div>Loading...</div>;
  }

  // Si hi ha un error en obtenir el rol, redirigeix o mostra un missatge d'error
  if (error) {
    return <div>There was an error fetching your role. Please try again later.</div>;
  }

  // Si l'usuari no està autenticat, redirigeix-lo al login
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // Si el rol de l'usuari no està permès, redirigeix-lo a una pàgina no autoritzada
  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/unauthorized" replace />;
  }

  // Si tot és correcte, renderitza els components fills
  return children;
};

export default ProtectedRoute;