// CAPEVShareRenderStandardAttributes.jsx

import React from 'react';
import CAPEVShareVASGraphs from './VASGraphs';

const CAPEVShareRenderStandardAttributes = ({
  attributes,
  attributeMapping,
  formData,
  sharedRatings,
  t,
  transformPVQDEvaluations,
  pvqdEvaluations,
  calculateSharedEvaluationsVariability,
  filterSharedEvaluationsByDirection,
  calculateSharedEvaluationsDirectionPercentage,
  extractSharedRatings
}) => {
  return (
    <div>
      <h2>{t('standard_attributes_evaluations')}</h2>
      {attributes.map(attribute => {
        const attributeName = attributeMapping[attribute];
        const dataKey = attribute;

        const validFormData = formData && formData[dataKey];
        const validSharedRatings = sharedRatings && sharedRatings.every(rating => rating && rating[dataKey]);

        if (!validFormData && !validSharedRatings) {
          return null;
        }

        const sharedVariability = validSharedRatings ? calculateSharedEvaluationsVariability(sharedRatings, dataKey) : {};
        const { consistentPercent: sharedConsistentPercent = 0, intermittentPercent: sharedIntermittentPercent = 0 } = sharedVariability;

        return (
          <div key={attributeName}>
            <h3>{attributeName}</h3>
            {validFormData && (
              <p className="small">
                {t('your_evaluations')}: {t(formData[dataKey]?.variability || '')}
              </p>
            )}
            {validSharedRatings && (
              <p className="small">
                {t('other_allvoiced_users_evaluations')}: {t('C')} = {sharedConsistentPercent.toFixed(0)}%, {t('I')} = {sharedIntermittentPercent.toFixed(0)}%
              </p>
            )}
            {dataKey === 'pitch' ? (
              <>
                <h4>{t('too_high')}</h4>
                {validFormData && (
                  <p className="small">
                    {t('your_evaluations')}: {formData[dataKey]?.direction?.tooHigh ? t('yes') : t('no')}
                  </p>
                )}
                {validSharedRatings && (
                  <p className="small">
                    {t('other_allvoiced_users_evaluations')}: {calculateSharedEvaluationsDirectionPercentage(sharedRatings, dataKey, 'tooHigh').toFixed(0)}%
                  </p>
                )}
                <CAPEVShareVASGraphs
                  attributeName={`${attributeName} ${t('too_high')}`}
                  attributeData={{
                    formRating: formData[dataKey]?.direction?.tooHigh ? formData[dataKey]?.value : null,
                    sharedRatings: filterSharedEvaluationsByDirection(sharedRatings, dataKey, 'tooHigh'),
                    pvqdEvaluations: transformPVQDEvaluations(pvqdEvaluations, dataKey)
                  }}
                />
                <h4>{t('too_low')}</h4>
                {validFormData && (
                  <p className="small">
                    {t('your_evaluations')}: {formData[dataKey]?.direction?.tooLow ? t('yes') : t('no')}
                  </p>
                )}
                {validSharedRatings && (
                  <p className="small">
                    {t('other_allvoiced_users_evaluations')}: {calculateSharedEvaluationsDirectionPercentage(sharedRatings, dataKey, 'tooLow').toFixed(0)}%
                  </p>
                )}
                <CAPEVShareVASGraphs
                  attributeName={`${attributeName} ${t('too_low')}`}
                  attributeData={{
                    formRating: formData[dataKey]?.direction?.tooLow ? formData[dataKey]?.value : null,
                    sharedRatings: filterSharedEvaluationsByDirection(sharedRatings, dataKey, 'tooLow'),
                    pvqdEvaluations: transformPVQDEvaluations(pvqdEvaluations, dataKey)
                  }}
                />
              </>
            ) : dataKey === 'loudness' ? (
              <>
                <h4>{t('too_loud')}</h4>
                {validFormData && (
                  <p className="small">
                    {t('your_evaluations')}: {formData[dataKey]?.direction?.tooLoud ? t('yes') : t('no')}
                  </p>
                )}
                {validSharedRatings && (
                  <p className="small">
                    {t('other_allvoiced_users_evaluations')}: {calculateSharedEvaluationsDirectionPercentage(sharedRatings, dataKey, 'tooLoud').toFixed(0)}%
                  </p>
                )}
                <CAPEVShareVASGraphs
                  attributeName={`${attributeName} ${t('too_loud')}`}
                  attributeData={{
                    formRating: formData[dataKey]?.direction?.tooLoud ? formData[dataKey]?.value : null,
                    sharedRatings: filterSharedEvaluationsByDirection(sharedRatings, dataKey, 'tooLoud'),
                    pvqdEvaluations: transformPVQDEvaluations(pvqdEvaluations, dataKey)
                  }}
                />
                <h4>{t('too_soft')}</h4>
                {validFormData && (
                  <p className="small">
                    {t('your_evaluations')}: {formData[dataKey]?.direction?.tooSoft ? t('yes') : t('no')}
                  </p>
                )}
                {validSharedRatings && (
                  <p className="small">
                    {t('other_allvoiced_users_evaluations')}: {calculateSharedEvaluationsDirectionPercentage(sharedRatings, dataKey, 'tooSoft').toFixed(0)}%
                  </p>
                )}
                <CAPEVShareVASGraphs
                  attributeName={`${attributeName} ${t('too_soft')}`}
                  attributeData={{
                    formRating: formData[dataKey]?.direction?.tooSoft ? formData[dataKey]?.value : null,
                    sharedRatings: filterSharedEvaluationsByDirection(sharedRatings, dataKey, 'tooSoft'),
                    pvqdEvaluations: transformPVQDEvaluations(pvqdEvaluations, dataKey)
                  }}
                />
              </>
            ) : (
              <CAPEVShareVASGraphs
                attributeName={attributeName}
                attributeData={{
                  formRating: validFormData ? formData[dataKey]?.value : null,
                  sharedRatings: validSharedRatings ? extractSharedRatings(sharedRatings, dataKey, null) : [],
                  pvqdEvaluations: transformPVQDEvaluations(pvqdEvaluations, dataKey)
                }}
              />
            )}
          </div>
        );
      })}
      <p className="small">
        * {t('pvqd_data_not_shown_pitch_or_loudness')}.
      </p>
    </div>
  );
};

export default CAPEVShareRenderStandardAttributes;