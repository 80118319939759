// RandomVASexperiment.jsx

import React, { useEffect, useState } from 'react';
import { getCollectionsData } from './collectionsData'; // Adjust the import path as needed
import Attribute from './../vas-experiment/Attribute';
import { useTranslation, Trans } from 'react-i18next';

function RandomVASexperiment() {
  const { t } = useTranslation();
  const [selectedCollection, setSelectedCollection] = useState(null);

  useEffect(() => {
    const collections = getCollectionsData();
    const randomIndex = Math.floor(Math.random() * collections.length); // Randomly select an index
    setSelectedCollection(collections[randomIndex]); // Set the selected collection based on the random index
  }, []); // The empty dependency array ensures this effect runs only once when the component mounts

  // Assuming `Attribute` component is designed to receive a specific structure of `attributeData`
  // We should format the selectedCollection data accordingly if needed

  return (
    <div>
    <h1>{t('random_vas_experiment_title')}</h1>
    <Trans i18nKey="random_vas_experiment_instructions">
        </Trans>
      {selectedCollection ? (
        <div>
          <Attribute 
            attributeData={{
              title: selectedCollection.title,
              audioFilesSets: selectedCollection.audioFilesSets,
              numberOfSliders: selectedCollection.numberOfSliders,
              // Add any other data `Attribute` needs
            }}
            username="exampleUsername" // You might obtain this from user state or context
          />
        </div>
      ) : (
        <p>Loading...</p> // Placeholder text while the collection is being selected
      )}
    </div>
  );
}

export default RandomVASexperiment;
