// CAPEVShareVASGraphs.jsx

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

const CAPEVShareVASGraphs = ({ attributeData, attributeName }) => {
  const { t } = useTranslation();
  const d3Container = useRef(null);

  useEffect(() => {
    if (!attributeData) return;

    const { formRating, sharedRatings = [], pvqdEvaluations = [] } = attributeData;

    // Definició dels marges i dimensions del gràfic
    const margin = { top: 20, right: 40, bottom: 40, left: 40 };
    const width = 960 - margin.left - margin.right;
    const height = 150 - margin.top - margin.bottom;

    const svg = d3.select(d3Container.current)
      .attr('width', "100%")
      .attr('height', "100%")
      .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .style('overflow', 'visible');

    svg.selectAll("*").remove(); // Esborrar qualsevol cosa anterior abans de redibuixar

    const chart = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const xScale = d3.scaleLinear()
      .domain([0, 100])
      .range([0, width]);

    chart.append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale));

    const getRandomOffset = (range) => (Math.random() - 0.5) * range;

    // Dibuixar la valoració del formulari si existeix
    if (formRating !== null && formRating !== undefined) {
      chart.append("circle")
        .attr("cx", xScale(formRating))
        .attr("cy", height / 1.2)
        .attr("r", 9)
        .style("fill", "none")
        .style("stroke", "#19756a")
        .style("stroke-width", 2);
    }

    // Dibuixar les valoracions compartides
    if (sharedRatings.length > 0) {
      sharedRatings.forEach(rating => {
        chart.append("circle")
          .attr("cx", xScale(rating))
          .attr("cy", height / 1.2 - getRandomOffset(10))
          .attr("r", 4)
          .style("fill", "#4ca89d");
      });
    }

    // Dibuixar les valoracions de `pvqdEvaluations`
    if (pvqdEvaluations.length > 0) {
      pvqdEvaluations.forEach(evaluation => {
        chart.append("circle")
          .attr("cx", xScale(evaluation.value))
          .attr("cy", height / 1.2 - getRandomOffset(10))
          .attr("r", 4)
          .style("fill", "#a5d3ce"); // Color diferent per a `pvqdEvaluations`
      });
    }

    // Afegir llegendes si hi ha dades
    const legends = [];
    if (formRating !== null && formRating !== undefined) {
      legends.push({ color: "#19756a", text: t('your_evaluations'), fill: "white" });
    }
    if (sharedRatings.length > 0) {
      legends.push({ color: "#4ca89d", text: t('other_allvoiced_users_evaluations'), fill: "#4ca89d" });
    }
    if (pvqdEvaluations.length > 0) {
      legends.push({ color: "#a5d3ce", text: t('evaluations_from_pvqd_walden_2022'), fill: "#a5d3ce" });
    }

    if (legends.length > 0) {
      const legend = chart.selectAll(".legend")
        .data(legends)
        .enter().append("g")
        .attr("class", "legend")
        .attr("transform", (_, i) => `translate(${width - 200}, ${-40 + i * 20})`);

      legend.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 18)
        .attr("height", 18)
        .style("fill", d => d.fill)
        .style("stroke", d => d.color)
        .style("stroke-width", 2);

      legend.append("text")
        .attr("x", 24)
        .attr("y", 14)
        .style("text-anchor", "start")
        .style("font-size", "11px")
        .text(d => d.text);
    }
  }, [attributeData, attributeName, t]);

  return (
    <div>
      <svg ref={d3Container}></svg>
    </div>
  );
};

export default CAPEVShareVASGraphs;