// feedbackDataPVQDSustainedVowels.js

const feedbackData = [
  {
    "collectionId": 1.0,
    "sampleName": "1_01",
    "sampleLink": "samples/PVQD-sustained-vowels/1_01.wav",
    "subjectAge": 56.0,
    "subjectGender": "M",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      5,
      50
    ],
    "pastBreathinessRatings": [
      13,
      8
    ],
    "pastStrainRatings": [
      0,
      9,
      2
    ],
    "meanFo": 80.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_02",
    "sampleLink": "samples/PVQD-sustained-vowels/1_02.wav",
    "subjectAge": 57.0,
    "subjectGender": "M",
    "entDiagnose": "VFParesis",
    "pastRoughnessRatings": [
      40,
      64
    ],
    "pastBreathinessRatings": [
      100,
      99
    ],
    "pastStrainRatings": [
      3,
      32,
      17
    ],
    "meanFo": 86.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_03",
    "sampleLink": "samples/PVQD-sustained-vowels/1_03.wav",
    "subjectAge": 38.0,
    "subjectGender": "M",
    "entDiagnose": "Vfscar",
    "pastRoughnessRatings": [
      58,
      24
    ],
    "pastBreathinessRatings": [
      4,
      3
    ],
    "pastStrainRatings": [
      4,
      4,
      3
    ],
    "meanFo": 88.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_04",
    "sampleLink": "samples/PVQD-sustained-vowels/1_04.wav",
    "subjectAge": 56.0,
    "subjectGender": "M",
    "entDiagnose": "VFPAralysis",
    "pastRoughnessRatings": [
      33,
      29
    ],
    "pastBreathinessRatings": [
      99,
      95
    ],
    "pastStrainRatings": [
      5,
      37,
      20
    ],
    "meanFo": 90.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_05",
    "sampleLink": "samples/PVQD-sustained-vowels/1_05.wav",
    "subjectAge": 51.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      83,
      42
    ],
    "pastBreathinessRatings": [
      95,
      68
    ],
    "pastStrainRatings": [
      21,
      52,
      30
    ],
    "meanFo": 90.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_06",
    "sampleLink": "samples/PVQD-sustained-vowels/1_06.wav",
    "subjectAge": 37.0,
    "subjectGender": "M",
    "entDiagnose": "VFParesis",
    "pastRoughnessRatings": [
      9,
      16
    ],
    "pastBreathinessRatings": [
      1,
      8
    ],
    "pastStrainRatings": [
      9,
      1,
      1
    ],
    "meanFo": 95.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_07",
    "sampleLink": "samples/PVQD-sustained-vowels/1_07.wav",
    "subjectAge": 20.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      43,
      59
    ],
    "pastBreathinessRatings": [
      74,
      9
    ],
    "pastStrainRatings": [
      2,
      61,
      21
    ],
    "meanFo": 96.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_08",
    "sampleLink": "samples/PVQD-sustained-vowels/1_08.wav",
    "subjectAge": 34.0,
    "subjectGender": "M",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      88,
      34
    ],
    "pastBreathinessRatings": [
      20,
      9
    ],
    "pastStrainRatings": [
      24,
      49,
      6
    ],
    "meanFo": 97.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_09",
    "sampleLink": "samples/PVQD-sustained-vowels/1_09.wav",
    "subjectAge": 25.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      0,
      15
    ],
    "pastBreathinessRatings": [
      0,
      0
    ],
    "pastStrainRatings": [
      11,
      0,
      0
    ],
    "meanFo": 99.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_10",
    "sampleLink": "samples/PVQD-sustained-vowels/1_10.wav",
    "subjectAge": 55.0,
    "subjectGender": "M",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      21,
      44
    ],
    "pastBreathinessRatings": [
      26,
      4
    ],
    "pastStrainRatings": [
      43,
      58,
      16
    ],
    "meanFo": 100.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_11",
    "sampleLink": "samples/PVQD-sustained-vowels/1_11.wav",
    "subjectAge": 41.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      47,
      30
    ],
    "pastBreathinessRatings": [
      32,
      2
    ],
    "pastStrainRatings": [
      6,
      2,
      5
    ],
    "meanFo": 100.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_12",
    "sampleLink": "samples/PVQD-sustained-vowels/1_12.wav",
    "subjectAge": 18.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      62,
      34
    ],
    "pastBreathinessRatings": [
      7,
      6
    ],
    "pastStrainRatings": [
      16,
      8,
      7
    ],
    "meanFo": 100.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_13",
    "sampleLink": "samples/PVQD-sustained-vowels/1_13.wav",
    "subjectAge": 20.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      67,
      38
    ],
    "pastBreathinessRatings": [
      58,
      4
    ],
    "pastStrainRatings": [
      15,
      44,
      8
    ],
    "meanFo": 103.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_14",
    "sampleLink": "samples/PVQD-sustained-vowels/1_14.wav",
    "subjectAge": 21.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      74,
      13
    ],
    "pastBreathinessRatings": [
      78,
      17
    ],
    "pastStrainRatings": [
      6,
      39,
      13
    ],
    "meanFo": 103.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_15",
    "sampleLink": "samples/PVQD-sustained-vowels/1_15.wav",
    "subjectAge": 28.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      93,
      9
    ],
    "pastBreathinessRatings": [
      97,
      48
    ],
    "pastStrainRatings": [
      30,
      25,
      41
    ],
    "meanFo": 105.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_16",
    "sampleLink": "samples/PVQD-sustained-vowels/1_16.wav",
    "subjectAge": 21.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      13,
      52
    ],
    "pastBreathinessRatings": [
      38,
      10
    ],
    "pastStrainRatings": [
      3,
      41,
      12
    ],
    "meanFo": 105.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_17",
    "sampleLink": "samples/PVQD-sustained-vowels/1_17.wav",
    "subjectAge": 22.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      1,
      19
    ],
    "pastBreathinessRatings": [
      2,
      1
    ],
    "pastStrainRatings": [
      13,
      3,
      4
    ],
    "meanFo": 106.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_18",
    "sampleLink": "samples/PVQD-sustained-vowels/1_18.wav",
    "subjectAge": 59.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      91,
      43
    ],
    "pastBreathinessRatings": [
      85,
      29
    ],
    "pastStrainRatings": [
      14,
      55,
      25
    ],
    "meanFo": 107.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_19",
    "sampleLink": "samples/PVQD-sustained-vowels/1_19.wav",
    "subjectAge": 19.0,
    "subjectGender": "M",
    "entDiagnose": "vocal fold edema",
    "pastRoughnessRatings": [
      2,
      0
    ],
    "pastBreathinessRatings": [
      44,
      0
    ],
    "pastStrainRatings": [
      8,
      7,
      6
    ],
    "meanFo": 108.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_20",
    "sampleLink": "samples/PVQD-sustained-vowels/1_20.wav",
    "subjectAge": 56.0,
    "subjectGender": "M",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      98,
      48
    ],
    "pastBreathinessRatings": [
      89,
      45
    ],
    "pastStrainRatings": [
      27,
      24,
      47
    ],
    "meanFo": 111.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_21",
    "sampleLink": "samples/PVQD-sustained-vowels/1_21.wav",
    "subjectAge": 24.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      14,
      45
    ],
    "pastBreathinessRatings": [
      51,
      2
    ],
    "pastStrainRatings": [
      48,
      69,
      72
    ],
    "meanFo": 111.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_22",
    "sampleLink": "samples/PVQD-sustained-vowels/1_22.wav",
    "subjectAge": 24.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      80,
      6
    ],
    "pastBreathinessRatings": [
      64,
      1
    ],
    "pastStrainRatings": [
      22,
      13,
      9
    ],
    "meanFo": 112.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_23",
    "sampleLink": "samples/PVQD-sustained-vowels/1_23.wav",
    "subjectAge": 68.0,
    "subjectGender": "M",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      95,
      26
    ],
    "pastBreathinessRatings": [
      92,
      72
    ],
    "pastStrainRatings": [
      57,
      19,
      37
    ],
    "meanFo": 115.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_24",
    "sampleLink": "samples/PVQD-sustained-vowels/1_24.wav",
    "subjectAge": 28.0,
    "subjectGender": "M",
    "entDiagnose": "Vfpaolyp",
    "pastRoughnessRatings": [
      85,
      3
    ],
    "pastBreathinessRatings": [
      81,
      19
    ],
    "pastStrainRatings": [
      10,
      48,
      64
    ],
    "meanFo": 115.0
  },
  {
    "collectionId": 1.0,
    "sampleName": "1_25",
    "sampleLink": "samples/PVQD-sustained-vowels/1_25.wav",
    "subjectAge": 25.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      77,
      2
    ],
    "pastBreathinessRatings": [
      70,
      37
    ],
    "pastStrainRatings": [
      8,
      16,
      51
    ],
    "meanFo": 115.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_01",
    "sampleLink": "samples/PVQD-sustained-vowels/2_01.wav",
    "subjectAge": 21.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      13,
      11
    ],
    "pastBreathinessRatings": [
      61
    ],
    "pastStrainRatings": [
      6
    ],
    "meanFo": 115.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_02",
    "sampleLink": "samples/PVQD-sustained-vowels/2_02.wav",
    "subjectAge": 70.0,
    "subjectGender": "M",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      71,
      13
    ],
    "pastBreathinessRatings": [
      0
    ],
    "pastStrainRatings": [
      57
    ],
    "meanFo": 116.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_03",
    "sampleLink": "samples/PVQD-sustained-vowels/2_03.wav",
    "subjectAge": 77.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      64,
      42
    ],
    "pastBreathinessRatings": [
      78
    ],
    "pastStrainRatings": [
      61
    ],
    "meanFo": 117.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_04",
    "sampleLink": "samples/PVQD-sustained-vowels/2_04.wav",
    "subjectAge": 78.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      85,
      69
    ],
    "pastBreathinessRatings": [
      35
    ],
    "pastStrainRatings": [
      40
    ],
    "meanFo": 118.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_05",
    "sampleLink": "samples/PVQD-sustained-vowels/2_05.wav",
    "subjectAge": 60.0,
    "subjectGender": "M",
    "entDiagnose": "Vocal Fold Paralysis",
    "pastRoughnessRatings": [
      1,
      15
    ],
    "pastBreathinessRatings": [
      64
    ],
    "pastStrainRatings": [
      0
    ],
    "meanFo": 125.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_06",
    "sampleLink": "samples/PVQD-sustained-vowels/2_06.wav",
    "subjectAge": 69.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      75,
      14
    ],
    "pastBreathinessRatings": [
      1
    ],
    "pastStrainRatings": [
      60
    ],
    "meanFo": 126.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_07",
    "sampleLink": "samples/PVQD-sustained-vowels/2_07.wav",
    "subjectAge": 40.0,
    "subjectGender": "M",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      26,
      3
    ],
    "pastBreathinessRatings": [
      7
    ],
    "pastStrainRatings": [
      36
    ],
    "meanFo": 130.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_08",
    "sampleLink": "samples/PVQD-sustained-vowels/2_08.wav",
    "subjectAge": 59.0,
    "subjectGender": "F",
    "entDiagnose": "Vocal Fold Edema",
    "pastRoughnessRatings": [
      56,
      41
    ],
    "pastBreathinessRatings": [
      13
    ],
    "pastStrainRatings": [
      14
    ],
    "meanFo": 131.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_09",
    "sampleLink": "samples/PVQD-sustained-vowels/2_09.wav",
    "subjectAge": 23.0,
    "subjectGender": "M",
    "entDiagnose": "Vocal fold cyst",
    "pastRoughnessRatings": [
      5,
      12
    ],
    "pastBreathinessRatings": [
      5
    ],
    "pastStrainRatings": [
      17
    ],
    "meanFo": 137.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_10",
    "sampleLink": "samples/PVQD-sustained-vowels/2_10.wav",
    "subjectAge": 58.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      50,
      31
    ],
    "pastBreathinessRatings": [
      68
    ],
    "pastStrainRatings": [
      63
    ],
    "meanFo": 141.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_11",
    "sampleLink": "samples/PVQD-sustained-vowels/2_11.wav",
    "subjectAge": 72.0,
    "subjectGender": "M",
    "entDiagnose": "atrophy/hypomobility",
    "pastRoughnessRatings": [
      93,
      89
    ],
    "pastBreathinessRatings": [
      35
    ],
    "pastStrainRatings": [
      80
    ],
    "meanFo": 142.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_12",
    "sampleLink": "samples/PVQD-sustained-vowels/2_12.wav",
    "subjectAge": 24.0,
    "subjectGender": "F",
    "entDiagnose": "Vocal fold scar",
    "pastRoughnessRatings": [
      73,
      50
    ],
    "pastBreathinessRatings": [
      25
    ],
    "pastStrainRatings": [
      24
    ],
    "meanFo": 143.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_13",
    "sampleLink": "samples/PVQD-sustained-vowels/2_13.wav",
    "subjectAge": 22.0,
    "subjectGender": "M",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      3,
      17
    ],
    "pastBreathinessRatings": [
      49
    ],
    "pastStrainRatings": [
      3
    ],
    "meanFo": 145.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_14",
    "sampleLink": "samples/PVQD-sustained-vowels/2_14.wav",
    "subjectAge": 55.0,
    "subjectGender": "M",
    "entDiagnose": "Bilareral phonotraumatic Lesion",
    "pastRoughnessRatings": [
      82,
      82
    ],
    "pastBreathinessRatings": [
      31
    ],
    "pastStrainRatings": [
      76
    ],
    "meanFo": 147.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_15",
    "sampleLink": "samples/PVQD-sustained-vowels/2_15.wav",
    "subjectAge": 21.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      10,
      14
    ],
    "pastBreathinessRatings": [
      22
    ],
    "pastStrainRatings": [
      21
    ],
    "meanFo": 147.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_16",
    "sampleLink": "samples/PVQD-sustained-vowels/2_16.wav",
    "subjectAge": 61.0,
    "subjectGender": "F",
    "entDiagnose": "vocal fold polyp",
    "pastRoughnessRatings": [
      89,
      84
    ],
    "pastBreathinessRatings": [
      54
    ],
    "pastStrainRatings": [
      74
    ],
    "meanFo": 156.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_17",
    "sampleLink": "samples/PVQD-sustained-vowels/2_17.wav",
    "subjectAge": 63.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      32,
      38
    ],
    "pastBreathinessRatings": [
      93
    ],
    "pastStrainRatings": [
      31
    ],
    "meanFo": 160.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_18",
    "sampleLink": "samples/PVQD-sustained-vowels/2_18.wav",
    "subjectAge": 67.0,
    "subjectGender": "M",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      97,
      94
    ],
    "pastBreathinessRatings": [
      43
    ],
    "pastStrainRatings": [
      69
    ],
    "meanFo": 160.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_19",
    "sampleLink": "samples/PVQD-sustained-vowels/2_19.wav",
    "subjectAge": 52.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      6,
      4
    ],
    "pastBreathinessRatings": [
      40
    ],
    "pastStrainRatings": [
      9
    ],
    "meanFo": 164.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_20",
    "sampleLink": "samples/PVQD-sustained-vowels/2_20.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      7,
      9
    ],
    "pastBreathinessRatings": [
      65
    ],
    "pastStrainRatings": [
      11
    ],
    "meanFo": 166.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_21",
    "sampleLink": "samples/PVQD-sustained-vowels/2_21.wav",
    "subjectAge": 71.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      29,
      19
    ],
    "pastBreathinessRatings": [
      31
    ],
    "pastStrainRatings": [
      58
    ],
    "meanFo": 167.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_22",
    "sampleLink": "samples/PVQD-sustained-vowels/2_22.wav",
    "subjectAge": 62.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      4,
      7
    ],
    "pastBreathinessRatings": [
      20
    ],
    "pastStrainRatings": [
      28
    ],
    "meanFo": 167.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_23",
    "sampleLink": "samples/PVQD-sustained-vowels/2_23.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      11,
      4
    ],
    "pastBreathinessRatings": [
      93
    ],
    "pastStrainRatings": [
      15
    ],
    "meanFo": 167.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_24",
    "sampleLink": "samples/PVQD-sustained-vowels/2_24.wav",
    "subjectAge": 54.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      8,
      21
    ],
    "pastBreathinessRatings": [
      47
    ],
    "pastStrainRatings": [
      26
    ],
    "meanFo": 169.0
  },
  {
    "collectionId": 2.0,
    "sampleName": "2_25",
    "sampleLink": "samples/PVQD-sustained-vowels/2_25.wav",
    "subjectAge": 24.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      39,
      53
    ],
    "pastBreathinessRatings": [
      88
    ],
    "pastStrainRatings": [
      21
    ],
    "meanFo": 171.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_01",
    "sampleLink": "samples/PVQD-sustained-vowels/3_01.wav",
    "subjectAge": 57.0,
    "subjectGender": "F",
    "entDiagnose": "VFPolyp",
    "pastRoughnessRatings": [
      30,
      4
    ],
    "pastBreathinessRatings": [
      56,
      48,
      29
    ],
    "pastStrainRatings": [
      9,
      8
    ],
    "meanFo": 171.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_02",
    "sampleLink": "samples/PVQD-sustained-vowels/3_02.wav",
    "subjectAge": 74.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      32,
      15
    ],
    "pastBreathinessRatings": [
      54,
      42,
      11
    ],
    "pastStrainRatings": [
      26,
      5
    ],
    "meanFo": 173.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_03",
    "sampleLink": "samples/PVQD-sustained-vowels/3_03.wav",
    "subjectAge": 19.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      4,
      5
    ],
    "pastBreathinessRatings": [
      48,
      20,
      31
    ],
    "pastStrainRatings": [
      10,
      9
    ],
    "meanFo": 173.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_04",
    "sampleLink": "samples/PVQD-sustained-vowels/3_04.wav",
    "subjectAge": 24.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      6,
      0
    ],
    "pastBreathinessRatings": [
      17,
      14,
      6
    ],
    "pastStrainRatings": [
      3,
      0
    ],
    "meanFo": 174.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_05",
    "sampleLink": "samples/PVQD-sustained-vowels/3_05.wav",
    "subjectAge": 88.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      66,
      25
    ],
    "pastBreathinessRatings": [
      89,
      41,
      84
    ],
    "pastStrainRatings": [
      56,
      25
    ],
    "meanFo": 175.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_06",
    "sampleLink": "samples/PVQD-sustained-vowels/3_06.wav",
    "subjectAge": 32.0,
    "subjectGender": "F",
    "entDiagnose": "Vocal Fold Polyp",
    "pastRoughnessRatings": [
      12,
      12
    ],
    "pastBreathinessRatings": [
      64,
      47,
      47
    ],
    "pastStrainRatings": [
      40,
      19
    ],
    "meanFo": 176.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_07",
    "sampleLink": "samples/PVQD-sustained-vowels/3_07.wav",
    "subjectAge": 75.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      18,
      34
    ],
    "pastBreathinessRatings": [
      96,
      61,
      88
    ],
    "pastStrainRatings": [
      46,
      26
    ],
    "meanFo": 178.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_08",
    "sampleLink": "samples/PVQD-sustained-vowels/3_08.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      33,
      2
    ],
    "pastBreathinessRatings": [
      12,
      12,
      5
    ],
    "pastStrainRatings": [
      1,
      13
    ],
    "meanFo": 178.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_09",
    "sampleLink": "samples/PVQD-sustained-vowels/3_09.wav",
    "subjectAge": 53.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      19,
      6
    ],
    "pastBreathinessRatings": [
      59,
      28,
      49
    ],
    "pastStrainRatings": [
      14,
      14
    ],
    "meanFo": 180.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_10",
    "sampleLink": "samples/PVQD-sustained-vowels/3_10.wav",
    "subjectAge": 46.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      71,
      21
    ],
    "pastBreathinessRatings": [
      75,
      42,
      23
    ],
    "pastStrainRatings": [
      35,
      22
    ],
    "meanFo": 181.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_11",
    "sampleLink": "samples/PVQD-sustained-vowels/3_11.wav",
    "subjectAge": 56.0,
    "subjectGender": "F",
    "entDiagnose": "stenosis",
    "pastRoughnessRatings": [
      21,
      7
    ],
    "pastBreathinessRatings": [
      64,
      40,
      34
    ],
    "pastStrainRatings": [
      31,
      9
    ],
    "meanFo": 181.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_12",
    "sampleLink": "samples/PVQD-sustained-vowels/3_12.wav",
    "subjectAge": 22.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      29,
      1
    ],
    "pastBreathinessRatings": [
      2,
      2,
      2
    ],
    "pastStrainRatings": [
      6,
      10
    ],
    "meanFo": 181.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_13",
    "sampleLink": "samples/PVQD-sustained-vowels/3_13.wav",
    "subjectAge": 83.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      42,
      23
    ],
    "pastBreathinessRatings": [
      52,
      45,
      16
    ],
    "pastStrainRatings": [
      38,
      31
    ],
    "meanFo": 181.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_14",
    "sampleLink": "samples/PVQD-sustained-vowels/3_14.wav",
    "subjectAge": 86.0,
    "subjectGender": "M",
    "entDiagnose": "vocal fold parlysis (L)",
    "pastRoughnessRatings": [
      58,
      38
    ],
    "pastBreathinessRatings": [
      83,
      43,
      21
    ],
    "pastStrainRatings": [
      30,
      33
    ],
    "meanFo": 185.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_15",
    "sampleLink": "samples/PVQD-sustained-vowels/3_15.wav",
    "subjectAge": 65.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      29,
      27
    ],
    "pastBreathinessRatings": [
      91,
      50,
      86
    ],
    "pastStrainRatings": [
      50,
      24
    ],
    "meanFo": 186.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_16",
    "sampleLink": "samples/PVQD-sustained-vowels/3_16.wav",
    "subjectAge": 27.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      49,
      19
    ],
    "pastBreathinessRatings": [
      68,
      37,
      28
    ],
    "pastStrainRatings": [
      37,
      21
    ],
    "meanFo": 186.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_17",
    "sampleLink": "samples/PVQD-sustained-vowels/3_17.wav",
    "subjectAge": 69.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      7,
      2
    ],
    "pastBreathinessRatings": [
      56,
      14,
      45
    ],
    "pastStrainRatings": [
      13,
      7
    ],
    "meanFo": 186.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_18",
    "sampleLink": "samples/PVQD-sustained-vowels/3_18.wav",
    "subjectAge": 59.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      44,
      22
    ],
    "pastBreathinessRatings": [
      15,
      7,
      1
    ],
    "pastStrainRatings": [
      20,
      32
    ],
    "meanFo": 186.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_19",
    "sampleLink": "samples/PVQD-sustained-vowels/3_19.wav",
    "subjectAge": 17.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      11,
      5
    ],
    "pastBreathinessRatings": [
      16,
      11,
      9
    ],
    "pastStrainRatings": [
      16,
      17
    ],
    "meanFo": 186.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_20",
    "sampleLink": "samples/PVQD-sustained-vowels/3_20.wav",
    "subjectAge": 42.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      16,
      0
    ],
    "pastBreathinessRatings": [
      0,
      3,
      3
    ],
    "pastStrainRatings": [
      14,
      14
    ],
    "meanFo": 187.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_21",
    "sampleLink": "samples/PVQD-sustained-vowels/3_21.wav",
    "subjectAge": 54.0,
    "subjectGender": "F",
    "entDiagnose": "phonotrauma ",
    "pastRoughnessRatings": [
      3,
      0
    ],
    "pastBreathinessRatings": [
      1,
      1,
      0
    ],
    "pastStrainRatings": [
      2,
      12
    ],
    "meanFo": 187.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_22",
    "sampleLink": "samples/PVQD-sustained-vowels/3_22.wav",
    "subjectAge": 55.0,
    "subjectGender": "F",
    "entDiagnose": "Vflesions",
    "pastRoughnessRatings": [
      8,
      3
    ],
    "pastBreathinessRatings": [
      51,
      15,
      13
    ],
    "pastStrainRatings": [
      8,
      11
    ],
    "meanFo": 187.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_23",
    "sampleLink": "samples/PVQD-sustained-vowels/3_23.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      10,
      0
    ],
    "pastBreathinessRatings": [
      6,
      11,
      4
    ],
    "pastStrainRatings": [
      15,
      15
    ],
    "meanFo": 187.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_24",
    "sampleLink": "samples/PVQD-sustained-vowels/3_24.wav",
    "subjectAge": 25.0,
    "subjectGender": "F",
    "entDiagnose": "Vocal Fold Scar",
    "pastRoughnessRatings": [
      2,
      8
    ],
    "pastBreathinessRatings": [
      78,
      39,
      82
    ],
    "pastStrainRatings": [
      24,
      10
    ],
    "meanFo": 189.0
  },
  {
    "collectionId": 3.0,
    "sampleName": "3_25",
    "sampleLink": "samples/PVQD-sustained-vowels/3_25.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      35,
      9
    ],
    "pastBreathinessRatings": [
      49,
      3,
      20
    ],
    "pastStrainRatings": [
      24,
      16
    ],
    "meanFo": 191.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_01",
    "sampleLink": "samples/PVQD-sustained-vowels/4_01.wav",
    "subjectAge": 56.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      81,
      47
    ],
    "pastBreathinessRatings": [
      99,
      61
    ],
    "pastStrainRatings": [
      34,
      21,
      47
    ],
    "meanFo": 192.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_02",
    "sampleLink": "samples/PVQD-sustained-vowels/4_02.wav",
    "subjectAge": 80.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      50,
      52
    ],
    "pastBreathinessRatings": [
      4,
      28
    ],
    "pastStrainRatings": [
      11,
      39,
      20
    ],
    "meanFo": 193.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_03",
    "sampleLink": "samples/PVQD-sustained-vowels/4_03.wav",
    "subjectAge": 63.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      16,
      18
    ],
    "pastBreathinessRatings": [
      14,
      1
    ],
    "pastStrainRatings": [
      11,
      10,
      3
    ],
    "meanFo": 193.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_04",
    "sampleLink": "samples/PVQD-sustained-vowels/4_04.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      76,
      3
    ],
    "pastBreathinessRatings": [
      55,
      6
    ],
    "pastStrainRatings": [
      23,
      26,
      5
    ],
    "meanFo": 195.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_05",
    "sampleLink": "samples/PVQD-sustained-vowels/4_05.wav",
    "subjectAge": 51.0,
    "subjectGender": "F",
    "entDiagnose": "Vocal fold scar",
    "pastRoughnessRatings": [
      2,
      11
    ],
    "pastBreathinessRatings": [
      33,
      1
    ],
    "pastStrainRatings": [
      0,
      0,
      0
    ],
    "meanFo": 195.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_06",
    "sampleLink": "samples/PVQD-sustained-vowels/4_06.wav",
    "subjectAge": 42.0,
    "subjectGender": "F",
    "entDiagnose": "Vflesions",
    "pastRoughnessRatings": [
      40,
      14
    ],
    "pastBreathinessRatings": [
      52,
      20
    ],
    "pastStrainRatings": [
      12,
      9,
      8
    ],
    "meanFo": 195.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_07",
    "sampleLink": "samples/PVQD-sustained-vowels/4_07.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      11,
      59
    ],
    "pastBreathinessRatings": [
      38,
      18
    ],
    "pastStrainRatings": [
      3,
      14,
      14
    ],
    "meanFo": 195.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_08",
    "sampleLink": "samples/PVQD-sustained-vowels/4_08.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      5,
      8
    ],
    "pastBreathinessRatings": [
      97,
      47
    ],
    "pastStrainRatings": [
      6,
      2,
      4
    ],
    "meanFo": 196.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_09",
    "sampleLink": "samples/PVQD-sustained-vowels/4_09.wav",
    "subjectAge": 47.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      6,
      36
    ],
    "pastBreathinessRatings": [
      10,
      1
    ],
    "pastStrainRatings": [
      28,
      47,
      17
    ],
    "meanFo": 197.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_10",
    "sampleLink": "samples/PVQD-sustained-vowels/4_10.wav",
    "subjectAge": 25.0,
    "subjectGender": "F",
    "entDiagnose": "Phonotraumatic Lesion",
    "pastRoughnessRatings": [
      98,
      21
    ],
    "pastBreathinessRatings": [
      73,
      8
    ],
    "pastStrainRatings": [
      20,
      17,
      40
    ],
    "meanFo": 198.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_11",
    "sampleLink": "samples/PVQD-sustained-vowels/4_11.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      94,
      27
    ],
    "pastBreathinessRatings": [
      65,
      32
    ],
    "pastStrainRatings": [
      29,
      30,
      22
    ],
    "meanFo": 200.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_12",
    "sampleLink": "samples/PVQD-sustained-vowels/4_12.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      1,
      7
    ],
    "pastBreathinessRatings": [
      78,
      42
    ],
    "pastStrainRatings": [
      4,
      1,
      2
    ],
    "meanFo": 200.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_13",
    "sampleLink": "samples/PVQD-sustained-vowels/4_13.wav",
    "subjectAge": 17.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      43,
      4
    ],
    "pastBreathinessRatings": [
      23,
      4
    ],
    "pastStrainRatings": [
      15,
      15,
      6
    ],
    "meanFo": 200.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_14",
    "sampleLink": "samples/PVQD-sustained-vowels/4_14.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      64,
      2
    ],
    "pastBreathinessRatings": [
      70,
      11
    ],
    "pastStrainRatings": [
      8,
      4,
      25
    ],
    "meanFo": 201.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_15",
    "sampleLink": "samples/PVQD-sustained-vowels/4_15.wav",
    "subjectAge": 18.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      84,
      1
    ],
    "pastBreathinessRatings": [
      87,
      23
    ],
    "pastStrainRatings": [
      14,
      16,
      12
    ],
    "meanFo": 202.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_16",
    "sampleLink": "samples/PVQD-sustained-vowels/4_16.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      89,
      5
    ],
    "pastBreathinessRatings": [
      82,
      15
    ],
    "pastStrainRatings": [
      22,
      31,
      36
    ],
    "meanFo": 202.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_17",
    "sampleLink": "samples/PVQD-sustained-vowels/4_17.wav",
    "subjectAge": 37.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      19,
      38
    ],
    "pastBreathinessRatings": [
      7,
      4
    ],
    "pastStrainRatings": [
      19,
      45,
      38
    ],
    "meanFo": 202.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_18",
    "sampleLink": "samples/PVQD-sustained-vowels/4_18.wav",
    "subjectAge": 19.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      59,
      41
    ],
    "pastBreathinessRatings": [
      92,
      48
    ],
    "pastStrainRatings": [
      35,
      28,
      49
    ],
    "meanFo": 204.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_19",
    "sampleLink": "samples/PVQD-sustained-vowels/4_19.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      79,
      17
    ],
    "pastBreathinessRatings": [
      61,
      37
    ],
    "pastStrainRatings": [
      14,
      6,
      29
    ],
    "meanFo": 204.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_20",
    "sampleLink": "samples/PVQD-sustained-vowels/4_20.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      32,
      0
    ],
    "pastBreathinessRatings": [
      58,
      5
    ],
    "pastStrainRatings": [
      6,
      3,
      7
    ],
    "meanFo": 205.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_21",
    "sampleLink": "samples/PVQD-sustained-vowels/4_21.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      56,
      30
    ],
    "pastBreathinessRatings": [
      48,
      13
    ],
    "pastStrainRatings": [
      25,
      35,
      18
    ],
    "meanFo": 206.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_22",
    "sampleLink": "samples/PVQD-sustained-vowels/4_22.wav",
    "subjectAge": 22.0,
    "subjectGender": "F",
    "entDiagnose": "vocal fold lesions",
    "pastRoughnessRatings": [
      70,
      23
    ],
    "pastBreathinessRatings": [
      21,
      3
    ],
    "pastStrainRatings": [
      25,
      12,
      9
    ],
    "meanFo": 207.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_23",
    "sampleLink": "samples/PVQD-sustained-vowels/4_23.wav",
    "subjectAge": 62.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      8,
      34
    ],
    "pastBreathinessRatings": [
      1,
      3
    ],
    "pastStrainRatings": [
      27,
      44,
      30
    ],
    "meanFo": 207.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_24",
    "sampleLink": "samples/PVQD-sustained-vowels/4_24.wav",
    "subjectAge": 60.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      0,
      11
    ],
    "pastBreathinessRatings": [
      0,
      0
    ],
    "pastStrainRatings": [
      38,
      25,
      27
    ],
    "meanFo": 208.0
  },
  {
    "collectionId": 4.0,
    "sampleName": "4_25",
    "sampleLink": "samples/PVQD-sustained-vowels/4_25.wav",
    "subjectAge": 29.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      26,
      3
    ],
    "pastBreathinessRatings": [
      28,
      2
    ],
    "pastStrainRatings": [
      16,
      19,
      1
    ],
    "meanFo": 208.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_01",
    "sampleLink": "samples/PVQD-sustained-vowels/5_01.wav",
    "subjectAge": 57.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      0,
      2
    ],
    "pastBreathinessRatings": [
      0
    ],
    "pastStrainRatings": [
      70
    ],
    "meanFo": 208.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_02",
    "sampleLink": "samples/PVQD-sustained-vowels/5_02.wav",
    "subjectAge": 65.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      49,
      44
    ],
    "pastBreathinessRatings": [
      6
    ],
    "pastStrainRatings": [
      51
    ],
    "meanFo": 210.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_03",
    "sampleLink": "samples/PVQD-sustained-vowels/5_03.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      1,
      0
    ],
    "pastBreathinessRatings": [
      29
    ],
    "pastStrainRatings": [
      8
    ],
    "meanFo": 210.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_04",
    "sampleLink": "samples/PVQD-sustained-vowels/5_04.wav",
    "subjectAge": 30.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      2,
      1
    ],
    "pastBreathinessRatings": [
      67
    ],
    "pastStrainRatings": [
      2
    ],
    "meanFo": 212.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_05",
    "sampleLink": "samples/PVQD-sustained-vowels/5_05.wav",
    "subjectAge": 49.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      29,
      18
    ],
    "pastBreathinessRatings": [
      1
    ],
    "pastStrainRatings": [
      41
    ],
    "meanFo": 213.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_06",
    "sampleLink": "samples/PVQD-sustained-vowels/5_06.wav",
    "subjectAge": 35.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      43,
      37
    ],
    "pastBreathinessRatings": [
      72
    ],
    "pastStrainRatings": [
      2
    ],
    "meanFo": 213.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_07",
    "sampleLink": "samples/PVQD-sustained-vowels/5_07.wav",
    "subjectAge": 45.0,
    "subjectGender": "F",
    "entDiagnose": "Phonotraumatic Lesion",
    "pastRoughnessRatings": [
      81,
      6
    ],
    "pastBreathinessRatings": [
      60
    ],
    "pastStrainRatings": [
      77
    ],
    "meanFo": 213.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_08",
    "sampleLink": "samples/PVQD-sustained-vowels/5_08.wav",
    "subjectAge": 33.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      53,
      14
    ],
    "pastBreathinessRatings": [
      27
    ],
    "pastStrainRatings": [
      24
    ],
    "meanFo": 214.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_09",
    "sampleLink": "samples/PVQD-sustained-vowels/5_09.wav",
    "subjectAge": 25.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      41,
      20
    ],
    "pastBreathinessRatings": [
      47
    ],
    "pastStrainRatings": [
      21
    ],
    "meanFo": 214.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_10",
    "sampleLink": "samples/PVQD-sustained-vowels/5_10.wav",
    "subjectAge": 21.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      38,
      25
    ],
    "pastBreathinessRatings": [
      29
    ],
    "pastStrainRatings": [
      28
    ],
    "meanFo": 214.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_11",
    "sampleLink": "samples/PVQD-sustained-vowels/5_11.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      35,
      1
    ],
    "pastBreathinessRatings": [
      63
    ],
    "pastStrainRatings": [
      12
    ],
    "meanFo": 214.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_12",
    "sampleLink": "samples/PVQD-sustained-vowels/5_12.wav",
    "subjectAge": 31.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      64,
      54
    ],
    "pastBreathinessRatings": [
      43
    ],
    "pastStrainRatings": [
      64
    ],
    "meanFo": 215.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_13",
    "sampleLink": "samples/PVQD-sustained-vowels/5_13.wav",
    "subjectAge": 27.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      1,
      3
    ],
    "pastBreathinessRatings": [
      22
    ],
    "pastStrainRatings": [
      5
    ],
    "meanFo": 215.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_14",
    "sampleLink": "samples/PVQD-sustained-vowels/5_14.wav",
    "subjectAge": 64.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      33,
      18
    ],
    "pastBreathinessRatings": [
      10
    ],
    "pastStrainRatings": [
      64
    ],
    "meanFo": 215.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_15",
    "sampleLink": "samples/PVQD-sustained-vowels/5_15.wav",
    "subjectAge": 76.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      89,
      85
    ],
    "pastBreathinessRatings": [
      83
    ],
    "pastStrainRatings": [
      39
    ],
    "meanFo": 216.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_16",
    "sampleLink": "samples/PVQD-sustained-vowels/5_16.wav",
    "subjectAge": 24.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      4,
      1
    ],
    "pastBreathinessRatings": [
      4
    ],
    "pastStrainRatings": [
      18
    ],
    "meanFo": 217.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_17",
    "sampleLink": "samples/PVQD-sustained-vowels/5_17.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      46,
      34
    ],
    "pastBreathinessRatings": [
      18
    ],
    "pastStrainRatings": [
      59
    ],
    "meanFo": 217.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_18",
    "sampleLink": "samples/PVQD-sustained-vowels/5_18.wav",
    "subjectAge": 52.0,
    "subjectGender": "F",
    "entDiagnose": "Atrophy",
    "pastRoughnessRatings": [
      12,
      8
    ],
    "pastBreathinessRatings": [
      92
    ],
    "pastStrainRatings": [
      0
    ],
    "meanFo": 219.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_19",
    "sampleLink": "samples/PVQD-sustained-vowels/5_19.wav",
    "subjectAge": 16.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      60,
      69
    ],
    "pastBreathinessRatings": [
      38
    ],
    "pastStrainRatings": [
      45
    ],
    "meanFo": 219.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_20",
    "sampleLink": "samples/PVQD-sustained-vowels/5_20.wav",
    "subjectAge": 24.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      57,
      22
    ],
    "pastBreathinessRatings": [
      27
    ],
    "pastStrainRatings": [
      54
    ],
    "meanFo": 221.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_21",
    "sampleLink": "samples/PVQD-sustained-vowels/5_21.wav",
    "subjectAge": 23.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      31,
      0
    ],
    "pastBreathinessRatings": [
      67
    ],
    "pastStrainRatings": [
      36
    ],
    "meanFo": 221.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_22",
    "sampleLink": "samples/PVQD-sustained-vowels/5_22.wav",
    "subjectAge": 77.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      74,
      27
    ],
    "pastBreathinessRatings": [
      34
    ],
    "pastStrainRatings": [
      81
    ],
    "meanFo": 223.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_23",
    "sampleLink": "samples/PVQD-sustained-vowels/5_23.wav",
    "subjectAge": 25.0,
    "subjectGender": "M",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      5,
      0
    ],
    "pastBreathinessRatings": [
      14
    ],
    "pastStrainRatings": [
      19
    ],
    "meanFo": 223.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_24",
    "sampleLink": "samples/PVQD-sustained-vowels/5_24.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      9,
      17
    ],
    "pastBreathinessRatings": [
      72
    ],
    "pastStrainRatings": [
      3
    ],
    "meanFo": 224.0
  },
  {
    "collectionId": 5.0,
    "sampleName": "5_25",
    "sampleLink": "samples/PVQD-sustained-vowels/5_25.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      16,
      0
    ],
    "pastBreathinessRatings": [
      96
    ],
    "pastStrainRatings": [
      0
    ],
    "meanFo": 224.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_01",
    "sampleLink": "samples/PVQD-sustained-vowels/6_01.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      30,
      5
    ],
    "pastBreathinessRatings": [
      40,
      14,
      19
    ],
    "pastStrainRatings": [
      2,
      5
    ],
    "meanFo": 225.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_02",
    "sampleLink": "samples/PVQD-sustained-vowels/6_02.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      29,
      2
    ],
    "pastBreathinessRatings": [
      73,
      31,
      13
    ],
    "pastStrainRatings": [
      9,
      6
    ],
    "meanFo": 227.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_03",
    "sampleLink": "samples/PVQD-sustained-vowels/6_03.wav",
    "subjectAge": 42.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      4,
      0
    ],
    "pastBreathinessRatings": [
      0,
      0,
      0
    ],
    "pastStrainRatings": [
      1,
      0
    ],
    "meanFo": 228.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_04",
    "sampleLink": "samples/PVQD-sustained-vowels/6_04.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      47,
      25
    ],
    "pastBreathinessRatings": [
      34,
      8,
      18
    ],
    "pastStrainRatings": [
      40,
      34
    ],
    "meanFo": 228.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_05",
    "sampleLink": "samples/PVQD-sustained-vowels/6_05.wav",
    "subjectAge": 18.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      8,
      3
    ],
    "pastBreathinessRatings": [
      41,
      10,
      10
    ],
    "pastStrainRatings": [
      0,
      4
    ],
    "meanFo": 229.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_06",
    "sampleLink": "samples/PVQD-sustained-vowels/6_06.wav",
    "subjectAge": 27.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      28,
      6
    ],
    "pastBreathinessRatings": [
      80,
      43,
      29
    ],
    "pastStrainRatings": [
      27,
      8
    ],
    "meanFo": 231.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_07",
    "sampleLink": "samples/PVQD-sustained-vowels/6_07.wav",
    "subjectAge": 17.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      24,
      4
    ],
    "pastBreathinessRatings": [
      4,
      9,
      1
    ],
    "pastStrainRatings": [
      16,
      1
    ],
    "meanFo": 231.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_08",
    "sampleLink": "samples/PVQD-sustained-vowels/6_08.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      1,
      1
    ],
    "pastBreathinessRatings": [
      14,
      2,
      2
    ],
    "pastStrainRatings": [
      0,
      2
    ],
    "meanFo": 232.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_09",
    "sampleLink": "samples/PVQD-sustained-vowels/6_09.wav",
    "subjectAge": 18.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      7,
      4
    ],
    "pastBreathinessRatings": [
      6,
      3,
      4
    ],
    "pastStrainRatings": [
      18,
      7
    ],
    "meanFo": 235.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_10",
    "sampleLink": "samples/PVQD-sustained-vowels/6_10.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      19,
      7
    ],
    "pastBreathinessRatings": [
      71,
      30,
      22
    ],
    "pastStrainRatings": [
      21,
      9
    ],
    "meanFo": 238.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_11",
    "sampleLink": "samples/PVQD-sustained-vowels/6_11.wav",
    "subjectAge": 52.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      9,
      5
    ],
    "pastBreathinessRatings": [
      38,
      12,
      18
    ],
    "pastStrainRatings": [
      28,
      11
    ],
    "meanFo": 238.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_12",
    "sampleLink": "samples/PVQD-sustained-vowels/6_12.wav",
    "subjectAge": 18.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      18,
      12
    ],
    "pastBreathinessRatings": [
      75,
      39,
      32
    ],
    "pastStrainRatings": [
      29,
      24
    ],
    "meanFo": 239.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_13",
    "sampleLink": "samples/PVQD-sustained-vowels/6_13.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      1,
      10
    ],
    "pastBreathinessRatings": [
      10,
      8,
      3
    ],
    "pastStrainRatings": [
      7,
      32
    ],
    "meanFo": 240.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_14",
    "sampleLink": "samples/PVQD-sustained-vowels/6_14.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      1,
      13
    ],
    "pastBreathinessRatings": [
      25,
      11,
      9
    ],
    "pastStrainRatings": [
      8,
      30
    ],
    "meanFo": 243.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_15",
    "sampleLink": "samples/PVQD-sustained-vowels/6_15.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      5,
      9
    ],
    "pastBreathinessRatings": [
      27,
      1,
      21
    ],
    "pastStrainRatings": [
      0,
      3
    ],
    "meanFo": 243.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_16",
    "sampleLink": "samples/PVQD-sustained-vowels/6_16.wav",
    "subjectAge": 22.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      2,
      11
    ],
    "pastBreathinessRatings": [
      79,
      36,
      23
    ],
    "pastStrainRatings": [
      6,
      10
    ],
    "meanFo": 246.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_17",
    "sampleLink": "samples/PVQD-sustained-vowels/6_17.wav",
    "subjectAge": 20.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      41,
      20
    ],
    "pastBreathinessRatings": [
      41,
      15,
      17
    ],
    "pastStrainRatings": [
      42,
      33
    ],
    "meanFo": 251.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_18",
    "sampleLink": "samples/PVQD-sustained-vowels/6_18.wav",
    "subjectAge": 47.0,
    "subjectGender": "F",
    "entDiagnose": "",
    "pastRoughnessRatings": [
      59,
      18
    ],
    "pastBreathinessRatings": [
      32,
      9,
      12
    ],
    "pastStrainRatings": [
      26,
      19
    ],
    "meanFo": 253.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_19",
    "sampleLink": "samples/PVQD-sustained-vowels/6_19.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      40,
      21
    ],
    "pastBreathinessRatings": [
      82,
      45,
      36
    ],
    "pastStrainRatings": [
      33,
      18
    ],
    "meanFo": 254.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_20",
    "sampleLink": "samples/PVQD-sustained-vowels/6_20.wav",
    "subjectAge": 26.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      13,
      8
    ],
    "pastBreathinessRatings": [
      77,
      42,
      24
    ],
    "pastStrainRatings": [
      25,
      27
    ],
    "meanFo": 257.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_21",
    "sampleLink": "samples/PVQD-sustained-vowels/6_21.wav",
    "subjectAge": 32.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      75,
      16
    ],
    "pastBreathinessRatings": [
      35,
      7,
      8
    ],
    "pastStrainRatings": [
      51,
      35
    ],
    "meanFo": 257.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_22",
    "sampleLink": "samples/PVQD-sustained-vowels/6_22.wav",
    "subjectAge": 21.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      56,
      21
    ],
    "pastBreathinessRatings": [
      36,
      13,
      5
    ],
    "pastStrainRatings": [
      34,
      22
    ],
    "meanFo": 262.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_23",
    "sampleLink": "samples/PVQD-sustained-vowels/6_23.wav",
    "subjectAge": 19.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      15,
      17
    ],
    "pastBreathinessRatings": [
      84,
      35,
      33
    ],
    "pastStrainRatings": [
      19,
      21
    ],
    "meanFo": 267.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_24",
    "sampleLink": "samples/PVQD-sustained-vowels/6_24.wav",
    "subjectAge": 22.0,
    "subjectGender": "F",
    "entDiagnose": "N",
    "pastRoughnessRatings": [
      35,
      19
    ],
    "pastBreathinessRatings": [
      37,
      32,
      14
    ],
    "pastStrainRatings": [
      37,
      16
    ],
    "meanFo": 272.0
  },
  {
    "collectionId": 6.0,
    "sampleName": "6_25",
    "sampleLink": "samples/PVQD-sustained-vowels/6_25.wav",
    "subjectAge": 44.0,
    "subjectGender": "F",
    "entDiagnose": "Y",
    "pastRoughnessRatings": [
      72,
      31
    ],
    "pastBreathinessRatings": [
      86,
      29,
      15
    ],
    "pastStrainRatings": [
      55,
      40
    ],
    "meanFo": 331.0
  }
];

  export default feedbackData;