// FeedbackCapeV.jsx

// TODELETE?

import React from 'react';
import VASFeedbackGraph from '../../shared-files/feedback-graphs/VASFeedbackGraph';


const FeedbackCapeV = ({ feedbackData, formData, t }) => {
  const userRatings = {
    overallSeverity: formData && formData.overallSeverity ? Number(formData.overallSeverity.value) : undefined,
    roughness: formData && formData.roughness ? Number(formData.roughness.value) : undefined,
    breathiness: formData && formData.breathiness ? Number(formData.breathiness.value) : undefined,
    strain: formData && formData.strain ? Number(formData.strain.value) : undefined,
    pitch: formData && formData.pitch ? Number(formData.pitch.value) : undefined,
    loudness: formData && formData.loudness ? Number(formData.loudness.value) : undefined,
  };  

  const attributes = [t('overall_severity'), t('roughness'), t('breathiness'), t('strain'), t('pitch'), t('loudness')];

  const attributeMapping = {
    [t('overall_severity')]: 'overall_severity',
    [t('roughness')]: 'roughness',
    [t('breathiness')]: 'breathiness',
    [t('strain')]: 'strain',
    [t('pitch')]: 'pitch',
    [t('loudness')]: 'loudness'
  };  

  return (
    <div className="feedback-container">
      <h2>{t('feedback')}</h2>
      {feedbackData && feedbackData.map((sample, index) => (
        <div key={index} className="feedback-item">
          <p className="small">{t('subject')}: {sample.participantId}</p>
          <p className="small">{t('age')}: {sample.age}</p>
          <p className="small">{t('gender')}: {sample.gender}</p>
          <p className="small">{t('reason_for_consultation')}: {sample.reasonForConsultation}</p>
          <p className="small">{t('diagnose')}: {sample.diagnose}</p>
          <p className="small">{t('use_of_spoken_voice')}: {sample.useSpokenVoice}</p>
          <p className="small">{t('use_of_singing_voice')}: {sample.useSingingVoice}</p>
          <p className="small">{t('vhi_10')}: {sample.vhi10}</p>
          <p className="small">{t('language')}: {sample.language}</p>
          <p className="small">{t('database')}: {sample.database}</p>
        </div>
      ))}
    {attributes.map(attributeName => {
      const dataKey = attributeMapping[attributeName];
    
      return (
        <VASFeedbackGraph
          key={attributeName}
          attributeName={attributeName}
          attributeData={{
          pastRatings: feedbackData.map(sample => sample[`past_${dataKey}_ratings`]).flat().filter(rating => rating !== undefined),
          userRating: userRatings[dataKey],
            }}
          />
        );
      })}
      <p className="small">{t('other_allvoiced_users_evaluations_updated_soon')}</p><br/>
    </div>
  );
};

export default FeedbackCapeV;