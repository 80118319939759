// AboutUs.jsx

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

function AboutUs() {
  const { t } = useTranslation();  

  return (
    <div className="long-text-container">
      <section className="long-section">
        <Trans 
          i18nKey="about_us_whole_text" 
          components={{
            h1: <h1>{t('Title')}</h1>,
            h2: <h2>{t('Subtitle')}</h2>,
            h3: <h3>{t('Section')}</h3>,
            h4: <h4>{t('Subsection')}</h4>,
            p: <p />,
            ul: <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }} />,
            li: <li style={{ marginBottom: '5px' }} />,
            strong: <strong />,
          }}
        >
          {/* El contingut del text vindrà de les traduccions i es formatejarà amb les etiquetes especificades */}
        </Trans>
      </section>
    </div>
  );
}

export default AboutUs;