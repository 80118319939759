// OSForm.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function OSForm({ onSubmit, sampleData }) {
  const { t } = useTranslation();
  const [overallSeverity, setOverallSeverity] = useState(0);
  const [g, setG] = useState("");  // Estat per a la valoració de 0-3, inicialment buit
  const [showSociodemographicData, setShowSociodemographicData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");  // Per mostrar missatges d'error

  const submit = () => {
    if (g === "") {  // Comprova si l'usuari ha seleccionat alguna cosa al desplegable
      setErrorMessage(t('select_the_severity_grade'));  // Mostra el missatge d'error
      return;
    }

    // Si g és diferent de 0, assegura't que el lliscador no està a 0
    if (g !== "0" && overallSeverity === 0) {
      setErrorMessage(t('use_the_slider_to_evaluate_the_sample'));  // Mostra error si el lliscador és zero
      return;
    }
    
    const data = {
      overallSeverity: Number(overallSeverity),
      g: Number(g),  // Valor del desplegable afegit
    };
    setErrorMessage("");  // Neteja el missatge d'error si es selecciona una opció correcta
    onSubmit(data);
  };

  return (
    <div className="VocalEvaluationForm">
      {/* Checkbox per mostrar dades sociodemogràfiques */}
      <div className="sociodemographic-toggle" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <input
          type="checkbox"
          id="showSociodemographicData"
          checked={showSociodemographicData}
          onChange={() => setShowSociodemographicData(!showSociodemographicData)}
        />
        <label htmlFor="showSociodemographicData">
          {t('show_sociodemographic_data')}
        </label>
      </div>

      {showSociodemographicData && sampleData && (
        <div className="sample-info">
          <p className="small">{t('subject')}: {sampleData.participantId || t('not_available')}</p>
          <p className="small">{t('age')}: {sampleData.age || t('not_available')}</p>
          <p className="small">{t('gender')}: {sampleData.gender ? t(sampleData.gender) : t('not_available')}</p>
          <p className="small">{t('diagnose')}: {sampleData.diagnose ? t(sampleData.diagnose) : t('not_available')}</p>
          <p className="small">{t('language')}: {sampleData.language ? t(sampleData.language) : t('not_available')}</p>
          <p className="small">{t('database')}: {sampleData.database || t('not_available')}</p>
        </div>
      )}

      <br/>
      <div className="inputContainer">
        <select
          value={g}
          onChange={(e) => setG(e.target.value)}
        >
          <option value="">{t('select_the_severity_grade')}</option>  {/* Opció per defecte */}
          <option value="0">{t('0_normal')}</option>
          <option value="1">{t('1_mild')}</option>
          <option value="2">{t('2_moderate')}</option>
          <option value="3">{t('3_severe')}</option>
        </select>
      </div>

      {/* Missatge d'error */}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      <br/>
      <div className="inputContainer">
        <input
          type="range"
          min="0"
          max="100"
          value={overallSeverity}
          className="slider"
          onChange={(e) => setOverallSeverity(e.target.value)}
        />
      </div>

      <br/>
      <div className="button-container">
        <button className="button-center" onClick={submit}>{t('submit')}</button>
      </div>
    </div>
  );
}

export default OSForm;