// CAPEVCustomLab.jsx
// Delete?

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import CAPEVCitation from './cape-v-shared-files/CAPEVCitation';

function CAPEVCustomLab() {
    const { t } = useTranslation();
    return (
        <div>
            <h1>{t('cape_v_custom_lab_title')}</h1>
            <h2>{t('cape_v_custom_lab_subtitle')}</h2>
            <Trans i18nKey="cape_v_custom_lab_explanation_1" />
            <br /><br />
            <div className="highlighted-section">
                <Trans i18nKey="cape_v_custom_lab_explanation_2" /><br /><br />
                <Trans i18nKey="cape_v_custom_lab_explanation_3" /><br /><br />
                <Trans i18nKey="cape_v_custom_lab_explanation_4" />
            </div>
            <br />
            <Trans i18nKey="cape_v_custom_lab_explanation_5" />
            <br /><br /><br />
            <div className="button-container">
                <a 
                    href="https://neuscalaf.com/contacto" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="button-link"
                >
                    {t('contact')}
                </a><br /><br />
            </div>
            <div className="cites">
                <CAPEVCitation />
            </div>
        </div>
    );
}

export default CAPEVCustomLab;
