// CAPEVFeedback.jsx

import RenderStandardAttributes from './RenderStandardAttributes';
import RenderAdditionalCharacteristics from './RenderAdditionalCharacteristics';
import SharedDataProcessor from '../../cape-v-shared-files/SharedDataProcessor';
import StudentDataProcessor from './StudentDataProcessor';

const CAPEVFeedback = ({
  feedbackData,
  sampleData,
  showSociodemographicData,
  t,
  consensusData,
  consensusSubmitted,
  sharedEvaluations,
  showSharedEvaluations,
  showPVQDEvaluations,
  pvqdEvaluations,
  showStandardAttributes,
  showAdditionalAttributes,
  showStudentRatings,
  showConsensusData // Afegit per a gestionar la visibilitat de les dades del consens
}) => {

  // Utilitza StudentDataProcessor per processar les dades dels estudiants
  const {
    userRatings,
    calculateVariability,
    filterRatingsByDirection,
    calculateDirectionPercentage,
    allAdditionalResonances,
    allAdditionalFeatures,
    allStudentComments
  } = StudentDataProcessor({ feedbackData });

  // Utilitza SharedDataProcessor per obtenir les dades processades i les funcions de càlcul
  const {
    sharedRatings,
    calculateSharedEvaluationsVariability,
    filterSharedEvaluationsByDirection,
    calculateSharedEvaluationsDirectionPercentage,
    calculateSharedPercentage,
    extractSharedRatings,
    extractSharedResonanceData,
    extractSharedAdditionalFeaturesData,
    extractSharedComments
  } = SharedDataProcessor({ sharedEvaluations });

  // Definició dels atributs per a l'avaluació estàndard
  const attributes = [
    'overallSeverity',
    'roughness',
    'breathiness',
    'strain',
    'pitch',
    'loudness'
  ];

  // Mapeig dels atributs amb les seves etiquetes traduïdes
  const attributeMapping = {
    overallSeverity: t('overall_severity'),
    roughness: t('roughness'),
    breathiness: t('breathiness'),
    strain: t('strain'),
    pitch: t('pitch'),
    loudness: t('loudness')
  };

  // Transformació de les avaluacions de PVQD
  const transformPVQDEvaluations = (evaluations, attribute) => {
    if (!evaluations) return [];
  
    // Evitar la transformació de dades per pitch i loudness
    if (['pitch', 'loudness'].includes(attribute)) {
      return []; // Retornar una llista buida per aquests atributs
    }
  
    return evaluations.flatMap(evaluation => [
      ...(evaluation[attribute] || []).map(value => ({ type: attribute, value }))
    ]);
  };  

  return (
    <div className="feedback-container">
      {showSociodemographicData && sampleData && (
        <div className="sample-info">
          <h2>{t('sociodemographic_data')}</h2>
          <p className="small centered-text">{t('subject')}: {sampleData.participantId}</p>
          <p className="small centered-text">{t('age')}: {sampleData.age}</p>
          <p className="small centered-text">{t('gender')}: {sampleData.gender ? t(sampleData.gender) : t('not_available')}</p>
          <p className="small centered-text">{t('diagnose')}: {sampleData.diagnose ? t(sampleData.diagnose) : t('not_available')}</p>
          <p className="small centered-text">{t('language')}: {sampleData.language ? t(sampleData.language) : t('not_available')}</p>
          <p className="small centered-text">{t('database')}: {sampleData.database}</p>
        </div>
      )}

      {showStandardAttributes && (
        <RenderStandardAttributes
          attributes={attributes}
          attributeMapping={attributeMapping}
          userRatings={showStudentRatings ? userRatings : []}
          consensusSubmitted={consensusSubmitted}
          consensusData={showConsensusData ? consensusData : {}}
          showSharedEvaluations={showSharedEvaluations}
          sharedRatings={sharedRatings}
          t={t}
          transformPVQDEvaluations={transformPVQDEvaluations}
          pvqdEvaluations={pvqdEvaluations}
          calculateVariability={calculateVariability}
          filterRatingsByDirection={filterRatingsByDirection}
          calculateDirectionPercentage={calculateDirectionPercentage}
          calculateSharedEvaluationsVariability={calculateSharedEvaluationsVariability}
          filterSharedEvaluationsByDirection={filterSharedEvaluationsByDirection}
          calculateSharedEvaluationsDirectionPercentage={calculateSharedEvaluationsDirectionPercentage}
          extractSharedRatings={extractSharedRatings}
        />
      )}
<br/>
      {showAdditionalAttributes && (
        <RenderAdditionalCharacteristics
          allAdditionalResonances={showStudentRatings ? allAdditionalResonances : {}}
          allAdditionalFeatures={showStudentRatings ? allAdditionalFeatures : {}}
          allStudentComments={showStudentRatings ? allStudentComments : []}
          consensusSubmitted={consensusSubmitted}
          consensusData={showConsensusData ? consensusData : {}}
          showSharedEvaluations={showSharedEvaluations}
          sharedRatings={sharedRatings}
          feedbackData={feedbackData}
          t={t}
          calculateVariability={calculateVariability}
          calculateSharedPercentage={calculateSharedPercentage}
          extractSharedResonanceData={extractSharedResonanceData}
          extractSharedAdditionalFeaturesData={extractSharedAdditionalFeaturesData}
          extractSharedComments={extractSharedComments}
          showConsensusData={showConsensusData} // Passa la nova prop
        />
      )}
    </div>
  );
};

export default CAPEVFeedback;