// rolesContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './authContext';

const RolesContext = createContext();

export const useRoles = () => {
  return useContext(RolesContext);
};

export const RolesProvider = ({ children }) => {
  const { user } = useAuth();
  const [roleIds, setRoleIds] = useState(null);
  const [loadingRoles, setLoadingRoles] = useState(true);

  useEffect(() => {
    const fetchRoleIds = async () => {
      if (user) { 
        try {
          const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';
          const response = await axios.get(`${apiUrl}/users/roles`);
          const roleIdMap = {};
          response.data.forEach(role => {
            roleIdMap[role.name] = role._id;
          });
          setRoleIds(roleIdMap);
        } catch (error) {
          console.error('Error fetching roles:', error);
        } finally {
          setLoadingRoles(false);
        }
      } else {
        setRoleIds(null);  // Si no hi ha usuari, no hi ha rols
        setLoadingRoles(false);
      }
    };

    fetchRoleIds();
  }, [user]);

  return (
    <RolesContext.Provider value={{ roleIds, loadingRoles }}>
      {children}
    </RolesContext.Provider>
  );
};