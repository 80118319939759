// CAPEVFormSimplified.jsx
// CAPEVForm simplificat per ser utilitzat per CAPEVScholar, que no ha de tenir cap interacció amb el backend 
// i ha de rebre les dades tal qual s'envien des d'aquí. 
// He fet això perquè no hi havia manera d'aconseguir que e¡rebés les dades correctametn des de CAPEVForm
// després de tanta modificació per guardar dades per recerca i compartides. 

import React, { useState } from 'react';
import CAPEVFormItself from './CAPEVFormItself';
import { useTranslation } from 'react-i18next';

function CAPEVFormSimplified({ onSubmit, initialData = {} }) {
  const { t } = useTranslation();

  const DEFAULT_CHARACTERISTIC_PARAMETERS = { value: 0, variability: 'consistent' };
  const INITIAL_PITCH_DIRECTION = { tooHigh: false, tooLow: false };
  const INITIAL_LOUDNESS_DIRECTION = { tooLoud: false, tooSoft: false };
  
  // Inicialitzacions per a pitch i loudness
  const INITIAL_PITCH_CHARACTERISTIC_PARAMETERS = { 
    value: 0, 
    variability: 'consistent', 
    direction: INITIAL_PITCH_DIRECTION 
  };
  const INITIAL_LOUDNESS_CHARACTERISTIC_PARAMETERS = { 
    value: 0, 
    variability: 'consistent', 
    direction: INITIAL_LOUDNESS_DIRECTION 
  };

  const [overallSeverity, setOverallSeverity] = useState(initialData.overallSeverity || DEFAULT_CHARACTERISTIC_PARAMETERS);
  const [roughness, setRoughness] = useState(initialData.roughness || DEFAULT_CHARACTERISTIC_PARAMETERS);
  const [breathiness, setBreathiness] = useState(initialData.breathiness || DEFAULT_CHARACTERISTIC_PARAMETERS);
  const [strain, setStrain] = useState(initialData.strain || DEFAULT_CHARACTERISTIC_PARAMETERS);
  const [pitch, setPitch] = useState(initialData.pitch || INITIAL_PITCH_CHARACTERISTIC_PARAMETERS);
  const [loudness, setLoudness] = useState(initialData.loudness || INITIAL_LOUDNESS_CHARACTERISTIC_PARAMETERS);
  const [additionalResonances, setAdditionalResonances] = useState({ resonances: initialData.additionalResonances || {}, text: '' });
  const [additionalFeatures, setAdditionalFeatures] = useState({ features: initialData.additionalFeatures || {}, text: '' });
  const [comments, setComments] = useState(initialData.comments || "");

  const handleAdditionalResonanceChange = (updatedResonances) => {
    setAdditionalResonances(prevState => ({
      ...prevState,
      resonances: updatedResonances.resonances,
      text: updatedResonances.text !== undefined ? updatedResonances.text : prevState.text
    }));
  };

  const handleAdditionalFeatureChange = (updatedFeatures) => {
    setAdditionalFeatures(prevState => ({
      ...prevState,
      features: updatedFeatures.features,
      text: updatedFeatures.text !== undefined ? updatedFeatures.text : prevState.text
    }));
  };

  const handleSubmit = () => {
    const formData = {
      overallSeverity,
      roughness,
      breathiness,
      strain,
      pitch,
      loudness,
      additionalResonances: additionalResonances.resonances,
      additionalFeatures: additionalFeatures.features,
      comments,
    };

    onSubmit(formData);
  };

  const clearForm = () => {
    setOverallSeverity(DEFAULT_CHARACTERISTIC_PARAMETERS);
    setRoughness(DEFAULT_CHARACTERISTIC_PARAMETERS);
    setBreathiness(DEFAULT_CHARACTERISTIC_PARAMETERS);
    setStrain(DEFAULT_CHARACTERISTIC_PARAMETERS);
    setPitch(INITIAL_PITCH_CHARACTERISTIC_PARAMETERS);
    setLoudness(INITIAL_LOUDNESS_CHARACTERISTIC_PARAMETERS);
    setAdditionalResonances({ resonances: {}, text: '' });
    setAdditionalFeatures({ features: {}, text: '' });
    setComments("");
  };

  return (
    <div className="VocalEvaluationForm">
      <p>{t('cape_v_general_instructions')}</p>
      <div className="button-container">
        <button className="button-center" onClick={clearForm}>{t('clear_form')}</button>
      </div>
      <CAPEVFormItself
        overallSeverity={overallSeverity} setOverallSeverity={setOverallSeverity}
        roughness={roughness} setRoughness={setRoughness}
        breathiness={breathiness} setBreathiness={setBreathiness}
        strain={strain} setStrain={setStrain}
        pitch={pitch} setPitch={setPitch}
        loudness={loudness} setLoudness={setLoudness}
        additionalResonances={additionalResonances} setAdditionalResonances={setAdditionalResonances}
        additionalFeatures={additionalFeatures} setAdditionalFeatures={setAdditionalFeatures}
        comments={comments} setComments={setComments}
        handleAdditionalResonanceChange={handleAdditionalResonanceChange}
        handleAdditionalFeatureChange={handleAdditionalFeatureChange}
      />
      <br/>
      <div className="button-container">
        <button className="button-center" onClick={handleSubmit}>{t('submit')}</button>
      </div>
    </div>
  );
}

export default CAPEVFormSimplified;
