// EmailVerification.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoginComponent from './LoginComponent';

const EmailVerification = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [status, setStatus] = useState(t('verifying'));

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  useEffect(() => {
    const verifyEmail = async () => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        console.log('Token:', token); // Afegeix aquest log
  
        try {
            const response = await axios.get(`${apiUrl}/users/verify-email?token=${token}`);
            console.log('Verification response:', response.data); // Afegeix aquest log
            setStatus(t('email_verified_success'));
  
            // Redirigir a una pàgina existent o simplement mostrar un missatge d'èxit
            // window.location.href = '/';  // O qualsevol altra pàgina existent
        } catch (error) {
            console.error('Verification error:', error);
            setStatus(t('email_verification_failed'));
        }
    };
    verifyEmail();
  }, [location, t, apiUrl]);  

  return (
    <div>
      <h1>{status}</h1>
      {status === t('email_verified_success') && (
        <LoginComponent /> // Afegeix el component de login aquí
      )}
    </div>
  );
};

export default EmailVerification;