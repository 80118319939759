// index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Obté l'URL del frontend des de les variables d'entorn
const frontendUrl = process.env.REACT_APP_FRONTEND_URL || window.location.origin;

// Comprova si estem en localhost o en desenvolupament
const isDevelopment = frontendUrl === 'http://localhost:3000';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <App isDevelopment={isDevelopment} />
  </Router>
);

reportWebVitals();