// FeedbackGraph.jsx

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const FeedbackGraph = ({ feedbackData, sliderValues, evaluatedAttribute }) => {
    const d3Container = useRef(null);

    useEffect(() => {
        const margin = { top: 20, right: 150, bottom: 40, left: 40 };
        let width = 960 - margin.left - margin.right;
        let height = 150 - margin.top - margin.bottom;

        const svg = d3.select(d3Container.current)
            .attr('width', "100%")
            .attr('height', "100%")
            .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .attr('preserveAspectRatio', 'xMidYMid')
            .style('overflow', 'visible');

        svg.selectAll("*").remove();

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const updateDimensions = () => {
            const containerWidth = d3Container.current.clientWidth;
            width = containerWidth - margin.left - margin.right;

            svg.attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`);

            chart.selectAll("*").remove();

            const xScale = d3.scaleLinear()
                .domain([0, 100])
                .range([0, width]);

            chart.append("g")
                .attr("transform", `translate(0, ${height})`)
                .call(d3.axisBottom(xScale));

            // Dibuixa els past ratings de l'atribut avaluat
            feedbackData.forEach(sample => {
                (sample[`past${evaluatedAttribute}Ratings`] || []).forEach(rating => {
                    chart.append("circle")
                        .attr("cx", xScale(rating))
                        .attr("cy", height / 1.2)
                        .attr("r", 5)
                        .style("fill", "blue");
                });
            });

            // Com l'usuari només ha avaluat un atribut, dibuixem directament aquest valor
            if (sliderValues.length > 0) {
                chart.append("circle")
                    .attr("cx", xScale(sliderValues[0].value))
                    .attr("cy", height / 1.2)
                    .attr("r", 5)
                    .style("fill", "red");
            }

            // Afegeix la llegenda
            const legends = [
                { color: "blue", text: `Past ${evaluatedAttribute} Ratings` },
                { color: "red", text: "User Rating" },
            ];

            const legend = chart.selectAll(".legend")
                .data(legends)
                .enter().append("g")
                .attr("class", "legend")
                .attr("transform", (_, i) => `translate(${width - 100}, ${20 + i * 20})`);

            legend.append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", 18)
                .attr("height", 18)
                .style("fill", d => d.color);

            legend.append("text")
                .attr("x", 24)
                .attr("y", 14)
                .style("text-anchor", "start")
                .attr("class", "p.small")
                .style("font-size", "14px")
                .text(d => d.text);
        };

        updateDimensions();

        const handleResize = () => {
            updateDimensions();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [feedbackData, sliderValues, evaluatedAttribute]);

    return <svg ref={d3Container}></svg>;
};

export default FeedbackGraph;