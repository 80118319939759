// StageThree.jsx

import React, { useState, useEffect } from 'react';

const StageThree = ({ previousResults, sliderValues, onUpdateSubcategoryValues, t }) => {
    const [values, setValues] = useState({});

    useEffect(() => {
        // Initialize with passed slider values or default to 0
        const initialSliders = {};
        Object.keys(previousResults.selections).forEach(category => {
            Object.keys(previousResults.selections[category]).forEach(subcategory => {
                if (previousResults.selections[category][subcategory]) {
                    const key = `${subcategory}`;
                    initialSliders[key] = sliderValues[key] ?? 0;
                }
            });
        });
        setValues(initialSliders);
    }, [previousResults.selections, sliderValues]);

    const handleSliderChange = (key, value) => {
        const newValue = parseInt(value, 10);
        setValues(prev => ({
            ...prev,
            [key]: newValue
        }));
        onUpdateSubcategoryValues({ [key]: newValue });
    };

    return (
        <div>
            <h2>{t('stage')} 3. {t('rate_subcategories')}</h2>
            <p>{t('lorem_ipsum')}</p>
            {Object.entries(values).map(([key, value]) => (
                <div key={key} style={{ marginBottom: '20px' }}>
                    <label>{t(key)}</label>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={value}
                        onChange={(e) => handleSliderChange(key, e.target.value)}
                        style={{ width: '100%' }}
                    />
                </div>
            ))}
        </div>
    );
};


export default StageThree;

