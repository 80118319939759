// AuthNavBar.jsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './../../locales/i18n';

const AuthNavBar = ({ userRole, roleIds }) => {
  const { t } = useTranslation();
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

   // Assegura't que roleIds està definit abans d'utilitzar-lo
   if (!roleIds) {
    return null; // O podries mostrar un missatge de càrrega o error
  }

  return (
    <nav>
      <ul>
        {/* Comprova si roleIds té la clau 'admin' abans d'accedir-hi */}
        {roleIds['admin'] && userRole === roleIds['admin'] && (
          <>
            <li onClick={handleSubMenu}>
              {t('admin')}
              <ul className={`submenu ${showSubMenu ? 'show' : ''}`}>
                <li><Link to="/admin-dashboard">{t('admin_dashboard')}</Link></li>
                <li><Link to="/cape-v-clinic">{t('cape_v_clinic_title')}</Link></li>
                <li><Link to="/cape-v-entrenament">CAPE-V Entrenament</Link></li>
                <li><Link to="/cape-v-entrenamiento">CAPE-V Entrenamiento</Link></li>
                <li><Link to="/training-in-three-phases">{t('training')} in three phases</Link></li>
                <li><Link to="/concepts">Conceptes</Link></li>
                <li><Link to="/2-afc">{t('2-AFC_test')}</Link></li>
                <li><Link to="/vas-experiment">{t('vas_experiment')}</Link></li>
                <li><Link to="/random-vas-experiment">{t('random_vas_experiment_title')}</Link></li>
                <li><Link to="/free-sorting-task">{t('free_sorting_task')}</Link></li>
                <li><Link to="/collections-free-sorting-task">{t('collections_free_sorting_task')}</Link></li>
                <li><Link to="/ear-v">EAR-V</Link></li>
                <li><Link to="/anchors">{t('anchors')}</Link></li>
              </ul>
            </li>
          </>
        )}
         {/* Comprova si roleIds té la clau 'friend' abans d'accedir-hi */}
         {roleIds['friend'] && userRole === roleIds['friend'] && (
          <>
            <li onClick={handleSubMenu}>
              Menú
              <ul className={`submenu ${showSubMenu ? 'show' : ''}`}>
                <li><Link to="/cape-v-training">{t('cape_v_training_title')}</Link></li>
                <li><Link to="/cape-v-scholar">{t('cape_v_scholar_title')}</Link></li>
                <li><Link to="/ear-v">EAR-V</Link></li>
                <li><Link to="/os-training">{t('os_edition')}</Link></li>
                <li><Link to="/rbs-training">{t('rbs_edition')}</Link></li>
                <li><Link to="/guess-the-diagnosis">{t('guess_the_diagnosis_title')}</Link></li>
                {/* Add more submenu items as needed */}
              </ul>
            </li>
          </>
        )}
        {/* Comprova si roleIds té la clau 'student' abans d'accedir-hi */}
        {roleIds['student'] && userRole === roleIds['student'] && (
          <>
            <li onClick={handleSubMenu}>
              Menú
              <ul className={`submenu ${showSubMenu ? 'show' : ''}`}>
                <li><Link to="/cape-v-training">{t('cape_v_training_title')}</Link></li>
                <li><Link to="/cape-v-scholar">{t('cape_v_scholar_title')}</Link></li>
                <li><Link to="/cape-v-entrenament">Entrenament CAPE-V</Link></li>
                <li><Link to="/cape-v-evidencia">EV1.1</Link></li>
                <li><Link to="/concepts">Conceptes</Link></li>
                <li><Link to="/os-training">{t('os_edition')}</Link></li>
                <li><Link to="/rbs-training">{t('rbs_edition')}</Link></li>
                <li><Link to="/collections-free-sorting-task">{t('collections_free_sorting_task')}</Link></li>
                <li><Link to="/ear-v">EAR-V</Link></li>
              </ul>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default AuthNavBar;