// randomConcepts.js

export const translationKeys = [
  'aerodynamic_measures', 
  'asthenia', 
  'avqi', 
  'breathiness', 
  'breathing_techniques', 
  'cape_v', 
  'cough_reflex_testing', 
  'decibel', 
  'egg', 
  'frequency', 
  'fry', 
  'glottal_efficiency', 
  'grbas', 
  'high_speed_videoendoscopy', 
  'hnr', 
  'intensity', 
  'jitter', 
  'laryngitis', 
  'lax_vox', 
  'loudness', 
  'manual_laryngeal_therapy', 
  'maximum_phonation_time', 
  'myofunctional_therapy', 
  'overall_severity', 
  'phonation_threshold_pressure', 
  'pitch', 
  'pitch_range', 
  'postural_reeducation', 
  'resonant_voice_therapy', 
  'roughness', 
  's_z_ratio', 
  'shimmer', 
  'spasmodic_dysphonia', 
  'spectrogram', 
  'strain', 
  'subglottic_pressure', 
  'timbre', 
  'tremor', 
  'vapp', 
  'vhi', 
  'videolaryngostroboscopy', 
  'visual_and_auditory_feedback', 
  'vocal_abuse', 
  'vocal_cysts', 
  'vocal_efficiency', 
  'vocal_fatigue', 
  'vocal_fold_paralysis', 
  'vocal_hygiene', 
  'vocal_loading_test', 
  'vocal_misuse', 
  'vocal_nodules', 
  'vocal_polyps', 
  'vocal_quality', 
  'vocal_sirens', 
  'vocal_warm_up_exercises', 
  'voice_projection', 
  'voice_range_profile', 
  'voice_therapy', 
  'voiss', 
  'volume_dynamics'
];

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  
  export const generateConcepts = () => {
    const shuffledKeys = shuffleArray([...translationKeys]);
    return shuffledKeys.slice(0, 30).map((key, index) => ({
        id: `concept-${index + 1}`,
        label: key
    }));
  };