// ConceptsFreeSortingTask.jsx

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConceptsFreeSortingTaskStructure from './ConceptsFreeSortingTaskStructure.jsx';
import { generateConcepts, translationKeys } from './randomConcepts.js';

const ConceptsFreeSortingTask = () => {
    const { t } = useTranslation();
    const [conceptKeys, setConceptKeys] = useState([]);

    useEffect(() => {
        setConceptKeys(generateConcepts(translationKeys).slice(0, 30));
    }, []);

    return (
        <div>
            <h2>{t('concepts_free_sorting_task')}</h2>
            <p>{t('lorem_ipsum')}</p>
            <div>
                <ConceptsFreeSortingTaskStructure userData={conceptKeys.map(concept => ({
                    ...concept,
                    label: t(concept.label)
                }))} />
            </div>
        </div>
    );
};

export default ConceptsFreeSortingTask;