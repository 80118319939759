// HowToCite.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

function HowToCite() {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    return (
        <div className="main-content">
            <h1>{t('how_to_cite')}</h1>
            <p className='centered-text'>Calaf, Neus. ({currentYear}). All-Voiced {t('citation_info')} https://www.all-voiced.com/</p>
        </div>
    );
}

export default HowToCite;