// CAPEVVocalTasks.jsx

import React from 'react';
import { Trans } from 'react-i18next';

function CAPEVVocalTasks() {

  return (
    <div className="highlighted-section">
     <Trans i18nKey="cape_v_tasks"></Trans><br/><br/>
    </div>
  );
}

export default CAPEVVocalTasks;
