// DragAndDropWrapper.jsx

import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import SortableContainer from './SortableContainer';

function DragAndDropWrapper({ sorts, onDragEnd, handleRemoveSort, handleRenameSort }) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="layout__wrapper">
        {sorts.map((sort, index) => (
          <SortableContainer 
            key={sort.id} 
            sort={sort} 
            isFirstContainer={index === 0} // True for the first container
            handleRemoveSort={handleRemoveSort}
            handleRenameSort={handleRenameSort}
          />
        ))}
      </div>
    </DragDropContext>
  );
}

export default DragAndDropWrapper;