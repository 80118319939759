// File: Collection.jsx

import React from 'react';
import LineWithMarks from './LineWithMarks';
import LineLabel from './LineLabel';
import { useTranslation } from 'react-i18next';

const Collection = ({ number, frequencyRange, lineData, selectedMarks, onMarkSelection }) => {
  const { t } = useTranslation();

  const handleShowValues = () => {

    const translatedLabels = {
      roughness: t('roughness'), 
      breathiness: t('breathiness'), 
      strain: t('strain'), 
    };
  
    const selectedValuesMessage = Object.keys(selectedMarks).map(key =>
      `${translatedLabels[key]}: ${selectedMarks[key] || 'None'}`).join(', ');
  
    alert(`${selectedValuesMessage}`);
  };  

  return (
    <div>
      <h2>{t('collection')} {number}</h2>
      <h3>{t(frequencyRange)}</h3>
      {lineData.map((data, index) => (
        <div key={index}>
          <LineLabel label={t(data.label)} />
          <LineWithMarks
            blackMarks={data.blackMarks}
            blueMarks={data.blueMarks}
            currentSelectedMark={selectedMarks[data.key]} // Use the consistent key here
            onMarkSelection={(value) => onMarkSelection(data.key, value)} // Pass the key instead of the translated label
          />
        </div>
      ))}
      <br/>
      <button className="button" onClick={handleShowValues}>{t('show_selected_values')}</button>
    </div>
  );
};

export default Collection;