// ReportBuilder.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 11,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  identification: {
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: '1px solid #209385',
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 18,
    marginBottom: 30,
    textAlign: 'center',
  },
  logo: {
    width: '100%',
    maxHeight: 150,
    objectFit: 'contain',
    marginBottom: 20,
  },
  footerLogo: {
    width: 100,
    margin: '10px auto',
  },
  centered: {
    textAlign: 'center',
  },
  footer: {
    paddingTop: 10,
    fontSize: 10,
    textAlign: 'center',
  },
  content: {
    flexGrow: 1,
  },
  signature: {
    marginTop: 20,
    textAlign: 'right',
  },
  note: {
    fontSize: 8,
    color: 'grey',
    textAlign: 'left',
    paddingBottom: 10,
    borderBottom: '1px solid #209385',
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 12,
    marginBottom: 5,
    marginTop: 5,
  },
  comments: {
    marginTop: 0,
  },
});

function ReportBuilder({ results, submissionDate, onRestart }) {
  const { t } = useTranslation();
  const [clientName, setClientName] = useState('');
  const [clinicianName, setClinicianName] = useState('');
  const [logoPreview, setLogoPreview] = useState(null);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const MyDocument = (
    <Document>
      <Page style={styles.page}>
        <View>
          {logoPreview && (
            <Image src={logoPreview} style={styles.logo} />
          )}
          <Text style={styles.title}>{t('cape_v_report')}</Text>
          <View style={styles.identification}>
            <Text>{t('client')}: {clientName}</Text>
            <Text>{t('date')}: {submissionDate}</Text>
          </View>
          <View style={styles.content}>
            <View style={styles.section}>
              <Text style={styles.subtitle}>{t('standard_attributes')}:</Text>
              <Text>{t('overall_severity')}: {results.overallSeverity}</Text>
              <Text>{t('roughness')}: {results.roughness}</Text>
              <Text>{t('breathiness')}: {results.breathiness}</Text>
              <Text>{t('strain')}: {results.strain}</Text>
              <Text>{t('pitch')}: {results.pitch}</Text>
              <Text>{t('loudness')}: {results.loudness}</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.subtitle}>{t('comments_resonance')}:</Text>
              <Text style={styles.comments}>{results.additionalResonancesResults}</Text>            
            </View>
            <View style={styles.section}>
              <Text style={styles.subtitle}>{t('additional_features')}:</Text>
              <Text style={styles.comments}>{results.additionalFeaturesResults}</Text>            
            </View>
            <View style={styles.section}>
              <Text style={styles.subtitle}>{t('general_comments')}:</Text>
              <Text style={styles.comments}>{results.comments}</Text>
            </View>
          </View>
          <View style={styles.signature}>
            <Text>{t('clinician')}: {clinicianName}</Text>
          </View>
        </View>
        <View style={[styles.footer]}>
          <Text style={styles.note}>{t('note')}: CAPE-V = {t('cape_v')} (Kempster et al., 2009)</Text>
          <Image src="/images/Portada.png" style={styles.footerLogo} />
          <Text>{t('powered_by')} all-voiced.com.</Text>
        </View>
      </Page>
    </Document>
  );  

  return (
    <div className="pdf-builder">
      <h2>{t('customize_and_download_your_cape_v_report')}</h2>
      <div className="input-group">
        <label>{t('client')}</label>
        <input
          type="text"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label>{t('clinician')}</label>
        <input
          type="text"
          value={clinicianName}
          onChange={(e) => setClinicianName(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label>{t('upload_your_logo')}</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleLogoUpload}
        />
      </div>
      <br/><p className="small">{t('data_in_browser')}</p><br/>
      <PDFViewer style={{ width: '100%', height: '1000px' }}>
        {MyDocument}
      </PDFViewer>
      <br/>
      <PDFDownloadLink document={MyDocument} fileName="report.pdf">
        {({ blob, url, loading, error }) => 
          loading ? 'Loading document...' : <button className="button-center">{t('download_report')}</button>
        }
      </PDFDownloadLink>
      <br/>
      <button onClick={onRestart} className="button-center">{t('evaluate_another_sample')}</button>
    </div>
  );
}

export default ReportBuilder;