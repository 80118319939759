// VisualizationToggles.jsx

import { useTranslation } from 'react-i18next';

const VisualizationToggles = ({
  showSociodemographicData,
  showStandardAttributes, // Això es passa correctament com a `true` inicialment
  showAdditionalAttributes,
  onToggleSociodemographicData,
  onToggleStandardAttributes,
  onToggleAdditionalAttributes
}) => {
  const { t } = useTranslation();

  return (
    <div className="data-visualization-controls">
      <div className="controls-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Visualització de dades */}
        <div className="visualization-toggles-section">
          <h3>{t('visualization_toggles')}</h3>
          <div className="checkbox-container">
            <div>
              <input
                type="checkbox"
                checked={showSociodemographicData}
                onChange={onToggleSociodemographicData}
              />
              <label>{t('show_sociodemographic_data')}</label>
            </div>

            <div>
              <input
                type="checkbox"
                checked={showStandardAttributes}
                onChange={onToggleStandardAttributes}
              />
              <label>{t('show_standard_attributes_evaluations')}</label>
            </div>

            <div>
              <input
                type="checkbox"
                checked={showAdditionalAttributes}
                onChange={onToggleAdditionalAttributes}
              />
              <label>{t('show_additional_attributes_evaluations')}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisualizationToggles;