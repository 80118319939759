// RegisterComponent.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DataProtectionInformation from './DataProtectionInformation';
import countryOptions from './../auth/countryOptions';
import languageOptions from './../auth/languageOptions'; 

const RegisterComponent = ({ onRequestClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    dateOfBirth: '',
    pronoun: '',
    countryOfResidence: '',
    communicationLanguage: '', 
    profession: '',
    voiceCompetenceLevel: '',
    wantsToShareEvaluations: false, 
    receiveInformation: false,
    acceptPrivacyPolicy: false,
    acceptResearchUsage: false 
  });

  const [errors, setErrors] = useState({}); // Estat per emmagatzemar errors

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  // useEffect per establir wantsToShareEvaluations com true si voiceCompetenceLevel és advanced o expert
  useEffect(() => {
    if (formData.voiceCompetenceLevel === 'advanced' || formData.voiceCompetenceLevel === 'expert') {
      setFormData((prevData) => ({
        ...prevData,
        wantsToShareEvaluations: true,
      }));
    }
  }, [formData.voiceCompetenceLevel]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });

    // Restablir la validesa personalitzada del camp
    if (name === 'dateOfBirth' || name === 'voiceCompetenceLevel') {
      e.target.setCustomValidity('');
    }
  };

  const validateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age >= 14;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!formData.username) {
      validationErrors.username = 'Username is required.';
    }

    if (!formData.email) {
      validationErrors.email = 'Email is required.';
    }

    if (!formData.password) {
      validationErrors.password = 'Password is required.';
    }

    if (!formData.dateOfBirth) {
      validationErrors.dateOfBirth = 'Date of birth is required.';
    } else if (!validateAge(formData.dateOfBirth)) {
      validationErrors.dateOfBirth = 'You must be at least 14 years old to register.';
      const dateInput = document.querySelector('input[name="dateOfBirth"]');
      dateInput.setCustomValidity(validationErrors.dateOfBirth);
      dateInput.reportValidity();
    }

    if (!formData.voiceCompetenceLevel) {
      validationErrors.voiceCompetenceLevel = 'Voice competence level is required.';
      const voiceLevelInput = document.querySelector('select[name="voiceCompetenceLevel"]');
      voiceLevelInput.setCustomValidity(validationErrors.voiceCompetenceLevel);
      voiceLevelInput.reportValidity();
    }

    if (!formData.acceptPrivacyPolicy) {
      validationErrors.acceptPrivacyPolicy = 'You must accept the privacy policy to proceed.';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({}); // Esborra els errors si la validació passa

    const dataToSend = {
      username: formData.username,
      email: formData.email,
      password: formData.password,
      dateOfBirth: formData.dateOfBirth,
      pronoun: formData.pronoun || undefined,
      countryOfResidence: formData.countryOfResidence || undefined,
      communicationLanguage: formData.communicationLanguage || undefined,
      profession: formData.profession || undefined,
      voiceCompetenceLevel: formData.voiceCompetenceLevel, 
      wantsToShareEvaluations: formData.wantsToShareEvaluations, 
      acceptPrivacyPolicy: formData.acceptPrivacyPolicy,
      receiveInformation: formData.receiveInformation, 
      acceptResearchUsage: formData.acceptResearchUsage 
    };

    console.log('Data to send:', dataToSend);
  
    axios.post(`${apiUrl}/users/register`, dataToSend)
      .then(response => {
        console.log('User registered:', response.data);
        onRequestClose(); // Tancar el modal
        navigate('/confirmation');
      })
      .catch(error => {
        if (error.response && error.response.data === 'Email already exists') {
          setErrors({ email: 'The email address you entered is already in use.' });
          const emailInput = document.querySelector('input[name="email"]');
          emailInput.setCustomValidity('The email address you entered is already in use.');
          emailInput.reportValidity();
        } else {
          console.error('Error:', error.response ? error.response.data : error.message);
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="register-form">
        <input 
          type="text" 
          name="username" 
          value={formData.username} 
          onChange={handleChange} 
          placeholder={t('username')} 
          className="input-field" 
          required 
          aria-invalid={!!errors.username}
          title={errors.username || ''}
        />
        <input 
          type="email" 
          name="email" 
          value={formData.email} 
          onChange={handleChange} 
          placeholder={t('email')} 
          className="input-field" 
          required 
          aria-invalid={!!errors.email}
          title={errors.email || ''}
        />
        <input 
          type="password" 
          name="password" 
          value={formData.password} 
          onChange={handleChange} 
          placeholder={t('password')} 
          className="input-field" 
          required 
          aria-invalid={!!errors.password}
          title={errors.password || ''}
        />
        <p className="small">{t('date_of_birth')}</p>
        <input 
          type="date" 
          name="dateOfBirth" 
          value={formData.dateOfBirth} 
          onChange={handleChange} 
          placeholder={t('date_of_birth')} 
          className="input-field" 
          required
          aria-invalid={!!errors.dateOfBirth}
          title={errors.dateOfBirth || ''}
        />

        <select name="pronoun" value={formData.pronoun} onChange={handleChange} className="input-field">
            <option value="">{t('select_pronoun')}</option>
            <option value="she/her">She/Her</option>
            <option value="he/him">He/Him</option>
            <option value="they/them">They/Them</option>
            <option value="she/they">She/They</option>
            <option value="he/they">He/They</option>
            <option value="other">Other</option>
        </select>

        <select name="countryOfResidence" value={formData.countryOfResidence} onChange={handleChange} className="input-field">
          <option value="">{t('select_country_of_residence')}</option>
          {countryOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <select name="communicationLanguage" value={formData.communicationLanguage} onChange={handleChange} className="input-field">
          <option value="">{t('select_communication_language')}</option>
          {languageOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <select name="profession" value={formData.profession} onChange={handleChange} className="input-field">
          <option value="">{t('select_profession')}</option>
          <option value="SLP">{t('slp')}</option>
          <option value="ENT">{t('ent')}</option>
          <option value="vocal_coach">{t('vocal_coach')}</option>
          <option value="singing_teacher">{t('singing_teacher')}</option>
          <option value="other">{t('other')}</option>
        </select>

        <select 
          name="voiceCompetenceLevel" 
          value={formData.voiceCompetenceLevel} 
          onChange={handleChange} 
          className="input-field"
          required
          aria-invalid={!!errors.voiceCompetenceLevel}
          title={errors.voiceCompetenceLevel || ''}
        >
          <option value="">{t('select_voice_competence_level')}</option>
          <option value="beginner">{t('beginner')}</option>
          <option value="intermediate">{t('intermediate')}</option>
          <option value="advanced">{t('advanced')}</option>
          <option value="expert">{t('expert')}</option>
        </select>

        {(formData.voiceCompetenceLevel === 'advanced' || formData.voiceCompetenceLevel === 'expert') && (
          <div className="checkbox-container">
            <label>
              <input
                type="checkbox"
                name="wantsToShareEvaluations"
                checked={formData.wantsToShareEvaluations}
                onChange={handleChange}
              />
              {t('sharing_consent')}
            </label>
          </div>
        )}
        <br/>
        <DataProtectionInformation formData={formData} handleChange={handleChange} fontSize="0.8em" />
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img 
              src="/images/global-database-icon.png" 
              alt="Global Database Icon" 
              className="centered-icon" 
              style={{ maxWidth: '100px', height: 'auto' }}
              />
          </div>
        <button type="submit" className="button-center">{t('register')}</button>
      </form>
    </>
  );
};

export default RegisterComponent;