// FreeSortingTaskStructure.jsx
import React, { useState, useEffect } from 'react';
import DragAndDropWrapper from './DragAndDropWrapper';
import { useTranslation } from 'react-i18next';

const FreeSortingTaskStructure = ({ userData }) => {
  const { t, ready } = useTranslation();
  const [sorts, setSorts] = useState([]);

  useEffect(() => {
    if (!ready || !userData) return;

    const initialSorts = userData.attributes.slice(0, 3).map((attr, index) => ({
      id: `sort-${index}`,
      name: attr.title,
      items: attr.audioFilesSets[0].map((audioLink, itemIndex) => ({
        id: `item-${index}-${itemIndex}`,
        audioLink
      }))
    }));

    while (initialSorts.length < 3) {
      let newSortId = `sort-${initialSorts.length}`;
      initialSorts.push({
        id: newSortId,
        name: t('name_optional'),
        items: []
      });
    }

    setSorts(initialSorts);
  }, [ready, userData, t]);


  const handleDragAndDrop = (results) => {
    const { source, destination } = results;
  
    if (!destination) return;
  
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) return;
  
    const startSort = sorts.find(sort => sort.id === source.droppableId);
    const finishSort = sorts.find(sort => sort.id === destination.droppableId);
  
    if (startSort !== finishSort) {
      const startItems = Array.from(startSort.items);
      const [movedItem] = startItems.splice(source.index, 1);
      const finishItems = Array.from(finishSort.items);
      finishItems.splice(destination.index, 0, movedItem);

      const newStartSort = { ...startSort, items: startItems };
      const newFinishSort = { ...finishSort, items: finishItems };

      const newSorts = sorts.map(sort => {
        if (sort.id === newStartSort.id) {
          return newStartSort;
        } else if (sort.id === newFinishSort.id) {
          return newFinishSort;
        }
        return sort;
      });

      setSorts(newSorts);
    }
  };

   // Function to handle adding a new sort
   const handleAddSort = () => {
    const newSortId = `type-${sorts.length + 1}-id`;
    const newSort = {
      id: newSortId,
      name: t('name_optional'),
      items: []
    };
    setSorts([...sorts, newSort]);
  };

  // Function to handle removing a sort
  const handleRemoveSort = (sortId) => {
    const sortToRemove = sorts.find(sort => sort.id === sortId);
  
    // Check if there are items in the sort to be removed
    if (sortToRemove.items.length > 0) {
      // Get the first container (where you want to move the items)
      const firstContainer = sorts[0];
  
      // Combine items from the removed sort with the first container's items
      const updatedFirstContainerItems = [...firstContainer.items, ...sortToRemove.items];
  
      // Update the first container with the combined items
      const updatedFirstContainer = { ...firstContainer, items: updatedFirstContainerItems };
  
      // Update the sorts array, replacing the first container and removing the deleted sort
      setSorts(sorts.map(sort => sort.id === firstContainer.id ? updatedFirstContainer : sort).filter(sort => sort.id !== sortId));
    } else {
      // If no items in the sort to be removed, just remove the sort
      setSorts(sorts.filter(sort => sort.id !== sortId));
    }
  };

  // Function to handle renaming a sort
  const handleRenameSort = (sortId, newName) => {
    const updatedSorts = sorts.map(sort => {
      if (sort.id === sortId) {
        return {...sort, name: newName};
      }
      return sort;
    });
    setSorts(updatedSorts);
  };

  function convertToCSV(sorts) {
    let csvContent = "data:text/csv;charset=utf-8,";
  
    // Adding the header
    csvContent += "Sort Name,Sample Names\r\n";
  
    sorts.forEach(sort => {
      let sampleNames = sort.items.map(item => {
        // Extract file name from the audioLink
        let fileName = item.audioLink.split('/').pop(); // Gets the last part of the URL
        return fileName.replace('.wav', ''); // Removes the .wav extension
      }).join(';');
  
      let row = `${sort.name},${sampleNames}`;
      csvContent += row + "\r\n";
    });
  
    return encodeURI(csvContent);
  }
  
  function downloadCSV(sorts) {
    const csvData = convertToCSV(sorts);
    const link = document.createElement('a');
    link.setAttribute('href', csvData);
    link.setAttribute('download', 'sorting_results.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (!ready) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div className="layout__wrapper">
      <DragAndDropWrapper 
        sorts={sorts} 
        onDragEnd={handleDragAndDrop}
        handleRemoveSort={handleRemoveSort}
        handleRenameSort={handleRenameSort}
      />
      <button onClick={handleAddSort} className="add-sort-button">{t('add_sort')}</button>
      <button onClick={() => downloadCSV(sorts)}>{t('download')}</button>
    </div>
  );
};

export default FreeSortingTaskStructure;