// StudentDataProcessor.jsx

const StudentDataProcessor = ({ feedbackData }) => {
  const processStudentData = (data) => {
    return data.map(data => ({
      student: data.student,
      overallSeverity: data.overallSeverity ? {
        value: Number(data.overallSeverity.value),
        variability: data.overallSeverity.variability
      } : {},
      roughness: data.roughness ? {
        value: Number(data.roughness.value),
        variability: data.roughness.variability
      } : {},
      breathiness: data.breathiness ? {
        value: Number(data.breathiness.value),
        variability: data.breathiness.variability
      } : {},
      strain: data.strain ? {
        value: Number(data.strain.value),
        variability: data.strain.variability
      } : {},
      pitch: data.pitch ? {
        value: Number(data.pitch.value),
        variability: data.pitch.variability,
        direction: data.pitch.direction
      } : {},
      loudness: data.loudness ? {
        value: Number(data.loudness.value),
        variability: data.loudness.variability,
        direction: data.loudness.direction
      } : {},
      additionalResonances: data.additionalResonances,
      additionalFeatures: data.additionalFeatures,
      comments: data.comments
    }));
  };

  const userRatings = processStudentData(feedbackData);

  const calculateVariability = (variabilities) => {
    const consistent = variabilities.filter(v => v === 'consistent').length;
    const intermittent = variabilities.filter(v => v === 'intermittent').length;
    const total = consistent + intermittent;
    if (total === 0) return { consistentPercent: 0, intermittentPercent: 0 };
    const consistentPercent = (consistent / total) * 100;
    const intermittentPercent = (intermittent / total) * 100;
    return { consistentPercent, intermittentPercent };
  };

  const filterRatingsByDirection = (ratings, directionKey, option) => {
    return ratings
      .filter(rating => rating[directionKey]?.direction && rating[directionKey].direction[option])
      .map(rating => rating[directionKey]?.value);
  };

  const calculateDirectionPercentage = (ratings, directionKey, option) => {
    const total = ratings.length;
    if (total === 0) return 0;
    const count = ratings.filter(rating => rating[directionKey]?.direction && rating[directionKey].direction[option]).length;
    return (count / total) * 100;
  };

  const allAdditionalResonances = {};
  const allAdditionalFeatures = {};
  const allStudentComments = [];

  userRatings.forEach(rating => {
    if (rating.additionalResonances) {
      Object.entries(rating.additionalResonances).forEach(([key, value]) => {
        if (!allAdditionalResonances[key]) {
          allAdditionalResonances[key] = [];
        }
        allAdditionalResonances[key].push(value);
      });
    }
    if (rating.additionalFeatures) {
      Object.entries(rating.additionalFeatures).forEach(([key, value]) => {
        if (!allAdditionalFeatures[key]) {
          allAdditionalFeatures[key] = [];
        }
        allAdditionalFeatures[key].push(value);
      });
    }
    if (rating.comments) {
      allStudentComments.push(rating.comments);
    }
  });

  return {
    userRatings,
    calculateVariability,
    filterRatingsByDirection,
    calculateDirectionPercentage,
    allAdditionalResonances,
    allAdditionalFeatures,
    allStudentComments
  };
};

export default StudentDataProcessor;