// DataProtectionInformationContactForm.jsx

import React from 'react';
import { Trans } from 'react-i18next';

const DataProtectionInformationContactForm = ({ formData, handleChange, fontSize = '0.8em' }) => {

    return (
        <div style={{ fontSize }}>
            <div>
                   <Trans
                       i18nKey="data_protection_register_form"
                       components={{
                           a1: <a href="https://www.aepd.es/" target="_blank" rel="noopener noreferrer">Agencia Española de Protección de Datos</a>,
                           a2: <a href="/legal-notice" target="_blank" rel="noopener noreferrer">Legal Notice</a>,
                       }}
                   />
            </div>
            <br/>
            <div className="checkbox-container">
                <label>
                    <input
                        type="checkbox"
                        name="acceptPrivacyPolicy"
                        checked={formData.acceptPrivacyPolicy}
                        onChange={handleChange}
                        required
                    />
                    <Trans
                        i18nKey="accept_privacy_policy"
                        components={{
                            a: <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Política de Privacitat</a>,
                        }}
                    />
                </label>
            </div>
        </div>
    );
};

export default DataProtectionInformationContactForm;