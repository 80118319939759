// ConsentModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Trans, useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

function ConsentModal({ isOpen, onRequestClose, onConsentChange, consentGiven, onSubmit, fontSize = '0.8em' }) {
  const { t } = useTranslation();

  // Establir `accepted` a `true` si `consentGiven` és `true` al muntar el component
  const [accepted, setAccepted] = useState(consentGiven === true);
  const [notAccepted, setNotAccepted] = useState(consentGiven === false);

  useEffect(() => {
    setAccepted(consentGiven === true);
    setNotAccepted(consentGiven === false);
  }, [consentGiven]); // Actualitzar si `consentGiven` canvia

  const handleConsentChange = (isAccepted) => {
    setAccepted(isAccepted);
    setNotAccepted(!isAccepted);
    onConsentChange(isAccepted);
  };

  const handleSubmit = () => {
    onSubmit();
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Consent Modal"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '500px',
          minWidth: '300px',
          padding: '20px',
          maxHeight: '80%',
          overflowY: 'auto',
          zIndex: 1000,
        },
        overlay: {
          zIndex: 999,
        },
      }}
    >
      <strong>{t('consent_to_share_this_evaluation_title')}</strong>
      <br/><br/>
      <div style={{ fontSize }}>
        <Trans
          i18nKey="consent_to_share_this_evaluation_text"
          components={{
            a: <a href="/privacy-policy">Privacy Policy</a>,
          }}
        />
      </div>
      <br />
      <div className="checkbox-container" style={{ fontSize }}>
        <label>
          <input
            type="checkbox"
            checked={accepted}
            onChange={() => handleConsentChange(true)}
          />
          <Trans
            i18nKey="consent_to_share_this_evaluation_yes"
            components={{
              a: <a href="/privacy-policy">Privacy Policy</a>,
            }}
          />
        </label>
      </div>
      <div className="checkbox-container" style={{ fontSize }}>
        <label>
          <input
            type="checkbox"
            checked={notAccepted}
            onChange={() => handleConsentChange(false)}
          />
          {t('consent_to_share_this_evaluation_no')}
        </label>
      </div>
      <br />
      <div style={{ textAlign: 'center' }}>
        <button className="button-center" onClick={handleSubmit}>{t('submit')}</button>
      </div>
    </Modal>
  );
}

export default ConsentModal;