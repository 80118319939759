// SharedDataProcessor.jsx

const SharedDataProcessor = ({ sharedEvaluations }) => {
    const processSharedData = (data) => {
      return data.map(evaluation => ({
        ...evaluation.data,
        additionalResonances: evaluation.data.additionalResonances || {},
        additionalFeatures: evaluation.data.additionalFeatures || {},
        comments: evaluation.data.comments || ''
      }));
    };
  
    const sharedRatings = processSharedData(sharedEvaluations);
  
    // Shared Evaluations Variability
    const calculateSharedEvaluationsVariability = (sharedEvaluations, dataKey) => {
      if (!sharedEvaluations || sharedEvaluations.length === 0) return { consistentPercent: 0, intermittentPercent: 0 };
  
      const variabilities = sharedEvaluations.map(evaluation => evaluation[dataKey]?.variability || '');
      const consistent = variabilities.filter(v => v === 'consistent').length;
      const intermittent = variabilities.filter(v => v === 'intermittent').length;
      const total = consistent + intermittent;
  
      if (total === 0) return { consistentPercent: 0, intermittentPercent: 0 };
  
      const consistentPercent = (consistent / total) * 100;
      const intermittentPercent = (intermittent / total) * 100;
  
      return { consistentPercent, intermittentPercent };
    };
  
    // Shared Evaluations by Direction Percentage
    const filterSharedEvaluationsByDirection = (sharedEvaluations, directionKey, option) => {
      return sharedEvaluations
        .filter(evaluation => evaluation[directionKey]?.direction && evaluation[directionKey].direction[option])
        .map(evaluation => evaluation[directionKey]?.value);
    };
  
    const calculateSharedEvaluationsDirectionPercentage = (sharedEvaluations, directionKey, option) => {
      const total = sharedEvaluations.length;
      if (total === 0) return 0;
      const count = sharedEvaluations.filter(evaluation => evaluation[directionKey]?.direction && evaluation[directionKey].direction[option]).length;
      return (count / total) * 100;
    };
  
    // Shared Evaluations Additional Characteristics Percentage
    const calculateSharedPercentage = (sharedEvaluations, key, category) => {
      if (!sharedEvaluations || sharedEvaluations.length === 0) {
        return 0;
      }
  
      const sharedCount = sharedEvaluations.filter(evaluation => {
        const hasValue = evaluation[category] && evaluation[category][key] && evaluation[category][key].value !== undefined;
        return hasValue;
      }).length;
  
      const percentage = (sharedCount / sharedEvaluations.length) * 100;
      return percentage.toFixed(0);
    };
  
    // Shared Evaluations Data Extraction
    const extractSharedRatings = (sharedEvaluations, dataKey, option) => {
      return sharedEvaluations
        .filter(evaluation =>
          !option ||
          (evaluation[dataKey]?.direction && evaluation[dataKey].direction[option])
        )
        .map(evaluation => evaluation[dataKey]?.value);
    };
  
    const extractSharedResonanceData = (sharedEvaluations, key) => {
      const result = [];
      if (sharedEvaluations) {
        sharedEvaluations.forEach(evaluation => {
          if (evaluation.additionalResonances && evaluation.additionalResonances[key]) {
            result.push({
              value: evaluation.additionalResonances[key].value,
              variability: evaluation.additionalResonances[key].variability
            });
          }
        });
      }
      return result;
    };
  
    const extractSharedAdditionalFeaturesData = (sharedEvaluations, key) => {
      const result = [];
      if (sharedEvaluations) {
        sharedEvaluations.forEach(evaluation => {
          if (evaluation.additionalFeatures && evaluation.additionalFeatures[key]) {
            result.push({
              value: evaluation.additionalFeatures[key].value,
              variability: evaluation.additionalFeatures[key].variability
            });
          }
        });
      }
      return result;
    };
  
    const extractSharedComments = (sharedEvaluations) => {
      const result = sharedEvaluations
        .map(evaluation => evaluation.comments)
        .filter(comment => comment);
      return result;
    };
  
    return {
      sharedRatings,
      calculateSharedEvaluationsVariability,
      filterSharedEvaluationsByDirection,
      calculateSharedEvaluationsDirectionPercentage,
      calculateSharedPercentage,
      extractSharedRatings,
      extractSharedResonanceData,
      extractSharedAdditionalFeaturesData,
      extractSharedComments
    };
  };
  
  export default SharedDataProcessor;  