// consensusDataUtils.js

export const calculateMedian = (values) => {
  if (values.length === 0) return 0;
  values.sort((a, b) => a - b);
  const middle = Math.floor(values.length / 2);
  if (values.length % 2 === 0) {
    return (values[middle - 1] + values[middle]) / 2;
  }
  return values[middle];
};

export const prepareInitialConsensusData = (studentsResults) => {
  const initialConsensusData = {
    overallSeverity: {
      value: calculateMedian(studentsResults.map(result => result.overallSeverity.value)),
      variability: 'consistent'
    },
    roughness: {
      value: calculateMedian(studentsResults.map(result => result.roughness.value)),
      variability: 'consistent'
    },
    breathiness: {
      value: calculateMedian(studentsResults.map(result => result.breathiness.value)),
      variability: 'consistent'
    },
    strain: {
      value: calculateMedian(studentsResults.map(result => result.strain.value)),
      variability: 'consistent'
    },
    pitch: {
      value: calculateMedian(studentsResults.map(result => result.pitch.value)),
      variability: 'consistent',
      direction: {
        tooHigh: studentsResults.some(result => result.pitch && result.pitch.direction && result.pitch.direction.tooHigh),
        tooLow: studentsResults.some(result => result.pitch && result.pitch.direction && result.pitch.direction.tooLow)
      }
    },
    loudness: {
      value: calculateMedian(studentsResults.map(result => result.loudness.value)),
      variability: 'consistent',
      direction: {
        tooLoud: studentsResults.some(result => result.loudness && result.loudness.direction && result.loudness.direction.tooLoud),
        tooSoft: studentsResults.some(result => result.loudness && result.loudness.direction && result.loudness.direction.tooSoft)
      }
    },
    additionalResonances: {},
    additionalFeatures: {},
    comments: ''
  };

  // Process additional resonances
  studentsResults.forEach(result => {
    Object.keys(result.additionalResonances || {}).forEach(key => {
      if (!initialConsensusData.additionalResonances[key]) {
        initialConsensusData.additionalResonances[key] = { value: 0, variability: 'consistent' };
      }
      initialConsensusData.additionalResonances[key].value += result.additionalResonances[key].value;
    });
  });

  // Process additional features
  studentsResults.forEach(result => {
    Object.keys(result.additionalFeatures || {}).forEach(key => {
      if (!initialConsensusData.additionalFeatures[key]) {
        initialConsensusData.additionalFeatures[key] = { value: 0, variability: 'consistent' };
      }
      initialConsensusData.additionalFeatures[key].value += result.additionalFeatures[key].value;
    });
  });

  // Calculate medians for additional resonances and features
  Object.keys(initialConsensusData.additionalResonances).forEach(key => {
    initialConsensusData.additionalResonances[key].value = calculateMedian(
      studentsResults.map(result => (result.additionalResonances[key] ? result.additionalResonances[key].value : 0))
    );
  });

  Object.keys(initialConsensusData.additionalFeatures).forEach(key => {
    initialConsensusData.additionalFeatures[key].value = calculateMedian(
      studentsResults.map(result => (result.additionalFeatures[key] ? result.additionalFeatures[key].value : 0))
    );
  });

  return initialConsensusData;
};