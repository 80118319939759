// TranslationIssuesComponent.jsx

import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import axios from 'axios';
import DataProtectionInformationContactForm from './DataProtectionInformationContactForm';

const TranslationIssuesComponent = () => {
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        language: i18n.language,
        issue: '',
        correction: ''
    });

    const [formSubmitted, setFormSubmitted] = useState(false); 

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${apiUrl}/translations/send-translation-issue`, formData)
            .then(response => {
                console.log('Translation issue submitted:', formData);
                setFormSubmitted(true);  // Actualitza l'estat quan el formulari s'ha enviat correctament
            })
            .catch(error => {
                console.error('There was an error submitting the form:', error);
                alert('There was an error submitting your translation issue.');
            });
    };

    return (
        <div className="form-container">
            {/* Mostrar la vista de confirmació si el formulari s'ha enviat */}
            {formSubmitted ? (
                <div className="confirmation-message">
                    <h2>{t('thank_you')}</h2>
                    <p>{t('your_message_has_been_sent')}</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="register-form">
                    <h2>{t('help_us_improve_our_language_adaptations')}</h2>
                    <Trans i18nKey="translation_issues_explanation"></Trans><br/><br/>

                    <label className="form-label">
                        {t('your_name')}
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="input-field"
                    />

                    <label className="form-label">
                        {t('your_email')}
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="input-field"
                    />

                    <label className="form-label">
                        {t('language')}: {formData.language}
                    </label>

                    <label className="form-label">
                        {t('where_it_says')}
                    </label>
                    <textarea
                        name="issue"
                        value={formData.issue}
                        onChange={handleChange}
                        required
                        className="input-field wide-input"
                        rows="4"
                    />

                    <label className="form-label">
                        {t('it_should_say')}
                    </label>
                    <textarea
                        name="correction"
                        value={formData.correction}
                        onChange={handleChange}
                        required
                        className="input-field wide-input"
                        rows="4"
                    />

                    <DataProtectionInformationContactForm formData={formData} handleChange={handleChange} fontSize="0.8em" />
                    <button type="submit" className="button-center">{t('submit')}</button>
                </form>
            )}
        </div>
    );
};

export default TranslationIssuesComponent;