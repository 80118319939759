// RenderAdditionalCharacteristics.jsx

import React from 'react';
import VASGraphs from './VASGraphs';

const RenderAdditionalCharacteristics = ({
  allAdditionalResonances,
  allAdditionalFeatures,
  allStudentComments,
  consensusSubmitted,
  consensusData,
  showSharedEvaluations,
  sharedRatings,
  feedbackData,
  t,
  calculateVariability,
  calculateSharedPercentage,
  extractSharedResonanceData,
  extractSharedAdditionalFeaturesData,
  extractSharedComments,
  showConsensusData // Afegit per gestionar la visibilitat de les dades del consens
}) => {
  // Define all possible resonance and additional feature keys
  const resonanceKeys = [
    'normal_resonance',
    'hypernasality',
    'hyponasality',
    'other'
  ];

  const featureKeys = [
    'diplophonia',
    'fry',
    'falsetto',
    'asthenia',
    'aphonia',
    'pitch_instability',
    'tremor',
    'wet_gurgly',
    'other'
  ];

  const calculateStudentPercentage = (studentValues, totalStudents) => {
    return totalStudents > 0 ? (studentValues.length / totalStudents) * 100 : 0;
  };

  const renderGraph = (key, title, studentValues, sharedValues, consensusDataForKey, totalStudents, category) => {
    const studentPercentage = calculateStudentPercentage(studentValues, totalStudents);
    let sharedPercentage = calculateSharedPercentage(sharedRatings, key, category);

    // Ensure sharedPercentage is a number
    sharedPercentage = Number(sharedPercentage) || 0;

    if (key === 'normal_resonance') {
      return (
        <div key={key}>
          <h4>{t(title)}</h4>
          <p className="small">
            {t('students_in_this_session')}: {studentPercentage.toFixed(0)}% 
          </p>
          {showSharedEvaluations && (
            <p className="small">
              {t('other_allvoiced_users_evaluations')}: {sharedPercentage.toFixed(0)}% 
            </p>
          )}
        </div>
      );
    }

    const studentVariabilities = studentValues.map(v => v.variability || '');
    const { consistentPercent: studentConsistentPercent, intermittentPercent: studentIntermittentPercent } = calculateVariability(studentVariabilities);

    const sharedVariabilities = sharedValues.map(v => v.variability || '');
    const { consistentPercent: sharedConsistentPercent, intermittentPercent: sharedIntermittentPercent } = calculateVariability(sharedVariabilities);

    return (
      <div key={key}>
        <h4>{t(title)}</h4>
        <p className="small">
          {t('students_in_this_session')}: {studentPercentage.toFixed(0)}% ({t('C')} = {studentConsistentPercent.toFixed(0)}%, {t('I')} = {studentIntermittentPercent.toFixed(0)}%)
        </p>
        {showConsensusData && consensusSubmitted && consensusDataForKey && (
          <p className="small">
            {t('consensus_reached_in_this_session')}: {t(consensusDataForKey.variability || '')}
          </p>
        )}
        {showSharedEvaluations && (
          <p className="small">
            {t('other_allvoiced_users_evaluations')}: {sharedPercentage.toFixed(0)}% ({t('C')} = {sharedConsistentPercent.toFixed(0)}%, {t('I')} = {sharedIntermittentPercent.toFixed(0)}%)
          </p>
        )}
        <VASGraphs
          attributeName={title}
          attributeData={{
            userRatings: studentValues.map(v => v.value),
            consensusRating: showConsensusData && consensusSubmitted && consensusDataForKey ? consensusDataForKey.value : null,
            sharedRatings: showSharedEvaluations ? sharedValues.map(v => v.value) : [],
          }}
        />
      </div>
    );
  };

  return (
    <div className="additional-info">
      <h2>{t('additional_attributes_evaluations')}</h2>
      <h3>{t('comments_resonance')}</h3>
      {resonanceKeys.map(key => {
        const studentValues = allAdditionalResonances[key] || [];
        const sharedValues = extractSharedResonanceData(sharedRatings, key) || [];
        const consensusDataForKey = showConsensusData && consensusSubmitted && consensusData && consensusData.additionalResonances ? consensusData.additionalResonances[key] : null;
        const totalStudents = feedbackData.length; // Use the correct total number of students
        return renderGraph(key, key, studentValues, sharedValues, consensusDataForKey, totalStudents, 'additionalResonances');
      })}

      <h3>{t('additional_features')}</h3>
      {featureKeys.map(key => {
        const studentValues = allAdditionalFeatures[key] || [];
        const sharedValues = extractSharedAdditionalFeaturesData(sharedRatings, key) || [];
        const totalStudents = feedbackData.length; // Use the correct total number of students
        return renderGraph(key, key, studentValues, sharedValues, null, totalStudents, 'additionalFeatures');
      })}

      <h3>{t('general_comments')}</h3>
      <p className="small">
        <strong>{t('students_in_this_session')}:</strong> {allStudentComments.length > 0 ? (allStudentComments.join(', ')) : (t('no_comments'))}
      </p>
      {showConsensusData && consensusSubmitted && (
        <p className="small">
          <strong>{t('consensus_reached_in_this_session')}:</strong> {consensusData.comments ? (consensusData.comments) : (t('no_comments'))}
        </p>
      )}
      {showSharedEvaluations && (
        <p className="small">
          <strong>{t('other_allvoiced_users_evaluations')}: </strong> {extractSharedComments(sharedRatings).length > 0 ? (extractSharedComments(sharedRatings).join(', ')) : (t('no_comments'))}
        </p>
      )}
    </div>
  );
};

export default RenderAdditionalCharacteristics;