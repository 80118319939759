// useAudioPlayerCallback.js 

import { useRef } from 'react';

const useAudioPlayerCallback = () => {
  const audioRef = useRef(null);

  const playAudio = (audioLink) => {
    return new Promise((resolve, reject) => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      const audio = new Audio(audioLink);
      audioRef.current = audio;

      audio.onended = resolve;
      audio.onerror = reject;
      audio.onplay = () => console.log("Audio started:", audioLink);

      audio.play().catch(reject);
    });
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  return { playAudio, stopAudio };
};

export default useAudioPlayerCallback;