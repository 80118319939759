// SortableContainer.jsx
import React from 'react';
import SortList from './SortList';
import { useTranslation } from 'react-i18next';

function SortableContainer({ sort, handleRemoveSort, handleRenameSort, isFirstContainer }) {
  const { t } = useTranslation();
  return (
    <div className="card">
      {!isFirstContainer && (
        <>
          <input
            type="text"
            value={sort.name}
            onChange={(e) => handleRenameSort(sort.id, e.target.value)}
          />
        </>
      )}
      <SortList {...sort} />
      {!isFirstContainer && <div className="spacer"></div>}
      {!isFirstContainer && (
        <button onClick={() => handleRemoveSort(sort.id)}>{t('remove_sort')}</button>
      )}
    </div>
  );
}

export default SortableContainer;