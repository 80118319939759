// feedbackDataOriginalPVQD.js

// Per aquesta primera fase, s'ha fet una seleecció de 120 mostres 
// La selecció té en compte la representació per grups d'edat, sexe, diagnòstic i severitat de disfonia.
// En una segona fase, un cop ja es tinguin suficients dades perceptives per aquesta col·lecció, ja se n'afegiran més.

const feedbackDataOriginalPVQD = [

    {"database": "Walden (2022)","participantId": "BL01","sampleName": "BL01","sampleLink": "samples/PVQD-70dB/BL01_70dB.wav","age": 46,"gender": "male","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [8,20,4,35,18,2],"roughness": [8,14,5,10,13,7],"breathiness": [7,1,4,11,1,3],"strain": [7,4,4,10,0,2],"pitch": [7,1,4,24,0,2],"loudness": [6,1,4,44,0,3],"g": [1,1,0,1,1,0],"r": [0,1,0,1,1,0],"b": [0,0,0,0,0,0],"a": [1,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // EXCLUDED (En normalitzar a 70dB i guardar, El Praat diu "Writing samples to audio file: XX out of XXXXXX samles have been clipped") {"database": "Walden (2022)","participantId": "BL02","sampleName": "BL02","sampleLink": "samples/PVQD-70dB/BL02_70dB.wav","age": 76,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [61,78,87,65,67,85],"roughness": [40,65,79,65,62,79],"breathiness": [40,52,78,31,44,84],"strain": [59,45,78,66,37,65],"pitch": [40,45,31,49,25,27],"loudness": [40,61,75,30,50,73],"g": [2,3,3,2,2,3],"r": [2,2,2,1,1,2],"b": [1,2,2,0,1,3],"a": [1,3,2,1,2,2],"s": [2,1,3,2,2,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL03","sampleName": "BL03","sampleLink": "samples/PVQD-70dB/BL03_70dB.wav","age": 88,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [63,83,66,61,74,48],"roughness": [43,71,45,62,68,48],"breathiness": [9,43,66,13,33,30],"strain": [43,19,61,61,42,41],"pitch": [44,40,62,26,27,48],"loudness": [44,21,57,12,40,49],"g": [2,3,3,2,2,2],"r": [2,3,2,2,2,2],"b": [0,1,2,0,1,1],"a": [2,1,2,2,2,1],"s": [2,2,2,2,1,1],},
     {"database": "Walden (2022)","participantId": "BL04","sampleName": "BL04","sampleLink": "samples/PVQD-70dB/BL04_70dB.wav","age": 42,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [13,3,4,12,7,18],"roughness": [12,1,4,12,5,3],"breathiness": [12,0,11,11,1,2],"strain": [11,0,5,11,1,3],"pitch": [11,0,17,11,1,37],"loudness": [10,0,5,10,6,4],"g": [1,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL05","sampleName": "BL05","sampleLink": "samples/PVQD-70dB/BL05_70dB.wav","age": 75,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [24,61,48,25,27,73],"roughness": [24,35,46,25,21,57],"breathiness": [24,46,64,12,20,75],"strain": [25,31,47,5,10,42],"pitch": [7,7,44,24,1,9],"loudness": [7,27,39,24,4,76],"g": [1,2,2,1,1,2],"r": [1,1,2,1,1,2],"b": [1,1,2,1,1,2],"a": [0,2,2,1,1,2],"s": [1,1,1,1,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL06","sampleName": "BL06","sampleLink": "samples/PVQD-70dB/BL06_70dB.wav","age": 65,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [80,87,100,81,92,93],"roughness": [9,8,22,27,31,24],"breathiness": [80,67,97,82,58,95],"strain": [64,83,99,67,92,84],"pitch": [42,63,91,68,58,59],"loudness": [42,79,90,67,81,95],"g": [3,3,3,3,3,3],"r": [0,0,1,1,1,2],"b": [3,2,3,3,2,3],"a": [3,3,3,3,3,3],"s": [3,3,3,3,3,3],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL07","sampleName": "BL07","sampleLink": "samples/PVQD-70dB/BL07_70dB.wav","age": 24,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [11,8,15,13,23,19],"roughness": [11,4,7,14,18,19],"breathiness": [10,1,10,4,20,2],"strain": [10,2,24,4,19,10],"pitch": [9,0,24,11,1,18],"loudness": [9,0,3,11,1,14],"g": [0,0,0,0,1,0],"r": [0,0,0,0,1,1],"b": [0,0,1,0,1,0],"a": [0,0,0,1,0,0],"s": [0,0,1,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL08","sampleName": "BL08","sampleLink": "samples/PVQD-70dB/BL08_70dB.wav","age": 27,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [14,11,43,11,20,8],"roughness": [13,8,28,11,13,8],"breathiness": [12,0,51,11,9,2],"strain": [13,0,30,10,1,13],"pitch": [12,1,48,10,1,19],"loudness": [13,0,7,9,1,2],"g": [0,0,1,0,1,0],"r": [0,0,1,0,1,0],"b": [0,0,2,0,1,0],"a": [0,0,0,0,0,0],"s": [0,0,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL09","sampleName": "BL09","sampleLink": "samples/PVQD-70dB/BL09_70dB.wav","age": 70,"gender": "male","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [39,20,67,28,19,23],"roughness": [17,13,38,29,20,22],"breathiness": [5,1,5,4,1,18],"strain": [64,6,72,50,17,17],"pitch": [5,1,11,43,5,10],"loudness": [63,1,4,58,1,12],"g": [2,1,2,1,1,1],"r": [0,1,1,1,1,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [2,0,3,2,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL10","sampleName": "BL10","sampleLink": "samples/PVQD-70dB/BL10_70dB.wav","age": 21,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [7,20,21,11,23,34],"roughness": [8,4,11,11,8,16],"breathiness": [8,18,24,10,18,42],"strain": [7,4,5,10,1,14],"pitch": [6,1,14,10,1,33],"loudness": [6,1,4,11,0,3],"g": [0,1,0,0,1,0],"r": [0,0,0,0,0,0],"b": [0,1,1,0,0,1],"a": [0,0,0,0,1,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL11","sampleName": "BL11","sampleLink": "samples/PVQD-70dB/BL11_70dB.wav","age": 77,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [25,45,33,13,51,34],"roughness": [16,21,38,13,40,33],"breathiness": [7,23,18,5,20,34],"strain": [26,38,62,21,39,40],"pitch": [9,16,15,13,9,12],"loudness": [9,22,5,13,12,5],"g": [1,2,1,0,2,1],"r": [1,1,1,1,1,1],"b": [0,1,1,0,1,1],"a": [1,1,0,0,1,0],"s": [1,2,2,0,2,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL12","sampleName": "BL12","sampleLink": "samples/PVQD-70dB/BL12_70dB.wav","age": 68,"gender": "male","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [38,57,60,39,80,37],"roughness": [39,18,44,30,65,38],"breathiness": [14,26,64,8,43,49],"strain": [11,51,40,8,42,30],"pitch": [10,11,5,39,13,28],"loudness": [11,23,5,23,40,18],"g": [1,2,2,1,3,2],"r": [1,1,1,1,3,1],"b": [0,2,2,0,2,2],"a": [1,1,2,1,2,1],"s": [0,2,1,1,2,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL13","sampleName": "BL13","sampleLink": "samples/PVQD-70dB/BL13_70dB.wav","age": 63,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [18,51,10,5,45,31],"roughness": [18,2,1,5,1,23],"breathiness": [9,20,22,4,26,33],"strain": [8,40,10,5,35,19],"pitch": [17,19,27,5,2,43],"loudness": [8,15,2,4,11,8],"g": [1,2,0,0,2,2],"r": [1,0,0,0,0,1],"b": [0,1,1,0,1,2],"a": [0,1,0,0,1,1],"s": [0,2,0,0,2,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL14","sampleName": "BL14","sampleLink": "samples/PVQD-70dB/BL14_70dB.wav","age": 34,"gender": "male","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [20,10,3,21,8,31],"roughness": [13,5,16,3,3,30],"breathiness": [4,1,3,3,1,2],"strain": [5,0,11,7,1,23],"pitch": [20,0,6,22,0,8],"loudness": [14,1,3,12,0,23],"g": [1,0,0,1,0,1],"r": [1,0,1,1,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL16","sampleName": "BL16","sampleLink": "samples/PVQD-70dB/BL16_70dB.wav","age": 65,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [22,28,37,28,25,40],"roughness": [12,1,35,28,4,36],"breathiness": [12,21,40,9,13,40],"strain": [19,15,31,28,5,41],"pitch": [14,1,14,28,1,10],"loudness": [27,13,23,44,10,26],"g": [1,1,1,1,1,1],"r": [0,0,1,1,1,1],"b": [0,1,1,0,1,1],"a": [1,1,1,1,0,1],"s": [1,1,1,1,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL17","sampleName": "BL17","sampleLink": "samples/PVQD-70dB/BL17_70dB.wav","age": 55,"gender": "male","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [12,11,22,10,19,17],"roughness": [10,6,19,9,19,19],"breathiness": [10,0,20,10,1,5],"strain": [11,2,8,9,12,10],"pitch": [10,0,5,9,1,13],"loudness": [10,1,3,8,1,8],"g": [1,0,1,0,1,0],"r": [0,0,1,0,1,1],"b": [0,0,1,0,0,0],"a": [1,0,0,1,0,0],"s": [0,0,1,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL18","sampleName": "BL18","sampleLink": "samples/PVQD-70dB/BL18_70dB.wav","age": 49,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [6,12,26,14,18,34],"roughness": [7,9,27,13,15,37],"breathiness": [6,1,11,2,1,33],"strain": [6,9,26,2,1,46],"pitch": [7,1,24,3,1,14],"loudness": [5,1,11,3,0,9],"g": [0,0,1,1,1,1],"r": [0,0,1,1,1,1],"b": [0,0,1,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL19","sampleName": "BL19","sampleLink": "samples/PVQD-70dB/BL19_70dB.wav","age": 47,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [33,45,58,27,54,52],"roughness": [33,42,51,28,50,51],"breathiness": [6,12,13,6,20,20],"strain": [6,9,48,7,26,53],"pitch": [28,25,52,28,37,49],"loudness": [28,21,47,29,22,41],"g": [1,2,2,1,2,2],"r": [1,2,2,1,2,2],"b": [0,0,1,0,1,1],"a": [1,1,2,1,1,2],"s": [0,1,1,0,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL20","sampleName": "BL20","sampleLink": "samples/PVQD-70dB/BL20_70dB.wav","age": 71,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [39,53,33,9,46,34],"roughness": [39,25,31,10,8,30],"breathiness": [14,26,37,1,9,24],"strain": [13,49,42,1,41,25],"pitch": [14,10,32,1,1,34],"loudness": [31,21,17,1,1,27],"g": [1,2,1,0,2,2],"r": [1,1,1,1,1,2],"b": [0,1,1,0,1,1],"a": [0,1,0,0,0,1],"s": [1,2,1,0,2,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "BL21","sampleName": "BL21","sampleLink": "samples/PVQD-70dB/BL21_70dB.wav","age": 74,"gender": "female","diagnose": "no_data_regarding_voice_complaint_or_diagnosis","language": "english","overallSeverity": [10,43,50,25,27,53],"roughness": [9,11,52,11,18,49],"breathiness": [9,15,45,11,18,63],"strain": [9,19,40,12,10,33],"pitch": [10,17,46,11,1,9],"loudness": [9,18,22,25,12,4],"g": [1,2,1,1,1,2],"r": [0,1,1,1,1,1],"b": [0,1,1,0,0,2],"a": [1,2,0,1,1,0],"s": [0,1,1,1,1,1],},
     {"database": "Walden (2022)","participantId": "LA10001","sampleName": "LA10001","sampleLink": "samples/PVQD-70dB/LA10001_70dB.wav","age": 24,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [8,29,30,14,41,37],"roughness": [8,14,26,14,20,25],"breathiness": [8,19,34,2,19,39],"strain": [8,21,28,3,36,28],"pitch": [8,1,33,3,18,35],"loudness": [7,1,30,12,4,18],"g": [1,1,2,1,2,1],"r": [0,1,1,1,1,0],"b": [0,1,2,0,1,1],"a": [0,0,1,1,0,1],"s": [1,1,1,0,2,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA1001","sampleName": "LA1001","sampleLink": "samples/PVQD-70dB/LA1001_70dB.wav","age": 60,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [3,5,10,4,4,30],"roughness": [3,4,2,4,4,3],"breathiness": [4,1,2,3,1,3],"strain": [4,1,23,3,1,41],"pitch": [3,1,19,4,1,3],"loudness": [4,1,2,4,1,1],"g": [0,0,0,0,0,1],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,1,0,0,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA1002","sampleName": "LA1002","sampleLink": "samples/PVQD-70dB/LA1002_70dB.wav","age": 33,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [7,3,2,3,19,24],"roughness": [8,1,2,2,1,3],"breathiness": [8,1,2,3,18,20],"strain": [8,1,2,3,23,32],"pitch": [7,1,2,2,1,17],"loudness": [8,1,2,2,1,18],"g": [0,0,0,0,1,1],"r": [0,0,0,0,0,0],"b": [0,0,0,0,1,1],"a": [0,0,0,0,0,0],"s": [0,0,0,0,1,1],},
     {"database": "Walden (2022)","participantId": "LA1003","sampleName": "LA1003","sampleLink": "samples/PVQD-70dB/LA1003_70dB.wav","age": 28,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [40,24,40,18,25,28],"roughness": [28,21,28,18,16,23],"breathiness": [8,3,24,17,2,24],"strain": [39,18,44,16,19,28],"pitch": [9,1,63,28,1,23],"loudness": [64,17,17,15,4,14],"g": [1,1,1,1,1,1],"r": [1,1,1,0,1,1],"b": [0,0,1,0,0,1],"a": [1,0,1,0,0,1],"s": [1,1,2,0,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA1005","sampleName": "LA1005","sampleLink": "samples/PVQD-70dB/LA1005_70dB.wav","age": 20,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [10,15,3,14,9,5],"roughness": [10,9,9,14,9,3],"breathiness": [10,12,13,14,1,5],"strain": [10,1,10,15,3,9],"pitch": [9,1,7,15,1,9],"loudness": [9,1,2,14,0,6],"g": [0,1,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,1,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA1009","sampleName": "LA1009","sampleLink": "samples/PVQD-70dB/LA1009_70dB.wav","age": 67,"gender": "male","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [62,69,72,63,81,74],"roughness": [78,72,81,63,80,79],"breathiness": [8,1,29,5,2,51],"strain": [38,41,67,5,51,63],"pitch": [38,45,61,4,28,79],"loudness": [62,6,35,4,39,50],"g": [3,3,2,2,3,2],"r": [3,3,3,2,3,3],"b": [0,0,1,0,0,1],"a": [3,1,1,1,1,1],"s": [2,2,2,0,2,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA2001","sampleName": "LA2001","sampleLink": "samples/PVQD-70dB/LA2001_70dB.wav","age": 31,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [17,18,36,5,28,8],"roughness": [16,14,38,5,18,11],"breathiness": [16,1,37,5,1,3],"strain": [16,7,44,4,11,20],"pitch": [16,1,12,4,18,4],"loudness": [16,1,13,4,1,3],"g": [0,1,1,0,1,0],"r": [0,1,1,0,1,0],"b": [0,0,1,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA2004","sampleName": "LA2004","sampleLink": "samples/PVQD-70dB/LA2004_70dB.wav","age": 65,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [60,47,61,41,66,68],"roughness": [4,8,34,12,4,41],"breathiness": [39,37,61,41,33,42],"strain": [11,48,45,24,67,56],"pitch": [6,9,31,12,20,43],"loudness": [61,33,53,41,27,43],"g": [2,2,2,2,2,2],"r": [0,0,0,0,0,2],"b": [1,1,2,2,1,2],"a": [2,1,1,2,2,1],"s": [1,2,1,1,2,2],},
     {"database": "Walden (2022)","participantId": "LA2007","sampleName": "LA2007","sampleLink": "samples/PVQD-70dB/LA2007_70dB.wav","age": 35,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [24,22,48,13,51,14],"roughness": [9,1,23,5,2,2],"breathiness": [31,17,55,11,21,38],"strain": [23,1,39,5,42,2],"pitch": [11,0,6,6,2,2],"loudness": [22,1,29,10,15,3],"g": [1,1,2,1,2,0],"r": [0,0,1,0,0,0],"b": [1,1,2,0,1,1],"a": [1,0,1,1,1,0],"s": [0,0,1,0,2,0],},
     {"database": "Walden (2022)","participantId": "LA5003","sampleName": "LA5003","sampleLink": "samples/PVQD-70dB/LA5003_70dB.wav","age": 44,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [79,90,93,77,89,90],"roughness": [14,5,69,5,8,48],"breathiness": [62,57,88,78,39,79],"strain": [81,85,88,78,80,91],"pitch": [63,51,89,79,70,87],"loudness": [81,78,90,80,80,91],"g": [3,3,3,3,3,3],"r": [1,0,2,0,1,2],"b": [3,2,2,3,2,3],"a": [3,3,3,3,3,3],"s": [3,3,3,3,3,3],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA7003","sampleName": "LA7003","sampleLink": "samples/PVQD-70dB/LA7003_70dB.wav","age": 80,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [39,49,81,40,52,39],"roughness": [24,43,67,41,19,43],"breathiness": [10,18,75,5,36,29],"strain": [38,37,70,24,31,36],"pitch": [24,4,38,4,8,34],"loudness": [24,18,47,24,22,24],"g": [2,2,3,2,2,1],"r": [1,2,1,2,1,1],"b": [0,0,2,0,1,1],"a": [1,1,2,2,1,0],"s": [1,1,3,2,2,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA7004","sampleName": "LA7004","sampleLink": "samples/PVQD-70dB/LA7004_70dB.wav","age": 62,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [4,18,26,24,25,29],"roughness": [3,10,25,12,23,29],"breathiness": [3,1,21,12,1,18],"strain": [4,5,25,22,7,30],"pitch": [4,1,25,13,1,9],"loudness": [5,1,17,14,1,3],"g": [0,1,1,1,1,1],"r": [0,1,1,0,1,1],"b": [0,0,0,0,0,0],"a": [0,0,1,1,0,1],"s": [0,0,2,0,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA7005","sampleName": "LA7005","sampleLink": "samples/PVQD-70dB/LA7005_70dB.wav","age": 52,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [14,13,13,5,13,2],"roughness": [15,6,12,5,9,4],"breathiness": [14,1,16,9,0,2],"strain": [14,4,20,5,4,1],"pitch": [14,1,18,4,1,4],"loudness": [24,6,7,4,1,2],"g": [1,1,1,0,1,0],"r": [0,1,0,0,1,0],"b": [0,0,1,0,0,0],"a": [1,0,1,0,0,0],"s": [0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "LA7006","sampleName": "LA7006","sampleLink": "samples/PVQD-70dB/LA7006_70dB.wav","age": 78,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [25,40,57,43,45,48],"roughness": [25,33,45,43,41,51],"breathiness": [14,3,29,14,12,5],"strain": [19,17,64,32,18,51],"pitch": [14,1,45,14,15,35],"loudness": [29,18,44,45,25,30],"g": [1,2,2,1,2,2],"r": [1,2,1,1,2,2],"b": [0,0,1,0,1,0],"a": [2,1,2,2,1,1],"s": [1,1,2,1,1,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA7007","sampleName": "LA7007","sampleLink": "samples/PVQD-70dB/LA7007_70dB.wav","age": 54,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [8,18,16,15,26,51],"roughness": [4,13,11,15,21,46],"breathiness": [3,1,25,15,1,49],"strain": [3,0,5,15,8,39],"pitch": [3,0,23,14,21,60],"loudness": [8,0,6,18,27,40],"g": [0,1,0,1,1,2],"r": [0,1,1,1,1,2],"b": [0,0,0,1,0,2],"a": [0,0,0,1,1,2],"s": [0,0,0,0,0,2],},
     {"database": "Walden (2022)","participantId": "LA7008","sampleName": "LA7008","sampleLink": "samples/PVQD-70dB/LA7008_70dB.wav","age": 46,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [41,51,65,63,69,57],"roughness": [46,44,63,65,61,57],"breathiness": [6,33,51,10,26,41],"strain": [35,23,56,39,28,32],"pitch": [6,28,39,26,22,50],"loudness": [5,9,44,15,24,23],"g": [2,2,2,2,2,2],"r": [2,2,2,2,2,2],"b": [0,1,1,1,1,1],"a": [1,1,1,1,1,0],"s": [0,1,2,1,1,1],},
     {"database": "Walden (2022)","participantId": "LA7009","sampleName": "LA7009","sampleLink": "samples/PVQD-70dB/LA7009_70dB.wav","age": 56,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [28,51,70,25,47,40],"roughness": [15,17,43,26,26,27],"breathiness": [28,35,72,26,12,48],"strain": [27,45,34,1,47,14],"pitch": [17,7,25,1,2,38],"loudness": [29,23,69,1,1,49],"g": [1,2,2,1,2,2],"r": [1,1,1,0,1,1],"b": [1,1,2,1,1,2],"a": [1,1,2,1,1,1],"s": [1,2,2,1,2,1],},
     {"database": "Walden (2022)","participantId": "LA7011","sampleName": "LA7011","sampleLink": "samples/PVQD-70dB/LA7011_70dB.wav","age": 27,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [49,82,51,40,64,54],"roughness": [49,40,47,41,54,47],"breathiness": [13,27,26,12,25,60],"strain": [35,65,36,22,41,44],"pitch": [24,7,50,12,20,52],"loudness": [15,26,39,12,40,61],"g": [2,3,2,1,2,2],"r": [2,2,2,1,2,1],"b": [0,1,1,0,1,2],"a": [1,2,1,1,1,1],"s": [1,3,1,1,1,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA7012","sampleName": "LA7012","sampleLink": "samples/PVQD-70dB/LA7012_70dB.wav","age": 25,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [7,5,9,3,22,2],"roughness": [6,5,10,2,5,5],"breathiness": [5,1,17,2,15,2],"strain": [9,1,9,2,1,4],"pitch": [12,1,9,3,1,4],"loudness": [5,1,4,9,1,3],"g": [0,0,0,0,1,0],"r": [0,0,0,0,0,0],"b": [0,0,1,0,1,0],"a": [0,0,0,0,0,0],"s": [0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "LA8001","sampleName": "LA8001","sampleLink": "samples/PVQD-70dB/LA8001_70dB.wav","age": 47,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [38,61,33,5,54,39],"roughness": [29,11,35,13,25,36],"breathiness": [9,27,30,5,24,44],"strain": [20,57,30,12,53,36],"pitch": [21,21,4,4,15,46],"loudness": [21,20,25,4,21,26],"g": [1,2,1,1,2,2],"r": [1,0,2,1,1,2],"b": [1,1,1,0,0,2],"a": [1,1,1,0,1,1],"s": [1,2,1,1,2,2],},
     {"database": "Walden (2022)","participantId": "LA8002","sampleName": "LA8002","sampleLink": "samples/PVQD-70dB/LA8002_70dB.wav","age": 40,"gender": "male","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [12,17,20,13,21,32],"roughness": [4,13,24,13,15,45],"breathiness": [4,0,3,5,2,5],"strain": [12,4,29,5,3,42],"pitch": [12,0,6,14,0,24],"loudness": [11,0,6,14,0,2],"g": [0,1,1,1,1,1],"r": [0,1,1,1,1,1],"b": [0,0,0,0,0,0],"a": [0,0,0,1,0,0],"s": [0,0,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9002","sampleName": "LA9002","sampleLink": "samples/PVQD-70dB/LA9002_70dB.wav","age": 16,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [30,21,15,31,11,8],"roughness": [4,5,3,14,0,3],"breathiness": [34,30,19,39,0,8],"strain": [5,3,0,0,6,0],"pitch": [1,15,0,3,0,3],"loudness": [33,14,14,3,15,10],"g": [1,1,1,1,1,1],"r": [0,0,0,0,0,0],"b": [1,1,1,1,0,1],"a": [1,1,1,0,1,1],"s": [0,0,0,0,1,0],},
    // EXCLUDED? SEMBLA EL MATEIX INFORMANT QUE LA2026 {"database": "Walden (2022)","participantId": "LA90026","sampleName": "LA90026","sampleLink": "samples/PVQD-70dB/LA90026_70dB.wav","age": 22,"gender": "male","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [5,1,4,12,0,10],"roughness": [4,1,5,12,0,6],"breathiness": [4,1,5,11,0,9],"strain": [2,1,10,11,0,15],"pitch": [3,1,2,11,0,2],"loudness": [1,1,2,10,0,1],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,1],"a": [0,0,0,0,0,1],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "LA9003","sampleName": "LA9003","sampleLink": "samples/PVQD-70dB/LA9003_70dB.wav","age": 27,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,3,0,0,0,4],"roughness": [0,1,0,0,3,0],"breathiness": [3,0,0,0,0,4],"strain": [0,4,0,0,0,0],"pitch": [0,0,0,0,0,0],"loudness": [0,0,0,0,0,0],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // EXCLUDED due to low signal; microphone was too far away. {"database": "Walden (2022)","participantId": "LA9004","sampleName": "LA9004","sampleLink": "samples/PVQD-70dB/LA9004_70dB.wav","age": 59,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [31,40,6,5,2,10],"roughness": [21,0,2,0,0,3],"breathiness": [35,0,3,5,2,0],"strain": [21,43,6,0,8,10],"pitch": [10,22,0,0,0,2],"loudness": [13,88,4,2,0,6],"g": [1,2,1,0,0,0],"r": [1,0,0,0,0,0],"b": [1,0,0,0,0,0],"a": [0,3,0,0,0,0],"s": [0,2,1,0,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9006","sampleName": "LA9006","sampleLink": "samples/PVQD-70dB/LA9006_70dB.wav","age": 18,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [39,2,13,23,6,12],"roughness": [21,6,3,26,3,6],"breathiness": [35,0,13,32,11,12],"strain": [23,10,2,40,8,2],"pitch": [3,2,0,22,0,0],"loudness": [3,0,7,3,0,5],"g": [1,0,1,1,0,1],"r": [1,0,0,1,0,1],"b": [1,0,1,1,1,1],"a": [0,0,0,0,0,0],"s": [1,0,0,1,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9007","sampleName": "LA9007","sampleLink": "samples/PVQD-70dB/LA9007_70dB.wav","age": 30,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [48,11,11,37,18,8],"roughness": [33,5,11,37,22,7],"breathiness": [49,16,21,33,6,10],"strain": [28,12,0,24,15,0],"pitch": [10,0,0,18,0,0],"loudness": [28,4,8,18,0,0],"g": [1,1,0,1,1,0],"r": [1,0,1,1,1,1],"b": [1,1,1,1,0,1],"a": [0,0,0,0,0,0],"s": [1,1,0,1,1,0],},
     {"database": "Walden (2022)","participantId": "LA9008","sampleName": "LA9008","sampleLink": "samples/PVQD-70dB/LA9008_70dB.wav","age": 56,"gender": "male","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [49,20,6,53,21,8],"roughness": [53,26,6,47,26,8],"breathiness": [38,0,4,34,5,6],"strain": [20,14,0,35,13,0],"pitch": [22,0,0,30,8,0],"loudness": [16,0,0,40,0,6],"g": [2,1,1,2,1,1],"r": [2,1,1,2,1,1],"b": [1,0,0,1,0,0],"a": [0,0,0,1,0,0],"s": [1,1,0,1,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9009","sampleName": "LA9009","sampleLink": "samples/PVQD-70dB/LA9009_70dB.wav","age": 20,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [47,5,8,33,14,10],"roughness": [45,2,8,40,2,9],"breathiness": [38,0,18,35,5,19],"strain": [37,7,0,37,17,0],"pitch": [31,0,0,37,0,0],"loudness": [31,0,9,32,0,0],"g": [1,1,0,1,1,0],"r": [1,0,1,1,0,1],"b": [1,0,1,1,0,1],"a": [1,0,0,0,0,0],"s": [1,1,0,1,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9010","sampleName": "LA9010","sampleLink": "samples/PVQD-70dB/LA9010_70dB.wav","age": 56,"gender": "male","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [62,17,13,43,10,24],"roughness": [62,0,6,42,3,16],"breathiness": [41,5,16,43,14,21],"strain": [67,20,0,19,0,0],"pitch": [19,0,0,16,5,0],"loudness": [2,0,8,39,0,0],"g": [2,1,1,2,1,1],"r": [2,0,1,2,0,1],"b": [0,0,1,2,1,1],"a": [0,0,0,1,0,0],"s": [1,1,0,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9011","sampleName": "LA9011","sampleLink": "samples/PVQD-70dB/LA9011_70dB.wav","age": 22,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [3,12,2,3,18,1],"roughness": [3,15,3,0,24,2],"breathiness": [3,0,3,3,0,0],"strain": [3,5,0,0,18,0],"pitch": [3,0,0,0,0,0],"loudness": [3,0,0,0,0,0],"g": [0,1,0,0,1,0],"r": [0,1,0,0,1,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9012","sampleName": "LA9012","sampleLink": "samples/PVQD-70dB/LA9012_70dB.wav","age": 27,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [24,8,3,3,2,9],"roughness": [8,4,3,9,2,2],"breathiness": [27,5,5,11,0,10],"strain": [11,11,0,3,5,0],"pitch": [0,0,0,3,0,0],"loudness": [6,0,0,3,0,13],"g": [1,1,0,0,0,1],"r": [1,1,0,0,0,0],"b": [1,0,0,0,0,1],"a": [0,0,0,0,0,1],"s": [1,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9013","sampleName": "LA9013","sampleLink": "samples/PVQD-70dB/LA9013_70dB.wav","age": 29,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [18,12,5,29,5,2],"roughness": [22,2,4,29,9,2],"breathiness": [7,0,3,1,0,0],"strain": [22,10,3,31,7,0],"pitch": [2,0,0,1,0,0],"loudness": [6,0,0,1,0,0],"g": [1,1,0,1,0,0],"r": [1,0,0,1,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,1,0,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9014","sampleName": "LA9014","sampleLink": "samples/PVQD-70dB/LA9014_70dB.wav","age": 32,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [15,1,3,6,0,3],"roughness": [3,0,0,1,0,0],"breathiness": [14,0,2,7,0,10],"strain": [0,1,0,2,2,0],"pitch": [0,0,2,2,0,0],"loudness": [5,0,0,2,0,0],"g": [0,0,0,1,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,1,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9015","sampleName": "LA9015","sampleLink": "samples/PVQD-70dB/LA9015_70dB.wav","age": 24,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [5,3,6,0,2,0],"roughness": [3,2,4,0,2,0],"breathiness": [8,0,6,0,0,0],"strain": [3,5,0,0,5,0],"pitch": [3,0,0,0,0,0],"loudness": [3,0,0,0,0,0],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // EXCLUDED CANTA {"database": "Walden (2022)","participantId": "LA9016","sampleName": "LA9016","sampleLink": "samples/PVQD-70dB/LA9016_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [18,0,3,8,3,5],"roughness": [20,8,3,19,4,5],"breathiness": [18,0,2,3,0,7],"strain": [13,15,2,18,8,0],"pitch": [3,6,0,0,0,0],"loudness": [15,0,0,0,0,5],"g": [1,1,0,1,0,0],"r": [1,1,1,1,0,1],"b": [0,0,0,0,0,1],"a": [0,0,0,0,0,0],"s": [0,1,0,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9017","sampleName": "LA9017","sampleLink": "samples/PVQD-70dB/LA9017_70dB.wav","age": 18,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [6,13,10,26,3,7],"roughness": [6,7,7,33,1,5],"breathiness": [1,0,5,6,0,5],"strain": [1,12,0,15,2,6],"pitch": [1,0,0,9,0,6],"loudness": [1,0,0,12,0,0],"g": [1,1,0,1,0,0],"r": [1,0,1,1,0,1],"b": [0,0,0,0,0,1],"a": [0,0,0,0,0,0],"s": [0,1,0,1,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9018","sampleName": "LA9018","sampleLink": "samples/PVQD-70dB/LA9018_70dB.wav","age": 24,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [3,0,0,1,1,3],"roughness": [3,0,0,1,1,0],"breathiness": [3,0,0,1,0,5],"strain": [3,1,0,1,5,0],"pitch": [3,0,0,1,0,0],"loudness": [3,0,0,1,0,0],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,1],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9019","sampleName": "LA9019","sampleLink": "samples/PVQD-70dB/LA9019_70dB.wav","age": 42,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,3,7,14,0,5],"roughness": [0,0,5,17,1,3],"breathiness": [0,0,9,3,0,1],"strain": [0,6,8,0,0,6],"pitch": [0,0,0,0,0,0],"loudness": [0,0,0,0,0,0],"g": [0,0,0,0,0,0],"r": [0,0,1,0,0,1],"b": [0,0,1,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9020","sampleName": "LA9020","sampleLink": "samples/PVQD-70dB/LA9020_70dB.wav","age": 18,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [4,3,8,16,16,11],"roughness": [4,5,6,16,10,9],"breathiness": [1,0,11,22,3,9],"strain": [1,5,0,24,20,2],"pitch": [1,0,14,3,0,11],"loudness": [1,0,4,3,0,0],"g": [1,0,0,1,1,1],"r": [1,0,1,1,1,1],"b": [0,0,1,1,0,1],"a": [0,0,0,0,0,0],"s": [0,0,0,1,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9021","sampleName": "LA9021","sampleLink": "samples/PVQD-70dB/LA9021_70dB.wav","age": 24,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [3,1,10,11,7,23],"roughness": [2,0,12,11,4,35],"breathiness": [2,0,2,11,1,4],"strain": [3,0,12,9,1,13],"pitch": [2,0,10,9,1,12],"loudness": [2,0,2,9,1,11],"g": [0,0,0,1,0,1],"r": [0,0,0,0,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,1,1,0,1],},
     {"database": "Walden (2022)","participantId": "LA9022","sampleName": "LA9022","sampleLink": "samples/PVQD-70dB/LA9022_70dB.wav","age": 25,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [1,0,8,0,3,3],"roughness": [0,1,10,0,3,2],"breathiness": [0,0,0,0,0,4],"strain": [1,0,1,2,3,8],"pitch": [0,1,8,0,0,8],"loudness": [0,1,3,0,0,7],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9023","sampleName": "LA9023","sampleLink": "samples/PVQD-70dB/LA9023_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [5,8,4,7,20,3],"roughness": [5,1,2,7,4,5],"breathiness": [5,1,13,7,1,8],"strain": [5,7,21,7,13,2],"pitch": [5,1,2,7,1,2],"loudness": [6,1,2,7,1,3],"g": [0,0,0,0,1,0],"r": [0,0,0,0,0,0],"b": [0,0,1,0,0,1],"a": [0,0,0,0,0,0],"s": [0,0,1,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9024","sampleName": "LA9024","sampleLink": "samples/PVQD-70dB/LA9024_70dB.wav","age": 22,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [10,9,3,15,0,6],"roughness": [9,5,2,16,0,3],"breathiness": [5,0,2,15,0,14],"strain": [5,0,3,15,0,2],"pitch": [5,0,3,23,0,2],"loudness": [5,0,2,13,0,3],"g": [1,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [1,0,0,1,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9025","sampleName": "LA9025","sampleLink": "samples/PVQD-70dB/LA9025_70dB.wav","age": 25,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [5,7,2,5,4,9],"roughness": [5,5,3,5,5,2],"breathiness": [5,1,3,5,1,8],"strain": [5,1,3,5,2,12],"pitch": [5,8,3,4,1,12],"loudness": [5,1,2,4,1,4],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "LA9026","sampleName": "LA9026","sampleLink": "samples/PVQD-70dB/LA9026_70dB.wav","age": 22,"gender": "male","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [5,1,4,8,1,6],"roughness": [4,1,3,8,1,3],"breathiness": [4,1,4,8,1,7],"strain": [4,1,4,9,1,4],"pitch": [4,1,3,7,1,3],"loudness": [4,1,4,7,1,2],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "LA9027","sampleName": "LA9027","sampleLink": "samples/PVQD-70dB/LA9027_70dB.wav","age": 36,"gender": "female","diagnose": "voice_complaint_no_confirmed_diagnosis","language": "english","overallSeverity": [5,24,16,7,28,21],"roughness": [4,11,2,7,10,24],"breathiness": [4,14,25,3,5,18],"strain": [4,1,30,3,7,22],"pitch": [4,1,2,3,1,18],"loudness": [16,17,2,3,12,4],"g": [1,1,1,1,1,1],"r": [0,1,0,1,1,1],"b": [0,1,1,0,0,1],"a": [1,0,1,0,0,0],"s": [1,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "NYU1001","sampleName": "NYU1001","sampleLink": "samples/PVQD-70dB/NYU1001_70dB.wav","age": 23,"gender": "male","diagnose": "vocal_fold_cyst","language": "english","overallSeverity": [1,0,5,0,1,5],"roughness": [1,2,3,0,2,5],"breathiness": [1,2,0,0,3,2],"strain": [1,4,9,1,4,2],"pitch": [1,2,15,0,4,3],"loudness": [1,5,11,0,5,5],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "NYU1002","sampleName": "NYU1002","sampleLink": "samples/PVQD-70dB/NYU1002_70dB.wav","age": 86,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [90,60,60,76,82,65],"roughness": [53,64,32,44,83,48],"breathiness": [75,66,64,65,86,62],"strain": [31,74,16,22,91,38],"pitch": [67,73,38,74,87,40],"loudness": [98,72,40,62,92,46],"g": [3,2,3,3,3,3],"r": [2,2,1,2,3,1],"b": [3,2,2,3,3,2],"a": [2,2,2,2,3,3],"s": [2,2,1,1,3,1],},
     {"database": "Walden (2022)","participantId": "NYU1003","sampleName": "NYU1003","sampleLink": "samples/PVQD-70dB/NYU1003_70dB.wav","age": 24,"gender": "female","diagnose": "vocal_fold_scar","language": "english","overallSeverity": [12,43,25,14,8,14],"roughness": [21,36,29,11,0,17],"breathiness": [0,37,2,1,0,7],"strain": [18,45,26,29,0,12],"pitch": [9,43,27,1,7,15],"loudness": [16,41,16,14,10,13],"g": [1,1,1,1,0,1],"r": [1,2,1,1,0,1],"b": [0,1,0,0,0,0],"a": [0,1,0,0,0,0],"s": [1,1,1,1,0,1],},
    // EXCLUDED? SOROLLETS {"database": "Walden (2022)","participantId": "NYU1004","sampleName": "NYU1004","sampleLink": "samples/PVQD-70dB/NYU1004_70dB.wav","age": 55,"gender": "male","diagnose": "bilareral_phonotraumatic_lesion","language": "english","overallSeverity": [80,90,46,31,72,52],"roughness": [49,95,32,6,69,44],"breathiness": [50,48,25,6,56,40],"strain": [8,89,8,3,45,32],"pitch": [70,91,7,0,41,30],"loudness": [7,93,22,19,37,36],"g": [3,3,2,1,2,2],"r": [2,3,1,1,2,1],"b": [2,3,1,1,2,1],"a": [0,3,1,1,1,1],"s": [1,3,0,0,2,0],},
     {"database": "Walden (2022)","participantId": "NYU1005","sampleName": "NYU1005","sampleLink": "samples/PVQD-70dB/NYU1005_70dB.wav","age": 45,"gender": "female","diagnose": "phonotraumatic_lesion","language": "english","overallSeverity": [81,73,40,81,91,38],"roughness": [82,71,36,75,91,32],"breathiness": [52,76,15,32,54,17],"strain": [28,72,30,69,90,30],"pitch": [40,76,31,43,87,27],"loudness": [72,73,22,41,89,18],"g": [3,2,2,3,2,2],"r": [3,2,1,3,2,1],"b": [2,2,1,2,3,1],"a": [0,2,0,0,3,0],"s": [1,2,1,2,2,2],},
     {"database": "Walden (2022)","participantId": "NYU1006","sampleName": "NYU1006","sampleLink": "samples/PVQD-70dB/NYU1006_70dB.wav","age": 60,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [20,1,25,10,45,11],"roughness": [0,2,16,0,40,6],"breathiness": [13,1,32,0,51,15],"strain": [8,1,17,10,52,5],"pitch": [0,2,12,0,57,6],"loudness": [40,3,0,0,73,17],"g": [1,0,1,1,2,1],"r": [0,0,0,0,2,0],"b": [1,0,1,0,2,1],"a": [0,0,0,0,2,1],"s": [1,0,0,0,2,0],},
    // EXCLUDED? SORROLL {"database": "Walden (2022)","participantId": "NYU1007","sampleName": "NYU1007","sampleLink": "samples/PVQD-70dB/NYU1007_70dB.wav","age": 25,"gender": "female","diagnose": "vocal_fold_scar","language": "english","overallSeverity": [0,0,14,0,52,6],"roughness": [0,0,6,0,51,2],"breathiness": [6,1,15,2,53,8],"strain": [0,0,10,0,55,11],"pitch": [0,3,12,0,56,17],"loudness": [0,5,8,0,59,12],"g": [0,0,0,0,2,0],"r": [0,0,0,0,2,0],"b": [0,0,1,0,2,0],"a": [0,0,0,0,2,0],"s": [0,0,0,0,2,0],},
    // EXCLUDED? Soroll {"database": "Walden (2022)","participantId": "NYU1008","sampleName": "NYU1008","sampleLink": "samples/PVQD-70dB/NYU1008_70dB.wav","age": 59,"gender": "female","diagnose": "vocal_fold_edema","language": "english","overallSeverity": [5,0,13,1,0,8],"roughness": [4,0,14,1,0,3],"breathiness": [0,0,1,1,0,2],"strain": [6,0,5,1,1,2],"pitch": [14,2,2,6,1,3],"loudness": [0,2,2,1,1,2],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // EXCLUDED? SE SENT LA LOGOPEDA {"database": "Walden (2022)","participantId": "NYU1009","sampleName": "NYU1009","sampleLink": "samples/PVQD-70dB/NYU1009_70dB.wav","age": 32,"gender": "female","diagnose": "vocal_fold_polyp","language": "english","overallSeverity": [20,0,21,12,21,17],"roughness": [8,2,22,9,15,18],"breathiness": [12,2,30,9,3,8],"strain": [4,2,7,0,3,5],"pitch": [0,0,7,5,9,24],"loudness": [1,2,14,0,10,15],"g": [1,0,1,0,0,1],"r": [0,0,0,0,0,1],"b": [1,0,1,0,0,0],"a": [0,0,0,0,0,0],"s": [0,1,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "NYU1010","sampleName": "NYU1010","sampleLink": "samples/PVQD-70dB/NYU1010_70dB.wav","age": 56,"gender": "female","diagnose": "laryngeal_stenosis","language": "english","overallSeverity": [12,4,24,5,4,17],"roughness": [1,9,3,0,2,16],"breathiness": [9,4,29,4,3,24],"strain": [0,25,10,0,5,6],"pitch": [0,31,14,0,3,24],"loudness": [7,23,14,9,3,16],"g": [1,1,0,0,0,1],"r": [0,1,0,0,0,0],"b": [0,1,1,0,0,1],"a": [0,1,0,0,0,1],"s": [0,1,0,0,0,0],},
    // EXCLUDED? SORROLL {"database": "Walden (2022)","participantId": "NYU1011","sampleName": "NYU1011","sampleLink": "samples/PVQD-70dB/NYU1011_70dB.wav","age": 25,"gender": "female","diagnose": "vocal_fold_nodules","language": "english","overallSeverity": [42,14,18,23,3,18],"roughness": [8,6,14,24,1,19],"breathiness": [15,6,13,14,1,24],"strain": [17,8,5,18,1,14],"pitch": [10,5,12,6,3,20],"loudness": [1,4,7,0,2,15],"g": [2,0,1,1,0,2],"r": [1,0,1,1,0,0],"b": [1,0,1,1,0,1],"a": [0,0,0,0,0,0],"s": [1,0,0,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "NYU1012","sampleName": "NYU1012","sampleLink": "samples/PVQD-70dB/NYU1012_70dB.wav","age": 25,"gender": "female","diagnose": "phonotraumatic_lesion","language": "english","overallSeverity": [12,8,10,11,6,14],"roughness": [0,0,7,12,15,8],"breathiness": [0,7,13,0,32,20],"strain": [11,0,17,2,51,28],"pitch": [0,6,8,7,57,20],"loudness": [17,6,7,0,45,10],"g": [1,0,1,1,1,1],"r": [0,0,0,1,1,0],"b": [0,0,0,0,1,0],"a": [1,0,1,0,1,1],"s": [1,0,0,0,1,1],},
    // EXCLUDED. COSES RARES AMB LA INTENSITAT {"database": "Walden (2022)","participantId": "NYU1013","sampleName": "NYU1013","sampleLink": "samples/PVQD-70dB/NYU1013_70dB.wav","age": 22,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [12,23,9,9,0,3],"roughness": [13,10,4,5,1,3],"breathiness": [0,8,3,0,0,2],"strain": [13,5,7,6,0,13],"pitch": [0,5,3,0,1,10],"loudness": [6,7,3,24,1,4],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [1,0,0,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "NYU1014","sampleName": "NYU1014","sampleLink": "samples/PVQD-70dB/NYU1014_70dB.wav","age": 51,"gender": "female","diagnose": "vocal_fold_scar","language": "english","overallSeverity": [7,1,14,30,9,20],"roughness": [0,1,10,0,11,8],"breathiness": [0,5,16,8,21,21],"strain": [4,1,0,27,17,3],"pitch": [1,1,5,0,9,3],"loudness": [25,9,16,41,12,5],"g": [0,0,0,1,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,1,2,0,0],"s": [0,0,0,1,0,0],},
    // EXCLUDED: mono sound {"database": "Walden (2022)","participantId": "NYU1015","sampleName": "NYU1015","sampleLink": "samples/PVQD-70dB/NYU1015_70dB.wav","age": 52,"gender": "female","diagnose": "atrophy","language": "english","overallSeverity": [74,25,36,82,26,30],"roughness": [22,27,20,34,27,20],"breathiness": [61,9,35,49,16,25],"strain": [42,26,32,57,10,28],"pitch": [0,26,29,14,30,26],"loudness": [70,28,32,67,44,32],"g": [3,1,2,3,1,2],"r": [1,1,0,2,1,0],"b": [1,1,1,2,1,2],"a": [0,1,2,0,1,1],"s": [2,1,1,2,1,1],},
     {"database": "Walden (2022)","participantId": "NYU1016","sampleName": "NYU1016","sampleLink": "samples/PVQD-70dB/NYU1016_70dB.wav","age": 61,"gender": "female","diagnose": "vocal_fold_polyp","language": "english","overallSeverity": [30,63,33,52,53,62],"roughness": [13,72,36,22,58,45],"breathiness": [0,64,14,0,58,27],"strain": [19,77,30,30,59,48],"pitch": [23,73,29,30,59,38],"loudness": [0,76,33,0,60,38],"g": [1,2,2,1,2,2],"r": [1,2,2,1,2,2],"b": [0,2,0,0,2,1],"a": [0,2,0,0,2,0],"s": [1,2,1,1,2,1],},
     {"database": "Walden (2022)","participantId": "NYU1017","sampleName": "NYU1017","sampleLink": "samples/PVQD-70dB/NYU1017_70dB.wav","age": 68,"gender": "male","diagnose": "tremor","language": "english","overallSeverity": [0,37,16,10,41,15],"roughness": [0,29,2,0,23,4],"breathiness": [0,11,14,0,0,10],"strain": [0,22,1,0,9,4],"pitch": [0,19,26,8,24,40],"loudness": [0,33,10,0,34,14],"g": [0,1,1,0,1,1],"r": [0,1,0,0,1,0],"b": [0,0,1,0,0,1],"a": [0,0,0,0,1,0],"s": [0,0,0,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "NYU1018","sampleName": "NYU1018","sampleLink": "samples/PVQD-70dB/NYU1018_70dB.wav","age": 80,"gender": "female","diagnose": "atrophy","language": "english","overallSeverity": [83,90,54,45,94,57],"roughness": [0,50,11,4,98,35],"breathiness": [0,44,34,22,66,48],"strain": [12,94,16,23,99,30],"pitch": [77,84,66,85,73,78],"loudness": [12,100,25,20,92,45],"g": [3,3,2,2,2,2],"r": [0,3,0,0,2,0],"b": [0,2,1,1,3,2],"a": [0,3,2,0,3,2],"s": [0,3,1,1,3,2],},
     {"database": "Walden (2022)","participantId": "NYU1019","sampleName": "NYU1019","sampleLink": "samples/PVQD-70dB/NYU1019_70dB.wav","age": 54,"gender": "female","diagnose": "phonotrauma","language": "english","overallSeverity": [1,1,9,0,5,30],"roughness": [0,1,11,0,7,25],"breathiness": [0,1,5,0,10,5],"strain": [3,3,15,0,25,26],"pitch": [2,4,12,0,31,24],"loudness": [0,6,15,0,30,31],"g": [0,0,1,0,1,1],"r": [0,0,0,0,2,0],"b": [0,0,0,0,1,0],"a": [0,0,0,0,1,0],"s": [0,0,1,0,1,1],},
     {"database": "Walden (2022)","participantId": "NYU1020","sampleName": "NYU1020","sampleLink": "samples/PVQD-70dB/NYU1020_70dB.wav","age": 19,"gender": "male","diagnose": "vocal_fold_edema","language": "english","overallSeverity": [2,3,5,6,18,7],"roughness": [6,1,11,5,20,6],"breathiness": [0,1,2,0,6,2],"strain": [5,1,5,1,0,8],"pitch": [5,1,3,1,83,3],"loudness": [4,1,4,0,0,5],"g": [0,0,0,0,1,0],"r": [0,0,1,0,1,1],"b": [0,0,0,0,1,0],"a": [0,0,0,0,1,0],"s": [0,0,0,0,1,0],},
    // EXCLUDED? SOROLL {"database": "Walden (2022)","participantId": "NYU1022","sampleName": "NYU1022","sampleLink": "samples/PVQD-70dB/NYU1022_70dB.wav","age": 57,"gender": "male","diagnose": "unilateral_vocal_fold_paresis","language": "english","overallSeverity": [81,81,97,80,79,98],"roughness": [26,12,74,62,11,79],"breathiness": [80,79,87,63,78,95],"strain": [80,22,67,53,46,75],"pitch": [80,33,85,26,15,89],"loudness": [80,74,85,41,76,82],"g": [3,3,3,3,3,3],"r": [1,0,2,3,0,3],"b": [3,3,3,2,3,3],"a": [3,3,3,2,2,3],"s": [3,1,2,2,2,2],},
     {"database": "Walden (2022)","participantId": "NYU1023","sampleName": "NYU1023","sampleLink": "samples/PVQD-70dB/NYU1023_70dB.wav","age": 28,"gender": "male","diagnose": "vocal_fold_polyp","language": "english","overallSeverity": [27,10,45,6,49,35],"roughness": [27,10,56,6,35,41],"breathiness": [9,1,46,6,21,16],"strain": [10,0,16,6,23,31],"pitch": [20,0,9,7,3,29],"loudness": [10,0,3,6,5,6],"g": [1,1,1,0,2,1],"r": [1,1,2,0,2,2],"b": [0,0,1,0,1,0],"a": [0,0,0,0,1,1],"s": [0,0,1,0,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "NYU1024","sampleName": "NYU1024","sampleLink": "samples/PVQD-70dB/NYU1024_70dB.wav","age": 42,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [2,0,6,0,2,8],"roughness": [1,0,1,0,1,3],"breathiness": [1,0,8,0,1,5],"strain": [3,0,3,0,1,3],"pitch": [1,0,1,0,1,3],"loudness": [1,0,0,0,1,4],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "NYU1025","sampleName": "NYU1025","sampleLink": "samples/PVQD-70dB/NYU1025_70dB.wav","age": 56,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [81,94,79,71,53,68],"roughness": [82,91,67,65,55,56],"breathiness": [0,79,75,40,63,80],"strain": [81,87,37,25,74,28],"pitch": [83,99,41,15,88,42],"loudness": [30,100,54,70,96,55],"g": [3,3,3,3,3,3],"r": [3,3,2,2,2,1],"b": [0,2,3,2,2,3],"a": [1,3,3,1,2,3],"s": [3,3,1,1,2,1],},
     {"database": "Walden (2022)","participantId": "NYU1026","sampleName": "NYU1026","sampleLink": "samples/PVQD-70dB/NYU1026_70dB.wav","age": 55,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [55,8,28,60,11,23],"roughness": [53,11,18,61,16,18],"breathiness": [57,1,27,62,0,30],"strain": [54,7,5,31,6,9],"pitch": [33,5,0,12,0,14],"loudness": [24,9,17,34,0,16],"g": [2,1,1,2,1,1],"r": [2,1,1,2,1,1],"b": [2,0,1,2,0,1],"a": [2,0,1,1,0,1],"s": [2,1,1,1,0,0],},
     {"database": "Walden (2022)","participantId": "NYU1027","sampleName": "NYU1027","sampleLink": "samples/PVQD-70dB/NYU1027_70dB.wav","age": 37,"gender": "male","diagnose": "unilateral_vocal_fold_paresis","language": "english","overallSeverity": [14,1,1,29,0,1],"roughness": [15,1,2,24,0,10],"breathiness": [16,1,1,0,0,1],"strain": [1,1,6,25,0,1],"pitch": [7,1,1,32,0,10],"loudness": [0,1,1,32,0,1],"g": [1,0,0,1,0,0],"r": [1,0,0,1,0,1],"b": [1,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,1,0,0],},
     {"database": "Walden (2022)","participantId": "NYU1028","sampleName": "NYU1028","sampleLink": "samples/PVQD-70dB/NYU1028_70dB.wav","age": 38,"gender": "male","diagnose": "vocal_fold_scar","language": "english","overallSeverity": [0,0,1,6,0,1],"roughness": [0,1,1,6,17,11],"breathiness": [0,1,1,0,0,1],"strain": [0,14,1,0,13,1],"pitch": [0,0,1,0,0,1],"loudness": [0,0,1,0,0,1],"g": [0,0,0,0,0,0],"r": [0,0,0,0,1,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,1,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "NYU1029","sampleName": "NYU1029","sampleLink": "samples/PVQD-70dB/NYU1029_70dB.wav","age": 55,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [66,80,16,60,52,63,50,80],"roughness": [42,25,4,25,50,18,45,80],"breathiness": [66,82,22,61,36,63,47,80],"strain": [37,8,13,25,35,64,49,40],"pitch": [61,9,0,1,40,0,19,0],"loudness": [49,56,13,45,10,31,20,40],"g": [2,3,1,2,2,2,2,3],"r": [1,1,0,1,2,0,1,3],"b": [2,2,1,2,1,2,2,3],"a": [1,3,0,2,1,2,1,2],"s": [1,0,0,1,1,2,2,2],},
     {"database": "Walden (2022)","participantId": "NYU1030","sampleName": "NYU1030","sampleLink": "samples/PVQD-70dB/NYU1030_70dB.wav","age": 57,"gender": "female","diagnose": "vocal_fold_polyp","language": "english","overallSeverity": [82,26,40,70,27,60],"roughness": [83,27,53,70,29,62],"breathiness": [81,14,9,44,27,27],"strain": [85,0,47,72,12,51],"pitch": [74,10,18,45,28,84],"loudness": [72,8,19,18,16,32],"g": [2,1,2,2,1,1],"r": [2,1,2,2,1,2],"b": [2,1,0,1,1,2],"a": [0,1,1,0,1,1],"s": [2,0,2,2,1,2],},
     {"database": "Walden (2022)","participantId": "PT001","sampleName": "PT001","sampleLink": "samples/PVQD-70dB/PT001_70dB.wav","age": 61,"gender": "male","diagnose": "reinke_edema","language": "english","overallSeverity": [97,83,75,100,95,85],"roughness": [83,86,60,84,98,83],"breathiness": [0,60,18,1,69,35],"strain": [100,90,66,100,100,76],"pitch": [22,85,25,7,100,66],"loudness": [97,85,34,100,97,62],"g": [3,3,3,3,3,3],"r": [3,3,2,3,3,3],"b": [0,1,1,0,2,2],"a": [0,3,0,0,3,0],"s": [3,3,3,3,3,3],},
     {"database": "Walden (2022)","participantId": "PT002","sampleName": "PT002","sampleLink": "samples/PVQD-70dB/PT002_70dB.wav","age": 72,"gender": "male","diagnose": "atrophy_hypomobility","language": "english","overallSeverity": [13,62,33,30,65,36],"roughness": [13,63,39,28,73,33],"breathiness": [0,68,0,1,44,16],"strain": [21,65,20,9,72,15],"pitch": [0,64,21,1,73,25],"loudness": [0,60,29,3,71,11],"g": [1,2,1,1,2,2],"r": [1,2,2,1,2,1],"b": [0,2,0,0,2,0],"a": [0,2,0,0,2,0],"s": [1,2,1,1,2,1],},
     {"database": "Walden (2022)","participantId": "PT003","sampleName": "PT003","sampleLink": "samples/PVQD-70dB/PT003_70dB.wav","age": 24,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [8,0,26,12,0,17],"roughness": [5,0,2,12,0,15],"breathiness": [26,9,35,2,2,20],"strain": [17,5,33,13,5,30],"pitch": [7,0,42,5,11,15],"loudness": [18,0,25,6,11,20],"g": [1,0,1,0,0,1],"r": [0,0,0,0,0,0],"b": [1,0,0,0,0,0],"a": [0,0,1,0,0,1],"s": [0,0,1,0,0,1],},
     {"database": "Walden (2022)","participantId": "PT004","sampleName": "PT004","sampleLink": "samples/PVQD-70dB/PT004_70dB.wav","age": 74,"gender": "male","diagnose": "ulcerative_laryngitis","language": "english","overallSeverity": [100,100,95,100,82,96],"roughness": [43,100,92,10,63,70],"breathiness": [30,100,94,40,99,95],"strain": [61,100,68,94,85,94],"pitch": [100,99,97,100,78,94],"loudness": [78,100,97,91,90,93],"g": [3,3,3,3,3,3],"r": [1,3,3,0,3,2],"b": [1,3,3,1,2,3],"a": [0,3,3,0,2,3],"s": [2,3,3,3,3,3],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT005","sampleName": "PT005","sampleLink": "samples/PVQD-70dB/PT005_70dB.wav","age": 58,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [7,2,10,2,6,9],"roughness": [2,5,4,0,5,7],"breathiness": [0,3,14,0,6,10],"strain": [0,11,0,0,10,6],"pitch": [15,2,31,3,4,17],"loudness": [0,4,14,0,12,12],"g": [0,0,1,0,0,1],"r": [0,1,0,0,0,0],"b": [0,1,1,0,0,1],"a": [0,1,0,0,0,0],"s": [0,1,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT006","sampleName": "PT006","sampleLink": "samples/PVQD-70dB/PT006_70dB.wav","age": 54,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [7,1,2,0,1,3],"roughness": [0,1,1,0,1,2],"breathiness": [0,1,7,1,1,8],"strain": [5,1,3,0,1,2],"pitch": [0,3,0,0,1,6],"loudness": [31,4,17,0,1,7],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT007","sampleName": "PT007","sampleLink": "samples/PVQD-70dB/PT007_70dB.wav","age": 36,"gender": "female","diagnose": "laryngeal_granuloma","language": "english","overallSeverity": [5,1,9,6,4,22],"roughness": [3,1,17,5,4,23],"breathiness": [1,1,7,0,2,6],"strain": [0,4,4,0,1,15],"pitch": [1,4,12,0,1,28],"loudness": [4,9,11,0,1,10],"g": [0,0,1,0,0,1],"r": [0,0,1,0,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // EXCLUDED (En normalitzar a 70dB i guardar, El Praat diu "Writing samples to audio file: XX out of XXXXXX samles have been clipped") {"database": "Walden (2022)","participantId": "PT008","sampleName": "PT008","sampleLink": "samples/PVQD-70dB/PT008_70dB.wav","age": 71,"gender": "female","diagnose": "reinke_edema","language": "english","overallSeverity": [96,100,99,100,100,97],"roughness": [0,99,37,0,100,98],"breathiness": [100,100,100,100,100,97],"strain": [22,100,100,82,100,96],"pitch": [100,100,98,100,100,97],"loudness": [100,100,97,100,100,98],"g": [3,3,3,3,3,3],"r": [0,3,1,0,3,3],"b": [3,3,3,3,3,3],"a": [3,3,3,2,3,3],"s": [2,3,3,3,3,3],},
     {"database": "Walden (2022)","participantId": "PT009","sampleName": "PT009","sampleLink": "samples/PVQD-70dB/PT009_70dB.wav","age": 49,"gender": "male","diagnose": "laryngeal_stenosis_bilateral_vocal_fold_paralysis","language": "english","overallSeverity": [9,76,32,21,8,32],"roughness": [11,72,40,6,5,30],"breathiness": [0,48,2,18,14,27],"strain": [9,69,0,8,5,18],"pitch": [0,63,32,0,44,36],"loudness": [0,69,23,13,61,15],"g": [1,2,2,1,2,2],"r": [0,2,1,1,0,1],"b": [0,1,0,0,0,1],"a": [0,2,0,0,2,0],"s": [0,2,0,1,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT010","sampleName": "PT010","sampleLink": "samples/PVQD-70dB/PT010_70dB.wav","age": 72,"gender": "female","diagnose": "muscle_tension_dysphonia_atrophy_unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [3,0,10,3,0,11],"roughness": [3,0,11,5,1,12],"breathiness": [3,0,19,2,1,15],"strain": [2,0,10,3,1,0],"pitch": [0,0,11,0,1,7],"loudness": [1,8,12,1,1,8],"g": [0,0,0,0,0,0],"r": [0,3,0,0,0,0],"b": [0,0,1,0,0,0],"a": [0,0,1,0,0,1],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT011","sampleName": "PT011","sampleLink": "samples/PVQD-70dB/PT011_70dB.wav","age": 39,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [99,60,62,81,76,66],"roughness": [10,64,62,0,72,60],"breathiness": [72,78,48,71,80,52],"strain": [85,79,68,65,90,57],"pitch": [24,78,64,53,82,60],"loudness": [99,83,42,86,84,55],"g": [3,2,3,3,2,3],"r": [0,2,2,0,3,2],"b": [1,2,2,3,3,3],"a": [0,2,2,3,3,1],"s": [3,2,3,2,3,3],},
     {"database": "Walden (2022)","participantId": "PT012","sampleName": "PT012","sampleLink": "samples/PVQD-70dB/PT012_70dB.wav","age": 38,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [72,64,40,48,62,32],"roughness": [0,64,8,2,74,18],"breathiness": [0,70,29,1,69,32],"strain": [72,86,36,61,95,48],"pitch": [0,75,0,0,83,14],"loudness": [80,78,36,87,90,31],"g": [3,2,2,2,2,2],"r": [0,3,0,0,3,0],"b": [0,3,1,0,3,2],"a": [2,3,1,2,3,1],"s": [3,3,2,3,3,1],},
    // EXCLUDED (En normalitzar a 70dB i guardar, El Praat diu "Writing samples to audio file: XX out of XXXXXX samles have been clipped") {"database": "Walden (2022)","participantId": "PT013","sampleName": "PT013","sampleLink": "samples/PVQD-70dB/PT013_70dB.wav","age": 61,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [91,81,53,64,88,34],"roughness": [39,55,30,26,92,27],"breathiness": [6,69,39,17,83,33],"strain": [89,62,36,64,94,38],"pitch": [67,75,48,65,88,59],"loudness": [99,70,26,33,83,49],"g": [3,2,2,3,3,2],"r": [2,2,1,1,3,0],"b": [1,2,2,1,3,2],"a": [0,3,2,0,3,2],"s": [3,3,1,3,3,2],},
     {"database": "Walden (2022)","participantId": "PT014","sampleName": "PT014","sampleLink": "samples/PVQD-70dB/PT014_70dB.wav","age": 65,"gender": "female","diagnose": "reinke_edema","language": "english","overallSeverity": [79,99,47,79,100,65],"roughness": [99,100,49,99,100,62],"breathiness": [0,99,10,0,56,32],"strain": [55,99,53,61,100,45],"pitch": [100,100,37,91,85,63],"loudness": [45,100,14,11,98,43],"g": [3,3,3,3,3,3],"r": [3,3,3,3,3,3],"b": [0,3,2,0,2,0],"a": [0,3,0,0,3,0],"s": [2,3,3,3,3,2],},
    // EXCLUDED (En normalitzar a 70dB i guardar, El Praat diu "Writing samples to audio file: XX out of XXXXXX samles have been clipped") {"database": "Walden (2022)","participantId": "PT015","sampleName": "PT015","sampleLink": "samples/PVQD-70dB/PT015_70dB.wav","age": 75,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [100,95,93,100,99,98],"roughness": [17,94,81,0,100,87],"breathiness": [15,98,94,0,100,95],"strain": [100,100,86,100,100,95],"pitch": [100,99,72,99,100,95],"loudness": [100,100,87,100,100,91],"g": [3,3,3,3,3,3],"r": [0,3,3,0,3,3],"b": [0,3,3,0,3,3],"a": [0,3,3,0,3,3],"s": [3,3,3,3,3,3],},
     {"database": "Walden (2022)","participantId": "PT016","sampleName": "PT016","sampleLink": "samples/PVQD-70dB/PT016_70dB.wav","age": 64,"gender": "female","diagnose": "paradoxical_vocal_fold_movement","language": "english","overallSeverity": [28,36,35,4,1,32],"roughness": [4,41,33,1,1,29],"breathiness": [17,38,22,2,1,18],"strain": [46,44,29,4,1,29],"pitch": [1,49,37,1,1,30],"loudness": [32,54,22,6,1,16],"g": [2,2,2,0,0,1],"r": [0,2,2,0,0,1],"b": [0,2,0,0,0,0],"a": [1,2,0,0,0,0],"s": [1,2,1,0,0,1],},
     {"database": "Walden (2022)","participantId": "PT017","sampleName": "PT017","sampleLink": "samples/PVQD-70dB/PT017_70dB.wav","age": 64,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [53,59,36,61,81,77],"roughness": [17,47,13,66,77,33],"breathiness": [0,45,7,0,75,15],"strain": [73,65,37,75,89,71],"pitch": [0,60,39,53,79,60],"loudness": [32,61,19,77,92,29],"g": [2,2,3,3,3,3],"r": [1,2,2,2,3,2],"b": [0,2,0,0,2,0],"a": [0,2,0,0,2,0],"s": [3,2,3,3,3,3],},
     {"database": "Walden (2022)","participantId": "PT018","sampleName": "PT018","sampleLink": "samples/PVQD-70dB/PT018_70dB.wav","age": 30,"gender": "male","diagnose": "bilateral_vocal_fold_paresis","language": "english","overallSeverity": [36,48,31,61,0,33],"roughness": [14,38,24,40,0,32],"breathiness": [0,38,2,0,0,24],"strain": [32,42,23,61,0,40],"pitch": [0,42,36,20,0,38],"loudness": [0,38,17,11,0,32],"g": [2,2,2,2,0,2],"r": [1,2,1,2,0,2],"b": [0,2,0,0,0,0],"a": [0,2,0,0,0,0],"s": [2,2,2,3,0,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT019","sampleName": "PT019","sampleLink": "samples/PVQD-70dB/PT019_70dB.wav","age": 81,"gender": "male","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [97,99,97,100,100,98],"roughness": [0,100,16,0,96,54],"breathiness": [38,44,90,0,91,55],"strain": [91,100,94,100,100,95],"pitch": [92,100,96,100,97,98],"loudness": [92,100,88,100,99,67],"g": [3,3,3,3,3,3],"r": [0,3,1,0,3,1],"b": [2,2,3,0,3,2],"a": [0,3,3,0,3,3],"s": [3,3,3,3,3,3],},
     {"database": "Walden (2022)","participantId": "PT020","sampleName": "PT020","sampleLink": "samples/PVQD-70dB/PT020_70dB.wav","age": 80,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis_atrophy","language": "english","overallSeverity": [83,63,58,88,80,63],"roughness": [10,66,43,35,84,56],"breathiness": [53,72,48,83,73,66],"strain": [75,77,50,59,84,50],"pitch": [15,76,27,41,76,50],"loudness": [88,79,30,93,83,68],"g": [3,2,3,3,2,3],"r": [0,2,1,1,2,1],"b": [3,2,3,3,2,3],"a": [3,3,3,2,2,3],"s": [2,3,2,2,2,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT022","sampleName": "PT022","sampleLink": "samples/PVQD-70dB/PT022_70dB.wav","age": 67,"gender": "female","diagnose": "atrophy_unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [42,44,32,49,46,42],"roughness": [6,35,26,5,50,25],"breathiness": [7,45,13,13,66,31],"strain": [10,45,18,11,67,22],"pitch": [45,46,25,46,67,25],"loudness": [47,51,15,25,71,32],"g": [2,2,2,2,2,2],"r": [0,2,1,0,2,1],"b": [0,2,1,1,2,2],"a": [1,2,1,1,2,1],"s": [1,2,1,1,2,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT023","sampleName": "PT023","sampleLink": "samples/PVQD-70dB/PT023_70dB.wav","age": 70,"gender": "male","diagnose": "parkinson_disease_atrophy","language": "english","overallSeverity": [10,46,27,4,1,8],"roughness": [0,45,15,0,1,13],"breathiness": [9,32,25,2,2,14],"strain": [1,45,9,1,1,3],"pitch": [0,50,7,0,1,8],"loudness": [5,55,2,7,1,9],"g": [0,2,0,0,0,0],"r": [0,2,0,0,0,0],"b": [0,2,1,0,0,1],"a": [0,2,0,0,0,0],"s": [0,2,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT024","sampleName": "PT024","sampleLink": "samples/PVQD-70dB/PT024_70dB.wav","age": 65,"gender": "male","diagnose": "parkinson_disease","language": "english","overallSeverity": [7,9,3,7,0,16],"roughness": [7,12,5,17,0,17],"breathiness": [0,10,4,0,0,6],"strain": [4,17,7,5,5,14],"pitch": [5,14,15,5,0,20],"loudness": [14,14,9,8,6,19],"g": [0,0,0,1,0,0],"r": [0,0,1,0,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT025","sampleName": "PT025","sampleLink": "samples/PVQD-70dB/PT025_70dB.wav","age": 66,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [56,37,9,38,66,16],"roughness": [36,33,13,14,73,17],"breathiness": [5,33,9,11,50,19],"strain": [8,34,16,26,79,3],"pitch": [0,28,17,0,74,7],"loudness": [59,36,3,50,81,14],"g": [3,1,0,2,2,1],"r": [2,1,0,1,2,0],"b": [0,1,1,1,1,1],"a": [1,1,0,1,2,0],"s": [1,1,1,1,2,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT026","sampleName": "PT026","sampleLink": "samples/PVQD-70dB/PT026_70dB.wav","age": 41,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [24,23,8,42,38,18],"roughness": [0,25,5,0,11,16],"breathiness": [5,10,4,10,21,4],"strain": [33,2,3,23,16,3],"pitch": [0,6,3,0,19,12],"loudness": [67,3,3,55,23,16],"g": [1,0,0,2,1,0],"r": [0,0,0,0,0,0],"b": [0,0,0,1,0,0],"a": [1,0,0,1,0,0],"s": [2,0,0,1,0,0],},
     {"database": "Walden (2022)","participantId": "PT027","sampleName": "PT027","sampleLink": "samples/PVQD-70dB/PT027_70dB.wav","age": 35,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [15,0,13,27,46,14],"roughness": [0,0,16,51,44,25],"breathiness": [0,0,2,0,36,7],"strain": [55,2,17,61,44,23],"pitch": [5,0,28,36,43,15],"loudness": [14,95,9,14,36,6],"g": [1,0,0,2,1,1],"r": [0,0,1,1,2,1],"b": [0,0,0,0,1,0],"a": [0,0,0,0,1,0],"s": [1,0,1,2,2,1],},
     {"database": "Walden (2022)","participantId": "PT028","sampleName": "PT028","sampleLink": "samples/PVQD-70dB/PT028_70dB.wav","age": 76,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [51,45,35,5,46,33],"roughness": [15,43,15,0,39,22],"breathiness": [25,4,31,7,42,27],"strain": [14,36,12,3,45,24],"pitch": [15,31,16,7,45,31],"loudness": [41,27,10,7,47,26],"g": [2,1,1,1,1,1],"r": [1,1,0,0,1,0],"b": [2,0,1,0,1,1],"a": [0,1,1,0,1,1],"s": [2,0,0,0,1,1],},
     {"database": "Walden (2022)","participantId": "PT029","sampleName": "PT029","sampleLink": "samples/PVQD-70dB/PT029_70dB.wav","age": 77,"gender": "female","diagnose": "atrophy","language": "english","overallSeverity": [39,16,21,42,11,32],"roughness": [37,11,10,44,6,21],"breathiness": [33,17,29,39,13,30],"strain": [24,0,4,3,8,5],"pitch": [23,0,20,3,0,0],"loudness": [10,25,16,21,0,12],"g": [1,1,1,1,1,1],"r": [1,1,1,1,0,1],"b": [1,1,2,1,1,2],"a": [1,1,1,0,0,0],"s": [1,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT030","sampleName": "PT030","sampleLink": "samples/PVQD-70dB/PT030_70dB.wav","age": 42,"gender": "male","diagnose": "leukoplakia","language": "english","overallSeverity": [55,46,23,80,39,34],"roughness": [61,56,38,82,53,45],"breathiness": [54,0,17,3,25,20],"strain": [10,27,0,77,7,0],"pitch": [39,16,12,59,14,16],"loudness": [46,0,19,60,33,18],"g": [2,2,1,3,2,1],"r": [2,2,2,3,2,2],"b": [1,0,1,0,1,1],"a": [1,0,1,2,1,1],"s": [0,1,0,2,0,0],},
     {"database": "Walden (2022)","participantId": "PT031","sampleName": "PT031","sampleLink": "samples/PVQD-70dB/PT031_70dB.wav","age": 68,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [43,5,11,34,7,21],"roughness": [24,0,2,3,0,9],"breathiness": [45,8,21,39,14,24],"strain": [30,0,0,34,0,3],"pitch": [13,0,12,20,0,0],"loudness": [29,8,9,25,2,17],"g": [2,0,1,1,1,1],"r": [1,0,0,0,0,0],"b": [2,0,1,1,1,1],"a": [2,0,0,1,0,1],"s": [1,0,0,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT032","sampleName": "PT032","sampleLink": "samples/PVQD-70dB/PT032_70dB.wav","age": 46,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [89,89,86,82,86,80],"roughness": [15,3,8,26,11,22],"breathiness": [90,81,86,91,73,69],"strain": [92,22,28,64,47,18],"pitch": [77,50,49,66,68,44],"loudness": [83,92,81,61,93,56],"g": [3,3,3,3,3,3],"r": [1,0,1,0,1,1],"b": [3,3,3,3,3,3],"a": [3,3,3,3,3,2],"s": [3,1,1,2,2,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT033","sampleName": "PT033","sampleLink": "samples/PVQD-70dB/PT033_70dB.wav","age": 90,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis_atrophy","language": "english","overallSeverity": [10,0,5,26,17,3],"roughness": [15,0,2,11,1,3],"breathiness": [3,0,0,28,0,0],"strain": [3,1,5,11,16,4],"pitch": [3,0,7,29,28,4],"loudness": [5,0,7,18,0,12],"g": [1,0,0,1,1,0],"r": [1,0,1,0,0,0],"b": [0,0,0,1,0,0],"a": [0,0,0,1,0,0],"s": [0,0,1,1,1,0],},
    // 2ND PHASE {"database": "Walden (2022)","participantId": "PT034","sampleName": "PT034","sampleLink": "samples/PVQD-70dB/PT034_70dB.wav","age": 80,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis_atrophy","language": "english","overallSeverity": [55,72,64,60,77,72],"roughness": [31,16,38,39,42,38],"breathiness": [33,42,62,53,18,51],"strain": [39,17,21,53,12,40],"pitch": [61,71,48,60,79,48],"loudness": [33,0,47,32,22,26],"g": [2,3,2,2,3,3],"r": [1,1,1,2,2,1],"b": [2,2,2,2,1,2],"a": [0,0,2,1,1,0],"s": [1,1,1,2,1,1],},
     {"database": "Walden (2022)","participantId": "PT036","sampleName": "PT036","sampleLink": "samples/PVQD-70dB/PT036_70dB.wav","age": 64,"gender": "female","diagnose": "tremor","language": "english","overallSeverity": [82,43,73,80,47,81],"roughness": [30,22,37,62,30,23],"breathiness": [44,22,53,3,0,16],"strain": [84,42,59,84,56,54],"pitch": [23,22,0,22,26,0],"loudness": [39,42,15,17,45,19],"g": [3,2,3,3,2,3],"r": [1,1,2,2,1,1],"b": [2,1,2,0,0,1],"a": [1,2,1,1,2,0],"s": [3,2,2,3,2,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT037","sampleName": "PT037","sampleLink": "samples/PVQD-70dB/PT037_70dB.wav","age": 79,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [55,18,21,58,29,29],"roughness": [56,10,15,67,12,27],"breathiness": [60,0,11,0,0,15],"strain": [59,29,25,64,42,12],"pitch": [16,0,0,5,7,3],"loudness": [27,0,16,19,15,15],"g": [2,1,1,2,2,1],"r": [2,1,1,2,1,1],"b": [2,0,0,0,0,1],"a": [1,0,0,0,1,0],"s": [1,2,1,2,2,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT038","sampleName": "PT038","sampleLink": "samples/PVQD-70dB/PT038_70dB.wav","age": 59,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [47,14,18,57,30,14],"roughness": [33,4,11,52,5,8],"breathiness": [46,25,24,38,16,18],"strain": [43,7,8,50,43,8],"pitch": [39,0,0,3,14,0],"loudness": [41,17,14,33,24,9],"g": [2,1,1,2,1,1],"r": [2,0,1,2,1,1],"b": [2,1,2,2,1,2],"a": [2,1,1,2,1,1],"s": [2,0,0,2,2,0],},
     {"database": "Walden (2022)","participantId": "PT039","sampleName": "PT039","sampleLink": "samples/PVQD-70dB/PT039_70dB.wav","age": 93,"gender": "male","diagnose": "atrophy","language": "english","overallSeverity": [55,44,34,54,24,23],"roughness": [58,35,24,43,12,23],"breathiness": [31,0,16,3,10,0],"strain": [52,23,15,40,19,22],"pitch": [1,0,16,3,0,0],"loudness": [17,0,32,11,0,22],"g": [2,2,1,2,1,1],"r": [2,2,1,2,1,1],"b": [1,0,1,0,1,0],"a": [0,0,0,0,0,0],"s": [2,1,1,1,0,1],},
     {"database": "Walden (2022)","participantId": "PT040","sampleName": "PT040","sampleLink": "samples/PVQD-70dB/PT040_70dB.wav","age": 17,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [19,8,6,18,11,5],"roughness": [20,3,4,36,3,4],"breathiness": [3,0,4,21,0,2],"strain": [3,13,0,32,17,0],"pitch": [5,0,4,3,0,0],"loudness": [3,0,6,26,0,0],"g": [1,1,1,1,1,1],"r": [1,0,1,1,0,1],"b": [0,0,1,1,0,1],"a": [0,0,0,0,0,0],"s": [0,1,0,1,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT041","sampleName": "PT041","sampleLink": "samples/PVQD-70dB/PT041_70dB.wav","age": 68,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [84,81,67,97,88,43],"roughness": [85,40,41,97,80,32],"breathiness": [55,67,54,81,46,39],"strain": [62,15,15,28,18,10],"pitch": [54,42,46,36,33,30],"loudness": [42,83,37,34,95,24],"g": [3,3,3,3,3,2],"r": [3,2,2,3,3,2],"b": [3,3,3,3,2,2],"a": [1,3,2,3,3,1],"s": [2,1,1,3,1,0],},
     {"database": "Walden (2022)","participantId": "PT042","sampleName": "PT042","sampleLink": "samples/PVQD-70dB/PT042_70dB.wav","age": 57,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [9,2,14,9,9,7],"roughness": [13,4,10,6,7,6],"breathiness": [13,0,8,8,0,7],"strain": [0,5,6,3,16,2],"pitch": [0,0,0,4,0,0],"loudness": [0,0,15,4,0,0],"g": [0,0,1,0,1,1],"r": [0,0,1,0,0,1],"b": [0,0,1,0,0,1],"a": [0,0,0,0,0,0],"s": [0,0,1,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT043","sampleName": "PT043","sampleLink": "samples/PVQD-70dB/PT043_70dB.wav","age": 43,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [61,22,41,29,50,31],"roughness": [62,20,11,25,33,20],"breathiness": [67,2,42,29,20,19],"strain": [19,3,0,34,0,0],"pitch": [20,0,8,1,0,19],"loudness": [58,25,36,13,62,35],"g": [2,1,1,2,2,1],"r": [2,1,1,1,1,1],"b": [2,0,2,1,1,2],"a": [2,1,2,1,2,2],"s": [2,0,0,2,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT044","sampleName": "PT044","sampleLink": "samples/PVQD-70dB/PT044_70dB.wav","age": 57,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [74,59,67,78,69,67],"roughness": [17,0,17,1,14,15],"breathiness": [77,78,71,78,57,69],"strain": [16,30,11,46,0,6],"pitch": [19,43,18,1,37,27],"loudness": [79,71,62,81,74,54],"g": [3,2,2,3,3,3],"r": [0,0,1,0,1,1],"b": [3,3,3,3,2,3],"a": [3,2,2,3,3,2],"s": [1,1,0,2,0,0],},
     {"database": "Walden (2022)","participantId": "PT045","sampleName": "PT045","sampleLink": "samples/PVQD-70dB/PT045_70dB.wav","age": 56,"gender": "male","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [25,5,0,9,2,0],"roughness": [11,1,0,3,2,5],"breathiness": [27,0,0,3,0,0],"strain": [10,9,0,3,3,3],"pitch": [3,0,0,6,0,0],"loudness": [6,0,0,3,0,4],"g": [1,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [1,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [1,1,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT046","sampleName": "PT046","sampleLink": "samples/PVQD-70dB/PT046_70dB.wav","age": 65,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [86,79,82,97,80,79],"roughness": [92,11,37,98,61,46],"breathiness": [83,63,83,83,71,79],"strain": [74,20,18,68,17,31],"pitch": [69,42,30,52,50,15],"loudness": [81,87,70,85,97,57],"g": [3,3,3,3,3,3],"r": [3,1,2,3,2,2],"b": [3,3,3,3,3,3],"a": [3,3,3,3,3,2],"s": [3,1,1,2,1,1],},
     {"database": "Walden (2022)","participantId": "PT047","sampleName": "PT047","sampleLink": "samples/PVQD-70dB/PT047_70dB.wav","age": 78,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [57,52,20,62,56,30],"roughness": [2,0,9,2,0,20],"breathiness": [59,38,32,63,27,50],"strain": [2,0,5,37,0,6],"pitch": [36,62,24,62,35,29],"loudness": [37,72,13,54,68,29],"g": [2,2,1,2,2,1],"r": [0,0,0,0,0,1],"b": [2,2,1,2,2,2],"a": [2,2,1,2,2,1],"s": [0,0,0,2,0,0],},
     {"database": "Walden (2022)","participantId": "PT048","sampleName": "PT048","sampleLink": "samples/PVQD-70dB/PT048_70dB.wav","age": 27,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [76,69,72,74,50,68],"roughness": [46,5,0,2,6,0],"breathiness": [78,40,63,73,26,62],"strain": [23,29,0,41,10,0],"pitch": [5,5,6,22,0,10],"loudness": [78,79,66,76,71,66],"g": [3,2,2,3,2,2],"r": [2,1,0,1,0,0],"b": [3,2,2,3,1,2],"a": [3,3,2,3,2,2],"s": [1,1,0,2,0,0],},
     {"database": "Walden (2022)","participantId": "PT049","sampleName": "PT049","sampleLink": "samples/PVQD-70dB/PT049_70dB.wav","age": 84,"gender": "male","diagnose": "glottic_cancer","language": "english","overallSeverity": [64,34,23,36,22,19],"roughness": [65,34,30,38,27,16],"breathiness": [22,0,19,3,0,15],"strain": [60,11,16,19,33,14],"pitch": [23,0,1,25,9,0],"loudness": [18,0,15,23,0,12],"g": [2,2,2,1,1,1],"r": [2,2,2,1,1,1],"b": [0,0,1,0,0,1],"a": [0,0,0,0,0,0],"s": [2,1,1,1,1,1],},
    // EXCLUDED? En normalitzar a 70dB i guardar, El Praat diu "Writing samples to audio file: XX out of XXXXXX samles have been clipped" {"database": "Walden (2022)","participantId": "PT050","sampleName": "PT050","sampleLink": "samples/PVQD-70dB/PT050_70dB.wav","age": 78,"gender": "male","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [84,81,90,95,89,85],"roughness": [67,6,76,94,2,53],"breathiness": [85,32,81,94,40,85],"strain": [89,75,61,95,73,65],"pitch": [62,94,62,80,95,53],"loudness": [64,60,71,83,64,63],"g": [3,3,3,3,3,3],"r": [3,0,3,3,1,2],"b": [3,2,3,3,2,3],"a": [3,2,3,3,2,3],"s": [3,3,3,3,3,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT051","sampleName": "PT051","sampleLink": "samples/PVQD-70dB/PT051_70dB.wav","age": 71,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [71,56,52,71,38,31],"roughness": [73,41,40,70,51,25],"breathiness": [46,0,14,6,0,17],"strain": [65,49,38,71,19,25],"pitch": [70,65,28,16,14,10],"loudness": [29,0,35,4,0,20],"g": [2,2,2,3,2,2],"r": [2,2,2,3,2,2],"b": [1,0,1,1,0,1],"a": [1,0,0,1,0,0],"s": [2,2,2,3,1,2],},
     {"database": "Walden (2022)","participantId": "PT052","sampleName": "PT052","sampleLink": "samples/PVQD-70dB/PT052_70dB.wav","age": 15,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [9,0,10,0,3,5],"roughness": [3,0,0,0,0,0],"breathiness": [15,0,10,0,4,9],"strain": [3,0,0,0,6,0],"pitch": [3,0,0,3,0,0],"loudness": [3,0,0,0,0,0],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,1,0,0,1],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT053","sampleName": "PT053","sampleLink": "samples/PVQD-70dB/PT053_70dB.wav","age": 47,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [60,32,57,55,48,37],"roughness": [63,41,52,57,58,49],"breathiness": [47,14,26,41,0,9],"strain": [58,22,27,56,17,27],"pitch": [43,0,25,100,0,18],"loudness": [37,0,33,44,0,21],"g": [2,2,2,2,2,2],"r": [2,2,2,2,2,2],"b": [2,1,1,2,0,1],"a": [1,0,1,1,0,1],"s": [2,1,1,2,1,1],},
     {"database": "Walden (2022)","participantId": "PT054","sampleName": "PT054","sampleLink": "samples/PVQD-70dB/PT054_70dB.wav","age": 61,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [87,70,72,71,79,60],"roughness": [58,0,29,59,3,19],"breathiness": [88,48,64,80,73,61],"strain": [71,0,39,76,13,38],"pitch": [32,0,24,60,46,0],"loudness": [86,82,37,74,84,46],"g": [3,3,2,3,3,2],"r": [1,0,1,3,0,1],"b": [3,2,3,3,3,3],"a": [3,3,2,3,3,2],"s": [2,0,1,3,1,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT055","sampleName": "PT055","sampleLink": "samples/PVQD-70dB/PT055_70dB.wav","age": 70,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [67,48,77,84,62,72],"roughness": [81,44,51,85,68,54],"breathiness": [68,16,73,62,21,69],"strain": [44,49,33,45,0,50],"pitch": [41,24,22,42,29,39],"loudness": [63,24,58,80,46,28],"g": [3,2,3,3,2,3],"r": [3,2,2,3,3,2],"b": [2,1,3,2,1,2],"a": [1,1,2,2,1,1],"s": [2,1,1,2,0,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT056","sampleName": "PT056","sampleLink": "samples/PVQD-70dB/PT056_70dB.wav","age": 68,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [25,6,6,21,0,4],"roughness": [11,3,4,25,2,0],"breathiness": [31,12,4,31,0,0],"strain": [22,0,9,24,5,3],"pitch": [3,0,0,0,0,0],"loudness": [3,0,18,0,0,9],"g": [1,1,0,1,0,0],"r": [0,0,0,1,0,0],"b": [1,1,0,1,0,0],"a": [0,0,0,0,0,0],"s": [0,0,1,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT057","sampleName": "PT057","sampleLink": "samples/PVQD-70dB/PT057_70dB.wav","age": 69,"gender": "female","diagnose": "atrophy_muscle_tension_dysphonia","language": "english","overallSeverity": [61,17,36,75,22,48],"roughness": [44,0,0,12,4,0],"breathiness": [66,19,54,80,30,48],"strain": [44,5,9,37,0,0],"pitch": [27,0,0,31,13,12],"loudness": [68,20,24,69,29,18],"g": [2,1,2,2,1,2],"r": [1,0,0,1,0,0],"b": [2,1,2,2,1,2],"a": [2,1,1,2,1,1],"s": [1,0,0,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT058","sampleName": "PT058","sampleLink": "samples/PVQD-70dB/PT058_70dB.wav","age": 70,"gender": "female","diagnose": "atrophy_muscle_tension_dysphonia","language": "english","overallSeverity": [92,91,73,95,92,86],"roughness": [93,10,32,91,20,21],"breathiness": [95,93,57,92,86,85],"strain": [96,23,21,91,44,24],"pitch": [95,96,71,82,97,69],"loudness": [67,98,33,44,98,59],"g": [3,3,3,3,3,3],"r": [3,1,1,3,1,1],"b": [3,3,2,3,3,3],"a": [2,3,1,2,3,2],"s": [3,1,1,3,2,1],},
     {"database": "Walden (2022)","participantId": "PT060","sampleName": "PT060","sampleLink": "samples/PVQD-70dB/PT060_70dB.wav","age": 75,"gender": "male","diagnose": "parkinson_disease","language": "english","overallSeverity": [61,16,11,58,48,25],"roughness": [17,2,5,6,0,0],"breathiness": [67,15,20,56,22,25],"strain": [35,2,0,3,0,0],"pitch": [37,0,0,24,0,0],"loudness": [60,20,15,22,59,25],"g": [2,1,0,2,2,1],"r": [1,0,0,0,0,0],"b": [2,1,1,2,1,1],"a": [2,1,0,2,2,1],"s": [1,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT061","sampleName": "PT061","sampleLink": "samples/PVQD-70dB/PT061_70dB.wav","age": 58,"gender": "male","diagnose": "atrophy","language": "english","overallSeverity": [38,15,67,42,28,63],"roughness": [39,5,68,42,9,63],"breathiness": [39,2,29,83,27,39],"strain": [21,0,69,13,0,40],"pitch": [21,1,41,13,0,55],"loudness": [42,0,73,87,1,56],"g": [1,1,3,2,1,3],"r": [1,1,2,1,1,3],"b": [1,0,1,2,1,2],"a": [1,0,3,1,0,2],"s": [1,0,2,1,0,2],},
     {"database": "Walden (2022)","participantId": "PT062","sampleName": "PT062","sampleLink": "samples/PVQD-70dB/PT062_70dB.wav","age": 65,"gender": "male","diagnose": "atrophy","language": "english","overallSeverity": [12,0,11,11,0,12],"roughness": [12,0,15,12,0,12],"breathiness": [0,0,1,0,0,1],"strain": [13,0,12,3,0,25],"pitch": [0,0,2,1,0,1],"loudness": [0,0,2,1,0,1],"g": [1,0,0,0,0,1],"r": [1,0,1,0,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [1,0,0,0,0,1],},
     {"database": "Walden (2022)","participantId": "PT063","sampleName": "PT063","sampleLink": "samples/PVQD-70dB/PT063_70dB.wav","age": 55,"gender": "male","diagnose": "leukoplakia","language": "english","overallSeverity": [0,0,16,16,4,13],"roughness": [0,0,3,1,1,12],"breathiness": [0,0,4,19,1,31],"strain": [0,0,20,0,1,11],"pitch": [0,0,2,1,1,9],"loudness": [0,4,25,18,2,30],"g": [0,0,1,1,0,1],"r": [0,0,0,0,0,0],"b": [0,0,0,1,0,1],"a": [0,0,1,1,0,1],"s": [0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT064","sampleName": "PT064","sampleLink": "samples/PVQD-70dB/PT064_70dB.wav","age": 56,"gender": "male","diagnose": "laryngeal_granuloma","language": "english","overallSeverity": [81,40,83,81,37,82],"roughness": [82,42,42,82,39,72],"breathiness": [84,1,64,69,40,82],"strain": [84,39,86,86,37,79],"pitch": [84,26,61,47,13,72],"loudness": [85,3,61,19,6,83],"g": [3,2,3,3,2,3],"r": [3,2,2,3,2,2],"b": [3,0,3,2,1,3],"a": [3,0,2,1,0,3],"s": [3,2,3,3,1,3],},
     {"database": "Walden (2022)","participantId": "PT065","sampleName": "PT065","sampleLink": "samples/PVQD-70dB/PT065_70dB.wav","age": 57,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [91,56,80,82,66,93],"roughness": [83,40,35,41,29,1],"breathiness": [84,6,36,44,47,61],"strain": [92,51,85,88,68,97],"pitch": [91,37,65,91,36,84],"loudness": [90,32,65,92,57,93],"g": [3,2,3,3,2,3],"r": [3,2,1,2,1,0],"b": [3,0,1,2,2,2],"a": [3,1,3,2,2,3],"s": [3,2,3,3,2,3],},
     {"database": "Walden (2022)","participantId": "PT066","sampleName": "PT066","sampleLink": "samples/PVQD-70dB/PT066_70dB.wav","age": 72,"gender": "male","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [74,39,66,81,41,63],"roughness": [74,38,66,82,39,18],"breathiness": [75,19,64,83,18,64],"strain": [75,37,63,83,39,64],"pitch": [44,29,2,61,20,21],"loudness": [45,12,25,62,5,43],"g": [2,1,2,3,2,2],"r": [2,1,2,3,2,1],"b": [2,1,2,3,1,2],"a": [1,0,3,2,0,2],"s": [2,1,3,3,2,2],},
     {"database": "Walden (2022)","participantId": "PT067","sampleName": "PT067","sampleLink": "samples/PVQD-70dB/PT067_70dB.wav","age": 50,"gender": "male","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [12,0,1,2,0,30],"roughness": [14,0,1,0,0,21],"breathiness": [1,0,1,0,0,34],"strain": [17,0,11,0,0,27],"pitch": [1,0,11,4,0,45],"loudness": [1,0,1,0,0,38],"g": [1,0,0,0,0,2],"r": [0,0,0,0,0,1],"b": [0,0,0,0,0,1],"a": [0,0,0,0,0,0],"s": [1,0,1,0,0,1],},
     {"database": "Walden (2022)","participantId": "PT068","sampleName": "PT068","sampleLink": "samples/PVQD-70dB/PT068_70dB.wav","age": 37,"gender": "male","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [41,0,22,32,0,26],"roughness": [43,1,22,32,5,26],"breathiness": [1,0,2,0,0,7],"strain": [45,1,17,32,0,27],"pitch": [45,1,18,32,0,3],"loudness": [46,0,3,0,0,3],"g": [2,0,1,1,0,1],"r": [2,0,1,1,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [2,0,1,1,0,1],},
     {"database": "Walden (2022)","participantId": "PT069","sampleName": "PT069","sampleLink": "samples/PVQD-70dB/PT069_70dB.wav","age": 25,"gender": "male","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [41,2,39,34,14,39],"roughness": [42,3,26,35,19,39],"breathiness": [42,0,43,36,3,39],"strain": [43,4,39,36,18,58],"pitch": [19,3,2,37,3,4],"loudness": [20,5,3,1,0,4],"g": [2,0,2,2,1,1],"r": [2,0,1,2,1,2],"b": [2,0,2,2,0,2],"a": [0,0,0,1,0,1],"s": [2,0,2,2,0,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT070","sampleName": "PT070","sampleLink": "samples/PVQD-70dB/PT070_70dB.wav","age": 37,"gender": "female","diagnose": "laryngeal_granuloma","language": "english","overallSeverity": [25,7,11,25,10,7],"roughness": [26,2,23,26,8,15],"breathiness": [16,1,1,1,1,1],"strain": [30,1,1,28,7,8],"pitch": [18,0,1,29,3,22],"loudness": [15,0,1,30,4,1],"g": [1,0,0,1,0,0],"r": [1,0,1,1,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [1,0,0,1,0,1],},
     {"database": "Walden (2022)","participantId": "PT071","sampleName": "PT071","sampleLink": "samples/PVQD-70dB/PT071_70dB.wav","age": 59,"gender": "male","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [0,0,27,9,0,28],"roughness": [0,0,28,5,0,52],"breathiness": [0,0,2,3,0,19],"strain": [0,0,5,2,0,12],"pitch": [0,0,2,3,0,32],"loudness": [0,0,4,0,0,12],"g": [0,0,1,1,0,1],"r": [0,0,1,1,0,2],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT072","sampleName": "PT072","sampleLink": "samples/PVQD-70dB/PT072_70dB.wav","age": 16,"gender": "female","diagnose": "vocal_fold_polyp","language": "english","overallSeverity": [23,3,24,41,0,19],"roughness": [18,3,25,41,2,11],"breathiness": [42,2,42,42,0,25],"strain": [43,0,24,43,0,23],"pitch": [43,1,5,25,0,2],"loudness": [26,1,13,1,0,9],"g": [1,0,1,2,0,1],"r": [1,0,1,2,0,1],"b": [1,0,1,2,0,1],"a": [0,0,0,0,0,0],"s": [1,0,1,2,0,1],},
     {"database": "Walden (2022)","participantId": "PT073","sampleName": "PT073","sampleLink": "samples/PVQD-70dB/PT073_70dB.wav","age": 47,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [14,0,24,19,0,24],"roughness": [15,0,21,20,0,10],"breathiness": [0,0,22,1,0,26],"strain": [0,0,23,6,0,4],"pitch": [0,0,4,0,0,3],"loudness": [0,0,22,0,0,3],"g": [1,0,1,1,0,1],"r": [1,0,1,1,0,0],"b": [0,0,1,0,0,1],"a": [0,0,1,0,0,0],"s": [0,0,1,0,0,0],},
    // EXCLUDED? SOROLLETS {"database": "Walden (2022)","participantId": "PT074","sampleName": "PT074","sampleLink": "samples/PVQD-70dB/PT074_70dB.wav","age": 51,"gender": "female","diagnose": "candida_laryngitis","language": "english","overallSeverity": [13,22,26,27,3,25],"roughness": [9,1,1,18,4,4],"breathiness": [14,0,43,27,2,25],"strain": [0,0,41,1,2,11],"pitch": [0,0,1,1,3,1],"loudness": [16,21,38,20,0,10],"g": [1,0,1,1,0,1],"r": [0,0,0,1,0,0],"b": [1,0,1,1,0,1],"a": [0,1,1,1,0,1],"s": [0,0,2,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT075","sampleName": "PT075","sampleLink": "samples/PVQD-70dB/PT075_70dB.wav","age": 25,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [40,24,12,41,14,12],"roughness": [27,20,11,28,13,8],"breathiness": [44,13,1,29,3,2],"strain": [36,12,15,44,1,23],"pitch": [1,4,1,9,0,1],"loudness": [20,0,3,12,0,2],"g": [1,1,1,2,1,1],"r": [1,1,1,1,1,0],"b": [1,1,0,1,0,0],"a": [0,0,0,0,0,0],"s": [1,0,1,2,0,1],},
     {"database": "Walden (2022)","participantId": "PT076","sampleName": "PT076","sampleLink": "samples/PVQD-70dB/PT076_70dB.wav","age": 42,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [62,24,65,80,21,69],"roughness": [63,18,69,81,20,81],"breathiness": [63,0,9,82,0,2],"strain": [64,12,72,82,16,83],"pitch": [65,7,9,82,7,2],"loudness": [21,7,25,47,0,27],"g": [2,1,2,3,1,2],"r": [2,1,2,3,1,3],"b": [2,0,0,3,0,0],"a": [1,0,1,2,0,2],"s": [2,1,3,3,1,3],},
     {"database": "Walden (2022)","participantId": "PT077","sampleName": "PT077","sampleLink": "samples/PVQD-70dB/PT077_70dB.wav","age": 68,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis_reinke_edema","language": "english","overallSeverity": [40,37,60,41,25,36],"roughness": [40,33,60,25,25,40],"breathiness": [41,16,24,25,1,23],"strain": [19,28,44,28,24,42],"pitch": [1,7,2,44,9,2],"loudness": [17,2,9,19,1,1],"g": [1,1,2,1,1,1],"r": [1,1,2,1,1,2],"b": [1,1,1,1,0,1],"a": [1,0,0,1,1,0],"s": [0,1,2,1,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT078","sampleName": "PT078","sampleLink": "samples/PVQD-70dB/PT078_70dB.wav","age": 54,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [0,0,2,0,0,2],"roughness": [0,0,1,0,0,1],"breathiness": [0,0,1,0,0,5],"strain": [0,0,1,0,0,0],"pitch": [0,0,1,0,1,1],"loudness": [0,0,14,0,0,11],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT079","sampleName": "PT079","sampleLink": "samples/PVQD-70dB/PT079_70dB.wav","age": 64,"gender": "female","diagnose": "vocal_fold_scar","language": "english","overallSeverity": [63,21,38,69,13,41],"roughness": [64,23,39,58,11,42],"breathiness": [64,19,39,26,2,16],"strain": [65,8,42,73,8,44],"pitch": [14,4,26,60,2,8],"loudness": [14,0,24,42,3,37],"g": [2,1,1,2,1,2],"r": [2,1,2,2,1,2],"b": [2,1,1,1,0,1],"a": [0,0,1,0,0,0],"s": [2,1,2,2,0,1],},
     {"database": "Walden (2022)","participantId": "PT080","sampleName": "PT080","sampleLink": "samples/PVQD-70dB/PT080_70dB.wav","age": 62,"gender": "male","diagnose": "reinke_edema","language": "english","overallSeverity": [27,3,23,41,7,10],"roughness": [29,3,38,43,7,23],"breathiness": [21,1,5,24,1,1],"strain": [1,1,9,24,1,7],"pitch": [34,1,22,45,1,16],"loudness": [1,1,1,20,8,2],"g": [1,0,1,2,0,1],"r": [1,0,2,2,0,1],"b": [1,0,0,1,0,0],"a": [0,0,0,0,0,0],"s": [0,0,1,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT081","sampleName": "PT081","sampleLink": "samples/PVQD-70dB/PT081_70dB.wav","age": 74,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [81,54,92,100,50,90],"roughness": [82,54,25,100,50,85],"breathiness": [83,51,98,100,48,78],"strain": [84,41,91,100,6,78],"pitch": [85,42,91,100,28,91],"loudness": [86,17,94,100,39,92],"g": [3,2,3,3,2,3],"r": [3,2,1,3,2,3],"b": [3,2,3,3,2,3],"a": [2,1,3,3,2,3],"s": [3,2,3,3,0,3],},
     {"database": "Walden (2022)","participantId": "PT082","sampleName": "PT082","sampleLink": "samples/PVQD-70dB/PT082_70dB.wav","age": 69,"gender": "female","diagnose": "ulcerative_laryngitis","language": "english","overallSeverity": [61,26,40,41,30,39],"roughness": [44,25,56,42,25,42],"breathiness": [28,0,4,33,21,11],"strain": [66,29,59,69,13,62],"pitch": [50,14,2,44,17,3],"loudness": [69,11,29,71,4,10],"g": [2,1,2,2,1,2],"r": [2,1,2,2,1,2],"b": [1,0,0,1,1,0],"a": [0,1,1,0,1,0],"s": [2,1,2,2,1,2],},
     {"database": "Walden (2022)","participantId": "PT083","sampleName": "PT083","sampleLink": "samples/PVQD-70dB/PT083_70dB.wav","age": 41,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [13,0,11,10,0,11],"roughness": [13,0,12,13,0,1],"breathiness": [5,0,2,10,0,25],"strain": [7,0,23,0,0,10],"pitch": [9,0,3,44,4,1],"loudness": [1,0,11,0,0,8],"g": [1,0,0,0,0,1],"r": [1,0,1,0,0,0],"b": [0,0,0,0,0,1],"a": [0,0,0,0,0,0],"s": [0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT084","sampleName": "PT084","sampleLink": "samples/PVQD-70dB/PT084_70dB.wav","age": 19,"gender": "male","diagnose": "bilateral_vocal_fold_paralysis","language": "english","overallSeverity": [80,38,89,81,40,99],"roughness": [13,1,1,0,10,1],"breathiness": [84,41,92,83,40,97],"strain": [85,24,65,84,16,83],"pitch": [17,28,93,1,27,4],"loudness": [91,33,93,85,39,92],"g": [3,2,3,3,2,3],"r": [2,0,0,0,0,0],"b": [3,2,3,3,2,3],"a": [3,1,3,3,2,3],"s": [2,1,2,2,1,3],},
    // EXCLUDED due to low signal; microphone was too far away. {"database": "Walden (2022)","participantId": "PT085","sampleName": "PT085","sampleLink": "samples/PVQD-70dB/PT085_70dB.wav","age": 29,"gender": "male","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [15,1,0,81,2,11],"roughness": [16,10,9,35,5,24],"breathiness": [1,1,1,29,0,1],"strain": [7,2,7,31,0,13],"pitch": [11,0,1,45,0,1],"loudness": [46,0,4,85,3,8],"g": [1,0,0,3,0,0],"r": [1,0,1,3,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,2,0,0],"s": [1,0,0,2,0,1],},
     {"database": "Walden (2022)","participantId": "PT086","sampleName": "PT086","sampleLink": "samples/PVQD-70dB/PT086_70dB.wav","age": 65,"gender": "female","diagnose": "atrophy","language": "english","overallSeverity": [34,0,22,27,0,26],"roughness": [35,1,7,27,1,1],"breathiness": [35,0,25,28,0,41],"strain": [20,0,20,15,1,3],"pitch": [0,1,8,1,1,3],"loudness": [0,1,26,18,1,42],"g": [1,0,1,1,0,1],"r": [1,0,0,1,0,0],"b": [1,0,1,1,0,1],"a": [1,0,1,1,0,1],"s": [0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT087","sampleName": "PT087","sampleLink": "samples/PVQD-70dB/PT087_70dB.wav","age": 83,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [82,39,93,78,34,91],"roughness": [82,39,94,81,34,92],"breathiness": [83,19,82,83,23,77],"strain": [47,0,63,84,19,78],"pitch": [85,19,85,76,17,85],"loudness": [88,35,96,76,26,82],"g": [3,2,3,3,2,3],"r": [3,2,3,3,2,3],"b": [3,1,2,3,1,3],"a": [3,1,3,3,1,3],"s": [2,0,3,3,1,3],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT088","sampleName": "PT088","sampleLink": "samples/PVQD-70dB/PT088_70dB.wav","age": 73,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [81,46,87,80,35,94],"roughness": [82,40,54,81,35,79],"breathiness": [83,48,89,82,33,95],"strain": [48,0,89,39,10,89],"pitch": [49,22,89,83,25,82],"loudness": [86,46,92,84,31,85],"g": [3,2,3,3,2,3],"r": [3,1,2,3,2,3],"b": [3,2,3,3,2,3],"a": [3,2,3,3,1,3],"s": [2,0,3,2,1,3],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT089","sampleName": "PT089","sampleLink": "samples/PVQD-70dB/PT089_70dB.wav","age": 71,"gender": "male","diagnose": "parkinson_disease_unliateral_vocal_fold_paralysis","language": "english","overallSeverity": [13,1,24,12,0,21],"roughness": [4,0,2,8,0,3],"breathiness": [5,5,1,10,0,10],"strain": [0,0,26,0,2,25],"pitch": [1,0,41,0,1,21],"loudness": [1,0,3,0,0,1],"g": [1,0,1,1,0,1],"r": [1,0,0,0,0,0],"b": [0,0,0,1,0,1],"a": [0,0,0,0,0,0],"s": [0,0,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT090","sampleName": "PT090","sampleLink": "samples/PVQD-70dB/PT090_70dB.wav","age": 26,"gender": "male","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [1,0,27,17,9,0],"roughness": [0,0,7,0,9,0],"breathiness": [1,1,39,20,9,0],"strain": [0,1,23,2,0,1],"pitch": [0,0,11,8,0,1],"loudness": [3,1,38,6,0,22],"g": [0,0,1,1,0,0],"r": [0,0,0,0,0,0],"b": [0,0,2,1,0,0],"a": [0,0,1,1,0,1],"s": [0,0,1,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT091","sampleName": "PT091","sampleLink": "samples/PVQD-70dB/PT091_70dB.wav","age": 35,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [81,28,58,81,38,58],"roughness": [82,27,4,81,38,40],"breathiness": [83,3,25,29,36,27],"strain": [84,21,60,84,37,53],"pitch": [69,7,24,52,22,29],"loudness": [79,1,26,74,1,28],"g": [3,1,2,3,1,2],"r": [3,1,0,3,1,1],"b": [3,0,1,2,1,1],"a": [0,0,1,0,0,1],"s": [3,1,2,3,1,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT092","sampleName": "PT092","sampleLink": "samples/PVQD-70dB/PT092_70dB.wav","age": 74,"gender": "male","diagnose": "atrophy","language": "english","overallSeverity": [30,3,48,40,14,44],"roughness": [30,3,27,41,2,8],"breathiness": [30,1,53,42,11,43],"strain": [12,0,32,3,7,11],"pitch": [9,2,44,12,1,25],"loudness": [10,2,61,43,3,71],"g": [1,0,2,2,0,2],"r": [1,0,1,2,0,0],"b": [1,0,2,2,0,1],"a": [1,0,2,1,0,2],"s": [0,0,1,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT093","sampleName": "PT093","sampleLink": "samples/PVQD-70dB/PT093_70dB.wav","age": 63,"gender": "male","diagnose": "muscle_tension_dysphonia_atrophy","language": "english","overallSeverity": [25,0,0,14,0,1],"roughness": [26,0,10,14,0,1],"breathiness": [27,0,0,0,0,1],"strain": [0,0,2,6,0,5],"pitch": [1,0,0,16,0,1],"loudness": [8,0,0,16,0,1],"g": [1,0,0,1,0,0],"r": [1,0,1,1,0,0],"b": [1,0,0,0,0,0],"a": [0,0,0,1,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT094","sampleName": "PT094","sampleLink": "samples/PVQD-70dB/PT094_70dB.wav","age": 59,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [82,48,64,81,50,66],"roughness": [83,45,51,82,52,59],"breathiness": [84,0,63,83,0,56],"strain": [85,34,64,84,47,59],"pitch": [24,26,64,46,32,1],"loudness": [67,46,64,47,50,61],"g": [3,2,2,3,2,2],"r": [3,2,3,3,2,2],"b": [3,0,2,3,0,2],"a": [3,1,3,2,2,3],"s": [3,1,3,2,2,2],},
     {"database": "Walden (2022)","participantId": "PT095","sampleName": "PT095","sampleLink": "samples/PVQD-70dB/PT095_70dB.wav","age": 24,"gender": "male","diagnose": "muscle_tension_dysphonia_vocal_fold_lesions","language": "english","overallSeverity": [24,0,20,42,0,41],"roughness": [25,3,5,30,4,8],"breathiness": [25,0,20,32,0,34],"strain": [25,0,21,32,0,52],"pitch": [7,0,12,0,2,4],"loudness": [9,0,21,28,1,13],"g": [1,0,1,1,0,1],"r": [1,0,0,1,0,0],"b": [1,0,1,1,0,1],"a": [0,0,1,0,0,2],"s": [1,0,1,1,0,2],},
     {"database": "Walden (2022)","participantId": "PT096","sampleName": "PT096","sampleLink": "samples/PVQD-70dB/PT096_70dB.wav","age": 45,"gender": "male","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [1,0,0,13,0,1],"roughness": [1,0,0,0,0,1],"breathiness": [0,0,0,0,0,1],"strain": [1,0,0,15,0,6],"pitch": [0,0,0,14,0,1],"loudness": [0,0,0,14,0,1],"g": [0,0,0,1,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,1,0,0],},
    // EXCLUDED (En normalitzar a 70dB i guardar, El Praat diu "Writing samples to audio file: XX out of XXXXXX samles have been clipped") {"database": "Walden (2022)","participantId": "PT097","sampleName": "PT097","sampleLink": "samples/PVQD-70dB/PT097_70dB.wav","age": 46,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [80,53,80,100,49,79],"roughness": [0,52,3,51,30,10],"breathiness": [83,48,81,100,48,81],"strain": [84,49,83,100,47,81],"pitch": [0,45,27,1,25,81],"loudness": [86,49,83,100,46,81],"g": [3,2,3,3,2,3],"r": [0,2,0,2,1,0],"b": [3,2,3,3,2,3],"a": [3,2,3,3,2,3],"s": [2,2,3,3,1,3],},
     {"database": "Walden (2022)","participantId": "PT098","sampleName": "PT098","sampleLink": "samples/PVQD-70dB/PT098_70dB.wav","age": 48,"gender": "male","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [11,0,22,11,0,12],"roughness": [12,0,24,9,1,24],"breathiness": [1,0,2,0,0,3],"strain": [10,0,40,5,0,8],"pitch": [31,0,3,10,0,35],"loudness": [37,2,7,11,0,5],"g": [1,0,0,1,0,1],"r": [1,0,1,1,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [1,0,0,0,0,1],},
     {"database": "Walden (2022)","participantId": "PT099","sampleName": "PT099","sampleLink": "samples/PVQD-70dB/PT099_70dB.wav","age": 15,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [24,0,10,22,2,10],"roughness": [24,1,21,22,2,10],"breathiness": [11,0,10,23,0,4],"strain": [27,0,22,0,0,22],"pitch": [0,0,8,0,0,3],"loudness": [11,0,23,0,2,14],"g": [1,0,1,1,0,1],"r": [1,0,1,1,0,0],"b": [1,0,0,1,0,0],"a": [0,0,1,0,0,0],"s": [1,0,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT100","sampleName": "PT100","sampleLink": "samples/PVQD-70dB/PT100_70dB.wav","age": 61,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [39,42,26,40,19,39],"roughness": [21,22,1,17,2,7],"breathiness": [42,38,27,41,0,41],"strain": [9,2,1,0,0,42],"pitch": [25,14,2,0,18,24],"loudness": [46,29,46,25,1,42],"g": [2,1,1,1,1,2],"r": [1,1,0,0,0,0],"b": [2,1,1,1,0,2],"a": [2,1,2,1,0,2],"s": [0,0,0,0,0,2],},
     {"database": "Walden (2022)","participantId": "PT101","sampleName": "PT101","sampleLink": "samples/PVQD-70dB/PT101_70dB.wav","age": 14,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [0,4,12,0,0,24],"roughness": [0,0,0,0,0,1],"breathiness": [0,0,10,0,0,43],"strain": [0,0,1,0,0,24],"pitch": [0,0,1,0,0,1],"loudness": [0,6,12,0,0,25],"g": [0,0,0,0,0,1],"r": [0,0,0,0,0,0],"b": [0,0,1,0,0,2],"a": [0,0,1,0,0,2],"s": [0,0,0,0,0,1],},
     {"database": "Walden (2022)","participantId": "PT102","sampleName": "PT102","sampleLink": "samples/PVQD-70dB/PT102_70dB.wav","age": 19,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [27,20,39,41,14,38],"roughness": [28,12,38,42,2,30],"breathiness": [0,2,15,26,1,11],"strain": [30,17,38,27,10,37],"pitch": [30,30,23,2,22,37],"loudness": [0,0,6,1,0,28],"g": [1,1,2,1,0,2],"r": [1,1,2,1,0,2],"b": [0,0,1,1,0,1],"a": [0,0,0,0,0,1],"s": [1,1,2,1,0,2],},
    // EXCLUDED (En normalitzar a 70dB i guardar, El Praat diu "Writing samples to audio file: XX out of XXXXXX samles have been clipped") {"database": "Walden (2022)","participantId": "PT103","sampleName": "PT103","sampleLink": "samples/PVQD-70dB/PT103_70dB.wav","age": 72,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis_atrophy","language": "english","overallSeverity": [57,34,80,67,38,81],"roughness": [58,32,56,42,20,3],"breathiness": [58,36,82,81,38,82],"strain": [59,0,44,25,1,25],"pitch": [15,32,18,47,22,9],"loudness": [38,0,71,84,35,83],"g": [1,2,2,2,1,2],"r": [1,1,2,2,1,0],"b": [1,1,3,3,1,3],"a": [1,0,3,2,1,3],"s": [1,0,2,1,0,2],},
    // EXCLUDED for having background noise {"database": "Walden (2022)","participantId": "PT104","sampleName": "PT104","sampleLink": "samples/PVQD-70dB/PT104_70dB.wav","age": 77,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [82,19,60,80,22,60],"roughness": [82,17,61,81,22,60],"breathiness": [86,1,26,81,1,25],"strain": [84,13,64,81,20,44],"pitch": [84,12,38,82,17,45],"loudness": [88,11,22,83,13,39],"g": [3,1,2,3,1,2],"r": [3,1,2,3,1,3],"b": [3,1,1,3,1,1],"a": [3,1,2,3,1,2],"s": [3,1,2,3,1,2],},
    // EXCLUDED. MISSING SAMPLE {"database": "Walden (2022)","participantId": "PT105","sampleName": "PT105","sampleLink": "samples/PVQD-70dB/PT105_70dB.wav","age": 81,"gender": "female","diagnose": "atrophy","language": "english","overallSeverity": ,"roughness": ,"breathiness": ,"strain": ,"pitch": ,"loudness": ,"g": ,"r": ,"b": ,"a": ,"s": ,},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT106","sampleName": "PT106","sampleLink": "samples/PVQD-70dB/PT106_70dB.wav","age": 75,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis_atrophy","language": "english","overallSeverity": [40,20,78,63,32,41],"roughness": [39,19,57,64,30,42],"breathiness": [34,0,41,65,20,7],"strain": [31,19,59,66,29,44],"pitch": [44,4,61,65,2,49],"loudness": [17,2,8,67,0,25],"g": [2,1,2,2,1,2],"r": [2,1,2,2,1,2],"b": [1,0,1,2,1,0],"a": [1,0,2,2,0,1],"s": [1,1,2,2,1,1],},
     {"database": "Walden (2022)","participantId": "PT107","sampleName": "PT107","sampleLink": "samples/PVQD-70dB/PT107_70dB.wav","age": 73,"gender": "male","diagnose": "atrophy","language": "english","overallSeverity": [42,3,24,26,6,24],"roughness": [43,3,2,26,6,13],"breathiness": [44,1,24,27,0,26],"strain": [34,16,41,0,4,25],"pitch": [35,5,8,27,1,1],"loudness": [36,5,25,27,0,40],"g": [2,0,1,1,0,1],"r": [1,0,0,1,0,0],"b": [2,0,1,1,0,1],"a": [1,0,1,1,0,2],"s": [1,0,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT108","sampleName": "PT108","sampleLink": "samples/PVQD-70dB/PT108_70dB.wav","age": 87,"gender": "male","diagnose": "glottic_cancer","language": "english","overallSeverity": [53,54,59,64,41,68],"roughness": [55,51,61,82,41,79],"breathiness": [16,0,1,41,3,11],"strain": [17,45,14,43,28,42],"pitch": [18,45,84,43,35,85],"loudness": [19,41,1,68,14,2],"g": [1,2,2,3,2,2],"r": [1,2,2,3,2,3],"b": [1,0,0,1,0,0],"a": [0,1,0,0,1,0],"s": [1,1,2,2,2,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT109","sampleName": "PT109","sampleLink": "samples/PVQD-70dB/PT109_70dB.wav","age": 55,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [8,0,0,10,10,1],"roughness": [7,15,0,11,0,6],"breathiness": [0,2,0,0,0,2],"strain": [0,9,0,0,16,2],"pitch": [6,0,22,0,0,1],"loudness": [0,1,0,0,0,1],"g": [0,0,0,0,0,0],"r": [0,1,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT110","sampleName": "PT110","sampleLink": "samples/PVQD-70dB/PT110_70dB.wav","age": 49,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [17,35,0,25,24,1],"roughness": [17,20,0,25,10,10],"breathiness": [0,0,15,1,25,1],"strain": [7,0,0,1,3,1],"pitch": [18,12,0,9,0,1],"loudness": [0,0,0,0,0,1],"g": [1,1,0,1,1,0],"r": [1,1,0,1,1,0],"b": [0,0,1,0,1,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT111","sampleName": "PT111","sampleLink": "samples/PVQD-70dB/PT111_70dB.wav","age": 39,"gender": "female","diagnose": "goiter","language": "english","overallSeverity": [10,0,11,18,16,12],"roughness": [0,11,0,10,2,1],"breathiness": [9,0,18,16,15,26],"strain": [9,0,0,7,0,10],"pitch": [0,5,0,0,0,1],"loudness": [0,0,0,0,0,1],"g": [0,0,0,1,0,0],"r": [0,1,0,0,0,0],"b": [0,0,1,1,1,1],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT112","sampleName": "PT112","sampleLink": "samples/PVQD-70dB/PT112_70dB.wav","age": 66,"gender": "female","diagnose": "tremor","language": "english","overallSeverity": [19,0,2,12,0,1],"roughness": [17,10,8,11,16,6],"breathiness": [0,0,0,0,1,1],"strain": [0,0,0,0,10,1],"pitch": [0,1,0,10,1,1],"loudness": [19,0,0,0,0,1],"g": [0,0,0,0,0,0],"r": [0,1,1,0,1,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT113","sampleName": "PT113","sampleLink": "samples/PVQD-70dB/PT113_70dB.wav","age": 62,"gender": "male","diagnose": "atrophy","language": "english","overallSeverity": [43,13,53,34,2,13],"roughness": [44,13,40,34,11,13],"breathiness": [33,2,18,22,0,8],"strain": [0,2,12,0,0,1],"pitch": [0,1,0,0,0,1],"loudness": [18,11,1,37,0,1],"g": [1,0,1,1,0,0],"r": [1,1,1,1,1,1],"b": [1,0,1,0,0,0],"a": [0,1,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT114","sampleName": "PT114","sampleLink": "samples/PVQD-70dB/PT114_70dB.wav","age": 46,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [24,2,0,28,3,15],"roughness": [24,15,14,29,11,31],"breathiness": [23,0,0,12,2,0],"strain": [0,2,2,0,2,0],"pitch": [0,14,5,21,6,0],"loudness": [0,3,0,0,1,0],"g": [1,1,0,1,0,0],"r": [1,1,0,1,1,1],"b": [1,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT115","sampleName": "PT115","sampleLink": "samples/PVQD-70dB/PT115_70dB.wav","age": 34,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [8,7,11,5,26,0],"roughness": [9,11,11,5,7,5],"breathiness": [0,0,11,0,1,0],"strain": [0,16,1,0,38,3],"pitch": [1,10,13,0,7,0],"loudness": [1,0,1,0,8,0],"g": [0,0,0,0,1,0],"r": [0,1,1,0,0,1],"b": [0,0,1,0,0,0],"a": [0,0,0,0,0,0],"s": [0,1,0,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT116","sampleName": "PT116","sampleLink": "samples/PVQD-70dB/PT116_70dB.wav","age": 81,"gender": "female","diagnose": "atrophy","language": "english","overallSeverity": [40,12,40,37,17,41],"roughness": [24,30,33,36,5,49],"breathiness": [32,30,44,30,5,50],"strain": [16,0,25,0,0,3],"pitch": [0,0,23,0,1,12],"loudness": [36,0,21,0,0,17],"g": [1,1,1,2,1,1],"r": [1,1,1,2,1,1],"b": [1,1,1,2,0,2],"a": [2,1,2,2,1,2],"s": [0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT117","sampleName": "PT117","sampleLink": "samples/PVQD-70dB/PT117_70dB.wav","age": 71,"gender": "female","diagnose": "adductor_spasmodic_dysphonia","language": "english","overallSeverity": [78,45,84,74,64,64],"roughness": [10,16,9,0,10,13],"breathiness": [0,12,0,0,25,0],"strain": [79,33,82,74,67,75],"pitch": [0,15,0,0,21,0],"loudness": [0,18,8,0,22,0],"g": [3,1,2,3,2,2],"r": [0,1,1,0,1,1],"b": [0,1,0,0,1,0],"a": [1,1,1,0,1,1],"s": [3,2,3,3,2,2],},
    // EXCLUDED? AFONIA {"database": "Walden (2022)","participantId": "PT118","sampleName": "PT118","sampleLink": "samples/PVQD-70dB/PT118_70dB.wav","age": 58,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [100,75,88,100,88,79],"roughness": [0,21,11,17,13,73],"breathiness": [100,69,100,100,76,100],"strain": [58,0,18,36,4,18],"pitch": [58,38,19,0,43,18],"loudness": [58,66,73,100,74,27],"g": [3,2,3,3,3,3],"r": [0,1,0,1,1,2],"b": [3,2,3,3,2,3],"a": [2,0,3,1,0,1],"s": [2,0,1,1,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT119","sampleName": "PT119","sampleLink": "samples/PVQD-70dB/PT119_70dB.wav","age": 71,"gender": "female","diagnose": "atrophy","language": "english","overallSeverity": [63,38,44,49,10,31],"roughness": [47,15,44,50,8,32],"breathiness": [47,1,1,26,1,29],"strain": [15,18,64,25,5,42],"pitch": [68,38,20,17,16,8],"loudness": [18,0,1,4,0,1],"g": [2,1,2,2,0,1],"r": [1,1,2,2,1,1],"b": [1,0,0,1,0,1],"a": [1,1,0,0,0,0],"s": [0,0,2,1,0,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT120","sampleName": "PT120","sampleLink": "samples/PVQD-70dB/PT120_70dB.wav","age": 55,"gender": "female","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [88,64,85,88,61,78],"roughness": [89,40,77,78,45,71],"breathiness": [92,8,90,84,2,61],"strain": [69,30,83,58,28,84],"pitch": [28,19,60,46,24,38],"loudness": [29,19,63,28,24,20],"g": [3,2,3,3,2,3],"r": [3,2,3,2,2,3],"b": [3,1,3,3,0,2],"a": [2,1,2,1,0,1],"s": [2,1,3,1,1,3],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT121","sampleName": "PT121","sampleLink": "samples/PVQD-70dB/PT121_70dB.wav","age": 75,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [85,27,73,80,61,87],"roughness": [33,9,23,23,12,13],"breathiness": [89,24,75,83,44,65],"strain": [0,0,62,30,8,74],"pitch": [72,32,61,52,45,77],"loudness": [56,15,70,30,66,70],"g": [3,1,2,3,2,3],"r": [1,0,1,1,1,1],"b": [3,1,3,3,2,2],"a": [2,1,3,2,2,3],"s": [0,0,1,1,1,2],},
    // EXCLUDED (En normalitzar a 70dB i guardar, El Praat diu "Writing samples to audio file: XX out of XXXXXX samles have been clipped") {"database": "Walden (2022)","participantId": "PT122","sampleName": "PT122","sampleLink": "samples/PVQD-70dB/PT122_70dB.wav","age": 61,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [74,30,41,66,52,43],"roughness": [15,15,49,31,49,43],"breathiness": [0,0,0,2,33,41],"strain": [74,39,61,71,42,44],"pitch": [14,12,13,52,23,2],"loudness": [0,1,0,3,20,1],"g": [3,1,2,2,2,2],"r": [0,1,2,1,2,2],"b": [0,0,0,0,1,0],"a": [0,0,0,0,1,0],"s": [3,1,2,2,2,2],},
    // EXCLUDED? ONLY ONE SENTENCE {"database": "Walden (2022)","participantId": "PT123","sampleName": "PT123","sampleLink": "samples/PVQD-70dB/PT123_70dB.wav","age": 59,"gender": "female","diagnose": "chronic_cough","language": "english","overallSeverity": [40,31,25,41,54,47],"roughness": [40,24,44,41,12,54],"breathiness": [0,4,13,8,0,3],"strain": [0,12,26,9,13,32],"pitch": [25,11,13,8,0,1],"loudness": [26,10,14,18,15,1],"g": [1,1,1,1,1,2],"r": [1,1,1,1,1,2],"b": [0,0,1,0,0,0],"a": [1,1,1,1,1,1],"s": [0,0,2,1,1,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT124","sampleName": "PT124","sampleLink": "samples/PVQD-70dB/PT124_70dB.wav","age": 48,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [18,12,1,29,2,1],"roughness": [16,6,1,29,2,12],"breathiness": [17,9,12,29,2,14],"strain": [0,16,12,0,13,1],"pitch": [0,9,1,0,8,1],"loudness": [0,3,1,1,1,1],"g": [1,1,0,1,0,0],"r": [1,1,0,1,0,1],"b": [1,0,1,1,0,1],"a": [0,0,0,0,0,0],"s": [0,1,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT125","sampleName": "PT125","sampleLink": "samples/PVQD-70dB/PT125_70dB.wav","age": 63,"gender": "female","diagnose": "adductor_spasmodic_dysphonia","language": "english","overallSeverity": [43,24,31,46,15,33],"roughness": [0,13,13,0,4,1],"breathiness": [11,4,1,0,0,14],"strain": [46,24,44,47,24,5],"pitch": [17,12,1,29,15,37],"loudness": [0,2,1,0,11,16],"g": [2,1,2,2,1,1],"r": [1,1,1,0,0,0],"b": [1,0,0,0,0,1],"a": [2,0,0,1,0,0],"s": [2,1,2,2,1,1],},
     {"database": "Walden (2022)","participantId": "PT126","sampleName": "PT126","sampleLink": "samples/PVQD-70dB/PT126_70dB.wav","age": 89,"gender": "female","diagnose": "adductor_spasmodic_dysphonia","language": "english","overallSeverity": [97,75,81,96,70,94],"roughness": [1,26,12,0,15,62],"breathiness": [2,0,1,0,0,2],"strain": [97,62,100,97,46,100],"pitch": [17,16,13,20,46,0],"loudness": [16,15,13,19,14,13],"g": [3,2,3,3,2,3],"r": [0,1,1,0,1,2],"b": [0,0,0,0,0,0],"a": [2,0,1,2,0,1],"s": [3,2,3,3,2,3],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT127","sampleName": "PT127","sampleLink": "samples/PVQD-70dB/PT127_70dB.wav","age": 74,"gender": "female","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [82,68,53,92,71,69],"roughness": [33,23,20,22,13,26],"breathiness": [83,42,58,92,36,69],"strain": [52,29,23,38,19,41],"pitch": [76,36,71,34,11,29],"loudness": [31,51,64,34,25,46],"g": [3,2,2,3,2,2],"r": [2,1,1,1,1,2],"b": [3,1,2,3,2,3],"a": [1,1,3,3,1,3],"s": [2,1,2,1,1,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT128","sampleName": "PT128","sampleLink": "samples/PVQD-70dB/PT128_70dB.wav","age": 35,"gender": "male","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [0,3,0,1,2,1],"roughness": [0,3,0,0,3,1],"breathiness": [0,4,0,1,2,1],"strain": [0,3,0,0,2,1],"pitch": [0,3,0,0,3,1],"loudness": [0,0,0,0,3,1],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT129","sampleName": "PT129","sampleLink": "samples/PVQD-70dB/PT129_70dB.wav","age": 70,"gender": "male","diagnose": "atrophy_muscle_tension_dysphonia","language": "english","overallSeverity": [21,0,1,14,5,0],"roughness": [22,0,1,13,9,0],"breathiness": [0,0,1,0,0,0],"strain": [0,13,1,0,13,0],"pitch": [0,0,1,0,0,0],"loudness": [1,0,1,0,9,0],"g": [0,0,0,0,0,0],"r": [0,0,0,0,1,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT130","sampleName": "PT130","sampleLink": "samples/PVQD-70dB/PT130_70dB.wav","age": 84,"gender": "female","diagnose": "adductor_spasmodic_dysphonia","language": "english","overallSeverity": [62,23,47,78,32,41],"roughness": [24,10,25,14,15,1],"breathiness": [0,2,26,14,19,13],"strain": [63,25,64,79,16,48],"pitch": [20,16,17,15,17,1],"loudness": [20,16,42,37,18,13],"g": [3,1,2,3,1,2],"r": [1,0,1,1,1,0],"b": [0,0,1,1,1,1],"a": [1,0,2,2,1,1],"s": [3,1,2,3,1,1],},
     {"database": "Walden (2022)","participantId": "PT131","sampleName": "PT131","sampleLink": "samples/PVQD-70dB/PT131_70dB.wav","age": 48,"gender": "male","diagnose": "vocal_fold_lesions","language": "english","overallSeverity": [25,29,12,49,25,13],"roughness": [23,26,20,50,11,13],"breathiness": [24,3,1,36,0,13],"strain": [0,5,1,0,2,14],"pitch": [0,17,1,0,10,1],"loudness": [0,9,1,0,0,1],"g": [1,1,0,2,1,1],"r": [1,1,1,2,1,1],"b": [1,0,0,1,0,1],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,1],},
     {"database": "Walden (2022)","participantId": "PT132","sampleName": "PT132","sampleLink": "samples/PVQD-70dB/PT132_70dB.wav","age": 72,"gender": "male","diagnose": "atrophy","language": "english","overallSeverity": [28,16,6,13,10,11],"roughness": [28,15,11,11,8,26],"breathiness": [0,2,5,0,9,1],"strain": [0,2,12,0,4,12],"pitch": [16,18,1,13,0,1],"loudness": [29,10,1,6,0,2],"g": [1,0,0,0,1,1],"r": [1,1,1,0,1,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT133","sampleName": "PT133","sampleLink": "samples/PVQD-70dB/PT133_70dB.wav","age": 59,"gender": "female","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [0,1,0,0,1,1],"roughness": [0,0,0,0,8,1],"breathiness": [0,2,0,0,0,1],"strain": [0,2,0,0,0,1],"pitch": [0,3,0,0,0,1],"loudness": [0,4,12,0,0,1],"g": [0,0,0,0,0,0],"r": [0,0,0,0,1,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT134","sampleName": "PT134","sampleLink": "samples/PVQD-70dB/PT134_70dB.wav","age": 19,"gender": "male","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [13,0,0,17,0,1],"roughness": [13,0,9,17,0,6],"breathiness": [12,9,0,18,14,1],"strain": [0,0,0,0,0,1],"pitch": [0,0,0,0,8,1],"loudness": [11,0,0,18,0,1],"g": [0,0,0,1,0,0],"r": [0,1,0,1,0,1],"b": [0,0,0,1,1,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "PT135","sampleName": "PT135","sampleLink": "samples/PVQD-70dB/PT135_70dB.wav","age": 69,"gender": "female","diagnose": "muscle_tension_dysphonia_atrophy","language": "english","overallSeverity": [42,5,25,23,3,44],"roughness": [19,10,18,34,11,13],"breathiness": [34,0,18,19,4,59],"strain": [0,7,35,19,4,30],"pitch": [0,7,1,20,0,29],"loudness": [22,4,10,0,4,32],"g": [1,0,1,1,1,2],"r": [0,1,1,1,1,1],"b": [1,0,1,0,1,2],"a": [1,0,1,1,1,2],"s": [0,0,1,0,0,1],},
     {"database": "Walden (2022)","participantId": "PT136","sampleName": "PT136","sampleLink": "samples/PVQD-70dB/PT136_70dB.wav","age": 65,"gender": "male","diagnose": "unilateral_vocal_fold_paralysis","language": "english","overallSeverity": [95,56,84,95,67,92],"roughness": [95,72,83,80,51,91],"breathiness": [94,2,74,97,34,92],"strain": [0,39,6,0,38,86],"pitch": [44,42,3,0,64,63],"loudness": [45,9,34,60,13,44],"g": [3,2,3,3,2,3],"r": [3,2,3,3,2,3],"b": [3,0,2,3,1,3],"a": [0,0,2,2,0,2],"s": [0,2,1,0,2,3],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "PT137","sampleName": "PT137","sampleLink": "samples/PVQD-70dB/PT137_70dB.wav","age": 27,"gender": "male","diagnose": "muscle_tension_dysphonia","language": "english","overallSeverity": [26,15,9,25,2,11],"roughness": [26,1,15,20,2,20],"breathiness": [26,10,1,17,3,4],"strain": [0,0,9,0,9,1],"pitch": [26,5,1,21,22,6],"loudness": [18,0,1,20,2,5],"g": [1,1,1,1,0,1],"r": [1,0,1,1,1,1],"b": [1,1,0,1,0,0],"a": [0,1,0,0,1,1],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ1001","sampleName": "SJ1001","sampleLink": "samples/PVQD-70dB/SJ1001_70dB.wav","age": 41,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,14,0,9,5,1],"roughness": [0,7,0,10,5,1],"breathiness": [0,0,0,10,2,1],"strain": [0,11,0,0,22,1],"pitch": [0,3,0,0,1,2],"loudness": [0,10,0,0,1,2],"g": [0,0,0,1,0,0],"r": [0,0,0,1,0,0],"b": [0,0,0,1,0,0],"a": [0,0,0,0,0,0],"s": [0,1,0,0,1,0],},
     {"database": "Walden (2022)","participantId": "SJ1002","sampleName": "SJ1002","sampleLink": "samples/PVQD-70dB/SJ1002_70dB.wav","age": 77,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [22,24,16,30,11,10],"roughness": [19,0,11,19,11,9],"breathiness": [20,13,6,27,38,5],"strain": [0,0,14,23,11,1],"pitch": [0,0,1,0,12,1],"loudness": [0,5,2,0,12,1],"g": [1,1,1,1,1,1],"r": [1,0,0,1,0,0],"b": [1,1,0,1,1,0],"a": [0,1,0,0,1,1],"s": [0,0,1,0,1,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ1003","sampleName": "SJ1003","sampleLink": "samples/PVQD-70dB/SJ1003_70dB.wav","age": 64,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,33,1,14,0,13],"roughness": [0,0,1,4,7,9],"breathiness": [0,11,1,0,1,1],"strain": [0,0,9,15,0,6],"pitch": [0,2,1,0,0,1],"loudness": [0,2,1,0,0,1],"g": [0,0,0,1,0,1],"r": [0,0,0,0,1,1],"b": [0,1,0,0,0,0],"a": [0,1,0,0,1,0],"s": [0,0,0,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ1004","sampleName": "SJ1004","sampleLink": "samples/PVQD-70dB/SJ1004_70dB.wav","age": 69,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [14,2,34,16,33,15],"roughness": [1,12,43,0,28,35],"breathiness": [1,0,46,0,2,0],"strain": [14,21,34,16,10,11],"pitch": [23,6,1,15,14,41],"loudness": [1,2,1,0,14,0],"g": [0,0,0,1,1,1],"r": [0,1,2,0,1,1],"b": [0,0,1,0,0,0],"a": [0,0,0,0,0,0],"s": [0,1,1,1,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2001","sampleName": "SJ2001","sampleLink": "samples/PVQD-70dB/SJ2001_70dB.wav","age": 69,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,1,1,0,2,1],"roughness": [0,2,1,0,0,6],"breathiness": [0,3,1,0,0,1],"strain": [0,2,1,0,2,1],"pitch": [0,2,1,0,1,1],"loudness": [0,2,1,0,2,2],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2002","sampleName": "SJ2002","sampleLink": "samples/PVQD-70dB/SJ2002_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [28,26,2,19,1,13],"roughness": [15,7,15,8,15,19],"breathiness": [27,27,0,19,3,10],"strain": [0,9,0,0,4,20],"pitch": [0,28,0,0,3,7],"loudness": [23,29,0,17,6,9],"g": [1,1,0,1,0,1],"r": [0,1,1,0,1,1],"b": [1,1,0,0,0,1],"a": [1,2,0,0,0,1],"s": [0,1,0,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2003","sampleName": "SJ2003","sampleLink": "samples/PVQD-70dB/SJ2003_70dB.wav","age": 18,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [19,2,0,6,0,1],"roughness": [19,3,0,6,1,1],"breathiness": [19,2,0,6,5,1],"strain": [0,2,0,0,0,1],"pitch": [0,1,0,0,1,1],"loudness": [0,3,0,0,0,1],"g": [1,0,0,0,0,0],"r": [1,0,0,0,0,0],"b": [1,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2004","sampleName": "SJ2004","sampleLink": "samples/PVQD-70dB/SJ2004_70dB.wav","age": 22,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [9,0,1,8,5,1],"roughness": [9,9,1,8,6,4],"breathiness": [9,0,1,8,0,1],"strain": [0,0,1,0,21,1],"pitch": [0,0,1,0,0,2],"loudness": [0,0,1,0,0,2],"g": [0,0,0,0,0,0],"r": [0,1,0,0,1,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,1,0],},
     {"database": "Walden (2022)","participantId": "SJ2005","sampleName": "SJ2005","sampleLink": "samples/PVQD-70dB/SJ2005_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,0,1,0,0,1],"roughness": [0,10,1,0,13,1],"breathiness": [0,0,1,0,0,1],"strain": [0,0,1,0,0,1],"pitch": [0,0,1,0,0,1],"loudness": [0,0,1,0,0,1],"g": [0,0,0,0,0,0],"r": [0,1,0,0,1,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ2006","sampleName": "SJ2006","sampleLink": "samples/PVQD-70dB/SJ2006_70dB.wav","age": 62,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,2,1,0,19,0],"roughness": [0,2,1,0,0,0],"breathiness": [0,2,1,0,0,0],"strain": [0,8,1,0,0,0],"pitch": [0,2,1,0,0,0],"loudness": [0,2,1,0,2,0],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ2007","sampleName": "SJ2007","sampleLink": "samples/PVQD-70dB/SJ2007_70dB.wav","age": 23,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [35,14,11,27,12,27],"roughness": [31,3,12,20,0,5],"breathiness": [35,16,11,28,12,24],"strain": [0,1,1,10,0,2],"pitch": [0,3,1,0,6,11],"loudness": [18,0,1,0,0,34],"g": [2,0,0,1,1,1],"r": [1,0,0,1,0,0],"b": [2,1,1,1,1,1],"a": [0,0,1,0,1,2],"s": [0,0,0,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2008","sampleName": "SJ2008","sampleLink": "samples/PVQD-70dB/SJ2008_70dB.wav","age": 59,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [5,0,1,2,2,1],"roughness": [5,1,1,2,7,1],"breathiness": [0,0,1,0,2,1],"strain": [0,0,2,2,2,1],"pitch": [0,2,1,0,3,1],"loudness": [0,2,1,0,2,1],"g": [0,0,0,0,0,0],"r": [0,0,0,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ2009","sampleName": "SJ2009","sampleLink": "samples/PVQD-70dB/SJ2009_70dB.wav","age": 21,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,8,1,8,0,8],"roughness": [0,18,12,6,9,9],"breathiness": [0,0,1,0,2,1],"strain": [0,0,1,1,3,1],"pitch": [0,0,1,0,2,1],"loudness": [0,0,1,0,3,1],"g": [0,0,0,0,0,0],"r": [0,1,1,0,0,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2010","sampleName": "SJ2010","sampleLink": "samples/PVQD-70dB/SJ2010_70dB.wav","age": 57,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [20,13,1,13,0,0],"roughness": [7,13,1,0,10,0],"breathiness": [0,1,1,0,0,0],"strain": [17,42,1,12,18,40],"pitch": [0,7,1,0,2,0],"loudness": [0,9,1,0,4,18],"g": [1,1,0,0,1,1],"r": [0,1,0,0,1,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [1,2,0,0,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2011","sampleName": "SJ2011","sampleLink": "samples/PVQD-70dB/SJ2011_70dB.wav","age": 59,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [11,18,2,8,22,1],"roughness": [0,5,8,7,8,13],"breathiness": [0,8,1,0,1,1],"strain": [0,8,1,0,25,0],"pitch": [10,18,1,10,10,1],"loudness": [0,2,1,0,0,1],"g": [0,0,0,0,1,0],"r": [0,0,0,0,1,0],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2012","sampleName": "SJ2012","sampleLink": "samples/PVQD-70dB/SJ2012_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [11,7,0,0,0,0],"roughness": [11,17,8,0,4,23],"breathiness": [0,0,0,0,0,0],"strain": [0,2,0,0,0,0],"pitch": [0,1,0,1,0,0],"loudness": [0,2,0,1,0,0],"g": [0,1,0,0,0,0],"r": [0,1,0,0,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2013","sampleName": "SJ2013","sampleLink": "samples/PVQD-70dB/SJ2013_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [4,7,0,0,0,0],"roughness": [3,25,10,0,13,9],"breathiness": [0,0,0,0,1,0],"strain": [0,15,0,0,11,0],"pitch": [0,7,0,0,0,0],"loudness": [0,0,0,0,0,0],"g": [0,0,0,0,0,0],"r": [0,1,1,0,1,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,1,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2014","sampleName": "SJ2014","sampleLink": "samples/PVQD-70dB/SJ2014_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,2,10,4,1,9],"roughness": [0,2,11,4,12,13],"breathiness": [0,2,12,0,0,1],"strain": [0,7,8,0,0,1],"pitch": [0,0,1,0,1,1],"loudness": [0,2,1,0,2,1],"g": [0,0,0,0,0,0],"r": [0,0,1,0,1,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2015","sampleName": "SJ2015","sampleLink": "samples/PVQD-70dB/SJ2015_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [3,6,0,12,23,5,5,24],"roughness": [2,6,5,10,33,5,1,30],"breathiness": [0,6,0,0,2,6,0,14],"strain": [2,0,5,19,19,0,5,23],"pitch": [0,0,0,18,2,0,0,23],"loudness": [0,0,0,0,3,0,0,4],"g": [0,0,0,1,1,0,0,1],"r": [0,0,0,1,1,0,0,1],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,1,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2016","sampleName": "SJ2016","sampleLink": "samples/PVQD-70dB/SJ2016_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [40,15,14,30,46,30,10,30],"roughness": [29,5,10,31,42,21,5,30],"breathiness": [36,15,0,39,46,9,1,11],"strain": [14,0,13,40,21,30,12,37],"pitch": [0,0,0,1,2,15,1,29],"loudness": [0,0,0,1,2,0,4,18],"g": [1,1,0,2,1,1,0,1],"r": [1,0,0,2,1,1,0,1],"b": [1,1,0,1,2,0,0,0],"a": [0,0,0,1,1,0,0,0],"s": [0,0,0,2,1,1,1,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ2017","sampleName": "SJ2017","sampleLink": "samples/PVQD-70dB/SJ2017_70dB.wav","age": 22,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [19,12,6,20,32,25,6,12],"roughness": [20,13,5,32,31,25,3,12],"breathiness": [2,0,1,1,2,0,2,1],"strain": [24,0,1,19,30,26,5,12],"pitch": [0,0,1,1,2,0,5,1],"loudness": [0,0,1,1,0,18,2,19],"g": [1,1,0,1,1,1,0,0],"r": [1,1,0,1,1,1,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,0,0,1,1,1,0,0],},
     {"database": "Walden (2022)","participantId": "SJ30001","sampleName": "SJ30001","sampleLink": "samples/PVQD-70dB/SJ30001_70dB.wav","age": 26,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [63,50,10,41,65,51,20,41],"roughness": [64,49,11,41,67,51,20,41],"breathiness": [28,0,0,1,40,0,0,1],"strain": [33,25,3,41,58,34,13,41],"pitch": [0,0,0,1,1,14,4,1],"loudness": [0,0,0,1,0,0,6,1],"g": [2,2,0,2,2,2,1,2],"r": [2,2,0,2,2,2,1,2],"b": [1,0,0,0,1,0,0,0],"a": [0,0,0,0,1,0,0,0],"s": [1,1,0,2,2,2,1,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ30002","sampleName": "SJ30002","sampleLink": "samples/PVQD-70dB/SJ30002_70dB.wav","age": 20,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [38,18,6,15,38,16,5,12],"roughness": [37,18,6,17,38,16,5,12],"breathiness": [16,18,1,7,22,16,0,18],"strain": [0,0,8,12,14,0,0,13],"pitch": [0,0,1,1,0,17,0,1],"loudness": [0,0,1,1,0,7,0,1],"g": [1,1,0,1,1,1,0,1],"r": [1,1,0,1,1,1,0,1],"b": [1,1,0,0,1,1,0,1],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ30003","sampleName": "SJ30003","sampleLink": "samples/PVQD-70dB/SJ30003_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [40,15,3,17,50,15,2,11],"roughness": [39,15,0,19,58,15,1,23],"breathiness": [22,15,0,13,50,15,2,3],"strain": [24,0,2,11,25,0,0,5],"pitch": [3,7,0,0,0,5,0,1],"loudness": [1,0,0,0,0,0,0,1],"g": [1,1,0,1,1,1,0,0],"r": [2,1,0,1,1,1,0,0],"b": [1,1,0,1,2,1,0,0],"a": [1,0,0,0,0,0,0,0],"s": [1,0,0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ30004","sampleName": "SJ30004","sampleLink": "samples/PVQD-70dB/SJ30004_70dB.wav","age": 20,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [48,15,0,18,36,9,7,29],"roughness": [30,15,0,12,34,9,1,12],"breathiness": [42,15,0,19,34,9,0,21],"strain": [23,0,0,4,5,0,7,15],"pitch": [2,0,0,13,4,0,0,19],"loudness": [2,16,0,1,4,0,0,7],"g": [1,1,0,0,1,0,0,1],"r": [1,1,0,0,1,0,0,1],"b": [2,0,0,1,1,0,0,1],"a": [0,1,0,0,0,0,0,0],"s": [1,0,0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ30005","sampleName": "SJ30005","sampleLink": "samples/PVQD-70dB/SJ30005_70dB.wav","age": 20,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [28,11,8,22,17,7,0,1],"roughness": [28,12,0,22,22,0,0,1],"breathiness": [10,0,8,7,0,0,0,1],"strain": [12,0,0,22,26,7,0,1],"pitch": [0,0,0,1,0,0,0,1],"loudness": [0,0,0,16,0,0,0,11],"g": [1,1,0,1,1,0,0,0],"r": [1,1,0,1,1,0,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,1,1,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ30006","sampleName": "SJ30006","sampleLink": "samples/PVQD-70dB/SJ30006_70dB.wav","age": 18,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [31,32,0,21,55,7,0,25],"roughness": [30,0,0,25,37,8,0,1],"breathiness": [12,0,0,1,37,5,0,0],"strain": [25,33,0,24,34,0,0,41],"pitch": [0,0,0,0,2,0,0,0],"loudness": [0,0,0,0,2,0,0,0],"g": [1,2,0,0,2,1,0,1],"r": [1,0,0,0,1,1,0,0],"b": [0,0,0,0,1,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [0,2,0,1,1,0,0,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ30007","sampleName": "SJ30007","sampleLink": "samples/PVQD-70dB/SJ30007_70dB.wav","age": 19,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [20,24,5,33,27,28,0,40],"roughness": [20,10,5,33,21,0,0,19],"breathiness": [21,26,2,27,37,29,1,40],"strain": [0,0,2,24,13,10,1,1],"pitch": [0,0,5,37,0,0,5,1],"loudness": [0,26,1,23,0,29,1,1],"g": [1,1,0,1,1,1,0,1],"r": [1,1,0,1,1,0,0,1],"b": [0,1,0,0,1,1,0,1],"a": [1,0,0,0,0,1,0,1],"s": [0,0,0,1,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ30008","sampleName": "SJ30008","sampleLink": "samples/PVQD-70dB/SJ30008_70dB.wav","age": 19,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [28,7,7,12,23,13,0,12],"roughness": [30,0,0,1,10,8,0,18],"breathiness": [16,8,3,0,11,14,0,0],"strain": [11,0,8,12,20,0,0,12],"pitch": [0,0,0,1,0,0,0,0],"loudness": [0,10,0,12,0,0,0,0],"g": [1,0,0,0,1,1,0,1],"r": [1,0,0,0,1,1,0,1],"b": [1,0,0,0,0,1,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,0,0,0,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ4001","sampleName": "SJ4001","sampleLink": "samples/PVQD-70dB/SJ4001_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [15,6,3,11,0,4,1,25],"roughness": [13,6,4,11,0,4,1,17],"breathiness": [0,6,0,1,0,0,0,19],"strain": [0,0,0,0,0,0,0,12],"pitch": [1,0,0,1,0,0,0,1],"loudness": [1,0,0,10,0,0,0,1],"g": [0,0,0,0,0,0,0,0],"r": [0,0,0,1,0,0,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ4002","sampleName": "SJ4002","sampleLink": "samples/PVQD-70dB/SJ4002_70dB.wav","age": 20,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [15,24,4,15,11,36,3,2],"roughness": [15,25,4,14,7,36,1,5],"breathiness": [0,0,0,1,0,17,0,0],"strain": [10,19,5,13,15,18,4,0],"pitch": [0,0,0,1,0,0,0,0],"loudness": [1,0,0,1,0,26,0,0],"g": [0,1,0,1,1,1,0,0],"r": [0,1,0,1,1,1,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,1,0,0],"s": [0,1,0,0,1,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ4003","sampleName": "SJ4003","sampleLink": "samples/PVQD-70dB/SJ4003_70dB.wav","age": 53,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [34,23,4,23,49,9,0,8],"roughness": [34,0,3,2,21,0,1,0],"breathiness": [18,25,8,23,50,10,3,11],"strain": [35,0,0,6,35,0,1,0],"pitch": [19,8,0,1,50,0,1,0],"loudness": [23,13,0,16,51,0,0,0],"g": [1,1,0,1,2,0,0,0],"r": [1,0,0,0,1,0,0,0],"b": [1,1,0,1,2,0,0,1],"a": [1,1,0,1,1,0,0,0],"s": [1,0,0,0,1,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ4004","sampleName": "SJ4004","sampleLink": "samples/PVQD-70dB/SJ4004_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [15,14,3,12,25,5,2,10],"roughness": [10,14,2,12,25,5,1,9],"breathiness": [12,15,1,16,19,0,0,15],"strain": [13,0,1,8,9,0,3,1],"pitch": [0,15,1,1,0,6,0,1],"loudness": [0,0,1,1,0,0,0,4],"g": [0,1,0,1,1,0,0,0],"r": [0,1,0,1,1,0,0,0],"b": [0,1,0,1,0,0,0,1],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,0,1,0,0,0],},
    // EXCLUDED? CANVIA DE POSICIÓ RESPECTE EL MICRÒFON {"database": "Walden (2022)","participantId": "SJ4005","sampleName": "SJ4005","sampleLink": "samples/PVQD-70dB/SJ4005_70dB.wav","age": 51,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [52,38,4,38,32,46,1,38],"roughness": [36,38,4,39,13,46,2,27],"breathiness": [14,0,12,0,0,0,0,23],"strain": [51,23,13,1,35,24,3,1],"pitch": [0,0,5,75,0,0,0,77],"loudness": [0,0,0,10,32,0,0,20],"g": [2,1,0,2,1,1,0,2],"r": [1,1,0,2,1,1,0,1],"b": [0,0,0,0,0,0,0,1],"a": [0,0,0,0,0,1,0,0],"s": [2,0,0,0,1,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ4006","sampleName": "SJ4006","sampleLink": "samples/PVQD-70dB/SJ4006_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [34,33,3,24,29,6,3,40],"roughness": [26,34,0,25,23,0,0,30],"breathiness": [32,13,5,24,26,7,4,30],"strain": [0,0,1,25,0,0,0,40],"pitch": [0,0,0,12,0,0,0,22],"loudness": [0,0,0,1,0,0,0,41],"g": [1,1,0,1,1,0,0,1],"r": [1,1,0,1,1,0,0,1],"b": [1,0,0,1,0,0,0,1],"a": [0,0,0,1,0,0,0,1],"s": [0,0,0,1,0,0,0,2],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ4007","sampleName": "SJ4007","sampleLink": "samples/PVQD-70dB/SJ4007_70dB.wav","age": 21,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [24,20,1,18,21,53,2,18],"roughness": [26,20,2,1,26,39,2,18],"breathiness": [11,0,0,17,3,14,0,19],"strain": [12,4,1,12,26,0,0,1],"pitch": [0,0,0,1,0,0,0,1],"loudness": [0,0,0,39,0,55,0,16],"g": [1,1,0,1,1,2,0,1],"r": [1,1,0,0,1,1,0,1],"b": [0,0,0,1,0,1,0,1],"a": [0,0,0,1,0,2,0,0],"s": [1,0,0,0,1,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ4008","sampleName": "SJ4008","sampleLink": "samples/PVQD-70dB/SJ4008_70dB.wav","age": 19,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [24,27,4,11,19,0,5,39],"roughness": [24,0,1,1,21,0,2,24],"breathiness": [24,28,4,13,30,0,1,40],"strain": [10,16,4,12,12,0,4,17],"pitch": [0,0,0,0,0,0,0,1],"loudness": [0,0,0,1,0,0,0,1],"g": [1,1,0,1,1,0,0,1],"r": [1,0,0,0,1,0,0,1],"b": [1,1,0,1,1,0,0,1],"a": [0,0,0,0,0,0,0,0],"s": [1,1,0,1,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ4009","sampleName": "SJ4009","sampleLink": "samples/PVQD-70dB/SJ4009_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [10,42,11,25,7,27,7,27],"roughness": [3,5,11,11,22,10,1,11],"breathiness": [24,43,11,32,14,28,7,41],"strain": [0,0,1,2,3,0,1,0],"pitch": [0,0,5,26,3,0,0,17],"loudness": [0,25,1,12,3,9,0,1],"g": [0,2,0,1,0,1,0,1],"r": [0,0,0,1,0,0,0,1],"b": [1,2,0,2,0,1,0,2],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5001","sampleName": "SJ5001","sampleLink": "samples/PVQD-70dB/SJ5001_70dB.wav","age": 20,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [24,44,17,47,52,41,19,25],"roughness": [17,13,14,24,12,0,20,6],"breathiness": [29,44,20,47,66,42,21,35],"strain": [13,0,12,1,12,0,6,1],"pitch": [2,22,0,1,2,0,4,1],"loudness": [17,22,0,1,2,26,2,15],"g": [1,2,1,2,1,1,1,1],"r": [0,1,0,1,0,0,1,0],"b": [1,2,1,2,2,1,1,1],"a": [0,1,0,0,0,1,0,0],"s": [0,0,0,0,1,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5002","sampleName": "SJ5002","sampleLink": "samples/PVQD-70dB/SJ5002_70dB.wav","age": 20,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [28,45,24,32,23,26,10,39],"roughness": [26,32,18,32,26,27,9,27],"breathiness": [24,32,25,32,30,8,9,28],"strain": [12,15,10,2,11,20,0,1],"pitch": [0,47,18,24,0,29,4,1],"loudness": [0,12,8,33,0,28,0,1],"g": [1,2,1,2,1,1,0,2],"r": [1,2,1,1,1,1,0,2],"b": [1,1,1,1,1,0,0,2],"a": [0,0,0,2,0,0,0,0],"s": [0,1,0,0,0,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5003","sampleName": "SJ5003","sampleLink": "samples/PVQD-70dB/SJ5003_70dB.wav","age": 20,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [13,9,11,2,6,11,1,4],"roughness": [16,0,0,2,0,0,0,3],"breathiness": [0,9,11,2,6,11,10,5],"strain": [0,0,0,0,0,0,0,1],"pitch": [0,0,0,0,0,0,0,1],"loudness": [0,0,0,0,0,0,0,1],"g": [1,0,0,0,0,0,0,0],"r": [0,0,0,0,0,0,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5004","sampleName": "SJ5004","sampleLink": "samples/PVQD-70dB/SJ5004_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [18,11,1,1,3,14,3,10],"roughness": [18,0,1,10,6,13,1,0],"breathiness": [7,0,0,1,0,0,0,9],"strain": [19,12,0,1,2,0,0,9],"pitch": [0,0,0,1,0,0,4,0],"loudness": [0,0,0,1,0,0,1,0],"g": [1,1,0,1,0,1,0,0],"r": [1,0,0,1,0,1,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,1,0,1,0,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5005","sampleName": "SJ5005","sampleLink": "samples/PVQD-70dB/SJ5005_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [10,18,11,10,7,11,4,25],"roughness": [16,18,7,10,7,11,4,4],"breathiness": [3,18,1,10,6,11,0,34],"strain": [19,0,12,1,0,0,3,0],"pitch": [0,10,1,1,0,0,4,0],"loudness": [0,1,0,1,0,0,0,33],"g": [1,1,0,0,0,0,0,1],"r": [1,1,0,0,0,0,0,0],"b": [0,1,0,0,0,0,0,1],"a": [0,0,0,0,0,0,0,1],"s": [1,0,0,0,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ5006","sampleName": "SJ5006","sampleLink": "samples/PVQD-70dB/SJ5006_70dB.wav","age": 24,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [24,24,5,21,33,24,22,25],"roughness": [13,6,4,15,15,8,22,24],"breathiness": [0,25,1,0,0,26,1,12],"strain": [21,0,6,30,50,0,21,41],"pitch": [0,0,4,1,0,27,1,13],"loudness": [0,26,0,16,0,10,1,25],"g": [1,1,0,1,1,1,1,1],"r": [0,0,0,1,1,0,1,2],"b": [0,1,0,0,0,1,0,0],"a": [0,1,0,0,0,0,0,1],"s": [1,0,0,1,1,0,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5007","sampleName": "SJ5007","sampleLink": "samples/PVQD-70dB/SJ5007_70dB.wav","age": 20,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [14,3,7,11,6,6,1,6],"roughness": [17,4,8,11,2,0,0,5],"breathiness": [10,0,1,10,0,7,0,8],"strain": [9,0,4,1,9,0,2,1],"pitch": [0,0,0,1,0,0,0,0],"loudness": [0,0,0,1,0,0,0,15],"g": [1,0,0,0,0,0,0,0],"r": [0,0,0,0,0,0,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,0,0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5008","sampleName": "SJ5008","sampleLink": "samples/PVQD-70dB/SJ5008_70dB.wav","age": 22,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,9,0,2,0,5,3,24],"roughness": [0,0,0,3,0,0,3,4],"breathiness": [0,10,0,6,0,7,4,24],"strain": [0,0,0,0,0,0,0,12],"pitch": [0,0,0,0,0,0,0,1],"loudness": [0,4,0,0,0,0,0,1],"g": [0,0,0,0,0,0,0,1],"r": [0,0,0,0,0,0,0,0],"b": [0,0,0,0,0,0,0,1],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5009","sampleName": "SJ5009","sampleLink": "samples/PVQD-70dB/SJ5009_70dB.wav","age": 63,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [19,5,4,1,43,6,1,3],"roughness": [19,5,1,10,52,0,5,11],"breathiness": [14,0,3,10,2,0,0,0],"strain": [38,0,1,1,58,7,1,0],"pitch": [0,0,1,24,0,8,0,19],"loudness": [0,0,0,1,0,8,0,0],"g": [1,0,0,0,2,0,0,0],"r": [1,0,0,0,2,0,0,1],"b": [1,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,0,0,0,1,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5010","sampleName": "SJ5010","sampleLink": "samples/PVQD-70dB/SJ5010_70dB.wav","age": 20,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [19,11,0,12,32,11,3,13],"roughness": [19,12,2,11,44,12,3,12],"breathiness": [11,0,0,1,0,0,0,1],"strain": [32,0,3,11,42,0,2,25],"pitch": [0,7,0,0,0,4,0,1],"loudness": [0,7,0,0,0,0,0,1],"g": [1,1,0,1,1,0,0,0],"r": [1,1,0,1,1,0,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,0,0,1,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5011","sampleName": "SJ5011","sampleLink": "samples/PVQD-70dB/SJ5011_70dB.wav","age": 20,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [39,63,14,39,42,45,16,40],"roughness": [29,24,7,31,26,18,16,28],"breathiness": [36,0,0,35,33,0,1,5],"strain": [31,64,15,16,18,46,15,40],"pitch": [15,65,0,1,0,46,1,1],"loudness": [2,65,0,25,0,46,1,1],"g": [1,2,0,2,1,2,1,1],"r": [1,1,0,1,1,1,1,2],"b": [1,0,0,1,1,0,0,0],"a": [1,0,0,0,0,2,0,0],"s": [1,2,0,0,1,2,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5012","sampleName": "SJ5012","sampleLink": "samples/PVQD-70dB/SJ5012_70dB.wav","age": 20,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [30,20,32,32,22,42,24,40],"roughness": [31,0,32,30,25,0,26,7],"breathiness": [21,22,14,39,13,31,8,36],"strain": [3,0,10,31,5,43,21,33],"pitch": [0,0,7,27,0,0,12,1],"loudness": [0,0,0,1,0,11,5,1],"g": [1,1,1,1,1,2,1,1],"r": [1,0,1,0,1,0,1,0],"b": [1,1,1,1,0,1,0,2],"a": [0,0,0,0,0,0,0,0],"s": [0,0,1,1,0,2,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5014","sampleName": "SJ5014","sampleLink": "samples/PVQD-70dB/SJ5014_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [27,24,2,14,24,12,12,11],"roughness": [27,9,1,9,30,12,11,11],"breathiness": [5,25,1,12,32,12,0,0],"strain": [29,0,5,0,0,0,12,1],"pitch": [1,8,2,0,0,0,5,5],"loudness": [0,8,1,12,0,0,4,0],"g": [1,1,0,1,1,1,0,0],"r": [1,1,0,1,1,1,0,1],"b": [0,1,0,1,1,1,0,0],"a": [0,1,0,1,0,0,0,0],"s": [1,0,0,0,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5015","sampleName": "SJ5015","sampleLink": "samples/PVQD-70dB/SJ5015_70dB.wav","age": 21,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [25,8,0,5,17,22,11,32],"roughness": [20,8,0,7,17,22,3,32],"breathiness": [28,0,0,0,2,0,5,5],"strain": [8,0,0,1,12,9,10,24],"pitch": [0,0,0,0,0,0,18,61],"loudness": [0,0,0,0,0,0,8,10],"g": [1,0,0,0,0,1,0,1],"r": [1,0,0,0,0,1,0,1],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,0,0,1,0,1],},
     {"database": "Walden (2022)","participantId": "SJ5016","sampleName": "SJ5016","sampleLink": "samples/PVQD-70dB/SJ5016_70dB.wav","age": 20,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [38,42,7,31,28,27,4,30],"roughness": [25,42,5,30,15,27,4,30],"breathiness": [2,0,7,12,0,0,0,9],"strain": [51,0,1,19,31,0,0,1],"pitch": [2,0,7,42,0,28,9,6],"loudness": [0,13,1,27,0,12,2,18],"g": [1,2,0,1,1,1,0,1],"r": [1,2,0,1,1,1,0,1],"b": [0,0,0,0,0,0,0,0],"a": [0,1,0,0,0,0,0,1],"s": [1,0,0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ5017","sampleName": "SJ5017","sampleLink": "samples/PVQD-70dB/SJ5017_70dB.wav","age": 25,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [32,8,6,17,26,9,9,14],"roughness": [33,8,7,24,30,9,5,11],"breathiness": [3,8,1,11,0,0,0,0],"strain": [37,0,7,1,27,0,8,0],"pitch": [0,0,8,1,0,0,0,0],"loudness": [0,0,1,1,0,0,0,6],"g": [1,1,0,1,1,0,0,0],"r": [1,1,0,1,1,0,0,0],"b": [0,1,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,0,0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ5018","sampleName": "SJ5018","sampleLink": "samples/PVQD-70dB/SJ5018_70dB.wav","age": 21,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [9,15,4,1,12,20,0,10],"roughness": [3,0,0,1,3,0,0,1],"breathiness": [3,16,7,12,17,21,0,10],"strain": [11,0,0,1,22,0,2,0],"pitch": [0,0,4,1,0,0,5,0],"loudness": [0,10,0,1,0,0,1,12],"g": [0,1,0,0,1,1,0,0],"r": [0,0,0,0,0,0,0,0],"b": [0,1,0,1,0,1,0,1],"a": [0,1,0,0,0,0,0,0],"s": [0,0,0,0,1,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5019","sampleName": "SJ5019","sampleLink": "samples/PVQD-70dB/SJ5019_70dB.wav","age": 21,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [21,29,9,11,24,11,13,27],"roughness": [21,29,1,24,9,0,0,11],"breathiness": [0,9,1,24,0,12,0,25],"strain": [20,0,8,24,34,0,13,25],"pitch": [1,0,1,10,0,0,0,1],"loudness": [0,19,0,1,0,12,0,1],"g": [1,1,0,1,1,1,0,1],"r": [1,1,0,1,0,0,0,0],"b": [0,0,0,1,0,0,0,1],"a": [0,0,0,0,0,1,0,0],"s": [1,0,0,1,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5020","sampleName": "SJ5020","sampleLink": "samples/PVQD-70dB/SJ5020_70dB.wav","age": 52,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [31,15,0,3,10,19,0,12],"roughness": [18,0,0,2,8,0,0,11],"breathiness": [14,0,0,3,0,0,0,1],"strain": [32,5,0,0,5,20,0,11],"pitch": [0,0,0,0,0,0,0,1],"loudness": [1,15,0,4,0,21,0,24],"g": [1,0,0,0,0,1,0,0],"r": [1,0,0,0,0,0,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,0,0,0,0,1,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ5021","sampleName": "SJ5021","sampleLink": "samples/PVQD-70dB/SJ5021_70dB.wav","age": 37,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [22,16,1,30,33,13,8,18],"roughness": [25,17,1,24,33,13,3,18],"breathiness": [0,17,0,11,0,0,8,10],"strain": [7,17,5,32,29,0,0,15],"pitch": [36,0,1,0,21,0,0,0],"loudness": [0,0,1,0,0,0,0,10],"g": [1,1,0,1,1,1,0,1],"r": [0,1,0,1,1,1,0,1],"b": [0,1,0,0,0,0,0,0],"a": [0,0,0,0,0,1,0,1],"s": [1,1,0,1,1,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ6001","sampleName": "SJ6001","sampleLink": "samples/PVQD-70dB/SJ6001_70dB.wav","age": 19,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [17,8,4,23,14,9,3,23],"roughness": [16,0,6,23,12,0,2,1],"breathiness": [12,9,0,23,28,9,0,26],"strain": [14,0,0,11,0,0,0,13],"pitch": [2,0,0,1,0,0,8,26],"loudness": [2,0,0,1,0,0,0,1],"g": [1,0,0,0,1,0,0,0],"r": [1,0,0,0,1,0,0,0],"b": [0,0,0,1,1,0,0,1],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,0,1,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ6002","sampleName": "SJ6002","sampleLink": "samples/PVQD-70dB/SJ6002_70dB.wav","age": 18,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [9,0,7,24,6,11,6,1],"roughness": [9,0,0,23,0,0,3,10],"breathiness": [0,0,0,1,0,0,0,1],"strain": [7,0,8,10,6,12,7,10],"pitch": [0,0,0,23,0,0,0,24],"loudness": [0,0,0,1,0,0,0,24],"g": [0,0,0,1,0,1,0,0],"r": [0,0,0,1,0,0,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [0,0,0,0,0,1,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ6003","sampleName": "SJ6003","sampleLink": "samples/PVQD-70dB/SJ6003_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [13,14,8,13,12,7,1,12],"roughness": [10,10,4,13,0,8,5,12],"breathiness": [4,0,0,0,0,0,0,5],"strain": [15,16,9,15,12,0,5,8],"pitch": [0,0,1,12,0,0,0,1],"loudness": [0,0,1,0,0,0,0,13],"g": [1,1,0,1,0,0,0,0],"r": [0,1,0,1,0,0,0,1],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,1,0,1,0,0,0,0],},
     {"database": "Walden (2022)","participantId": "SJ6004","sampleName": "SJ6004","sampleLink": "samples/PVQD-70dB/SJ6004_70dB.wav","age": 83,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [56,16,20,40,52,34,10,33],"roughness": [56,7,14,17,55,34,4,23],"breathiness": [60,17,10,6,26,0,1,22],"strain": [51,0,20,28,62,0,9,31],"pitch": [15,18,9,1,22,0,1,1],"loudness": [16,0,0,28,34,0,1,40],"g": [2,1,1,1,2,1,0,1],"r": [2,1,1,1,2,1,0,1],"b": [0,0,0,0,1,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [2,1,1,1,2,0,0,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ6005","sampleName": "SJ6005","sampleLink": "samples/PVQD-70dB/SJ6005_70dB.wav","age": 58,"gender": "male","diagnose": "no_voice_complaints","language": "english","overallSeverity": [54,54,42,35,58,42,37,44],"roughness": [47,55,42,41,56,43,33,40],"breathiness": [55,22,25,29,17,0,25,46],"strain": [31,28,30,34,60,14,39,24],"pitch": [9,28,10,6,0,0,17,24],"loudness": [2,15,4,0,0,0,1,1],"g": [2,2,1,2,2,2,2,2],"r": [2,2,1,2,2,2,2,2],"b": [1,1,0,2,1,0,1,2],"a": [0,0,0,1,0,1,0,0],"s": [2,1,1,2,2,1,1,1],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ6006","sampleName": "SJ6006","sampleLink": "samples/PVQD-70dB/SJ6006_70dB.wav","age": 17,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [14,0,12,23,10,0,6,11],"roughness": [15,0,1,0,11,0,0,3],"breathiness": [15,0,1,0,12,0,6,1],"strain": [19,0,13,9,9,0,4,1],"pitch": [0,0,1,0,0,0,0,1],"loudness": [0,0,0,18,0,0,1,22],"g": [0,0,0,0,0,0,0,0],"r": [0,0,0,0,0,0,0,0],"b": [0,0,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [0,0,1,0,1,0,0,0],},
    // EXCLUDED: Fluctuacions de volum degudes a la gravació {"database": "Walden (2022)","participantId": "SJ6007","sampleName": "SJ6007","sampleLink": "samples/PVQD-70dB/SJ6007_70dB.wav","age": 17,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [38,20,10,27,29,13,6,25],"roughness": [48,21,9,21,26,13,6,11],"breathiness": [27,0,1,26,0,7,1,11],"strain": [41,0,6,1,18,0,8,24],"pitch": [0,0,0,1,0,0,0,1],"loudness": [1,0,0,7,0,0,0,1],"g": [2,1,0,1,1,1,0,1],"r": [1,1,0,1,1,1,0,1],"b": [1,0,0,1,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,0,0,0,1,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ6008","sampleName": "SJ6008","sampleLink": "samples/PVQD-70dB/SJ6008_70dB.wav","age": 17,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [27,11,2,10,15,6,5,8],"roughness": [30,0,1,10,14,0,9,1],"breathiness": [18,13,3,11,13,7,11,9],"strain": [15,0,3,11,7,0,4,0],"pitch": [0,0,0,0,0,0,4,0],"loudness": [0,0,0,0,0,0,1,6],"g": [1,1,0,1,1,0,0,0],"r": [1,0,0,0,1,0,0,0],"b": [1,1,0,0,0,0,0,0],"a": [0,0,0,0,0,0,0,0],"s": [1,0,0,1,0,0,0,0],},
    // 2ND PHASE? {"database": "Walden (2022)","participantId": "SJ7001","sampleName": "SJ7001","sampleLink": "samples/PVQD-70dB/SJ7001_70dB.wav","age": 21,"gender": "female","diagnose": "no_voice_complaints","language": "english","overallSeverity": [0,0,1,0,0,1],"roughness": [0,5,5,0,0,8],"breathiness": [0,0,1,0,0,1],"strain": [0,0,1,0,3,1],"pitch": [0,0,1,0,0,1],"loudness": [0,0,1,0,0,1],"g": [0,0,0,0,0,0],"r": [0,1,0,0,0,1],"b": [0,0,0,0,0,0],"a": [0,0,0,0,0,0],"s": [0,0,0,0,0,0],},

];
    export default feedbackDataOriginalPVQD;