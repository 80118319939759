// StageFour.jsx

import React from 'react';

const StageFour = ({ results, onReset, t }) => {
    // Function to format results into a string and copy them to the clipboard
    const handleCopyResults = () => {
        const resultsString = Object.entries(results.subcategoryValues).map(
            ([key, value]) => `${t(key)}: ${value}`  // Use the translation function on each key
        ).join('\n');
        navigator.clipboard.writeText(resultsString)
            .then(() => {
                alert(t("ratings_copied_to_clipboard"));
            })
                .catch(err => {
            console.error(t("error_copying_to_clipboard"), err);
        });
    };

    return (
        <div>
            <h2 className="centered-text">{t('stage')} 4. {t('your_ratings')}</h2>
            <div className="centered-text">
                {Object.entries(results.subcategoryValues).map(([key, value]) => (
                    <p key={key}>{t(key)}: {value}/100</p>  // Apply translation to each key here too
                ))}
            </div>
            <div className="button-container">
                <button onClick={handleCopyResults} className="button-center">{t('copy_to_clipboard')}</button>
            </div>
        </div>
    );
};

export default StageFour;