// OSTraining.jsx

// En aquest codi es separen les lògiques per guardar dades per a la recerca i per compartir 
// avaluacions. `dataForResearch` s'utilitza per emmagatzemar dades en un format més estructurat 
// per a la recerca, mentre que `evaluationData` es manté en el format original per assegurar 
// que els mecanismes de feedback continuïn funcionant. Aquesta separació permet una transició 
// més suau cap a una organització de dades més eficient i mantenible. 
// Quan hi ha consentiment de l'usuari per compartir, les mateixes dades `dataForResearch` 
// s'emmagatzemen paral·lelament en una nova col·lecció anomenada `sharing_data`, a l'espera 
// de ser utilitzada en els nous mecanismes de feedback que es programin.

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ConsentModal from '../../auth-and-backend/modals/ConsentModal';
import OSForm from './OSForm';
import OSFeedback from './OSFeedback';
import feedbackDataOriginalPVQD from './../../feedback-data/feedbackDataOriginalPVQD';
import NavigationIcons from './../../views/app-views/NavigationIcons';
import WaldenCitation from './../../shared-files/WaldenCitation';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

export function calculateAge(birthDate, evaluationDate = new Date()) {
  const birth = new Date(birthDate);
  let age = evaluationDate.getFullYear() - birth.getFullYear();
  const monthDiff = evaluationDate.getMonth() - birth.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && evaluationDate.getDate() < birth.getDate())) {
      age--;
  }
  return age;
}

function OSTraining() {
  const { t, i18n } = useTranslation();
  const [randomSample, setRandomSample] = useState(null);
  const [formData, setFormData] = useState(null);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [sharedRatingsData, setSharedRatingsData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [age, setAge] = useState(null);
  const [pronoun, setPronoun] = useState("");
  const [countryOfResidence, setCountryOfResidence] = useState("");
  const [communicationLanguage, setCommunicationLanguage] = useState("");
  const [profession, setProfession] = useState("");
  const [voiceCompetenceLevel, setVoiceCompetenceLevel] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const [userWantsToShareEvaluations, setUserWantsToShareEvaluations] = useState(false);
  const [evaluationData, setEvaluationData] = useState(null);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * feedbackDataOriginalPVQD.length);
    setRandomSample(feedbackDataOriginalPVQD[randomIndex]);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.get(`${apiUrl}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        const user = response.data;
        setUserId(user._id);
        setPronoun(user.pronoun);
        setCountryOfResidence(user.countryOfResidence);
        setCommunicationLanguage(user.communicationLanguage);
        setProfession(user.profession);
        setVoiceCompetenceLevel(user.voiceCompetenceLevel);

        const evaluationDate = new Date();
        const calculatedAge = calculateAge(user.dateOfBirth, evaluationDate);
        setAge(calculatedAge);

        if ((user.voiceCompetenceLevel === 'advanced' || user.voiceCompetenceLevel === 'expert') && user.wantsToShareEvaluations) {
          setUserWantsToShareEvaluations(true);
          setConsentGiven(true); 
        }
      }).catch(error => {
        console.error('Error fetching user data:', error);
      });
    }
  }, []);

  useEffect(() => {
    if (randomSample) {
      axios.get(`${apiUrl}/shared-evaluations/sample/${randomSample.sampleName}`)
        .then(response => {
          setSharedRatingsData(response.data);
        })
        .catch(error => {
          console.error('Error fetching shared evaluations:', error);
        });
    }
  }, [randomSample]);

  const mergeData = (originalData, sharedData) => {
    return originalData.map(original => {
      const shared = sharedData.find(s => s.sampleName === original.sampleName);
      return { ...original, ...shared };
    });
  };

  const mergedFeedbackData = mergeData(feedbackDataOriginalPVQD, sharedRatingsData);

  const currentFeedbackData = randomSample ? mergedFeedbackData.filter((data) => data.sampleName === randomSample.sampleName) : [];

  const handleSubmit = async (formData) => {
    const updatedFormData = {
      ...formData,
      sampleName: randomSample.sampleName
    };
    
    // Dades per a recerca
    const researchData = {
      sociodemographicData: {
        age,
        pronoun,
        countryOfResidence,
        communicationLanguage,
        profession,
        voiceCompetenceLevel,
      },
      perceptualData: {
        overallSeverity: {
          value: formData.overallSeverity,
          variability: 'n/a',
        },
        g: formData.g,  // Nova valoració 0-3 afegida per a recerca
      }
    };

    // Dades per a compartir avaluacions
    const evaluationData = {
      overallSeverity: {
        value: formData.overallSeverity,
        variability: 'n/a',
      },
      g: formData.g,  // Nova valoració 0-3 afegida per compartir avaluacions
      age,
      pronoun,
      countryOfResidence,
      communicationLanguage,
      profession,
      voiceCompetenceLevel,
    };

    setFormData(updatedFormData);

    const token = localStorage.getItem('token');

    try {
      if (token) {
        const response = await axios.get(`${apiUrl}/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const user = response.data;

        if (user.acceptResearchUsage) {
          await axios.post(`${apiUrl}/research`, {
            userId: user._id,
            evaluationType: 'OS',
            dataForResearch: researchData,
            sampleName: randomSample.sampleName,
          });
          console.log('Dades de recerca guardades correctament');
        } else {
          console.log('L\'usuari no ha donat el seu consentiment per a la recerca. No es guarden dades de recerca.');
        }

        if (userWantsToShareEvaluations) {
          setModalIsOpen(true);
          setEvaluationData(evaluationData);  // Guarda evaluationData a un estat temporal
          return;
        }
      } else {
        console.log('Usuari no autenticat. No es guarden dades de recerca.');
      }

      setFeedbackVisible(true);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error guardant les dades de recerca:', error);
    }
  };

  const handleConsentSubmit = async () => {
    if (consentGiven && evaluationData) {  // Assegura't que evaluationData està definit
      try {
        // Primera operació: enviar evaluationData
        await axios.post(`${apiUrl}/shared-evaluations`, {
          userId: userId,
          evaluationType: 'OS',
          evaluationData: evaluationData,  // Utilitza evaluationData aquí
          sampleName: randomSample.sampleName,
        });
        console.log('Avaluació compartida correctament');

        // Segona operació: enviar dataForResearch
        await axios.post(`${apiUrl}/sharing`, {
          userId: userId,
          evaluationType: 'OS',  
          dataForResearch: evaluationData,  // Utilitza evaluationData aquí
          sampleName: randomSample.sampleName
        });
        console.log('Dades pels propers feedbacks que es programin compartides correctament');

      } catch (error) {
        console.error('Error compartint les dades:', error);
      }
    } else {
      console.log('L\'usuari ha decidit no compartir l\'avaluació.');
    }

    setModalIsOpen(false);
    setFeedbackVisible(true);
    window.scrollTo(0, 0);
  };

  const handleRestart = () => {
    const randomIndex = Math.floor(Math.random() * feedbackDataOriginalPVQD.length);
    setRandomSample(feedbackDataOriginalPVQD[randomIndex]);
    setFormData(null);
    setFeedbackVisible(false);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <h1>{t('training_app_title')}</h1>
      <h2>{t('overall_severity')}</h2>
      {randomSample && (
        <div className="audio-container">
          <audio controls src={randomSample.sampleLink}>
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
      {!feedbackVisible && (
        <>
          <p>{t('os_training_introduction')}</p>
          {i18n.language !== 'en' && <p>{t('note_language')}</p>}
          <OSForm onSubmit={handleSubmit} sampleData={randomSample}/>
        </>
      )}
      {feedbackVisible && (
        <div className="feedback-screen">
          <p>{t('os_feedback_introduction')}</p>
          <OSFeedback feedbackData={currentFeedbackData} formData={formData} t={t} />
          <div className="button-container">
            <button onClick={handleRestart} className="navigation-button">{t('restart_with_another_sample')}</button>
          </div>
        </div>
      )}
      <br/>
      <WaldenCitation />
      <div className="navigation-container">
                <NavigationIcons />
      </div>
      <ConsentModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        onConsentChange={setConsentGiven}
        consentGiven={consentGiven}
        onSubmit={handleConsentSubmit}
      />
    </div>
  );
}

export default OSTraining;