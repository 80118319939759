// EARV.jsx

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import StageOne from './StageOne';
import StageTwo from './StageTwo';
import StageThree from './StageThree';
import StageFour from './StageFour';
import AudioUploader from '../../shared-files/tools-and-functions/AudioUploader';
import feedbackDataThesisConcatenatedBilingual from '../../feedback-data/feedbackDataThesisConcatenatedBilingual';

class EARV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStage: 1,
            totalStages: 4,
            results: {
                selections: {
                    overall: false,
                    pitch: false,
                    loudness: false,
                    voice_quality_phonation_features: false,
                    voice_quality_vocal_tract_features: false,
                    length: false,
                },
                subcategoryValues: {}  // Initialize this to ensure it's ready for updates
            },
            // file: null, // Commented out until it's needed
            randomSample: null,
            selectedSampleIndex: null  // New state to track the selected sample index
        };
    }

    componentDidMount() {
        this.selectRandomSample();
    }    

    selectRandomSample = (index = null) => {
        // If no index provided, select a random one
        if (index === null) {
            const randomIndex = Math.floor(Math.random() * feedbackDataThesisConcatenatedBilingual.length);
            index = randomIndex;
        }
        const sample = feedbackDataThesisConcatenatedBilingual[index];
        this.setState({ randomSample: sample, selectedSampleIndex: index });
    }
    
    handleSampleChange = (event) => {
        const index = event.target.value;
        this.selectRandomSample(index);
    }    

    handleFileUpload = (uploadedFile) => {
    }    

    goToNextStage = () => {
        this.setState(prevState => ({
            currentStage: Math.min(prevState.currentStage + 1, prevState.totalStages)
        }));
    };
    
    goToPreviousStage = () => {
        this.setState(prevState => ({
            currentStage: Math.max(1, prevState.currentStage - 1)
        }));
    };

    updateSelections = (newSelections) => {
        this.setState(prevState => ({
            results: {
                ...prevState.results,
                selections: {
                    ...prevState.results.selections,
                    ...newSelections
                }
            }
        }));
    };

    updateSubcategoryValues = (newValues) => {
        this.setState(prevState => ({
            results: {
                ...prevState.results,
                subcategoryValues: {
                    ...prevState.results.subcategoryValues,
                    ...newValues
                }
            }
        }));
    };
    
    handleReset = () => {
        this.setState({
            currentStage: 1,
            results: {
                selections: {
                    overall: false,
                    pitch: false,
                    loudness: false,
                    voice_quality_phonation_features: false,
                    voice_quality_vocal_tract_features: false,
                    length: false,
                },
                subcategoryValues: {}
            },
        }, () => {
            this.selectRandomSample();  // No index provided, so a random sample will be selected
        });
    };    

    renderStage = () => {
        const { currentStage, results } = this.state;
        const { t } = this.props;

        switch(currentStage) {
            case 1:
                return <StageOne onUpdateSelections={this.updateSelections} selections={results.selections} t={t} />;
            case 2:
                return <StageTwo previousResults={results} onUpdateSelections={this.updateSelections} selections={results.selections} t={t} />;
            case 3:
                return <StageThree previousResults={results} sliderValues={results.subcategoryValues} onUpdateSubcategoryValues={this.updateSubcategoryValues} t={t} />;
            case 4:
                return <StageFour results={results} onReset={this.handleReset} t={t}/>;
            default:
                return <div>Final Results: {JSON.stringify(results)}</div>;
        }
    };

    render() {
        const { t } = this.props;
        const { randomSample, currentStage, totalStages, selectedSampleIndex } = this.state;
    
        return (
            <div className="earv-container">
                <h1>{t('ear_v')} (EAR-V)</h1>
                <p>{t('lorem_ipsum')}</p>
                <div className="media-controls">
                    <div>
                        <select
                            value={selectedSampleIndex || ''}
                            onChange={this.handleSampleChange}
                            className="sample-selector" // Potser voldràs afegir estils específics per aquest selector
                        >
                            <option value="">{t('select_a_sample')}</option>
                            {feedbackDataThesisConcatenatedBilingual.map((sample, index) => (
                                <option key={index} value={index}>
                                    Sample {index + 1} - {sample.name}
                                </option>
                            ))}
                        </select>
                    </div>
    
                    {randomSample && (
                        <div className="audio-container">
                            <audio controls src={randomSample.sampleLink}>
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    )}
    
                    <div className="audio-uploader-fixed">
                        <AudioUploader onFileUpload={this.handleFileUpload} />
                    </div>
                </div>
                {this.renderStage()}
    
                <div className="navigation-button-container">
                    {currentStage > 1 && (
                        <button onClick={this.goToPreviousStage} className="navigation-button">{t('previous')}</button>
                    )}
                    {currentStage < totalStages && (
                        <button onClick={this.goToNextStage} className="navigation-button">{t('next')}</button>
                    )}
                </div>
                <div className="navigation-button-container">
                <button onClick={this.handleReset} className="button-center">{t('restart')}</button>
                </div>
            </div>
        );
    }    
}

export default withTranslation()(EARV);