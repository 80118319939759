// AdminDashboard.jsx

import React from 'react'; // useState eliminat

const AdminDashboard = () => {
  return (
    <div>
      <h2>Admin Dashboard</h2>
      {/* Contingut de l'Admin Dashboard */}
    </div>
  );
};

export default AdminDashboard;