// ResourcesForTeaching.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './../../locales/i18n';
import NavigationIcons from './../app-views/NavigationIcons';
import LoginModal from './../../auth-and-backend/modals/LoginModal';
import SignUpModal from './../../auth-and-backend/modals/SignUpModal';

function ResourcesForTeaching() {
    const { t } = useTranslation();
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

    return (
        <div className="main-content">
            <h1>{t('resources_for_teaching_and_learning_title')}</h1>
            <p>{t('resources_for_teaching_and_learning_presentation')}</p>

            {/* CAPE-V Workshop */}
            <p className="centered-text">{t('create_or_join_a_group_session_to_gather_compare_and_discuss_evaluations')}:</p>
            <Link to="/cape-v-workshop" className="button-link">{t('cape_v_workshop_title')}</Link><br/>

            {/* CAPE-V Classroom */}
            <p className="centered-text">{t('evaluate_instructor_s_selected_sample_and_get_feedback')}:</p>
            <Link to="/os-classroom" className="button-link">{t('overall_severity')} (0-3 & 0-100)<span className="new-indicator">{t('new')}</span></Link> <br/>
            <Link to="/cape-v-classroom" className="button-link">{t('cape_v_classroom_title')}</Link> <br/>
            
            {/* CAPE-V Scholar */}
            <p className="centered-text">{t('upload_and_evaluate_your_own_samples')}:</p>
            <Link to="/cape-v-scholar" className="button-link">{t('cape_v_scholar_title')}</Link><br/><br/>
    
            {/* Researck Link */}
            <p className="centered-text">{t('transform_your_classroom_insights_into_research_impact')}:</p>
            <Link to="/research-at-all-voiced" className="navigation-button">{t('research_at_allvoiced')}</Link><br/>

            <div className="navigation-container">
                <NavigationIcons exclude={['/resources-for-teaching-and-learning']} />
            </div>
    
            <LoginModal
                isOpen={isLoginModalOpen}
                onRequestClose={() => setIsLoginModalOpen(false)}
                openSignUpModal={() => { setIsLoginModalOpen(false); setIsSignUpModalOpen(true); }}
            />
            <SignUpModal
                isOpen={isSignUpModalOpen}
                onRequestClose={() => setIsSignUpModalOpen(false)}
                openLoginModal={() => { setIsSignUpModalOpen(false); setIsLoginModalOpen(true); }}
            />
        </div>
    );    
}

export default ResourcesForTeaching;
