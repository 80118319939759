// WaldenCitation.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const WaldenCitation = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // Llista inicial de modificacions
  let modifications = [];

  // Afegir descripcions específiques basades en la ruta
  if (location.pathname === "/anchors" || location.pathname === "/2024-asha-convention-anchors") {
    modifications.push(t('1500_sustained_a_extractions'));
  } else {
    // Afegir 'standardization_of_demographic_data' només si no estem en les rutes excloses
    modifications.push(t('standardization_of_demographic_data'));
  }

  // Normalització sempre aplicada, independent de la ruta
  modifications.push(t('normalization_to_70dB'));

  // Converteix l'array de modificacions en una cadena de text formatada
  const modificationsText = modifications.join(", ");

  return (
    <div className="cites">
      {t('samples_and_data_source')}:<br />
      Walden, P. R. (2022), “Perceptual Voice Qualities Database (PVQD)”, Mendeley Data, V4. <a href="https://doi.org/10.17632/9dz247gnyb.2" target="_blank" rel="noopener noreferrer">https://doi.org/10.17632/9dz247gnyb.2</a>. <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener noreferrer"><br/>CC BY 4.0</a> License. {t('modifications_applied')}: {modificationsText}.<br/><br/>
    </div>
  );
};

export default WaldenCitation;