// RenderStandardAttributes.jsx

import React, { useState, useEffect } from 'react';
import VASGraphs from './VASGraphs';

const RenderStandardAttributes = ({
  attributes,
  attributeMapping,
  userRatings,
  consensusSubmitted,
  consensusData,
  showSharedEvaluations,
  sharedRatings,
  t,
  transformPVQDEvaluations,
  pvqdEvaluations,
  calculateVariability,
  filterRatingsByDirection,
  calculateDirectionPercentage,
  calculateSharedEvaluationsVariability,
  filterSharedEvaluationsByDirection,
  calculateSharedEvaluationsDirectionPercentage,
  extractSharedRatings
}) => {

  const [showPVQDMessage, setShowPVQDMessage] = useState(false);

  useEffect(() => {
    // Comprova si hi ha dades de PVQD per a qualsevol atribut diferent de pitch i loudness
    const hasPVQDData = attributes.some(
      attribute =>
        !['pitch', 'loudness'].includes(attribute) &&
        transformPVQDEvaluations(pvqdEvaluations, attribute).length > 0
    );

    setShowPVQDMessage(hasPVQDData);
  }, [attributes, pvqdEvaluations, transformPVQDEvaluations]);

  return (
    <div>
      <h2>{t('standard_attributes_evaluations')}</h2>
      {attributes.map(attribute => {
        const attributeName = attributeMapping[attribute];
        const dataKey = attribute;

        // Comprovació de la validesa de les dades
        const validUserRatings = userRatings && userRatings.every(rating => rating && rating[dataKey]);
        const validConsensusData = consensusSubmitted && consensusData && consensusData[dataKey];
        const validSharedRatings = showSharedEvaluations && sharedRatings && sharedRatings.every(rating => rating && rating[dataKey]);

        if (!validUserRatings && !validConsensusData && !validSharedRatings) {
          return null;
        }

        const variabilities = validUserRatings ? userRatings.map(rating => rating[dataKey]?.variability || '') : [];
        const { consistentPercent, intermittentPercent } = calculateVariability(variabilities);
        const sharedVariability = validSharedRatings ? calculateSharedEvaluationsVariability(sharedRatings, dataKey) : {};
        const { consistentPercent: sharedConsistentPercent = 0, intermittentPercent: sharedIntermittentPercent = 0 } = sharedVariability;

        return (
          <div key={attributeName}>
            <h3>{attributeName}</h3>
            {validUserRatings && (
              <p className="small">
                {t('students_in_this_session')}: {t('C')} = {consistentPercent.toFixed(0)}%, {t('I')} = {intermittentPercent.toFixed(0)}%
              </p>
            )}
            {consensusSubmitted && validConsensusData && (
              <p className="small">
                {t('consensus_reached_in_this_session')}: {t(consensusData[dataKey]?.variability || '')}
              </p>
            )}
            {showSharedEvaluations && validSharedRatings && (
              <p className="small">
                {t('other_allvoiced_users_evaluations')}: {t('C')} = {sharedConsistentPercent.toFixed(0)}%, {t('I')} = {sharedIntermittentPercent.toFixed(0)}%
              </p>
            )}
            {dataKey === 'pitch' || dataKey === 'loudness' ? (
              <>
                {dataKey === 'pitch' && (
                  <>
                    <h4>{t('too_high')}</h4>
                    {validUserRatings && (
                      <p className="small">
                        {t('students_in_this_session')}: {calculateDirectionPercentage(userRatings, dataKey, 'tooHigh').toFixed(0)}%
                      </p>
                    )}
                    {consensusSubmitted && validConsensusData && (
                      <p className="small">
                        {t('consensus')}: {consensusData[dataKey]?.direction?.tooHigh ? t('yes') : t('no')}
                      </p>
                    )}
                    {showSharedEvaluations && validSharedRatings && (
                      <p className="small">
                        {t('other_allvoiced_users_evaluations')}: {calculateSharedEvaluationsDirectionPercentage(sharedRatings, dataKey, 'tooHigh').toFixed(0)}%
                      </p>
                    )}
                    <VASGraphs
                      attributeName={`${attributeName} ${t('too_high')}`}
                      attributeData={{
                        userRatings: validUserRatings ? filterRatingsByDirection(userRatings, dataKey, 'tooHigh') : [],
                        consensusRating: consensusSubmitted && validConsensusData && consensusData[dataKey]?.direction?.tooHigh ? consensusData[dataKey]?.value : null,
                        sharedRatings: validSharedRatings ? filterSharedEvaluationsByDirection(sharedRatings, dataKey, 'tooHigh') : [],
                      }}
                    />
                    <h4>{t('too_low')}</h4>
                    {validUserRatings && (
                      <p className="small">
                        {t('students_in_this_session')}: {calculateDirectionPercentage(userRatings, dataKey, 'tooLow').toFixed(0)}%
                      </p>
                    )}
                    {consensusSubmitted && validConsensusData && (
                      <p className="small">
                        {t('consensus')}: {consensusData[dataKey]?.direction?.tooLow ? t('yes') : t('no')}
                      </p>
                    )}
                    {showSharedEvaluations && validSharedRatings && (
                      <p className="small">
                        {t('other_allvoiced_users_evaluations')}: {calculateSharedEvaluationsDirectionPercentage(sharedRatings, dataKey, 'tooLow').toFixed(0)}%
                      </p>
                    )}
                    <VASGraphs
                      attributeName={`${attributeName} ${t('too_low')}`}
                      attributeData={{
                        userRatings: validUserRatings ? filterRatingsByDirection(userRatings, dataKey, 'tooLow') : [],
                        consensusRating: consensusSubmitted && validConsensusData && consensusData[dataKey]?.direction?.tooLow ? consensusData[dataKey]?.value : null,
                        sharedRatings: validSharedRatings ? filterSharedEvaluationsByDirection(sharedRatings, dataKey, 'tooLow') : [],
                      }}
                    />
                  </>
                )}
                {dataKey === 'loudness' && (
                  <>
                    <h4>{t('too_loud')}</h4>
                    {validUserRatings && (
                      <p className="small">
                        {t('students_in_this_session')}: {calculateDirectionPercentage(userRatings, dataKey, 'tooLoud').toFixed(0)}%
                      </p>
                    )}
                    {consensusSubmitted && validConsensusData && (
                      <p className="small">
                        {t('consensus')}: {consensusData[dataKey]?.direction?.tooLoud ? t('yes') : t('no')}
                      </p>
                    )}
                    {showSharedEvaluations && validSharedRatings && (
                      <p className="small">
                        {t('other_allvoiced_users_evaluations')}: {calculateSharedEvaluationsDirectionPercentage(sharedRatings, dataKey, 'tooLoud').toFixed(0)}%
                      </p>
                    )}
                    <VASGraphs
                      attributeName={`${attributeName} ${t('too_loud')}`}
                      attributeData={{
                        userRatings: validUserRatings ? filterRatingsByDirection(userRatings, dataKey, 'tooLoud') : [],
                        consensusRating: consensusSubmitted && validConsensusData && consensusData[dataKey]?.direction?.tooLoud ? consensusData[dataKey]?.value : null,
                        sharedRatings: validSharedRatings ? filterSharedEvaluationsByDirection(sharedRatings, dataKey, 'tooLoud') : [],
                      }}
                    />
                    <h4>{t('too_soft')}</h4>
                    {validUserRatings && (
                      <p className="small">
                        {t('students_in_this_session')}: {calculateDirectionPercentage(userRatings, dataKey, 'tooSoft').toFixed(0)}%
                      </p>
                    )}
                    {consensusSubmitted && validConsensusData && (
                      <p className="small">
                        {t('consensus')}: {consensusData[dataKey]?.direction?.tooSoft ? t('yes') : t('no')}
                      </p>
                    )}
                    {showSharedEvaluations && validSharedRatings && (
                      <p className="small">
                        {t('other_allvoiced_users_evaluations')}: {calculateSharedEvaluationsDirectionPercentage(sharedRatings, dataKey, 'tooSoft').toFixed(0)}%
                      </p>
                    )}
                    <VASGraphs
                      attributeName={`${attributeName} ${t('too_soft')}`}
                      attributeData={{
                        userRatings: validUserRatings ? filterRatingsByDirection(userRatings, dataKey, 'tooSoft') : [],
                        consensusRating: consensusSubmitted && validConsensusData && consensusData[dataKey]?.direction?.tooSoft ? consensusData[dataKey]?.value : null,
                        sharedRatings: validSharedRatings ? filterSharedEvaluationsByDirection(sharedRatings, dataKey, 'tooSoft') : [],
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <VASGraphs
                attributeName={attributeName}
                attributeData={{
                  userRatings: validUserRatings ? userRatings.map(rating => rating[dataKey]?.value) : [],
                  consensusRating: consensusSubmitted && validConsensusData ? consensusData[dataKey]?.value : null,
                  sharedRatings: validSharedRatings ? extractSharedRatings(sharedRatings, dataKey, null) : [],
                  pvqdEvaluations: transformPVQDEvaluations(pvqdEvaluations, dataKey)
                }}
              />
            )}
          </div>
        );
      })}
      {showPVQDMessage && (
        <p className="small">
          * {t('pvqd_data_not_shown_pitch_or_loudness')}
        </p>
      )}
    </div>
  );
};

export default RenderStandardAttributes;