// useSampleInitializer.js

import { useState, useCallback } from 'react';

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
}

const useSampleInitializer = (data, count) => {
    const [samples, setSamples] = useState([]);
    const [diagnoses, setDiagnoses] = useState([]);

    const initializeSamples = useCallback(() => {
        let shuffledSamples = shuffleArray([...data]);
        let uniqueSamples = [];
        let index = 0;

        while (uniqueSamples.length < count && index < shuffledSamples.length) {
            const sample = shuffledSamples[index];
            if (!uniqueSamples.find(s => s.diagnose === sample.diagnose)) {
                uniqueSamples.push(sample);
            }
            index++;
        }

        // If we run out of unique samples before reaching the desired count, we might need to handle it
        if (uniqueSamples.length < count) {
            console.error('Not enough unique diagnoses in the data to meet the requested count');
        }

        const extractedDiagnoses = uniqueSamples.map(sample => sample.diagnose);
        setSamples(uniqueSamples);
        setDiagnoses(shuffleArray(extractedDiagnoses)); // Shuffle diagnoses separately
    }, [data, count]); // Dependencies

    return { samples, diagnoses, initializeSamples };
};

export default useSampleInitializer;