// Ranking.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useAudioPlayer from '../shared-files/tools-and-functions/useAudioPlayer';

const Ranking = ({ evaluatedAttribute, userData, onConfirm }) => {
  const { t, ready } = useTranslation();
  const [sorts, setSorts] = useState([]);
  const { playAudio } = useAudioPlayer();

  useEffect(() => {
    if (!ready || !userData) return;
  
    const initialSorts = [{
      id: 'sort-0',
      name: 'Ranked Samples',
      items: userData.map((sample, index) => ({
        id: `item-${index}`,
        audioLink: sample.audioLink,
        sampleName: sample.sampleName
      }))
    }];
  
    setSorts(initialSorts);
  }, [ready, userData]);

  const onDragEndCustom = (result) => {
    // If dropped outside the droppable area, return early
    if (!result.destination) {
      return;
    }
  
    const { source, destination } = result;
  
    // If the item is dropped in the same position, return early
    if (source.index === destination.index) {
      return;
    }
  
    const newItems = Array.from(sorts[0]?.items); // Create a new array to avoid mutating the original array
    const [draggedItem] = newItems.splice(source.index, 1); // Remove the dragged item from the array
    newItems.splice(destination.index, 0, draggedItem); // Insert the dragged item at the new position
  
    const newSorts = [{ ...sorts[0], items: newItems }];
    setSorts(newSorts);
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm(sorts);
    console.log('sorts:', sorts);
  };

  if (!ready) {
    return <div>{t('loading')}</div>;
  }

  console.log('sorts:', sorts); // Log sorts for debugging

  return (
    <>
      <h2>{evaluatedAttribute}</h2>
      <p>{t('training_ranking_instructions')}</p>
      <DragDropContext onDragEnd={onDragEndCustom}>
        <Droppable droppableId={sorts[0]?.id || 'default-droppable-id'}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="droppable-area">
              {Array.isArray(sorts[0]?.items) && sorts[0]?.items.map((item, index) => (
                <Draggable draggableId={item.id} index={index} key={item.id}>
                  {(provided) => (
                    <div
                      className="item-container"
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      <button onClick={() => {
                        playAudio(item.audioLink); // Call playAudio function with the audioLink
                      }}>Play</button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <button onClick={handleConfirm}>{t('confirm_ranking')}</button>
    </>
  );  
};

export default Ranking;