// formatResultsForClipboard.js

export const formatResultsForCSV = (data, t) => {
  const allAdditionalResonances = ['hypernasality', 'hyponasality', 'other'];
  const allAdditionalFeatures = ['diplophonia', 'fry', 'falsetto', 'asthenia', 'aphonia', 'pitch_instability', 'tremor', 'wet_gurgly', 'other'];

  const additionalResonancesParts = allAdditionalResonances.map(resonance => {
    const detail = data.additionalResonances[resonance] || { value: 0, variability: 'NA' };
    const value = typeof detail.value === 'object' ? detail.value.value : detail.value;
    const resonanceLabel = resonance === 'other' && detail && detail.label ? detail.label : t(resonance);
    return `${t(resonanceLabel)},${value},${t(detail.variability)}`;
  });

  const additionalFeaturesParts = allAdditionalFeatures.map(feature => {
    const detail = data.additionalFeatures[feature] || { value: 0, variability: 'NA' };
    const value = typeof detail.value === 'object' ? detail.value.value : detail.value;
    const featureLabel = feature === 'other' && detail && detail.label ? detail.label : t(feature);
    return `${t(featureLabel)},${value},${t(detail.variability)}`;
  });

  const interpretDirection = (direction, context) => {
    let directionString = '';
    if (context === "pitch") {
      if (direction.tooHigh) directionString += t("too_high");
      if (direction.tooHigh && direction.tooLow) directionString += ", ";
      if (direction.tooLow) directionString += t("too_low");
    } else if (context === "loudness") {
      if (direction.tooLoud) directionString += t("too_loud");
      if (direction.tooLoud && direction.tooSoft) directionString += ", ";
      if (direction.tooSoft) directionString += t("too_soft");
    }
    return directionString || t("normal");
  };

  const parts = [
    `${t('sample')},${data.sampleName}`, 
    `${t('overall_severity')},${data.overallSeverity.value},${t(data.overallSeverity.variability)}`,
    `${t('roughness')},${data.roughness.value},${t(data.roughness.variability)}`,
    `${t('breathiness')},${data.breathiness.value},${t(data.breathiness.variability)}`,
    `${t('strain')},${data.strain.value},${t(data.strain.variability)}`,
    `${t('pitch')},${data.pitch.value},${interpretDirection(data.pitch.direction, "pitch")},${t(data.pitch.variability)}`,
    `${t('loudness')},${data.loudness.value},${interpretDirection(data.loudness.direction, "loudness")},${t(data.loudness.variability)}`
  ];

  const comments = [`${t('general_comments')},${data.comments}`];

  return [...parts, ...additionalResonancesParts, ...additionalFeaturesParts, ...comments].join(',');
};

export const formatResultsForReport = (data, t) => {
  let additionalResonancesResults = '';
  let additionalFeaturesResults = '';

  const interpretDirection = (direction, context) => {
    let directionString = '';
    if (context === "pitch") {
      if (direction.tooHigh) directionString += t('too_high');
      if (direction.tooHigh && direction.tooLow) directionString += `, `;
      if (direction.tooLow) directionString += t('too_low');
    } else if (context === "loudness") {
      if (direction.tooLoud) directionString += t('too_loud');
      if (direction.tooLoud && direction.tooSoft) directionString += `, `;
      if (direction.tooSoft) directionString += t('too_soft');
    }
    return directionString || t('normal');
  };

  if (data.additionalResonances) {
    Object.entries(data.additionalResonances).forEach(([key, detail]) => {
      const value = detail.value && typeof detail.value === 'object' ? detail.value.value : detail.value;
      if (value > 0) { // Només inclou si el valor és més gran que 0
        const label = key === 'other' && detail && detail.label ? detail.label : t(key);
        additionalResonancesResults += `${label}: ${value}/100 (${t(detail.variability)})\n`;
      }
    });
  }

  if (data.additionalFeatures) {
    Object.entries(data.additionalFeatures).forEach(([key, detail]) => {
      const value = detail.value && typeof detail.value === 'object' ? detail.value.value : detail.value;
      if (value > 0) { // Només inclou si el valor és més gran que 0
        const label = key === 'other' && detail && detail.label ? detail.label : t(key);
        additionalFeaturesResults += `${label}: ${value}/100 (${t(detail.variability)})\n`;
      }
    });
  }

  return {
    overallSeverity: `${data.overallSeverity.value}/100 (${t(data.overallSeverity.variability)})`,
    roughness: `${data.roughness.value}/100 (${t(data.roughness.variability)})`,
    breathiness: `${data.breathiness.value}/100 (${t(data.breathiness.variability)})`,
    strain: `${data.strain.value}/100 (${t(data.strain.variability)})`,
    pitch: `${data.pitch.value}/100, ${interpretDirection(data.pitch.direction, "pitch")} (${t(data.pitch.variability)})`,
    loudness: `${data.loudness.value}/100, ${interpretDirection(data.loudness.direction, "loudness")} (${t(data.loudness.variability)})`,
    additionalResonancesResults: additionalResonancesResults || t('none'),
    additionalFeaturesResults: additionalFeaturesResults || t('none'),
    comments: data.comments || t('none')
  };
};