// CAPEVFormItself.jsx

import React from 'react';
import VocalAttributeRatingInput from './VocalAttributeRatingInput';
import AdditionalResonances from './AdditionalResonances';
import AdditionalFeatures from './AdditionalFeatures';
import { useTranslation } from 'react-i18next';

function CAPEVFormItself({
  overallSeverity, setOverallSeverity,
  roughness, setRoughness,
  breathiness, setBreathiness,
  strain, setStrain,
  pitch, setPitch,
  loudness, setLoudness,
  additionalResonances, setAdditionalResonances,
  additionalFeatures, setAdditionalFeatures,
  comments, setComments,
  isNormalSelected,
  handleAdditionalResonanceChange,
  handleAdditionalFeatureChange
}) {
  const { t } = useTranslation();

  return (
    <div>
      <VocalAttributeRatingInput
        label={t('overall_severity')}
        value={overallSeverity}
        setValue={setOverallSeverity}
        index={1}
      />
      <VocalAttributeRatingInput
        label={t('roughness')}
        value={roughness}
        setValue={setRoughness}
        index={2}
      />
      <VocalAttributeRatingInput
        label={t('breathiness')}
        value={breathiness}
        setValue={setBreathiness}
        index={3}
      />
      <VocalAttributeRatingInput
        label={t('strain')}
        value={strain}
        setValue={setStrain}
        index={4}
      />
      <VocalAttributeRatingInput
        label={t('pitch')}
        value={pitch}
        setValue={setPitch}
        directionLabel1={t('too_high')} directionLabel2={t('too_low')}
        index={5}
      />

      <VocalAttributeRatingInput
        label={t('loudness')}
        value={loudness}
        setValue={setLoudness}
        directionLabel1={t('too_loud')} directionLabel2={t('too_soft')}
        index={6}
      />
      {Object.keys(additionalResonances.resonances).map((resonance, index) => (
        resonance !== 'normal_resonance' && (
          <VocalAttributeRatingInput
            key={resonance}
            label={resonance === 'other' ? additionalResonances.text : t(resonance)}
            value={additionalResonances.resonances[resonance]}
            setValue={(updatedValue) => {
              setAdditionalResonances((prevResonances) => ({
                ...prevResonances,
                resonances: {
                  ...prevResonances.resonances,
                  [resonance]: updatedValue,
                },
              }));
            }}
            index={7 + index}
          />
        )
      ))}
      {Object.keys(additionalFeatures.features).map((feature, index) => (
        <VocalAttributeRatingInput
          key={feature}
          label={feature === 'other' ? additionalFeatures.text : t(feature)}
          value={additionalFeatures.features[feature]}
          setValue={(updatedValue) => {
            setAdditionalFeatures((prevFeatures) => ({
              ...prevFeatures,
              features: {
                ...prevFeatures.features,
                [feature]: updatedValue,
              },
            }));
          }}
          index={7 + index}
        />
      ))}
      <br />
      <AdditionalResonances
        value={additionalResonances}
        onChange={handleAdditionalResonanceChange}
      />
      <br />
      <AdditionalFeatures
        value={additionalFeatures}
        onChange={handleAdditionalFeatureChange}
      />
      <br />
      <div className="button-container">
        <label className="centered-text"><strong>{t('general_comments')}</strong></label>
        <textarea
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          placeholder={t('add_your_comments_here')}
          rows="4"
          cols="50"
          className="form-control"
        ></textarea>
      </div>
    </div>
  );
}

export default CAPEVFormItself;