// createRandomPairs.js

function createRandomPairs(samples) {
    let pairs = [];
    for (let i = 0; i < samples.length; i++) {
      for (let j = i + 1; j < samples.length; j++) {
        pairs.push([samples[i], samples[j]]);
      }
    }
    // Shuffle the pairs to randomize the order
    pairs = shuffleArray(pairs);
    return pairs;
  }
  
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  export default createRandomPairs
  