// SessionDetails.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';

const SessionDetails = ({ session, onRefresh }) => {
  const { t } = useTranslation();
  const evaluationsSubmittedCount = session.results.length;

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>{t('student_name')}</th>
            <th>{t('evaluation_submitted')}</th>
          </tr>
        </thead>
        <tbody>
          {session.students.map((student, index) => (
            <tr key={index}>
              <td>{student}</td>
              <td>{session.results.some(result => result.student === student) ? t('yes') : t('no')}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>{t('students_connected')}: {session.students.length}</p>
      <p>{t('evaluations_submitted')}: {evaluationsSubmittedCount}</p>
    </div>
  );
};

export default SessionDetails;