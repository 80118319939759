// Anchors.jsx

import React, { useState, useEffect } from 'react';
import Collection from './Collection';
import { useTranslation } from 'react-i18next';
import anchorsData from './anchorsData';
import WaldenCitation from '../../shared-files/WaldenCitation';
import { Link } from 'react-router-dom';

const ASHAConventionAnchors = () => {
  const { t } = useTranslation();
  const [selectedCollectionNumber, setSelectedCollectionNumber] = useState('');
  
  // Initialize selectedMarks with consistent, language-independent keys
  const [selectedMarks, setSelectedMarks] = useState({ 
    roughness: null, 
    breathiness: null, 
    strain: null 
  });

  useEffect(() => {
    // Reset selected marks to null when the selected collection changes
    setSelectedMarks({ roughness: null, breathiness: null, strain: null });
  }, [selectedCollectionNumber]);

  const handleCollectionChange = (event) => {
    const value = event.target.value;
    setSelectedCollectionNumber(value === '' ? null : parseInt(value.split("-")[0].trim()));
  };

  // Adjusted to use consistent keys
  const handleMarkSelection = (key, value) => {
    setSelectedMarks(prevMarks => ({
      ...prevMarks,
      [key]: prevMarks[key] === value ? null : value, // Toggle selection
    }));
  };

  const selectedCollection = anchorsData.find(c => c.number === selectedCollectionNumber);

  return (
    <div className="app-container">
      <h1>{t('anchors_title')}</h1>
      <h1 class="red-title">{t('preliminary_results')}</h1>
      <div className="centered-select">
        <select onChange={handleCollectionChange} value={selectedCollectionNumber || ''}>
          <option value="">{t('select_a_collection')}</option>
          {anchorsData.map((collection, index) => (
            <option key={index} value={`${collection.number} - ${t(collection.frequencyRange)}`}>
              {t('collection')} {collection.number} - {t(collection.frequencyRange)}
            </option>
          ))}
        </select>
        <br />
      </div>

      {/* Render the selected Collection */}
      {selectedCollection && (
        <Collection
          {...selectedCollection}
          selectedMarks={selectedMarks}
          onMarkSelection={handleMarkSelection}
        />
      )}
      <br />
      <div className="main-content">
        <Link to="/join-anchors" target="_blank" className="button-link">{t('contribute_to_future_perceptual_anchors')}<span className="new-indicator">{t('new')}</span></Link>
      </div>
      <br/>
      <div className="cites">
        Based on the preliminary results reported in:<br />Calaf, N., & Garcia-Quintana, D. (2024). Establishing consensus anchors for enhanced auditory-perceptual voice evaluation: A methodological framework. 2024 American Speech-Language-Hearing Association (ASHA) Convention, Seattle, WA.<br /> 
        <br />
        <WaldenCitation />
      </div> 
      <br /> 
      <br /> 
      <br />
    </div>
  );
};

export default ASHAConventionAnchors;