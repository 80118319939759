// VocalAttributeRatingInput.jsx

import DirectionDeviation from './DirectionDeviation';
import React from 'react';
import { useTranslation } from 'react-i18next';

function VocalAttributeRatingInput({
    label,
    value = { value: 0, variability: 'consistent' },  
    setValue,
    index,
    directionLabel1,
    directionLabel2,
}) {
    const sliderId = `slider-${label}-${index}`;
    const consistentId = `consistent-${label}-${index}`;
    const intermittentId = `intermittent-${label}-${index}`;
    const { t } = useTranslation();  

    const onSliderChange = (event) => {
        setValue({ ...value, value: parseInt(event.target.value, 10) });
    }

    const onRadioChange = (event) => {
        setValue({ ...value, variability: event.target.value });
    }

    // Comprovem si s'ha seleccionat alguna direcció
    const isPitchDirectionSelected = value?.direction?.tooHigh || value?.direction?.tooLow;
    const isLoudnessDirectionSelected = value?.direction?.tooLoud || value?.direction?.tooSoft;
    
    const handleDirectionChange = (direction) => {
        // Si cap direcció està seleccionada, restablir el valor del slider a zero
        const isDirectionSelected = 
            (label === t('pitch') && (direction.tooHigh || direction.tooLow)) || 
            (label === t('loudness') && (direction.tooLoud || direction.tooSoft));
        
        setValue({
            ...value,
            direction,
            value: isDirectionSelected ? value.value : 0 // Si no hi ha direcció seleccionada, valor = 0
        });
    };

    const getOptionalDirectDeviation = () => {
        if (label === t('pitch')) {
            return (
                <DirectionDeviation
                    directionLabel1={t('too_high')}
                    directionLabel2={t('too_low')}
                    value={value.direction || { tooHigh: false, tooLow: false }}
                    setValue={handleDirectionChange}
                />
            );
        }
    
        if (label === t('loudness')) {
            return (
                <DirectionDeviation
                    directionLabel1={t('too_loud')}
                    directionLabel2={t('too_soft')}
                    value={value.direction || { tooLoud: false, tooSoft: false }}
                    setValue={handleDirectionChange}
                />
            );
        }
    
        return null;
    };

    return (
        <div className="VocalAttributeRatingInput">
            {getOptionalDirectDeviation()}
            <div className="VocalAttributeRatingInput-row">
                <div className="VocalAttributeRatingInput-col VocalAttributeRatingInput-col-left">
                    <label htmlFor={sliderId}>{label}</label>
                </div>
                <div className="VocalAttributeRatingInput-col VocalAttributeRatingInput-col-center">
                    <input
                        type="range"
                        id={sliderId}
                        name={sliderId}
                        min="0"
                        max="100"
                        value={value.value || 0}
                        style={{ pointerEvents: "auto" }}
                        disabled={
                          (label === t('pitch') && !isPitchDirectionSelected) ||
                          (label === t('loudness') && !isLoudnessDirectionSelected)
                        }
                        onChange={onSliderChange}
                    />
                </div>
                <div className="VocalAttributeRatingInput-col VocalAttributeRatingInput-col-right">
                    <div className="VocalAttributeRatingInput-radio">
                        <input type="radio" id={consistentId} name={label} value="consistent" onChange={onRadioChange} checked={value.variability === 'consistent'} />
                        <label htmlFor={consistentId}>{t('consistent')}</label>
                        <input type="radio" id={intermittentId} name={label} value="intermittent" onChange={onRadioChange} checked={value.variability === 'intermittent'} />
                        <label htmlFor={intermittentId}>{t('intermittent')}</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VocalAttributeRatingInput;