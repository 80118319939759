// RatingView.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import RatingDetails from '../../cape-v-shared-files/RatingDetails';

const RatingView = ({ results, onBack, sessionCode, studentName }) => {
  const { t } = useTranslation();

  return (
    <div>
        <h1>{t('cape_v_workshop_student_view')}</h1>
        <div className="results">
          <h2>{t('your_evaluations')}</h2>
          <h4>{t('session_code')}: {sessionCode}</h4> 
          <h4>{t('student_name')}: {studentName}</h4> 
          <RatingDetails results={results} />
          <br/>
          <div className="button-container">
            <button className="button-center" onClick={onBack}>{t('join_another_session')}</button>
          </div>
        </div>
    </div>
  );
};

export default RatingView;
