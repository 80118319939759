// useAudioPlayer.js

const useAudioPlayer = () => {

  const playAudio = (audioLink) => {
    console.log(audioLink)
    const uniqueAudioLink = audioLink + '?t=' + new Date().getTime();
    const newAudio = new Audio(uniqueAudioLink);
    newAudio.play().catch(e => console.error("Error playing audio:", e));
  };

  return { playAudio };
};

export default useAudioPlayer;
