// pathologyDataOriginalPVQD.js

const pathologyDataOriginalPVQD = [

    {"database": "Walden (2022)","participantId": "PT003","sampleName": "PT003","sampleLink": "samples/PVQD-70dB/PT003_70dB.wav","age": "24","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT006","sampleName": "PT006","sampleLink": "samples/PVQD-70dB/PT006_70dB.wav","age": "54","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT007","sampleName": "PT007","sampleLink": "samples/PVQD-70dB/PT007_70dB.wav","age": "36","gender": "female","diagnose": "laryngeal_granuloma",},
    {"database": "Walden (2022)","participantId": "PT008","sampleName": "PT008","sampleLink": "samples/PVQD-70dB/PT008_70dB.wav","age": "71","gender": "female","diagnose": "reinke_edema",},
    {"database": "Walden (2022)","participantId": "PT010","sampleName": "PT010","sampleLink": "samples/PVQD-70dB/PT010_70dB.wav","age": "72","gender": "female","diagnose": "muscle_tension_dysphonia_atrophy_unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT012","sampleName": "PT012","sampleLink": "samples/PVQD-70dB/PT012_70dB.wav","age": "38","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT013","sampleName": "PT013","sampleLink": "samples/PVQD-70dB/PT013_70dB.wav","age": "61","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT014","sampleName": "PT014","sampleLink": "samples/PVQD-70dB/PT014_70dB.wav","age": "65","gender": "female","diagnose": "reinke_edema",},
    {"database": "Walden (2022)","participantId": "PT016","sampleName": "PT016","sampleLink": "samples/PVQD-70dB/PT016_70dB.wav","age": "64","gender": "female","diagnose": "paradoxical_vocal_fold_movement",},
    {"database": "Walden (2022)","participantId": "PT017","sampleName": "PT017","sampleLink": "samples/PVQD-70dB/PT017_70dB.wav","age": "64","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT020","sampleName": "PT020","sampleLink": "samples/PVQD-70dB/PT020_70dB.wav","age": "80","gender": "female","diagnose": "unilateral_vocal_fold_paralysis_atrophy",},
    {"database": "Walden (2022)","participantId": "PT022","sampleName": "PT022","sampleLink": "samples/PVQD-70dB/PT022_70dB.wav","age": "67","gender": "female","diagnose": "atrophy_unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT025","sampleName": "PT025","sampleLink": "samples/PVQD-70dB/PT025_70dB.wav","age": "66","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT026","sampleName": "PT026","sampleLink": "samples/PVQD-70dB/PT026_70dB.wav","age": "41","gender": "female","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT027","sampleName": "PT027","sampleLink": "samples/PVQD-70dB/PT027_70dB.wav","age": "35","gender": "female","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT029","sampleName": "PT029","sampleLink": "samples/PVQD-70dB/PT029_70dB.wav","age": "77","gender": "female","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT032","sampleName": "PT032","sampleLink": "samples/PVQD-70dB/PT032_70dB.wav","age": "46","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT033","sampleName": "PT033","sampleLink": "samples/PVQD-70dB/PT033_70dB.wav","age": "90","gender": "female","diagnose": "unilateral_vocal_fold_paralysis_atrophy",},
    {"database": "Walden (2022)","participantId": "PT034","sampleName": "PT034","sampleLink": "samples/PVQD-70dB/PT034_70dB.wav","age": "80","gender": "female","diagnose": "unilateral_vocal_fold_paralysis_atrophy",},
    {"database": "Walden (2022)","participantId": "PT036","sampleName": "PT036","sampleLink": "samples/PVQD-70dB/PT036_70dB.wav","age": "64","gender": "female","diagnose": "tremor",},
    {"database": "Walden (2022)","participantId": "PT038","sampleName": "PT038","sampleLink": "samples/PVQD-70dB/PT038_70dB.wav","age": "59","gender": "female","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT040","sampleName": "PT040","sampleLink": "samples/PVQD-70dB/PT040_70dB.wav","age": "17","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT041","sampleName": "PT041","sampleLink": "samples/PVQD-70dB/PT041_70dB.wav","age": "68","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT042","sampleName": "PT042","sampleLink": "samples/PVQD-70dB/PT042_70dB.wav","age": "57","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT043","sampleName": "PT043","sampleLink": "samples/PVQD-70dB/PT043_70dB.wav","age": "43","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT044","sampleName": "PT044","sampleLink": "samples/PVQD-70dB/PT044_70dB.wav","age": "57","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT046","sampleName": "PT046","sampleLink": "samples/PVQD-70dB/PT046_70dB.wav","age": "65","gender": "female","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT047","sampleName": "PT047","sampleLink": "samples/PVQD-70dB/PT047_70dB.wav","age": "78","gender": "female","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT052","sampleName": "PT052","sampleLink": "samples/PVQD-70dB/PT052_70dB.wav","age": "15","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT053","sampleName": "PT053","sampleLink": "samples/PVQD-70dB/PT053_70dB.wav","age": "47","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT054","sampleName": "PT054","sampleLink": "samples/PVQD-70dB/PT054_70dB.wav","age": "61","gender": "female","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT057","sampleName": "PT057","sampleLink": "samples/PVQD-70dB/PT057_70dB.wav","age": "69","gender": "female","diagnose": "atrophy_muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT058","sampleName": "PT058","sampleLink": "samples/PVQD-70dB/PT058_70dB.wav","age": "70","gender": "female","diagnose": "atrophy_muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT070","sampleName": "PT070","sampleLink": "samples/PVQD-70dB/PT070_70dB.wav","age": "37","gender": "female","diagnose": "laryngeal_granuloma",},
    {"database": "Walden (2022)","participantId": "PT072","sampleName": "PT072","sampleLink": "samples/PVQD-70dB/PT072_70dB.wav","age": "16","gender": "female","diagnose": "vocal_fold_polyp",},
    {"database": "Walden (2022)","participantId": "PT073","sampleName": "PT073","sampleLink": "samples/PVQD-70dB/PT073_70dB.wav","age": "47","gender": "female","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT074","sampleName": "PT074","sampleLink": "samples/PVQD-70dB/PT074_70dB.wav","age": "51","gender": "female","diagnose": "candida_laryngitis",},
    {"database": "Walden (2022)","participantId": "PT075","sampleName": "PT075","sampleLink": "samples/PVQD-70dB/PT075_70dB.wav","age": "25","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT076","sampleName": "PT076","sampleLink": "samples/PVQD-70dB/PT076_70dB.wav","age": "42","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT077","sampleName": "PT077","sampleLink": "samples/PVQD-70dB/PT077_70dB.wav","age": "68","gender": "female","diagnose": "unilateral_vocal_fold_paralysis_reinke_edema",},
    {"database": "Walden (2022)","participantId": "PT078","sampleName": "PT078","sampleLink": "samples/PVQD-70dB/PT078_70dB.wav","age": "54","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT079","sampleName": "PT079","sampleLink": "samples/PVQD-70dB/PT079_70dB.wav","age": "64","gender": "female","diagnose": "vocal_fold_scar",},
    {"database": "Walden (2022)","participantId": "PT082","sampleName": "PT082","sampleLink": "samples/PVQD-70dB/PT082_70dB.wav","age": "69","gender": "female","diagnose": "ulcerative_laryngitis",},
    {"database": "Walden (2022)","participantId": "PT083","sampleName": "PT083","sampleLink": "samples/PVQD-70dB/PT083_70dB.wav","age": "41","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT086","sampleName": "PT086","sampleLink": "samples/PVQD-70dB/PT086_70dB.wav","age": "65","gender": "female","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT097","sampleName": "PT097","sampleLink": "samples/PVQD-70dB/PT097_70dB.wav","age": "46","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT099","sampleName": "PT099","sampleLink": "samples/PVQD-70dB/PT099_70dB.wav","age": "15","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT100","sampleName": "PT100","sampleLink": "samples/PVQD-70dB/PT100_70dB.wav","age": "61","gender": "female","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT101","sampleName": "PT101","sampleLink": "samples/PVQD-70dB/PT101_70dB.wav","age": "14","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT103","sampleName": "PT103","sampleLink": "samples/PVQD-70dB/PT103_70dB.wav","age": "72","gender": "female","diagnose": "unilateral_vocal_fold_paralysis_atrophy",},
    {"database": "Walden (2022)","participantId": "PT110","sampleName": "PT110","sampleLink": "samples/PVQD-70dB/PT110_70dB.wav","age": "49","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT111","sampleName": "PT111","sampleLink": "samples/PVQD-70dB/PT111_70dB.wav","age": "39","gender": "female","diagnose": "goiter",},
    {"database": "Walden (2022)","participantId": "PT112","sampleName": "PT112","sampleLink": "samples/PVQD-70dB/PT112_70dB.wav","age": "66","gender": "female","diagnose": "tremor",},
    {"database": "Walden (2022)","participantId": "PT116","sampleName": "PT116","sampleLink": "samples/PVQD-70dB/PT116_70dB.wav","age": "81","gender": "female","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT117","sampleName": "PT117","sampleLink": "samples/PVQD-70dB/PT117_70dB.wav","age": "71","gender": "female","diagnose": "adductor_spasmodic_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT119","sampleName": "PT119","sampleLink": "samples/PVQD-70dB/PT119_70dB.wav","age": "71","gender": "female","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT122","sampleName": "PT122","sampleLink": "samples/PVQD-70dB/PT122_70dB.wav","age": "61","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT123","sampleName": "PT123","sampleLink": "samples/PVQD-70dB/PT123_70dB.wav","age": "59","gender": "female","diagnose": "chronic_cough",},
    {"database": "Walden (2022)","participantId": "PT124","sampleName": "PT124","sampleLink": "samples/PVQD-70dB/PT124_70dB.wav","age": "48","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT125","sampleName": "PT125","sampleLink": "samples/PVQD-70dB/PT125_70dB.wav","age": "63","gender": "female","diagnose": "adductor_spasmodic_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT126","sampleName": "PT126","sampleLink": "samples/PVQD-70dB/PT126_70dB.wav","age": "89","gender": "female","diagnose": "adductor_spasmodic_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT127","sampleName": "PT127","sampleLink": "samples/PVQD-70dB/PT127_70dB.wav","age": "74","gender": "female","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT130","sampleName": "PT130","sampleLink": "samples/PVQD-70dB/PT130_70dB.wav","age": "84","gender": "female","diagnose": "adductor_spasmodic_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT133","sampleName": "PT133","sampleLink": "samples/PVQD-70dB/PT133_70dB.wav","age": "59","gender": "female","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT135","sampleName": "PT135","sampleLink": "samples/PVQD-70dB/PT135_70dB.wav","age": "69","gender": "female","diagnose": "muscle_tension_dysphonia_atrophy",},
    {"database": "Walden (2022)","participantId": "NYU1003","sampleName": "NYU1003","sampleLink": "samples/PVQD-70dB/NYU1003_70dB.wav","age": "24","gender": "female","diagnose": "vocal_fold_scar",},
    {"database": "Walden (2022)","participantId": "NYU1005","sampleName": "NYU1005","sampleLink": "samples/PVQD-70dB/NYU1005_70dB.wav","age": "45","gender": "female","diagnose": "phonotraumatic_lesion",},
    {"database": "Walden (2022)","participantId": "NYU1007","sampleName": "NYU1007","sampleLink": "samples/PVQD-70dB/NYU1007_70dB.wav","age": "25","gender": "female","diagnose": "vocal_fold_scar",},
    {"database": "Walden (2022)","participantId": "NYU1008","sampleName": "NYU1008","sampleLink": "samples/PVQD-70dB/NYU1008_70dB.wav","age": "59","gender": "female","diagnose": "vocal_fold_edema",},
    {"database": "Walden (2022)","participantId": "NYU1009","sampleName": "NYU1009","sampleLink": "samples/PVQD-70dB/NYU1009_70dB.wav","age": "32","gender": "female","diagnose": "vocal_fold_polyp",},
    {"database": "Walden (2022)","participantId": "NYU1010","sampleName": "NYU1010","sampleLink": "samples/PVQD-70dB/NYU1010_70dB.wav","age": "56","gender": "female","diagnose": "laryngeal_stenosis",},
    {"database": "Walden (2022)","participantId": "NYU1011","sampleName": "NYU1011","sampleLink": "samples/PVQD-70dB/NYU1011_70dB.wav","age": "25","gender": "female","diagnose": "vocal_fold_nodules",},
    {"database": "Walden (2022)","participantId": "NYU1012","sampleName": "NYU1012","sampleLink": "samples/PVQD-70dB/NYU1012_70dB.wav","age": "25","gender": "female","diagnose": "phonotraumatic_lesion",},
    {"database": "Walden (2022)","participantId": "NYU1014","sampleName": "NYU1014","sampleLink": "samples/PVQD-70dB/NYU1014_70dB.wav","age": "51","gender": "female","diagnose": "vocal_fold_scar",},
    {"database": "Walden (2022)","participantId": "NYU1015","sampleName": "NYU1015","sampleLink": "samples/PVQD-70dB/NYU1015_70dB.wav","age": "52","gender": "female","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "NYU1016","sampleName": "NYU1016","sampleLink": "samples/PVQD-70dB/NYU1016_70dB.wav","age": "61","gender": "female","diagnose": "vocal_fold_polyp",},
    {"database": "Walden (2022)","participantId": "NYU1018","sampleName": "NYU1018","sampleLink": "samples/PVQD-70dB/NYU1018_70dB.wav","age": "80","gender": "female","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "NYU1019","sampleName": "NYU1019","sampleLink": "samples/PVQD-70dB/NYU1019_70dB.wav","age": "54","gender": "female","diagnose": "phonotrauma",},
    {"database": "Walden (2022)","participantId": "NYU1029","sampleName": "NYU1029","sampleLink": "samples/PVQD-70dB/NYU1029_70dB.wav","age": "55","gender": "female","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "NYU1030","sampleName": "NYU1030","sampleLink": "samples/PVQD-70dB/NYU1030_70dB.wav","age": "57","gender": "female","diagnose": "vocal_fold_polyp",},
    {"database": "Walden (2022)","participantId": "PT001","sampleName": "PT001","sampleLink": "samples/PVQD-70dB/PT001_70dB.wav","age": "61","gender": "male","diagnose": "reinke_edema",},
    {"database": "Walden (2022)","participantId": "PT002","sampleName": "PT002","sampleLink": "samples/PVQD-70dB/PT002_70dB.wav","age": "72","gender": "male","diagnose": "atrophy_hypomobility",},
    {"database": "Walden (2022)","participantId": "PT004","sampleName": "PT004","sampleLink": "samples/PVQD-70dB/PT004_70dB.wav","age": "74","gender": "male","diagnose": "ulcerative_laryngitis",},
    {"database": "Walden (2022)","participantId": "PT009","sampleName": "PT009","sampleLink": "samples/PVQD-70dB/PT009_70dB.wav","age": "49","gender": "male","diagnose": "laryngeal_stenosis_bilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT018","sampleName": "PT018","sampleLink": "samples/PVQD-70dB/PT018_70dB.wav","age": "30","gender": "male","diagnose": "bilateral_vocal_fold_paresis",},
    {"database": "Walden (2022)","participantId": "PT023","sampleName": "PT023","sampleLink": "samples/PVQD-70dB/PT023_70dB.wav","age": "70","gender": "male","diagnose": "parkinson_disease_atrophy",},
    {"database": "Walden (2022)","participantId": "PT024","sampleName": "PT024","sampleLink": "samples/PVQD-70dB/PT024_70dB.wav","age": "65","gender": "male","diagnose": "parkinson_disease",},
    {"database": "Walden (2022)","participantId": "PT028","sampleName": "PT028","sampleLink": "samples/PVQD-70dB/PT028_70dB.wav","age": "76","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT030","sampleName": "PT030","sampleLink": "samples/PVQD-70dB/PT030_70dB.wav","age": "42","gender": "male","diagnose": "leukoplakia",},
    {"database": "Walden (2022)","participantId": "PT031","sampleName": "PT031","sampleLink": "samples/PVQD-70dB/PT031_70dB.wav","age": "68","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT037","sampleName": "PT037","sampleLink": "samples/PVQD-70dB/PT037_70dB.wav","age": "79","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT039","sampleName": "PT039","sampleLink": "samples/PVQD-70dB/PT039_70dB.wav","age": "93","gender": "male","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT045","sampleName": "PT045","sampleLink": "samples/PVQD-70dB/PT045_70dB.wav","age": "56","gender": "male","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT048","sampleName": "PT048","sampleLink": "samples/PVQD-70dB/PT048_70dB.wav","age": "27","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT049","sampleName": "PT049","sampleLink": "samples/PVQD-70dB/PT049_70dB.wav","age": "84","gender": "male","diagnose": "glottic_cancer",},
    {"database": "Walden (2022)","participantId": "PT056","sampleName": "PT056","sampleLink": "samples/PVQD-70dB/PT056_70dB.wav","age": "68","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT060","sampleName": "PT060","sampleLink": "samples/PVQD-70dB/PT060_70dB.wav","age": "75","gender": "male","diagnose": "parkinson_disease",},
    {"database": "Walden (2022)","participantId": "PT061","sampleName": "PT061","sampleLink": "samples/PVQD-70dB/PT061_70dB.wav","age": "58","gender": "male","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT062","sampleName": "PT062","sampleLink": "samples/PVQD-70dB/PT062_70dB.wav","age": "65","gender": "male","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT063","sampleName": "PT063","sampleLink": "samples/PVQD-70dB/PT063_70dB.wav","age": "55","gender": "male","diagnose": "leukoplakia",},
    {"database": "Walden (2022)","participantId": "PT064","sampleName": "PT064","sampleLink": "samples/PVQD-70dB/PT064_70dB.wav","age": "56","gender": "male","diagnose": "laryngeal_granuloma",},
    {"database": "Walden (2022)","participantId": "PT065","sampleName": "PT065","sampleLink": "samples/PVQD-70dB/PT065_70dB.wav","age": "57","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT068","sampleName": "PT068","sampleLink": "samples/PVQD-70dB/PT068_70dB.wav","age": "37","gender": "male","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT069","sampleName": "PT069","sampleLink": "samples/PVQD-70dB/PT069_70dB.wav","age": "25","gender": "male","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT080","sampleName": "PT080","sampleLink": "samples/PVQD-70dB/PT080_70dB.wav","age": "62","gender": "male","diagnose": "reinke_edema",},
    {"database": "Walden (2022)","participantId": "PT081","sampleName": "PT081","sampleLink": "samples/PVQD-70dB/PT081_70dB.wav","age": "74","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT084","sampleName": "PT084","sampleLink": "samples/PVQD-70dB/PT084_70dB.wav","age": "19","gender": "male","diagnose": "bilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT085","sampleName": "PT085","sampleLink": "samples/PVQD-70dB/PT085_70dB.wav","age": "29","gender": "male","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT087","sampleName": "PT087","sampleLink": "samples/PVQD-70dB/PT087_70dB.wav","age": "83","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT088","sampleName": "PT088","sampleLink": "samples/PVQD-70dB/PT088_70dB.wav","age": "73","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT089","sampleName": "PT089","sampleLink": "samples/PVQD-70dB/PT089_70dB.wav","age": "71","gender": "male","diagnose": "parkinson_disease_unliateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT090","sampleName": "PT090","sampleLink": "samples/PVQD-70dB/PT090_70dB.wav","age": "26","gender": "male","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT092","sampleName": "PT092","sampleLink": "samples/PVQD-70dB/PT092_70dB.wav","age": "74","gender": "male","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT093","sampleName": "PT093","sampleLink": "samples/PVQD-70dB/PT093_70dB.wav","age": "63","gender": "male","diagnose": "muscle_tension_dysphonia_atrophy",},
    {"database": "Walden (2022)","participantId": "PT094","sampleName": "PT094","sampleLink": "samples/PVQD-70dB/PT094_70dB.wav","age": "59","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT096","sampleName": "PT096","sampleLink": "samples/PVQD-70dB/PT096_70dB.wav","age": "45","gender": "male","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT098","sampleName": "PT098","sampleLink": "samples/PVQD-70dB/PT098_70dB.wav","age": "48","gender": "male","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT102","sampleName": "PT102","sampleLink": "samples/PVQD-70dB/PT102_70dB.wav","age": "19","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT104","sampleName": "PT104","sampleLink": "samples/PVQD-70dB/PT104_70dB.wav","age": "77","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT106","sampleName": "PT106","sampleLink": "samples/PVQD-70dB/PT106_70dB.wav","age": "75","gender": "male","diagnose": "unilateral_vocal_fold_paralysis_atrophy",},
    {"database": "Walden (2022)","participantId": "PT107","sampleName": "PT107","sampleLink": "samples/PVQD-70dB/PT107_70dB.wav","age": "73","gender": "male","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT108","sampleName": "PT108","sampleLink": "samples/PVQD-70dB/PT108_70dB.wav","age": "87","gender": "male","diagnose": "glottic_cancer",},
    {"database": "Walden (2022)","participantId": "PT113","sampleName": "PT113","sampleLink": "samples/PVQD-70dB/PT113_70dB.wav","age": "62","gender": "male","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT118","sampleName": "PT118","sampleLink": "samples/PVQD-70dB/PT118_70dB.wav","age": "58","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT121","sampleName": "PT121","sampleLink": "samples/PVQD-70dB/PT121_70dB.wav","age": "75","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT128","sampleName": "PT128","sampleLink": "samples/PVQD-70dB/PT128_70dB.wav","age": "35","gender": "male","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT129","sampleName": "PT129","sampleLink": "samples/PVQD-70dB/PT129_70dB.wav","age": "70","gender": "male","diagnose": "atrophy_muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT132","sampleName": "PT132","sampleLink": "samples/PVQD-70dB/PT132_70dB.wav","age": "72","gender": "male","diagnose": "atrophy",},
    {"database": "Walden (2022)","participantId": "PT134","sampleName": "PT134","sampleLink": "samples/PVQD-70dB/PT134_70dB.wav","age": "19","gender": "male","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "PT136","sampleName": "PT136","sampleLink": "samples/PVQD-70dB/PT136_70dB.wav","age": "65","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "PT137","sampleName": "PT137","sampleLink": "samples/PVQD-70dB/PT137_70dB.wav","age": "27","gender": "male","diagnose": "muscle_tension_dysphonia",},
    {"database": "Walden (2022)","participantId": "NYU1001","sampleName": "NYU1001","sampleLink": "samples/PVQD-70dB/NYU1001_70dB.wav","age": "23","gender": "male","diagnose": "vocal_fold_cyst",},
    {"database": "Walden (2022)","participantId": "NYU1002","sampleName": "NYU1002","sampleLink": "samples/PVQD-70dB/NYU1002_70dB.wav","age": "86","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "NYU1004","sampleName": "NYU1004","sampleLink": "samples/PVQD-70dB/NYU1004_70dB.wav","age": "55","gender": "male","diagnose": "bilareral_phonotraumatic_lesion",},
    {"database": "Walden (2022)","participantId": "NYU1006","sampleName": "NYU1006","sampleLink": "samples/PVQD-70dB/NYU1006_70dB.wav","age": "60","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "NYU1017","sampleName": "NYU1017","sampleLink": "samples/PVQD-70dB/NYU1017_70dB.wav","age": "68","gender": "male","diagnose": "tremor",},
    {"database": "Walden (2022)","participantId": "NYU1020","sampleName": "NYU1020","sampleLink": "samples/PVQD-70dB/NYU1020_70dB.wav","age": "19","gender": "male","diagnose": "vocal_fold_edema",},
    {"database": "Walden (2022)","participantId": "NYU1022","sampleName": "NYU1022","sampleLink": "samples/PVQD-70dB/NYU1022_70dB.wav","age": "57","gender": "male","diagnose": "unilateral_vocal_fold_paresis",},
    {"database": "Walden (2022)","participantId": "NYU1023","sampleName": "NYU1023","sampleLink": "samples/PVQD-70dB/NYU1023_70dB.wav","age": "28","gender": "male","diagnose": "vocal_fold_polyp",},
    {"database": "Walden (2022)","participantId": "NYU1025","sampleName": "NYU1025","sampleLink": "samples/PVQD-70dB/NYU1025_70dB.wav","age": "56","gender": "male","diagnose": "unilateral_vocal_fold_paralysis",},
    {"database": "Walden (2022)","participantId": "NYU1027","sampleName": "NYU1027","sampleLink": "samples/PVQD-70dB/NYU1027_70dB.wav","age": "37","gender": "male","diagnose": "unilateral_vocal_fold_paresis",},
    {"database": "Walden (2022)","participantId": "NYU1028","sampleName": "NYU1028","sampleLink": "samples/PVQD-70dB/NYU1028_70dB.wav","age": "38","gender": "male","diagnose": "vocal_fold_scar",},
];

export default pathologyDataOriginalPVQD;