// DataVisualizationControls.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

const DataVisualizationControls = ({
  showStudentRatings,
  showConsensusData,
  showSharedEvaluations,
  showPVQDEvaluations,
  showSociodemographicData,
  showStandardAttributes,
  showAdditionalAttributes,
  onRefresh,
  onToggleStudentRatings,
  onToggleConsensusData,
  onToggleSharedEvaluations,
  onTogglePVQDEvaluations,
  onToggleSociodemographicData,
  onToggleStandardAttributes,
  onToggleAdditionalAttributes,
  canShowConsensusData,
  canShowSharedEvaluations,
  canShowPVQDEvaluations,
  onShowConsensusForm
}) => {
  const { t } = useTranslation();
  const [consensusReached, setConsensusReached] = useState(false);

  useEffect(() => {
    if (canShowConsensusData) {
      setConsensusReached(true);
    }
  }, [canShowConsensusData]);

  const handleConsensusClick = (e) => {
    e.preventDefault();
    if (!consensusReached) {
      onShowConsensusForm();
      setConsensusReached(true);
    }
  };

  const handleToggleConsensusData = () => {
    if (canShowConsensusData) {
      onToggleConsensusData(!showConsensusData);
    }
  };

  return (
    <div>
      <div className="button-container">
        <button onClick={onRefresh} className="button-center">
          {t('refresh_session_data')}
        </button>
        <Trans i18nKey="visualization_guide"></Trans>
      </div>

      <div className="data-visualization-controls">
        <div className="controls-container">
          {/* Moure la secció de Visualització a la part superior */}
          <div className="visualization-toggles-section">
            <h3>{t('visualization_toggles')}</h3>
            <div className="checkbox-container">
              <div>
                <input
                  type="checkbox"
                  checked={showSociodemographicData}
                  onChange={onToggleSociodemographicData}
                />
                <label>{t('show_sociodemographic_data')}</label>
              </div>

              <div>
                <input
                  type="checkbox"
                  checked={showStandardAttributes}
                  onChange={onToggleStandardAttributes}
                />
                <label>{t('show_standard_attributes_evaluations')}</label>
              </div>

              <div>
                <input
                  type="checkbox"
                  checked={showAdditionalAttributes}
                  onChange={onToggleAdditionalAttributes}
                />
                <label>{t('show_additional_attributes_evaluations')}</label>
              </div>
            </div>
          </div>

          {/* Moure la secció de Flux de Dades a sota */}
          <div className="data-flow-section">
            <h3>{t('data_flow')}</h3>
            <div className="checkbox-container">
              <div>
                <input
                  type="checkbox"
                  checked={showStudentRatings}
                  onChange={onToggleStudentRatings}
                />
                <label>{t('show_evaluations_from_students_in_this_session')}</label>
              </div>

              <div>
                <input
                  type="checkbox"
                  checked={consensusReached}
                  onChange={handleConsensusClick}
                  disabled={consensusReached}
                />
                <label>{t('reach_consensus')}</label>
              </div>

              <div>
                <input
                  type="checkbox"
                  checked={showConsensusData}
                  onChange={handleToggleConsensusData}
                  disabled={!canShowConsensusData}
                />
                <label>{t('show_consensus_reached_in_this_session')}</label>
              </div>

              <div>
                <input
                  type="checkbox"
                  checked={showSharedEvaluations}
                  onChange={onToggleSharedEvaluations}
                  disabled={!canShowSharedEvaluations}
                />
                <label>{t('show_other_allvoiced_users_evaluations')}. {t('please_note_consensus_also_shared')}</label>
              </div>

              <div>
                <input
                  type="checkbox"
                  checked={showPVQDEvaluations}
                  onChange={onTogglePVQDEvaluations}
                  disabled={!canShowPVQDEvaluations}
                />
                <label>{t('show_evaluations_from_pvqd_walden_2022')}</label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default DataVisualizationControls;