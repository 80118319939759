// AdditionalResonances.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function AdditionalResonances({ value, onChange }) {
  const { t } = useTranslation();
  const [resonances, setResonances] = useState(value.resonances || {});
  const [additionalResonance, setAdditionalResonance] = useState(value.text || '');

  useEffect(() => {
    setResonances(value.resonances || {});
    setAdditionalResonance(value.text || '');
  }, [value]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const newResonances = { ...resonances };
    if (checked) {
      newResonances[name] = newResonances[name] || { value: 0, variability: 'consistent' };
    } else {
      delete newResonances[name];
    }
    setResonances(newResonances);
    onChange({ resonances: newResonances, text: additionalResonance });
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setAdditionalResonance(newText);
    onChange({ resonances: { ...resonances, other: { ...resonances.other, label: newText } }, text: newText });
  };

  const normalResonance = 'normal_resonance';
  const hypernasality = 'hypernasality';
  const hyponasality = 'hyponasality';
  const otherResonance = 'other';

  return (
    <div className="button-container">
      <label className="centered-text"><strong>{t('comments_resonance')}</strong></label>
      <div>
        {[normalResonance, hypernasality, hyponasality, otherResonance].map((resonance) => (
          <label key={resonance} className="horizontal-labels">
            <input
              type="checkbox"
              name={resonance}
              checked={resonances.hasOwnProperty(resonance)}
              onChange={handleCheckboxChange}
            />
            {t(resonance)}
          </label>
        ))}
      </div>
      {resonances.hasOwnProperty('other') && (
        <textarea
          value={additionalResonance}
          onChange={handleTextChange}
          rows="4"
          cols="50"
          placeholder={t('specify')}
        />
      )}
    </div>
  );
}

export default AdditionalResonances;