// VASFeedbackGraph.jsx

import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

const VASFeedbackGraph = ({ attributeData, attributeName }) => {
    const { t } = useTranslation();
    const d3Container = useRef(null);

    useEffect(() => {
        if (!attributeData) return;

        const { pastRatings = [], userRating, sharedRatings = [] } = attributeData;
        const flattenedPastRatings = pastRatings.flat().filter(rating => rating !== undefined);
        const flattenedSharedRatings = sharedRatings.flat().filter(rating => rating !== undefined);

        const margin = { top: 20, right: 100, bottom: 40, left: 40 }; // Augmenta el marge dret
        let width = 960 - margin.left - margin.right;
        let height = 150 - margin.top - margin.bottom;

        const svg = d3.select(d3Container.current)
            .attr('width', "100%")
            .attr('height', "100%")
            .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .style('overflow', 'visible');

        svg.selectAll("*").remove(); // Neteja per a re-dibuixar

        const chart = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const xScale = d3.scaleLinear()
            .domain([0, 100])
            .range([0, width]);

        chart.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(d3.axisBottom(xScale));

        // Funció per generar una desviació aleatòria
        const getRandomOffset = (range) => (Math.random() - 0.5) * range;

        // Dibuixa les valoracions anteriors
        flattenedPastRatings.forEach(rating => {
            chart.append("circle")
                .attr("cx", xScale(rating))
                .attr("cy", height / 1.2 + getRandomOffset(10)) // Desviació aleatòria
                .attr("r", 5)
                .style("fill", "#a5d3ce");
        });

        // Dibuixa les valoracions compartides
        flattenedSharedRatings.forEach(rating => {
            chart.append("circle")
                .attr("cx", xScale(rating))
                .attr("cy", height / 1.2 + getRandomOffset(10)) // Desviació aleatòria
                .attr("r", 5)
                .style("fill", "#209385");
        });

        // Dibuixa la valoració de l'usuari
        if (userRating !== undefined) {
            chart.append("circle")
                .attr("cx", xScale(userRating))
                .attr("cy", height / 1.2 + getRandomOffset(10)) // Desviació aleatòria
                .attr("r", 5)
                .style("fill", "#0b5345");
        }

        const legends = [
            { color: "#0b5345", text: t('your_evaluations') },
            { color: "#209385", text: t('other_allvoiced_users_evaluations') },
            { color: "#a5d3ce", text: t('evaluations_from_pvqd_walden_2022') },
        ];

        // Mou la llegenda fora del gràfic, a la dreta
        const legendGroup = svg.append('g')
            .attr('transform', `translate(${width + 60}, ${margin.top})`);  // Mou la llegenda fora de la zona del gràfic

        const legend = legendGroup.selectAll(".legend")
            .data(legends)
            .enter().append("g")
            .attr("class", "legend")
            .attr("transform", (_, i) => `translate(0, ${i * 20})`);

        legend.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", d => d.color);

        legend.append("text")
            .attr("x", 24)
            .attr("y", 14)
            .style("text-anchor", "start")
            .style("font-size", "11px")
            .text(d => d.text);

    }, [attributeData, attributeName, t]);

    return (
        <div>
            <h3>{attributeName}</h3>
            <svg ref={d3Container}></svg>
        </div>
    );
};

export default VASFeedbackGraph;