// CAPEVWorkshop.jsx

import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './../locales/i18n';
import { useAuth } from './../auth-and-backend/auth/authContext';
import LoginModal from './../auth-and-backend/modals/LoginModal';
import SignUpModal from './../auth-and-backend/modals/SignUpModal';
import NavigationIcons from './../views/app-views/NavigationIcons';

function CAPEVWorkshop() {
    const { t } = useTranslation();
    const { user } = useAuth(); 
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
    const navigate = useNavigate();

    function handleRestrictedButtonClick(targetRoute) {
        if (!user) {
            setIsLoginModalOpen(true);
        } else {
            navigate(targetRoute); 
            console.log("User is logged in, navigate to the restricted area");
        }
    }    

    const renderLockIcon = (isRestricted) => {
        return isRestricted ? (
            user ? (
                <i className="fas fa-lock-open" style={{ color: '#a5d3ce' }}></i>
            ) : (
                <i className="fas fa-lock" style={{ color: 'red' }}></i>
            )
        ) : null;
    };

    return (
        <div className="main-content">
            <h1>{t('cape_v_workshop_title')}</h1>
            <p>
                <Trans i18nKey="cape_v_workshop_presentation" />
            </p>

            {/* CAPE-V Workshop (Instructor Access) */}
            <button 
                className="button-link workshop-button restricted-link" 
                onClick={() => handleRestrictedButtonClick('/cape-v-workshop-instructor')}
            >
                {t('instructor_access')}
                <span className="restricted-marker">{renderLockIcon(true)}</span>
            </button><br/>

            {/* CAPE-V Workshop  (Student Access)*/}

            <button 
                className="button-link workshop-button" 
                onClick={() => window.location.href = '/cape-v-workshop-student'}
            >
                {t('student_access')}
            </button>
            <div className="navigation-container">
                <NavigationIcons />
            </div>
            <LoginModal
                isOpen={isLoginModalOpen}
                onRequestClose={() => setIsLoginModalOpen(false)}
                openSignUpModal={() => { setIsLoginModalOpen(false); setIsSignUpModalOpen(true); }}
            />
            <SignUpModal
                isOpen={isSignUpModalOpen}
                onRequestClose={() => setIsSignUpModalOpen(false)}
                openLoginModal={() => { setIsSignUpModalOpen(false); setIsLoginModalOpen(true); }}
            />
        </div>
    );    
}

export default CAPEVWorkshop;
