// AudioUploader.jsx

import React, { useState, useRef, useEffect } from 'react';

const AudioUploader = ({ onFileUpload }) => {
  const [uploadedAudioUrl, setUploadedAudioUrl] = useState(null);
  const audioRef = useRef(null);

  const handleAudioUpload = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type.startsWith('audio/')) {
        const audioUrl = URL.createObjectURL(file);
        setUploadedAudioUrl(audioUrl);
        onFileUpload(file); 
      } else {
        console.error("File is not an audio type:", file.type);
      }
    } else {
      console.error("No file selected");
    }
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    return () => {
      if (audioElement) {
        audioElement.pause();
        audioElement.src = '';
      }
    };
  }, [uploadedAudioUrl]);

  return (
    <div>
      <input className="button" type="file" accept="audio/*" onChange={handleAudioUpload} />
      {uploadedAudioUrl && (
        <div>
          <audio ref={audioRef} controls>
            <source src={uploadedAudioUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  );
};

export default AudioUploader;