// UserProfile.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './authContext';
import { useTranslation, Trans } from 'react-i18next';
import countryOptions from './../auth/countryOptions';
import languageOptions from './../auth/languageOptions'; 
import { useNavigate } from 'react-router-dom';
import NavigationIcons from './../../views/app-views/NavigationIcons';

const UserProfile = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    dateOfBirth: '',
    pronoun: '',
    countryOfResidence: '',
    communicationLanguage: '',
    profession: '',
    voiceCompetenceLevel: '',
    wantsToShareEvaluations: false,
    receiveInformation: false,
    acceptResearchUsage: false,
    createdAt: ''
  });

  const [userAge, setUserAge] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Estat per mostrar la modal de confirmació d'eliminació

  // Estats per a l'històric d'avaluacions
  const [evaluationHistory, setEvaluationHistory] = useState({
    researchEvaluationsCount: 0,
    sharedEvaluationsCount: 0
  });

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

  useEffect(() => {
    if (auth.user) {
        const token = auth.user.token;
        if (token) {
            axios.get(`${apiUrl}/users/me`, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                const userData = response.data;
                
                // Convertir la data de naixement al format yyyy-MM-dd
                if (userData.dateOfBirth) {
                    userData.dateOfBirth = new Date(userData.dateOfBirth).toISOString().split('T')[0];
                }

                // Calcular l'antiguitat com a usuari
                if (userData.createdAt) {
                  const registrationDate = new Date(userData.createdAt);
                  const currentDate = new Date();
                  const diffTime = Math.abs(currentDate - registrationDate);
                  const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
                  const diffMonths = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
                  const diffDays = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

                  setUserAge(`${diffYears} years, ${diffMonths} months, ${diffDays} days`);
                }

                setFormData({
                    username: userData.username || '',
                    email: userData.email || '',
                    dateOfBirth: userData.dateOfBirth || '',
                    pronoun: userData.pronoun || '',
                    countryOfResidence: userData.countryOfResidence || '',
                    communicationLanguage: userData.communicationLanguage || '',
                    profession: userData.profession || '',
                    voiceCompetenceLevel: userData.voiceCompetenceLevel || '',
                    wantsToShareEvaluations: userData.wantsToShareEvaluations || false,
                    receiveInformation: userData.receiveInformation || false,
                    acceptResearchUsage: userData.acceptResearchUsage || false,
                    createdAt: userData.createdAt || '',
                });
                 // Obtenir l'històric d'avaluacions
                 axios.get(`${apiUrl}/evaluation-history/${userData._id}`)
                 .then(res => setEvaluationHistory(res.data))
                 .catch(err => console.error('Error fetching evaluation history:', err));
           })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
        }
    }
}, [auth.user, apiUrl]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.put(`${apiUrl}/users/me`, formData, {
      headers: { Authorization: `Bearer ${auth.user.token}` }
    })
    .then(response => {
      alert(t('profile_updated'));
    })
    .catch(error => {
      console.error('Error updating profile:', error);
    });
  };

  const handleDeleteAccount = () => {
    // Eliminar el compte
    axios.delete(`${apiUrl}/users/me`, {
      headers: { Authorization: `Bearer ${auth.user.token}` }
    })
    .then(response => {
      alert(t('account_deleted'));
      auth.logout(); 
      navigate('/');
    })
    .catch(error => {
      console.error('Error deleting account:', error);
    });
  };

  return (
    <div className="user-profile">
      <h2>{t('my_profile')}</h2>
      <p>{t('registration_date')}: {new Date(formData.createdAt).toLocaleDateString()}</p>
      <p>{t('account_age')}: {userAge}</p>
      <p>{t('data_contributions_for_research')}: {evaluationHistory.researchEvaluationsCount}</p>
      {/* Mostrar només si l'usuari té un nivell de competència avançat o expert */}
      {(formData.voiceCompetenceLevel === 'advanced' || formData.voiceCompetenceLevel === 'expert') && (
        <p>{t('assessments_shared_with_the_community')}: {evaluationHistory.sharedEvaluationsCount}</p>
      )}
      <br/>
      <form onSubmit={handleSubmit} className="register-form">
        {t('username')}
        <input type="text" name="username" value={formData.username} onChange={handleChange} placeholder={t('username')} className="input-field" required />
        {t('email')}
        <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder={t('email')} className="input-field" required />
        {t('date_of_birth')}
        <input type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} placeholder={t('date_of_birth')} className="input-field" />
        {t('pronoun')}
        <select name="pronoun" value={formData.pronoun} onChange={handleChange} className="input-field">
            <option value="">{t('select_pronoun')}</option>
            <option value="she/her">She/Her</option>
            <option value="he/him">He/Him</option>
            <option value="they/them">They/Them</option>
            <option value="she/they">She/They</option>
            <option value="he/they">He/They</option>
            <option value="other">Other</option>
        </select>
        {t('country_of_residence')}
        <select name="countryOfResidence" value={formData.countryOfResidence} onChange={handleChange} className="input-field">
          <option value="">{t('select_country_of_residence')}</option>
          {countryOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {t('primary_communication_language')}
        <select name="communicationLanguage" value={formData.communicationLanguage} onChange={handleChange} className="input-field">
          <option value="">{t('select_communication_language')}</option>
          {languageOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {t('profession')}
        <select name="profession" value={formData.profession} onChange={handleChange} className="input-field">
          <option value="">{t('select_profession')}</option>
          <option value="SLP">{t('slp')}</option>
          <option value="ENT">{t('ent')}</option>
          <option value="vocal_coach">{t('vocal_coach')}</option>
          <option value="singing_teacher">{t('singing_teacher')}</option>
          <option value="other">{t('other')}</option>
        </select>
        {t('voice_competence_level')}
        <select name="voiceCompetenceLevel" value={formData.voiceCompetenceLevel} onChange={handleChange} className="input-field">
          <option value="">{t('select_voice_competence_level')}</option>
          <option value="beginner">{t('beginner')}</option>
          <option value="intermediate">{t('intermediate')}</option>
          <option value="advanced">{t('advanced')}</option>
          <option value="expert">{t('expert')}</option>
        </select>

        {(formData.voiceCompetenceLevel === 'advanced' || formData.voiceCompetenceLevel === 'expert') && (
          <div className="checkbox-container">
            <label>
              <input
                type="checkbox"
                name="wantsToShareEvaluations"
                checked={formData.wantsToShareEvaluations}
                onChange={handleChange}
              />
              {t('sharing_consent')}
            </label>
          </div>
        )}

        <div className="checkbox-container">
          <label>
            <input
              type="checkbox"
              name="receiveInformation"
              checked={formData.receiveInformation}
              onChange={handleChange}
            />
            {t('accept_receive_info')}
          </label>
        </div>

        <div className="checkbox-container">
          <label>
            <input
              type="checkbox"
              name="acceptResearchUsage"
              checked={formData.acceptResearchUsage}
              onChange={handleChange}
            />
            <Trans
              i18nKey="accept_research_usage"
              components={{
                a1: <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Política de Privacitat</a>,
                a2: <a href="/global-database-project-information-participants" target="_blank" rel="noopener noreferrer">Informació a les persones participants</a>,
                a3: <a href="/global-database-project-informed-consent" target="_blank" rel="noopener noreferrer">Consentiment informat</a>,
              }}
            />
          </label>
        </div>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img 
              src="/images/global-database-icon.png" 
              alt="Global Database Icon" 
              className="centered-icon" 
              style={{ maxWidth: '100px', height: 'auto' }}
              />
          </div>
        <button type="submit" className="button-center">{t('update_profile')}</button>
      </form>
      <br/>
      {/* Botó per eliminar el compte */}
      <div className="button-container">
        <button
          type="button"
          className="button-important"
          onClick={() => setShowDeleteModal(true)}
        >
          {t('delete_account')}
        </button>
      </div>

      <div className="navigation-container">
                <NavigationIcons />
      </div>

      {/* Modal de confirmació per eliminar el compte */}
      {showDeleteModal && (
        <div className="delete-modal">
          <p>{t('confirm_delete')}</p>
          <div className="button-container">
            <button
              type="button"
              className="button-important"
              onClick={handleDeleteAccount}
            >
              {t('confirm')}
            </button>
            <button
              type="button"
              className="button-center"
              onClick={() => setShowDeleteModal(false)}
            >
              {t('cancel')}
            </button>
          </div>
        </div>
      )}
      <br/>
    </div>
  );
};

export default UserProfile;