// App.jsx

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { AuthProvider } from './auth-and-backend/auth/authContext';
import ScrollToTop from './ScrollToTop';
import AppRoutes from './AppRoutes';
import './styles/app.css';

const RouteTracker = ({ isDevelopment }) => {
  const location = useLocation();

  useEffect(() => {
    if (!isDevelopment) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    } else {
      console.log('No tracking in development mode.');
    }
  }, [location, isDevelopment]);

  return null;
};

function App({ isDevelopment }) {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    if (!isDevelopment) {
      // Inicialitzar Google Analytics només si no estàs en desenvolupament
      ReactGA.initialize('G-TH1DDX5LF7');
    }
  }, [i18n, isDevelopment]);

  // Comprova si l'idioma actual és RTL (com el persa)
  const isRTL = i18n.language === 'fa'; // 'fa' és el codi per al persa

  return (
    <AuthProvider>
      <div className={isRTL ? 'rtl' : 'ltr'}>
        <ScrollToTop>
          <RouteTracker isDevelopment={isDevelopment} />
          <AppRoutes />
        </ScrollToTop>
      </div>
    </AuthProvider>
  );
}

export default App;